.modal-button-container {
  display:flex;
  flex-direction: row;
  justify-content: center;
}

.modal-button {
  width: 142px;
  height: 37px;
  margin-top: 20px;
  margin-bottom: 20px;
}
