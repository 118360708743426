.planet-exploration-modal {
  position:fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #000;
  z-index: 10;
}

.modal-content {
  z-index: 11;
}

.modal-dialog.planet-exploration-modal {
  margin-top: 0 !important;
}

.rotate-device-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #000;
  z-index: 9999;
  display: none;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2em;
  text-align: center;
}

@media screen and (orientation:landscape) {
  .rotate-device-overlay {
    display: flex;
  }
}
