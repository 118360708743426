@import "../../../variables";

.liftoff-carousel-main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.liftoff-carousel-title {
  background: linear-gradient(180deg, #f7fafb 0%, #c8d8df 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 82px;
  font-family: $oswald;
  font-weight: 700;
  line-height: 90px;
  margin-bottom: -25px;
}

.liftoff-carousel-second-title {
  background: linear-gradient(180deg, #c1d2da 0%, #94b0be 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 82px;
  font-family: $oswald;
  font-weight: 700;

}

.liftoff-content-text {
  background: linear-gradient(180deg, #618191 0%, #426170 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 82px;
  font-family: $oswald;
  font-weight: 700;
  margin-top: 35px;
}

.liftoff-main-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
}

.liftoff-button-content {
  width: 117px;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .liftoff-carousel-title {
    font-size: 6.738vw;
    line-height: 8.789vw;
    margin-bottom: -2.441vw;
  }
  .liftoff-carousel-second-title {
    font-size: 6.738vw;
  }
  .liftoff-content-text {
    font-size: 6.738vw;
    line-height: 6.836vw;
    margin-top: 3.418vw;
  }
  .liftoff-button-content {
    width: 9.863vw;
  }
}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .liftoff-carousel-title {
    font-size: 8.984vw;
    line-height: 11.719vw;
    margin-bottom: -3.255vw;
  }
  .liftoff-carousel-second-title {
    font-size: 8.984vw;
  }
  .liftoff-content-text {
    font-size: 8.984vw;
    line-height: 9.115vw;
    margin-top: 4.557vw;
  }
  .liftoff-button-content {
    width: 13.151vw;
  }
  .liftoff-main-button-container {
    margin-top: 5.208vw;
  }
}


@media screen and (max-width: 375px) {
  .header-title-liftoff-carousel{
    margin-top: 17.333vw;
  }
  .liftoff-carousel-main-container{
    margin-top: 5.333vw;
  }
  .liftoff-carousel-title {
    font-size: 12.800vw;
    padding-right: 10.667vw;
    padding-left: 10.667vw;
    line-height: 13.333vw;
    margin-bottom: 0;
  }
  .liftoff-carousel-second-title{
    font-size: 12.800vw;
    line-height: 13.333vw;
  }
  .liftoff-content-text{
    font-size: 12.800vw;
    padding-right: 2.333vw;
    padding-left: 2.333vw;
    line-height: 13.333vw;
    margin-top: 9.333vw;
  }
  .liftoff-main-button-container{
    margin-top: 19.733vw;
  }
  .liftoff-button-content {
    width:20vw;
  }
}
