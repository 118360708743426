@import '../../../variables';

.top-menu-dropdown-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 17px;
  position: relative;
}

.top-menu-dropdown-underline {
  width: 0;
  height: 3px;
  background-color: $pink;
  transition: width 0.3s ease-in-out;
}

.top-menu-dropdown-container:hover .top-menu-dropdown-underline {
  width: 100%;
}

.top-menu-dropdown-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.top-menu-button-dropdown {
  font-family: $oswald;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: $white;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 72px;
}

.top-menu-dropdown-arrow {
  width: 9px;
  height: 9px;
  margin-right: 5px;
  transform: rotate(-90deg);
  transition: all 0.3s ease-in-out;
}

.top-menu-dropdown-container:hover .top-menu-dropdown-arrow {
  transform: rotate(0deg);
}

.top-menu-dropdown-list {
  display: none;
  transition: all 0.3s 2s;
}

.top-menu-button-dropdown:hover + .top-menu-dropdown-list,
.top-menu-dropdown-list:hover {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  top: 60px;
  left: -1px;
  z-index: 11;
}

.top-menu-dropdown-list-item {
  font-family: $oswald;
  font-size: 16px;
  line-height: 30px;
  text-align: left;
  color: $white;
  cursor: pointer;
  width: 160px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  margin-left: 5px;
}

.top-menu-dropdown-list-item.COMMUNITY {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  width: 130px;
  padding-left: 16px;
  .top-menu-dropdown-list-text {
    margin: 0;
  }
}

.top-menu-dropdown-list-item.COMMUNITY:hover .top-menu-dropdown-list-text{
  filter: invert(41%) sepia(87%) saturate(6173%) hue-rotate(328deg) brightness(86%) contrast(93%);
}

.top-menu-dropdown-list-item.PORTFOLIO {
  text-align: left;
  width: 238px;
  height: 100%;

  .top-menu-dropdown-list-text {
    margin-left: 13px;
  }
}

.top-menu-dropdown-list-item.PORTFOLIO.child {
  padding-left: 15px;
}

.top-menu-dropdown-list-item.PORTFOLIO:hover .top-menu-dropdown-list-text {
  margin-left: 9px;
  color: $pink;
}

.top-menu-dropdown-list-item.EVENTS {
  text-align: left;
  width: 160px;
  padding-left: 16px;

  .top-menu-dropdown-list-text {
    margin-left: -5px;
  }
}

.top-menu-dropdown-list-item.EVENTS:hover .top-menu-dropdown-list-text {
  margin-left: -8px;
  color: $pink;
}

.top-menu-dropdown-list-item.COMMUNITY:hover .top-menu-dropdown-list-text {
  margin-left: -4px;
}

.top-menu-dropdown-list-item:hover {
  border-left: 4px solid $pink;
  background-color: $header-grey;

  margin-left: 5px;
}

.top-menu-dropdown-list-item:hover .top-menu-dropdown-list-text {
  margin-left: 6px;
}

.top-menu-dropdown-list-text {
  margin-left: 10px;
}

.top-menu-dropdown-lists-icon-text {
  margin-left: 10px;
}
