@import "../../../variables";

.planets-panels-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.planets-panels {
  scrollbar-width: thin;
}

.planets-panels::-webkit-scrollbar {
  width: 6px;
}

.planets-panels::-webkit-scrollbar-track {
  background: $scroll-bar-dark-gray;
  border-radius: 20px;
}

.planets-panels::-webkit-scrollbar-thumb {
  width: 6px;
  border-radius: 20px;
  background-color: $thumb-bar-dark-gray;
}

.planets-panels::-webkit-scrollbar-thumb:hover {
  background: $thumb-bar-hover-gray;
}

.total-planets-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.total-planets-text {
  font-family: $oswald;
  font-size: 1.75rem;
  text-align: center;
  margin-bottom: 60px;
  @media screen and (min-width: 376px) and (max-width: 768px) {
    font-size: 1.5rem;
    margin-bottom: 45px;
  }
  @media screen and (max-device-width: 375px) {
    font-size: 1.25rem;
    margin-bottom: 60px;
  }
}

.planets-panels {
  margin-top: 50px;
  overflow-y: auto;
  max-height: 2565px;
  overflow-x: hidden;
  @media screen and (max-device-width: 375px) {
    height: unset;
    max-height: unset;
    width: 100%;
    overflow-y: hidden;
  }
}



