.pgs-circle-container {
  position: relative;
  width: 100%;
  z-index: 11;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top:-25px;
}
.psg-border-img {
  width: 1464px;
  display: flex;
  z-index: 1;
}
.psg-vorder-titel {
  font-family: Oswald;
  font-size: 45px;
  position: absolute;
  z-index: 1;
}

.ticket-container{
  position: absolute;
  clip-path: polygon(82% 0, 100% 18%, 100% 100%, 0 100%, 0 0);
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 20%;
  width: 300px;
  height: 250px;
}

.ticket-container-inner-container{
  position: relative;
  height: 100%;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ticket-img{
  width: 60%;
}

.ticket-owned-container{
  width: 55%;
  margin-top: -36px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}

.ticket-owned-inner-container{
  height: 30px;
  clip-path: polygon(15% 1%, 85% 0, 100% 35%, 100% 100%, 0 100%, 0 35%);
  background: rgba(0, 0, 0, 0.5);
}

.ticket-owned-text{
  margin: 0;
  text-align: center;
}

.ticket-separator-picture{
  width: 100%;
  height: 13px;
  margin-left: 8px;
}

.ticket-opensea-button{
  margin-top: 20px;
}
.psg-border-img{
  display: flex;
  width: 100%;
}
