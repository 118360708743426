@import "../../../variables";

.select__button {
  border: 0;
  font-size: 8px;
  position: relative;
  width: 100%;
  background-color: $pg-midnight-grey;
  color: rgb(255, 255, 255);
  cursor: pointer;
  min-width: 136px;
  display: flex;
  padding: 16px;
  text-align: left;
  flex-basis: 0;
  flex-grow: 1;

  &:hover {
    background-color: $dark-gray-background;
  }
}


.select__button.disabled {
  cursor: default;
  // prevents hover effect on disabled button
  background-color: $pg-midnight-grey;
}
