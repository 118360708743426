@import "../../../variables";

.play-on-behalf-missions-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 505px;

  .play-on-behalf-text {
    font-size: 1rem;
    line-height: 4rem;
    font-family: $sofia;
  }

  .wallet-address-border {
    width: 470px;
    height: 42px;
    margin-bottom: 20px;

    background-color: white;
    clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);

    .wallet-address {
      display: flex;
      justify-content: center;
      width: 468px;
      height: 40px;

      color: white;
      font-family: $sofia;
      font-weight: 400;
      font-size: 1rem;
      text-align: left;
      padding-left: 20px;

      margin: 1px;
      background-color: $black;
      clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
    }
  }
}

@media screen and (max-width: 1024px) {
  .play-on-behalf-missions-main-container {
    width: 356px;

    .top-crown, .bottom-crown {
      width: 47px;
    }

    .play-on-behalf-text {
      line-height: 3rem;
    }

    .wallet-address-border {
      width: 337px;

      .wallet-address {
        padding-left: 0;
        font-size: 0.80rem;
        width: 335px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .play-on-behalf-missions-main-container {
    width: 65.755vw;

    .top-crown, .bottom-crown {
      width: 8.724vw;
    }

    .play-on-behalf-text {
      font-size: clamp(0.4875rem, 2.083vw, 1rem);
      line-height: 8.333vw;
    }

    .wallet-address-border {
      width: 61.198vw;
      height: 5.469vw;
      margin-bottom: 2.604vw;

      clip-path: polygon(1vw 0, 0 1vw, 0 calc(100% - 1vw), 1vw 100%, calc(100% - 1vw) 100%, 100% calc(100% - 1vw), 100% 1vw, calc(100% - 1vw) 0);

      .wallet-address {
        width: 60.938vw;
        height: 5.208vw;

        font-size: clamp(0.4875rem, 2.083vw, 1rem);
        line-height: 5.208vw;
        margin: 0.130vw;

        clip-path: polygon(1vw 0, 0 1vw, 0 calc(100% - 1vw), 1vw 100%, calc(100% - 1vw) 100%, 100% calc(100% - 1vw), 100% 1vw, calc(100% - 1vw) 0);
      }
    }
  }
}

@media screen and (max-device-width: 375px) {
  .play-on-behalf-missions-main-container {
    width: 91.200vw;

    .top-crown, .bottom-crown {
      width: 12vw;
    }

    .play-on-behalf-text {
      font-size: clamp(0.4375rem, 2.933vw, 0.6875rem);
      line-height: 12.800vw;
    }

    .wallet-address-border {
      width: 84.800vw;
      height: 7.467vw;

      margin-bottom: 4vw;

      .wallet-address {
        width: 84.267vw;
        height: 6.933vw;
        font-size: clamp(0.4375rem, 2.933vw, 0.6875rem);
        line-height: 6.933vw;
        margin: 0.267vw;
      }
    }
  }
}
