@import "../../../variables";


.share-button-popover {
  position: relative;
  .popover-arrow::after {
    left: 2px;
    border-right-color: $semi-dark-black;
  }
  .popover-body {
    color: $white;
    font-family: $sofia;
    background-color: $semi-dark-black;
    pointer-events: none;
    font-size: 14px;
    padding: 7px 10px;
    height: 35px;
    font-weight: initial;
    border-radius: 2px;
  }
}


.share-button {
  background-color: $black;
  opacity: 0.8;
  border: 1px solid $very-light-gray;
  padding: 0;
}
