@import '../../../variables';

.select-network {

  &__button {

    .select-network {
      &__container {
        p {
          font-size: 1rem;
          color: $light-gray-background;
          margin-bottom: 0.5rem;
        }
      }

      &__image-wrapper {
        display: none;

        @media screen and (min-width: $sm-breakpoint) {
          width: 45px;
          display: flex;
          margin-right: 16px;
          margin-bottom: 16px;
          align-items: center;
        }

        img {
          width: 100%;
        }
      }

      &__image-wrapper.none {
        background-color: $grey-tab-background;
        border-radius: 50%;
        width: 45px;
        height: 45px;
        margin-top: 6px;
      }

      &__main-content {
        display: flex;
      }
    }

    .select__text-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}
