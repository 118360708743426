@import "../../../variables";
@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,800,700,900);

.gam3-modal-main-container{
  width: 100%;
  height: 100%;
}

.gam3-background{
  width: 770px;
  height: 506px;
}

.gam3-mobile-background{
  display: none;
}

.medium-gam3-background{
  width: 584px;
  height: 569px;
  border: 2px solid white;
  display: none;
}

.gam3-modal .modal-content {
  background-color: transparent;
  width: 770px;
  height: 506px;
}

.game3-content-container{
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.gam3-badge-container{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: -346px;
}

.gam3-vote-container{
  position: absolute;
  bottom: 85px;
}


.gam3-vote-text-container{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 12px;
  width: 236px;
}

.gam3-vote-finalist-text{
  font-family: $raleway;
  font-size: 40px;
  line-height: 47px;
  font-weight: 900;
  letter-spacing: 0.02em;
  background:  linear-gradient(90deg, #49D647 20.81%, #645DD4 81.22%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gam3-vote-year-text{
  font-family: $raleway;
  font-weight: 700;
  font-size: 79px;
  margin-top: -10px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 2.36px;
}

.gam3-vote-now-text{
  font-family: $raleway;
  font-weight: 900;
  font-size: 31px;
  letter-spacing: 0.035em;
  color: #48DD3F;
  margin-top: 9px;
}

.gam3-description-container{
  position: absolute;
  height: 433px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  left: 328px;
}

.gam3-description-finalist-text{
  font-family: $raleway;
  font-size: 49px;
  font-weight: 400;
  line-height: 46px;
}

.gam3-description-phantom-text{
  position: relative;
  font-family: $raleway;
  font-weight: 900;
  font-size: 77px;
  line-height: 73px;
}
.gam3-description-best-rpg-text{
  font-family: $raleway;
  font-size: 78px;
  font-weight: 900;
  line-height: 65px;
  color: #3EDE91;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 2.36px;
}
.exit-gam3-img{
  cursor: pointer;
  position: absolute;
  width: 13px;
  height: 14px;
  right: 17px;
  top: 16px;
  z-index: 3;
}

.content-clickable,
.badge-clickable{
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  z-index: 2;
}

.content-clickable{
  clip-path: polygon(0 0, 83% 0, 83% 24%, 100% 24%, 100% 100%, 0 100%);
}

.badge-clickable{
  clip-path: polygon(7% 50%, 50% 7%, 93% 50%, 50% 93%);
}

.gam3-awards-logo-image-mobile{
  display: none;
}

@media screen and (max-width: 375px) {
  .gam3-mobile-background {
    display: block;
    width: 78.400vw;
    height: 163.733vw;
  }

  .gam3-background{
    display: none;
  }

  .gam3-dialog{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .gam3-modal .modal-content {
    width: 78.400vw;
    height: 163.733vw;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .game3-content-container {
    flex-direction: column;
  }
    .gam3-badge-container {
      left: 0;
      top: -32vw;
  }
  .gam3-badge-image{
    width: 86.267vw;
    height: 86.267vw;
  }
  .gam3-vote-container {
    bottom: 0;
  }
  .gam3-vote-background{
    width: 36.800vw;
    height: 30.133vw;
  }
  .gam3-vote-text-container {
    top: 2.667vw;
    width: 36.800vw;
  }
  .gam3-vote-finalist-text {
    font-size: 6.133vw;
    line-height: 7.200vw;
  }
  .gam3-vote-year-text {
    font-size: 12.267vw;
    margin-top: -2.133vw;
    -webkit-text-stroke: 0.373vw;
  }
  .gam3-vote-now-text {
    margin-top: 2.133vw;
    font-size: 4.800vw;
  }
  .gam3-description-container {
    position: relative;
    left: 0;
    height: 94.667vw;
    align-items: center;
    top: -26.667vw;
  }
  .gam3-description-finalist-text {
    position: relative;
    font-size: 7.467vw;
    line-height: 9.867vw;
    letter-spacing: 0.29em;
    left: 1.333vw;
  }
  .gam3-description-phantom-text {
    font-size: 9.333vw;
    line-height: 9.867vw;
  }
  .gam3-description-best-rpg-text {
    font-size: 9.333vw;
    line-height: 9.867vw;
    -webkit-text-stroke: 0.373vw;
    letter-spacing: 0.035em;
  }
  .gam3-awards-logo-image{
    display: none;
  }
  .gam3-awards-logo-image-mobile{
    display: block;
    width: 46.400vw;
    height: 55.200vw;
  }
  .exit-gam3-img{
    top: 1.600vw;
    right: 1.600vw;
  }
  .gam3-description-text-container{
    margin-top: 10px;
    text-align: center;
  }
  }

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .gam3-background {
    display: none;
  }
  .medium-gam3-background {
    display: block;
    width: 584px;
    height: 569px;
    background-color: rgba(0, 0, 0, 0.8);
  }
  .gam3-modal .modal-content {
    background-color: transparent;
    width: 584px;
    height: 569px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .gam3-dialog{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .gam3-badge-container {
    left: 210px;
    top: -168px;
  }

  .gam3-badge-image {
    width: 450px;
    height: 450px;
  }

  .gam3-vote-container {
    bottom: 51px;
  }

  .gam3-vote-text-container {
    top: 12px;
    width: 138px;
  }

  .gam3-vote-background {
    width: 138px;
    height: 113px;
  }

  .gam3-vote-finalist-text {
    font-size: 23px;
    line-height: 27px;
  }

  .gam3-vote-year-text {
    font-size: 46px;
    line-height: 54px;
  }

  .gam3-vote-now-text {
    font-size: 18px;
    line-height: 20px;
    margin-top: 2px;
  }

  .gam3-description-container {
    left: 39px;
    top: 47px;
  }

  .gam3-description-text-container {
    margin-top: 17px;
  }

  .gam3-awards-logo-image {
    width: 150px;
    height: 178px;
  }

  .gam3-description-finalist-text{
    font-size: 55px;
    line-height: 52px;
  }

  .gam3-description-phantom-text{
    font-size: 86px;
    line-height:82px;
  }
  .gam3-description-best-rpg-text{
    font-size: 88px;
    line-height: 84px;
  }

  .exit-gam3-img {
    top: 12px;
    right: 7px;
  }

  .content-clickable {
    clip-path: polygon(0 0, 78% 0, 78% 22%, 100% 22%, 100% 100%, 0 100%);
  }

  .badge-clickable{
    display: none;
  }
}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .gam3-background {
    display: none;
  }
  .medium-gam3-background {
    display: block;
    width: 76.042vw;
    height: 74.089vw;
    background-color: rgba(0, 0, 0, 0.8);
  }
  .gam3-modal .modal-content {
    background-color: transparent;
    width: 76.042vw;
    height: 74.089vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .gam3-dialog{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .gam3-badge-container {
    left: 27.644vw;
    top: -21.875vw;
  }

  .gam3-badge-image {
    width: 58.594vw;
    height: 58.594vw;
  }

  .gam3-vote-container {
    bottom: 6.710vw;
  }

  .gam3-vote-text-container {
    top: 1.562vw;
    width: 18.070vw;
  }

  .gam3-vote-background {
    width: 18.070vw;
    height: 14.765vw;
  }

  .gam3-vote-finalist-text {
    font-size: 3.046vw;
    line-height: 3.546vw;
  }

  .gam3-vote-year-text {
    font-size: 6.0468vw;
    line-height: 7.046vw;
    -webkit-text-stroke: 0.307vw;
    margin-top: -1.302vw;
  }

  .gam3-vote-now-text {
    font-size: 2.34375vw;
    line-height: 2.604vw;
    margin-top: 0.260vw;
  }

  .gam3-description-container {
    left: 5.117vw;
    top: 6.171vw;
    height: 63.281vw;
  }

  .gam3-description-text-container {
    margin-top: 2.234vw;
  }

  .gam3-awards-logo-image {
    width: 19.531vw;
    height: 23.203vw;
  }

  .gam3-description-finalist-text {
    font-size: 7.203vw;
    line-height: 6.859vw;
  }

  .gam3-description-phantom-text {
    font-size: 11.273vw;
    line-height: 10.757vw;
  }

  .gam3-description-best-rpg-text {
    font-size: 11.484vw;
    line-height: 11.132vw;
    -webkit-text-stroke: 0.307vw;

  }

  .exit-gam3-img {
    top: 1.562vw;
    right: 0.914vw;
    width: 1.693vw;
    height: 1.823vw;
  }

  .content-clickable {
    clip-path: polygon(0 0, 78% 0, 78% 22%, 100% 22%, 100% 100%, 0 100%);
  }

  .badge-clickable{
    display: none;
  }
}
