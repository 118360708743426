@import "../../../variables";

.carousel-button-main-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.cursor-pointer-button {
  cursor: pointer;
}

.carousel-container-for-design .carousel .slide img {
  width: initial;
}

.carousel-container-for-design .carousel img {
  width: initial;
}

.left-horn-button-adjustment {
  position: relative;
  z-index: 1;
}

.right-horn-button-adjustment {
  position: relative;
  z-index: 1;
}

.carousel-button-text-adjustment {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  width: 100%;
  height: 50px;
  margin-right: -12px;
  margin-left: -12px;
  background-color: black;
  clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
  font-family: $sourceCodePro;
}

@media (min-width: 769px) and (max-width: 1024px) {
  .carousel-button-text-adjustment {
    font-size: 1.270vw;
    height: 3.418vw;
    margin-right: -0.781vw;
    margin-left: -0.781vw;
  }
  .left-horn-button-adjustment {
    width: 0.879vw !important;
    height: 3.418vw;
  }

  .right-horn-button-adjustment {
    width: 0.879vw !important;
    height: 3.418vw;
  }
}

@media (min-width: 376px) and (max-width: 768px) {
  .carousel-button-text-adjustment {
    font-size: 1.693vw;
    height: 4.557vw;
    margin-right: -1.042vw;
    margin-left: -1.042vw;
  }
  .left-horn-button-adjustment {
    width: 1.172vw !important;
    height: 4.557vw;
  }

  .right-horn-button-adjustment {
    width: 1.172vw !important;
    height: 4.557vw;
  }
}

@media (max-width: 375px) {
  .carousel-button-text-adjustment {
    font-size: 3.200vw;
    background-color: black;
    height: 8.533vw;
    margin-left: -1.600vw;
    margin-right: -1.600vw;
  }

  .carousel-button-main-container {


  }
  .left-horn-button-adjustment {
    width: 1.867vw !important;
    height: 8.533vw;
  }
  .right-horn-button-adjustment {
    width: 1.867vw !important;
    height: 8.533vw;
  }
}
