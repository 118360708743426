@import '../../../variables';

.planet-minting-content {
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .form-select {
    width: 50%;
    max-width: 170px;
    margin-top: 55px;
    background-color: $black;
    color: $white;
    border-color: $mist-gray;
  }

  select {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url("../../../assets/images/AstrafiteRushEvent/arrow-bottom.png");
    background-repeat: no-repeat;
    background-position: right 8px top 50%;
  }

  .generative-planet-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .planet-minting-total-counter{
    text-align: center;
    font-size: 2.25rem;
    font-family: $oswald;
    opacity: 0.5;
    margin-bottom: 80px;
    @media screen and (min-width: 769px) and (max-width: 1024px) {
      font-size: 1.8125rem;
    }
    @media screen and (min-width: 376px) and (max-width: 768px) {
      font-size: 2.25rem;
    }
    @media screen and (max-device-width: 375px) {
      font-size: 1.25rem;
      margin-bottom: 0;
    }
  }

  .planet-minting-switch {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: -60px;
    @media screen and (max-device-width: 375px) {
      margin-top: -37.333vw;
    }

    .planet-minting-switch-title {
      color: white;
      font-family: $oswald;
      font-weight: 400;
      font-size: 1.558rem;
      transition-duration: 0.3s;
      @media screen and (max-device-width: 375px) {
        font-size: clamp(0.75rem, 5.333vw, 1.25rem);
      }
    }

    .planet-minting-switch-title.disabled-title {
      opacity: 0.5;
    }

    .minting-switch {
      position: relative;
      display: inline-block;
      width: 60.00px;
      height: 30.33px;
      margin: 0 25px;
      @media screen and (max-device-width: 375px) {
        width: 14.667vw;
        height: 8vw;
        margin: 0 4vw;
      }
    }

    .minting-switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      top: 2px;
      background-color: $black;
      -webkit-transition: .4s;
      transition: .4s;
      @media screen and (max-device-width: 375px) {
        height: 6.933vw;
        width: 6.933vw;
        top: 0.533vw;
        left: 1.067vw;
      }
    }

    input:checked + .slider:before {
      -ms-transform: translateX(23px);
      transform: translateX(23px);
      @media screen and (max-device-width: 375px) {
        -ms-transform: translateX(6.133vw);
        transform: translateX(6.133vw);
      }
    }

    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }

  .planet-minting-button-container {
    width: 293px;
    height: 62px;

    font-size: 2.25rem;
    font-family: $oswald;
    font-weight: 400;
    margin-bottom: 25px;
    @media screen and (min-width: 769px) and (max-width: 1024px) {
      width: 23.438vw;
      height: 5.078vw;
      margin-bottom: 15px;
      font-size: clamp(1.375rem, 2.930vw, 1.875rem);
    }
    @media screen and (min-width: 376px) and (max-width: 768px) {
      width: 28.646vw;
      height: 6.120vw;
      margin-bottom: 35px;
      font-size: clamp(0.75rem, 3.516vw, 1.6875rem);
    }
    @media screen and (max-device-width: 375px) {
      width: 171px;
      height: 40.5px;
      font-size: 1.4375rem;
      margin-bottom: 8px;
    }

    .base-button.scale.disabled {
      .base-button-filled {
        background-color: $stack;
      }
    }
  }
}
