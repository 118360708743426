@import "../../variables";

.team-main-container {
  position: relative;
  width: 100%;
  clip-path: $section-polygon-top-bottom-down-cut-1920;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

}

.team-background {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team-laptop-background {
  display: none;
}

.team-1024-background {
  display: none;
}

.team-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.team-members-main-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
  height: 100%;
  margin-top: 142px;
}

.advisors-member-main-controller {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
  height: 100%;
}

.development-team-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
  height: 100%;
}

.team-tablet-background {
  position: absolute;
  z-index: -1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  transform: translate(0px, 18%);
}

.team-member-description {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: $sofia;
  text-align: center;
}

.team-main-container .page-header {
  padding: 0;
  height: 253px;
  margin-bottom: 9px;
}

.team-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 48px;
}

.team-tablet-background {
  display: none;
}

.member-element {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 11px;
}

.team-member-name {
  font-size: 30px;
  font-family: $oswald;
  margin-right: 180px;
  margin-left: 180px;
}

.member-image {
  position: absolute;
  clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
}

.team-member {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.team-member h2 {
  font-family: $oswald;
  font-weight: 400;
  font-size: 30px;
  line-height: 44px;
  margin: 0;
  text-align: center;

}

.team-member p {
  font-family: $sofia;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  margin: 0;
  text-align: center;
}

.team-mobile-background {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  display: none;
}

.about-content-container-from-mobile-resolution {
  display: none;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .team-section {
    justify-content: space-evenly;
    margin-bottom: 70px;
  }
  .team-member-name {
    margin: 0;
  }

  .team-background {
    display: none;
  }
  .team-mobile-background {
    display: none;
  }
  .team-laptop-background {
    position: absolute;
    z-index: -1;
    object-fit: cover;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transform: translate(0px, 23%);
  }

}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .team-title .psg-border-img-mini-screen{
    width: 1300px;
  }
  .team-section {
    justify-content: space-evenly;
    margin-bottom: 70px;
  }
  .team-member-name {
    margin: 0px;
  }
  .team-background {
    display: none;
  }
  .team-mobile-background {
    display: none;
  }
  .team-laptop-background {
    display: none;
  }
  .team-tablet-background {
    display: none;
  }
  .team-1024-background {
    position: absolute;
    z-index: -1;
    object-fit: cover;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transform: translate(0px, 10%);
  }
  .team-main-container {
    clip-path: $section-polygon-top-bottom-down-cut-1024;
  }
}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .team-tablet-background {
    display: flex;
  }
  .team-section {
    margin-top: -7.813vw;
  }
  .team-background {
    display: none;
  }
  .team-mobile-background {
    display: none;
  }
  .linkedin-icon {
    width: 1.432vw;
    height: 1.432vw;
  }
  .team-members-main-container {
    margin-top: -4.792vw;
    flex-direction: row;
    margin-bottom: 0;
  }
  .team-member-name {
    font-size: 1.953vw;
    margin: 0px;
  }
  .team-member-description {
    font-size: 1.042vw;
  }
  .member-element {
    width: 17.578vw;
    height: 19.531vw;
    margin-bottom: -0.651vw;
  }
  .member-image {
    width: 15.234vw;
  }
  .member-frame {
    width: 100%;
    height: 100%;
  }
  .team-section {
    justify-content: space-evenly;
  }
  .team-members-main-container .page-mobile-header-text-no-margin {
    font-size: 2.604vw !important;
    width: initial !important;
  }
  .team-members-main-container .page-header {
    height: 32.943vw;
  }
  .team-members-main-container .psg-border-img-mobile {
    display: none;
  }
  .team-members-main-container .psg-border-img-tablet {
    display: initial;
    width: 145%;
  }
  .team-main-container .page-header {
    margin-bottom: 0px;
  }
  .team-main-container {
    clip-path: $section-polygon-top-bottom-down-cut-768;
  }
}

@media screen and (max-device-width: 375px) {
  .team-members-main-container {
    margin-top: 62px;
  }
  .team-title {
    margin-bottom: 6.933vw;
  }

  .team-section {
    justify-content: space-evenly;
    margin-bottom: 0px;
  }

  .team-member {
    max-width: 143px;
  }

  .member-element {
    width: 38.133vw;
    height: 42.400vw;
    margin-bottom: 0;
  }
  .member-frame {
    width: 100%;
    height: 100%;
  }
  .member-image {
    width: 90%;
    height: 90%;
  }
  .team-member-name {
    margin: 0px;
  }
  .team-member-name {
    font-size: 4.267vw;
  }
  .team-member-description {
    flex-direction: column;
    font-size: 3.733vw;
    padding-right: 1.333vw;
    padding-left: 1.333vw;

  }

  .development-team-container .psg-border-img-mobile {
    display: none;
  }

  .development-team-container .psg-border-img-tablet {
    display: initial;
    width: 184%;
  }

  .development-team-container .page-mobile-header-text-no-margin {
    font-size: 6.667vw;
  }
  .advisors-member-main-controller {
    margin-top: 8.267vw;
  }
  .advisors-member-main-controller .psg-border-img-tablet {
    display: initial;
    width: 184%;
  }
  .advisors-member-main-controller .page-mobile-header-text-no-margin {
    font-size: 6.667vw;
  }
  .advisors-member-main-controller .psg-border-img-mobile {
    display: none;
  }
  .team-main-container .page-header {
    height: initial;
    margin-bottom: -9.333vw;
    margin-top: -9.067vw;
  }

  .about-content-container-from-mobile-resolution {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .about-title .psg-border-img-mobile {
    display: none;
  }
  .about-title .psg-border-img-tablet {
    display: initial;
    width: 184%;
  }
  .about-title .page-mobile-header-text-no-margin {
    font-size: 6.667vw;
  }
  .about-content-container {
    margin-top: 0px;
  }
  .about-title .page-header {
    margin-top: 0;
    margin-bottom: -9.067vw;
    height: initial;
  }
  .reveal-info-adjustments {
    margin-bottom: 10.400vw;
    -webkit-transition: height 0.7s ease-in-out;
    -moz-transition: height 0.7s ease-in-out;
    -ms-transition: height 0.7s ease-in-out;
    -o-transition: height 0.7s ease-in-out;
    transition: height 0.7s ease-in-out;
    height: 240.88px;

  }

  .reveal-info-adjustments-animation {
    margin-bottom: 10.400vw;
    -webkit-transition: height 0.4s linear;
    -moz-transition: height 0.4s linear;
    -ms-transition: height 0.4s linear;
    -o-transition: height 0.4s linear;
    transition: height 0.4s linear;
    height: 591.75px;
  }
  .animation-reveal-info-adjustments-margin {

    margin-bottom: 10.400vw;
    height: 491.75px;
    -webkit-transition: height 0.6s linear;
    -moz-transition: height 0.6s linear;
    -ms-transition: height 0.6s linear;
    -o-transition: height 0.6s linear;
    transition: height 0.6s linear;

  }
  .reveal-info-adjustments-margin {
    margin-bottom: 10.400vw;
    -webkit-transition: height 0.6s linear;
    -moz-transition: height 0.6s linear;
    -ms-transition: height 0.6s linear;
    -o-transition: height 0.6s linear;
    transition: height 0.6s linear;
    height: 240.88px;
  }
  .team-mobile-background {
    display: initial;
  }
  .team-background {
    display: none;
  }
  .team-main-container {
    clip-path: $section-polygon-top-bottom-down-cut-375;
  }
  .linkedin-icon {
    width: 17px;
    height: 17px;
  }

  .team-mobile-background {
    display: initial;
    object-fit: cover;
    height: 2643px;
    transform: translate(0,100px);
    -webkit-transform: translate(0,100px);
    -moz-transform: translate(0,100px);
    -ms-transform: translate(0,100px);
    -o-transform: translate(0,100px);
}
}
