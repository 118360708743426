@import '../../../variables';

.safety-first-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.safety-first-title{
  font-family: $oswald;
  font-size: 82px;
  line-height: 78px;
  background: #51CBFF;
  background: linear-gradient(180deg, #FFFFFF 0%, #506f7e 65%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0;
}

.safety-first-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 36px;
}

.terms-check-icon-image{
  position: relative;
  top: -43px;
}

.safety-first-button{
  width: 198px;
  margin-top: -31px;
}

.safety-first-description{
  font-family: $oswald;
  font-size: 20px;
  line-height: 24px;
  margin-top: 35px;
  width: 639px;
}

.safety-first-description a{
  color: #FFFFFF;
  text-decoration: underline;
  cursor: pointer;
}
.safety-first-title-375,
.safety-first-second-title-375{
  display: none;
}


@media screen and (min-width: 769px) and (max-width: 1024px) {
    .safety-first-title {
      font-size: 6.738vw;
      line-height: 6.348vw;
    }

  .safety-first-container{
    margin-top: 4.883vw;
  }
  .safety-first-content {
    margin-top: 4.004vw;
  }
  .terms-icon-image{
    width: 11.719vw;
    height: 13.672vw;
  }
  .terms-check-icon-image{
    width: 6.250vw;
    height: 6.250vw;
    top: -3.125vw;
  }
  .safety-first-button{
    margin-top: -0.293vw;
    width: 12.988vw;
  }
  .safety-first-description{
    font-size: 1.563vw;
    line-height: 1.953vw;
    margin-top: 3.906vw;
    width: 50vw;
  }
}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .safety-first-title {
    font-size: 8.984vw;
    line-height:8.464vw;
  }

  .safety-first-container{
    margin-top: 6.510vw;
  }
  .safety-first-content {
    margin-top: 5.339vw;
  }
  .terms-icon-image{
    width: 15.625vw;
    height: 18.229vw;
  }
  .terms-check-icon-image{
    width: 8.333vw;
    height: 8.333vw;
    top: -4.167vw;
  }
  .safety-first-button{
    margin-top: -0.391vw;
    width: 17.318vw;
  }
  .safety-first-description{
    font-size: 2.083vw;
    line-height: 2.604vw;
    margin-top: 5.208vw;
    width: 66.667vw;
  }
}

@media screen and (max-width: 375px){
  .safety-first-title{
    display: none;
  }
  .safety-first-title-375,
  .safety-first-second-title-375{
    display: block;
    font-family: $oswald;
    font-size: 12.800vw;
    line-height: 12.267vw;
    font-weight: 500;
    margin-bottom: 0;
  }
  .safety-first-title-375{
    background: linear-gradient(180deg, #eff3f5 0%, #93abb6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .safety-first-second-title-375{
    letter-spacing: 1.867vw;
    margin-left: 2.133vw;
    background: linear-gradient(180deg, #839eaa 0%, #42606d 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .safety-first-container{
    margin-top: 16vw;
  }
  .safety-first-content {
    margin-top: 15.467vw;
  }
  .terms-icon-image{
    width: 33.067vw !important;
    height: 38.667vw;
  }
  .terms-check-icon-image{
    width: 17.067vw;
    height: 17.067vw;
    top: -8.267vw;
  }
  .safety-first-button{
    margin-top: 4.267vw;
    width: 32vw;
  }
  .safety-first-description{
    font-size: 4.267vw;
    line-height: 5.333vw;
    margin-top: 9.333vw;
    width: 74.133vw;
  }
}
