@import "src/variables";

.select-token {
  &__button {
    flex-grow: 2;
  }
  &__icon {
    width: 100%;

    &-container {
      width: 45px;
      height: 100%;
      margin-right: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &.no-logo {
      border-radius: 50%;
      background-color: $light-gray-background;
    }
  }
}
