@import "../../../../variables";

.other-nft-row-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 70px;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .other-nft-row-container {
    margin-bottom: 57px;
  }
  }
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .other-nft-row-container {
    margin-bottom: 4.297vw;
  }
}
@media screen and (min-width: 376px) and (max-width: 768px) {
  .other-nft-row-container {
    margin-bottom: 12vw;
  }
}
@media screen and (max-width: 375px) {
  .other-nft-row-container {
    margin-bottom: 8.800vw;
  }
}

