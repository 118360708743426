.le-principle-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.lec-img-dimension{
  width: 198px;
  height: 198px;
  border: 1px solid white;
}

.le-margin {
  margin-left: 32px;
  margin-right: 32px;
}

@media only screen and (max-width: 1500px) {
  .le-principle-container{
    width: auto;
    overflow: auto;
    justify-content: initial;
    align-items: initial;
    height: 460px;
  }
}



