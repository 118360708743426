@import '../../../variables';

.pg-accordion {
  .accordion-item {
    background-color: black;
  }
  .accordion-body, .accordion-button {
    background-color: #0d0d0d;
    color: white;
  }

  .accordion-button {
    font-size: 1.5rem;
    padding-left: 64px;
    border-radius: 2px;

    &::after {
      background-image: url("../../../assets/images/NFT/episode-dropdown-arrow.png");
      height: 0.75rem;
      position: absolute;
      left: 32px;
    }
  }

  .accordion-header {
    margin-bottom: 16px;
  }

  .accordion-body {
    margin-bottom: 16px;
    padding: 2rem;
    font-family: $sofia;
    font-weight: 300;
    color: $gray-dropdown;
    border-radius: 2px;
  }
}
