@import "../../variables";

.mission-tab-main-container{
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: left;
  width: 271px;
  height: 64px;
  background-color: $tab-gray;
  cursor: pointer;
}

.mission-tab-left-decor{
  width: 5px;
  height: 64px;
  background-color: $pink;
}

.mission-tab-right-container{
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  margin-top: 12px;
}

.mission-tab-title{
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 17px;
  font-family: $sofia;
  margin: 0 0 4px 0;
}

.mission-tab-content{
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 22px;
  font-family: $oswald;
  margin: 0;
}

@media screen and (min-width: 1441px) and (max-width: 1920px) {
  .planet-exploration-missions-left-container {
    .mission-tab-main-container {
      width: 14.115vw;
      height: 3.333vw;
    }

    .mission-tab-left-decor {
      width: 0.260vw;
      height: 3.333vw;
    }

    .mission-tab-right-container {
      margin-left: 0.625vw;
      margin-top: 0.625vw;
    }

    .mission-tab-title {
      line-height: 0.885vw;
      margin: 0 0 0.208vw 0;
    }

    .mission-tab-content {
      line-height: 1.146vw;
    }
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .planet-exploration-missions-left-container {
    .mission-tab-main-container {
      width: 14.115vw;
      height: 3.333vw;
    }

    .mission-tab-left-decor {
      width: 0.260vw;
      height: 3.333vw;
    }

    .mission-tab-right-container {
      margin-left: 0.625vw;
      margin-top: 0.625vw;
    }

    .mission-tab-title {
      font-size: clamp(0.59rem, 0.972vw, 0.875rem);
      font-weight: 200;
      line-height: 0.685vw;
      margin: 0 0 0.347vw 0;
    }

    .mission-tab-content {
      font-size: clamp(0.75rem, 1.250vw, 1.125rem);
      font-weight: 200;
      line-height: 1.146vw;
    }
  }
}

@media screen and (max-width: 1024px) {
  .planet-exploration-missions-left-container {
    .mission-tab-main-container {
      width: 170px;
      height: 66px;
    }

    .mission-tab-left-decor {
      height: 66px;
    }

    .mission-tab-title {
      font-size: 0.656rem;
      font-weight: 200;
    }

    .mission-tab-content {
      font-size: 0.844rem;
      font-weight: 200;
    }
  }
}

@media screen and (max-device-width: 375px) {
  .planet-exploration-missions-left-container {
    .mission-tab-main-container {
      width: 150px;
      height: 50px;
      margin-bottom: 5px;
    }

    .mission-tab-left-decor {
      height: 50px;
    }

    .mission-tab-right-container {
      margin: 5px;
    }

    .mission-tab-title {
      font-size: 0.75rem;
      margin-bottom: 0;
    }

    .mission-tab-content {
      font-size: 1rem;
    }
  }
}
