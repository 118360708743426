@import "../../../variables";


.copy-code-tooltip-main-container{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: $sofia;
  color: $black;
  pointer-events: none;
  width: 0;
  height: 0;
}
.scale-copy-code{
  background-color: $white;
  position: absolute;
  width: 140px;
  height: 31px;
  font-size: 14px;
  padding: 7px 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: initial;
}

.copy-code-triangle-white{
  position: relative;
  top: -15px;
}
