@import '../../../variables';
@import '../../../functions';

.toggle-switch-container {
  display: flex;
  margin-right: 14px;
  align-items: center;

  @media screen and (min-width: $md-breakpoint) {
    align-items: end;
  }
}

.toggle-switch-label {
  font-family: $oswald-light;
  margin-right: 10px;

  @media screen and (min-width: $md-breakpoint) {
    font-size: pxToRem(24px);
  }
}

.toggle-switch {
  display: inline-block;
  height: 25px;
  position: relative;
  width: 50px;

  @media screen and (min-width: $md-breakpoint) {
    height: 30px;
  }
}

.toggle-switch input {
  display:none;
}

.toggle-slider {
  background-color: $toggle-switch-grey-bg;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
}

.toggle-slider:before {
  background-color: $toggle-switch-white-bg;
  bottom: 3px;
  left: 3px;
  content: "";
  height: 19px;
  position: absolute;
  transition: .4s;
  width: 20px;
}

input:checked + .toggle-slider {
  background-color: $toggle-switch-white-bg;
}

input:checked + .toggle-slider:before {
  background-color: $black;
  transform: translateX(23px);
}

.toggle-slider.toggle-round {
  border-radius: 26px;
  height: 25px;
}

.toggle-slider.toggle-round:before {
  border-radius: 50%;
}
