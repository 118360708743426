@import '../../../variables';


.planet-trait-container,
.no-biome-trait {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 40px;
  @media screen and (min-width: 1025px) and (max-width: 1440px) {
    margin: 0 1.389vw;
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    margin: 0 1.465vw;
  }
  @media screen and (min-width: 376px) and (max-width: 768px) {
    margin: 0;
  }
  @media screen and (max-device-width: 375px) {
    margin: 0;
  }

  .planet-trait-image-container{
    position: relative;
  }

  .planet-trait-border {
    width: 180px;
    height: 172px;
    background-image: url("../../../assets/images/GenerativePlanetMinting/planet-mintpass-border.svg");
    background-position: center;
    background-size: 90% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: -4px;
    @media screen and (min-width: 1441px) and (max-width: 1920px) {
      width: 180px;
      height: 172px;
    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
      width: 140px;
      height: 11.944vw;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
      width: 110px;
      height: 9.375vw;
    }
    @media screen and (min-width: 376px) and (max-width: 768px) {
      width: 84px;
      height: 110px;
    }
    @media screen and (max-device-width: 375px) {
      width: 18.667vw;
      height: 22.667vw;
    }
  }

  .planet-trait-border.clickable {
    cursor: pointer;
  }

  .planet-trait-border.clickable:hover {
    background-image: url("../../../assets/images/GenerativePlanetMinting/planet-mintpass-border-hover.svg");
  }

  .planet-trait-border.selected {
    background-image: url("../../../assets/images/GenerativePlanetMinting/planet-mintpass-border-clickable.svg");
  }

  .planet-trait-image {
    width: 100px;
    margin-bottom: 21px;
    position: relative;
    top: 8px;
    @media screen and (min-width: 1441px) and (max-width: 1920px) {
      width: 5.208vw;
      margin-bottom: 1.094vw;
      top: 0.417vw;
    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
      width: 6.944vw;
      margin-bottom: 1.458vw;
      top: 0.625vw;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
      width: 6.348vw;
      margin-bottom: 2px;
      top: unset;
    }
    @media screen and (min-width: 376px) and (max-width: 768px) {
      width: 64px;
      top: 10px;
    }
    @media screen and (max-device-width: 375px) {
      width: 14.133vw;
      top: 2.400vw;
    }
  }
  .planet-trait-selected-border .planet-trait-stock{
    top: 14px;
    left: 16px;
    @media screen and (min-width: 1441px) and (max-width: 1920px) {
      top: 0.729vw;
      left: 0.833vw;
    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
      top: 0.972vw;
      left: 1.111vw;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
      font-size: 0.875rem;
      top: 0.879vw;
      left: 0.781vw;
    }
    @media screen and (min-width: 376px) and (max-width: 768px) {
      font-size: 0.875rem;
      top: 12px;
      left: 10px;
    }
    @media screen and (max-device-width: 375px) {
      font-size: clamp(0.5rem, 3.733vw, 0.875rem);
      top: 2.667vw;
      left: 2.133vw;
    }
  }

  .planet-trait-title {
    font-family: $oswald;
    font-size: 1.6625rem;
    cursor: pointer;
    margin-bottom: 23px;

    @media screen and (min-width: 1025px) and (max-width: 1440px) {
      font-size: 1.5rem;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
      font-size: 1rem;
    }
    @media screen and (min-width: 376px) and (max-width: 768px) {
      font-size: 1rem;
    }
    @media screen and (max-device-width: 375px) {
      font-size: clamp(0.5rem, 3.733vw, 0.875rem);
      width: 16.800vw;
      height: 8.533vw;
      text-align: center;
    }
  }

  .planet-trait-title.disabled-trait {
    filter: opacity(50%);
    cursor: initial;
  }

  .planet-trait-stock {
    position: absolute;
    font-family: $oswald;
    font-size: 22px;
    margin-bottom: 10px;
    top: 0;
    left: -3px;
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
      font-size: 22px;
      margin-bottom: 0;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
      font-size: 20px;
      margin-bottom: 0;
      top: -5px;
      left: -2px;
    }
    @media screen and (min-width: 376px) and (max-width: 768px) {
      font-size: 14px;
      margin-bottom: 0;
      top: 5px;
      left: 0;
    }
    @media screen and (max-device-width: 375px) {
      font-size: 12px;
      margin-bottom: 0;
      top: 5px;
      left: 0;
    }
  }

  .planet-trait-open-sea{
    @media screen and (max-device-width: 375px) {
      display: none;
    }
    &.mobile{
      display: none;
      @media screen and (max-device-width: 375px) {
        display: block;
      }
    }
  }

  .planet-trait-open-sea .buy-on-opensea-container {
    clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
    width: 158px;
    height: 38px;
    transition: opacity 0.5s ease-in-out;
    margin-bottom: -50px;

    .buy-on-opensea {
      width: 156px;
      height: 36px;
      clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
    }

    @media screen and (min-width: 769px) and (max-width: 1024px) {
      width: 13.184vw;
      height: 2.930vw;
      font-size: clamp(0.5rem, 1.270vw, 0.8125rem);
      clip-path: polygon(0.488vw 0, 0 0.488vw, 0 calc(100% - 0.488vw), 0.488vw 100%, calc(100% - 0.488vw) 100%, 100% calc(100% - 0.488vw), 100% 0.488vw, calc(100% - 0.488vw) 0);
      .buy-on-opensea{
        width: 12.988vw;
        height: 2.734vw;
        clip-path: polygon(0.488vw 0, 0 0.488vw, 0 calc(100% - 0.488vw), 0.488vw 100%, calc(100% - 0.488vw) 100%, 100% calc(100% - 0.488vw), 100% 0.488vw, calc(100% - 0.488vw) 0);
      }
      img {
        width: 1.660vw;
        height: 1.367vw;
      }
    }
    @media screen and (min-width: 376px) and (max-width: 768px) {
      width: 134px;
      height: 30px;
      font-size: 0.8125rem;
      clip-path: polygon(5px 0, 0 5px, 0 calc(100% - 5px), 5px 100%, calc(100% - 5px) 100%, 100% calc(100% - 5px), 100% 5px, calc(100% - 5px) 0);

      .buy-on-opensea{
        width: 132px;
        height: 28px;
        clip-path: polygon(5px 0, 0 5px, 0 calc(100% - 5px), 5px 100%, calc(100% - 5px) 100%, 100% calc(100% - 5px), 100% 5px, calc(100% - 5px) 0);
      }
      img {
        width: 17px;
        height: 14px;
      }
    }
    @media screen and (max-device-width: 375px) {
      width: 16.533vw;
      height: 7.200vw;
      font-size: clamp(0.5rem, 3.200vw, 0.75rem);
      clip-path: polygon(1.333vw 0, 0 1.333vw, 0 calc(100% - 1.333vw), 1.333vw 100%, calc(100% - 1.333vw) 100%, 100% calc(100% - 1.333vw), 100% 1.333vw, calc(100% - 1.333vw) 0);
      .buy-on-opensea{
        width: 16vw;
        height: 6.667vw;
        clip-path: polygon(1.333vw 0, 0 1.333vw, 0 calc(100% - 1.333vw), 1.333vw 100%, calc(100% - 1.333vw) 100%, 100% calc(100% - 1.333vw), 100% 1.333vw, calc(100% - 1.333vw) 0);
      }
      img {
        width: 4.533vw;
        height: 3.733vw;
      }
    }
  }
}
