.planet-minting-container {
  overflow-y: auto;
  overflow-x: hidden;
}

@supports (-webkit-touch-callout: none) {
  .planet-minting-container .page-mobile-header-text,
  .planet-minting-container .page-mobile-header-text-no-margin {
    padding-bottom: 31px;
  }
  .psg-border-img-pc{
      object-fit: contain;
  }
}
