@import "../../variables.scss";

.partner-logos {
  display: flex;
  justify-content: center;
  max-width: 800px;
  margin: auto;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem 2.5rem;

  &__mocaverse {
    width: 200px
  }

  &__aws {
    width: 90px;
  }

  &__mega-grants {
    width: 100px;
  }

  &__epic {
    width: 80px;
  }

  &__grease-monkey {
    width: 90px;
  }

  &__polygon {
    width: 200px;
  }

  &__gamefi {
    width: 180px;
  }

  &__avocado {
    width: 180px;
  }
}
