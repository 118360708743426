@import '../../../variables';
$images-path: '../../../assets/images/';

.planets-mission-title-container {
  .page-header {
    padding-bottom: 0;
    height: auto;
    margin-top: 48px;
    margin-bottom: 48px;
  }
}

.planet-exploration-missions-main-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 697px;
}

.planet-exploration-one-mission-main-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 697px;

  .planet-exploration-one-mission-picture-container {
    width: 1235.33px;
  }
}

.planet-exploration-missions-left-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 295.92px;
  height: 100%;
  overflow: auto;
  scrollbar-width: thin;
}

.planet-exploration-mission-tab {
  margin-bottom: 16px;
  margin-right: 20px;
}

.planet-exploration-mission-tab.selected > .mission-tab-main-container {
  background-color: $pink;
}

.planet-exploration-mission-tab.game-active > .mission-tab-main-container {
  filter: brightness(30%);
  transition: filter ease 0.2s;
  cursor: not-allowed;
}

.planet-exploration-missions-main-container ::-webkit-scrollbar {
  width: 6px;
  height: 410px;
  background: $scroll-bar-dark-gray;
  border-top: 20px;
}

.planet-exploration-missions-main-container ::-webkit-scrollbar-track {
  background: $scroll-bar-dark-gray;
}

.planet-exploration-missions-main-container ::-webkit-scrollbar-thumb {
  background: $thumb-bar-dark-gray;
  padding: 10px;
  height: 98px;
  border-radius: 10px;
}

.planet-exploration-missions-right-container {
  width: 1235.33px;
  margin-left: unset;
  position: relative;
}

.planet-exploration-missions-right-container.overlay {
  img {
    filter: brightness(50%);
  }
}

.planet-explorations-missions-micro-game-container {
  width: 100%;
  height: 100%;
  //transition: all 0.3s ease;

  &-fullscreen {
    @extend .planet-explorations-missions-micro-game-container;
    position: fixed;
  }
}

.planet-exploration-micro-game {
  display: flex;
  justify-content: center;
}

.planet-exploration-missions-right-container-text {
  position: absolute;
  left: 51%;
  top: 81%;

  font-family: $sofia;
  font-weight: 400;
  font-size: 1.375rem;
}

.planet-exploration-claim-button {
  position: absolute;
  top: 65%;
  right: 10%;

  width: 405.5px;
  height: 60.57px;

  color: white;
  font-family: $oswald;
  font-weight: 400;
  font-size: 2.25rem;

  background-color: $pink;
  border: none;
  clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
  transition-duration: 0.2s;
}

.planet-exploration-claim-button:hover {
  transform: scale(1.1);
  transition: all 0.2s ease;
}

.planet-exploration-timer-container {
  position: absolute;
  top: 52%;
  right: 15%;

  .planet-exploration-timer-counter-main-container {
    .planet-exploration-timer-counter-container {
      width: 312px;

      .top-decoration {
        display: none;
      }

      .sale-countdown-container {
        .bottom-decoration {
          display: none;
        }
      }
    }
  }
}

@media screen and (min-width: 1441px) and (max-width: 1920px) {
  .planet-exploration-missions-main-container {
    height: 36.302vw;
  }

  .planet-exploration-one-mission-main-container {
    height: 36.302vw;

    .planet-exploration-one-mission-picture-container {
      width: 64.323vw;
    }
  }

  .planet-exploration-missions-left-container {
    width: 15.417vw;
  }

  .planet-exploration-mission-tab {
    margin-bottom: 0.833vw;
    margin-right: 1.042vw;
  }

  .planet-exploration-missions-main-container ::-webkit-scrollbar {
    width: 0.313vw;
    height: 21.354vw;
    border-top: 1.042vw;
  }

  .planet-exploration-missions-main-container ::-webkit-scrollbar-thumb {
    padding: 0.521vw;
    height: 5.104vw;
    border-radius: 0.521vw;
  }

  .planet-exploration-missions-right-container {
    width: 64.323vw;
  }

  .planet-exploration-missions-right-container-text {
    font-size: clamp(1rem, 1.146vw, 1.375rem);
  }

  .planet-exploration-claim-button {
    width: 21.12vw;
    height: 3.155vw;

    font-size: clamp(1.6875rem, 1.875vw, 2.25rem);
  }

  .planet-exploration-timer-container {
    .planet-exploration-timer-counter-main-container {
      .planet-exploration-timer-counter-container {
        width: 16.25vw;

        .next-claim-text {
          font-size: clamp(0.75rem, 0.833vw, 1rem);
        }
        .sale-countdown-container {
          height: 4.792vw;

          .sale-countdown-timecells-container {
            .countdown-time-text,
            .countdown-time-text-separator {
              font-size: clamp(1.68rem, 1.875vw, 2.25rem);
            }
            .countdown-time-under-text {
              font-size: clamp(0.625rem, 0.729vw, 0.875rem);
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .planet-exploration-one-mission-main-container {
    height: 42.708vw;

    .planet-exploration-one-mission-picture-container {
      width: 75.903vw;
    }
  }

  .planet-exploration-missions-main-container {
    height: 42.708vw;

    .planet-exploration-missions-left-container {
      width: 15.417vw;
    }

    .planet-exploration-mission-tab {
      margin-bottom: 0.833vw;
      margin-right: 1.042vw;
    }

    .planet-exploration-missions-right-container {
      width: 75.903vw;
    }
  }

  .planet-exploration-missions-main-container ::-webkit-scrollbar {
    width: 0.313vw;
    height: 21.354vw;
    border-top: 1.042vw;
  }

  .planet-exploration-missions-main-container ::-webkit-scrollbar-thumb {
    padding: 0.521vw;
    height: 5.104vw;
    border-radius: 0.521vw;
  }

  .planet-exploration-missions-right-container-text {
    font-size: clamp(0.875rem, 1.389vw, 1.25rem);
    top: 83%;
  }

  .planet-exploration-claim-button {
    right: 16%;

    width: 15.347vw;
    height: 3.194vw;

    font-size: clamp(0.875rem, 1.389vw, 1.25rem);
  }

  .planet-exploration-timer-container {
    top: 50%;
    right: 13%;

    .planet-exploration-timer-counter-main-container {
      .planet-exploration-timer-counter-container {
        width: 21.667vw;

        .next-claim-text {
          font-size: clamp(0.75rem, 1.111vw, 1rem);
        }
        .sale-countdown-container {
          height: 6.389vw;

          .sale-countdown-timecells-container {
            .countdown-time-text,
            .countdown-time-text-separator {
              font-size: clamp(1.56rem, 2.5vw, 2.25rem);
            }
            .countdown-time-under-text {
              font-size: clamp(0.56rem, 0.972vw, 0.875rem);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .planets-mission-title-container {
    .page-header {
      padding-bottom: 0;
      height: auto;
      margin-top: 150px;
      margin-bottom: 150px;
    }
  }

  .planet-exploration-one-mission-main-container {
    .planet-exploration-one-mission-picture-container {
      width: 82.813vw;
    }
  }

  .planet-exploration-missions-main-container {
    height: unset;

    .planet-exploration-missions-left-container {
      width: 15.3vw;
      height: 46.484vw;
      margin-left: 1vw;
    }

    .planet-exploration-mission-tab {
      margin-bottom: 1.1vw;
      margin-right: 0.45vw;

      .mission-tab-main-container {
        width: 13.965vw;
        height: 4.2vw;

        .mission-tab-left-decor {
          height: 4.2vw;
        }

        .mission-tab-right-container {
          margin-top: 0.1vw;
          margin-left: 0.5vw;

          .mission-tab-title {
            font-size: clamp(0.49rem, 0.928vw, 0.59rem);
            margin-bottom: 0;
            height: 1.5vw;
          }

          .mission-tab-content {
            font-size: clamp(0.65rem, 1.172vw, 0.75rem);
          }
        }
      }
    }

    .planet-exploration-missions-right-container {
      width: 82.715vw;
    }
  }

  .planet-exploration-missions-main-container ::-webkit-scrollbar {
    width: 0.313vw;
    height: 21.354vw;
    border-top: 1.042vw;
  }

  .planet-exploration-missions-main-container ::-webkit-scrollbar-thumb {
    padding: 0.521vw;
    height: 5.104vw;
    border-radius: 0.521vw;
  }

  .planet-exploration-missions-right-container-text {
    font-size: clamp(0.75rem, 1.563vw, 1rem);
  }

  .planet-exploration-claim-button {
    right: 15%;

    width: 20.801vw;
    height: 4.297vw;

    font-size: clamp(0.9375rem, 1.953vw, 1.25rem);
  }

  .planet-exploration-timer-container {
    top: 48%;
    right: 15%;

    .planet-exploration-timer-counter-main-container {
      .planet-exploration-timer-counter-container {
        width: 21.387vw;

        .next-claim-text {
          font-size: clamp(0.625rem, 1.367vw, 0.875rem);
        }
        .sale-countdown-container {
          height: 7vw;

          .sale-countdown-timecells-container {
            .countdown-time-text,
            .countdown-time-text-separator {
              font-size: clamp(1.125rem, 2.441vw, 1.5625rem);
            }
            .countdown-time-under-text {
              font-size: clamp(0.5rem, 1.172vw, 0.75rem);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .planets-mission-title-container {
    .page-header {
      padding-bottom: 0;
      height: auto;
      margin-top: 48px;
      margin-bottom: 48px;
    }
  }

  .planet-exploration-one-mission-main-container {
    width: 100%;
    height: 56.51vw;

    .planet-exploration-one-mission-picture-container {
      width: 100%;
    }
  }

  .planet-exploration-missions-main-container {
    flex-direction: column-reverse;
    align-items: center;
    height: unset;
    width: 97%;

    .planet-exploration-missions-left-container {
      justify-content: center;
      width: 95%;
      max-width: 95%;
      height: 16.406vw;
      margin-top: 20px;
      padding: 0 10px 0 10px;

      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(27.344vw, 27.344vw));
      column-gap: 8px;

      .planet-exploration-mission-tab {
        width: 27.344vw;
        height: 7.161vw;

        .mission-tab-main-container {
          width: 27.344vw;
          height: 7.161vw;

          .mission-tab-left-decor {
            height: 7.161vw;
          }

          .mission-tab-right-container {
            margin-top: 1.042vw;
            margin-left: 1.5vw;

            .mission-tab-title {
              font-size: clamp(0.1rem, 1.823vw, 0.875rem);
              line-height: 2.344vw;
              height: unset;
            }

            .mission-tab-content {
              font-size: clamp(0.1rem, 2.083vw, 1rem);
              line-height: 2.344vw;
            }
          }
        }
      }
    }

    .planet-exploration-missions-right-container {
      width: 100%;
      margin: 0;

      .planet-exploration-missions-right-container-text {
        font-size: clamp(0.4375rem, 1.823vw, 0.875rem);
        top: 93%;
      }

      .planet-exploration-claim-button {
        right: 12%;

        width: 28.255vw;
        height: 5.469vw;

        font-size: clamp(0.5rem, 2.344vw, 1.125rem);
        clip-path: polygon(1vw 0, 0 1vw, 0 calc(100% - 1vw), 1vw 100%, calc(100% - 1vw) 100%, 100% calc(100% - 1vw), 100% 1vw, calc(100% - 1vw) 0);
      }
    }
  }

  .planet-exploration-timer-container {
    top: 46%;
    right: 13%;

    .planet-exploration-timer-counter-main-container {
      .planet-exploration-timer-counter-container {
        width: 28.646vw;

        .next-claim-text {
          font-size: clamp(0.625rem, 2.083vw, 1rem);
        }
        .sale-countdown-container {
          height: 9vw;

          .sale-countdown-timecells-container {
            .countdown-time-text,
            .countdown-time-text-separator {
              font-size: clamp(1.125rem, 3.255vw, 1.5625rem);
            }
            .countdown-time-under-text {
              font-size: clamp(0.5rem, 1.563vw, 0.75rem);
            }
          }
        }
      }
    }
  }
}

// Changed the max-device-width to support up to iPhone 14 Pro MAX which has a width of 428 pixels
@media screen and (max-device-width: 428px) {
  .planet-exploration-missions-main-container {

    .planet-exploration-missions-left-container {
      display: flex;
      height: auto;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;
      /* top | right | bottom | left */
      padding: 0 0 0 1rem;
      margin: 50px auto 0 auto;
      overflow: visible;
      scrollbar-width: none;
      gap: 10px;

      .planet-exploration-mission-tab {
        width: 42vw;
        height: 13.333vw;

        .mission-tab-main-container {
          width: 40vw;
          height: 13.333vw;
          margin-right: auto;

          .mission-tab-left-decor {
            height: 13.333vw;
          }

          .mission-tab-right-container {
            margin-top: 1.333vw;
            .mission-tab-title {
              font-size: clamp(0.1rem, 3.2vw, 0.75rem);
              height: unset;
              line-height: 4.8vw;
            }

            .mission-tab-content {
              font-size: 0.90rem;
              line-height: 4.8vw;
            }
          }
        }
      }
    }

    .planet-exploration-missions-image {
      display: none;
    }

    .planet-exploration-missions-image-mobile {
      display: block;

      -webkit-mask: radial-gradient(ellipse 700px 425px at 50% 80%, transparent, #fff 15%);
      mask: radial-gradient(ellipse 700px 425px at 50% 80%, transparent, #fff 15%);
      &-faded {
        opacity: 0.8;
      }
    }

    .planet-exploration-micro-game-mobile-launch-text {
      position: absolute;
      top: 75%;
      left: 34%;
      font-family: $oswald;
      font-weight: 400;
      font-size: 1.5rem;
      text-transform: uppercase;
      cursor: pointer;
    }

    .planet-exploration-missions-right-container {
      .planet-exploration-missions-right-container-text {
        font-size: clamp(0.4375rem, 3.8vw, 0.875rem);
        top: 82%;
        left: 11%;
        z-index: 1;
      }

      .planet-exploration-claim-button {
        left: 10%;
        top: 74%;
        margin: auto;

        width: 50.933vw;
        height: 9.867vw;

        font-size: clamp(0.5rem, 4.267vw, 1rem);
      }
    }
  }

  .planet-exploration-timer-container {
    top: 60%;
    right: 14%;

    background: radial-gradient(circle, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 72%);

    .planet-exploration-timer-counter-main-container {
      .planet-exploration-timer-counter-container {
        width: 72.267vw;

        .next-claim-text {
          font-size: 0.75rem;
        }
        .sale-countdown-container {
          height: 25vw;

          .sale-countdown-timecells-container {
            .countdown-time-text,
            .countdown-time-text-separator {
              font-size: 1.5625rem;
            }
            .countdown-time-under-text {
              font-size: 0.625rem;
            }
          }
        }
      }
    }
  }
}

.ceres-quadrant__modal-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;
  gap: 1.5rem;

  @media screen and (max-width: 375px) {
    padding: 0 1rem;
  }

  &>p{
    text-align: center;
    font-size: 1rem;
    margin-bottom: 0;

    @media screen and (max-width: 375px) {
      font-size: 1rem;
    }
  }

  a {
    color: $white;
  }
}

.ceres-quadrant__modal-success-text-container {
  &>p {
    text-align: center;
    margin-bottom: 0;

    @media screen and (max-width: 375px) {
      font-size: 1rem;
    }
  }

  p:first-child {
    margin-bottom: 1rem;
  }
}

.modal-error-icon {
  width: 32px;
  padding-bottom: 25px;
}

