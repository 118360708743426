@import "../../variables";


.burger-menu-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 327px;
  z-index: 14;
  font-family: Oswald;
  font-weight: 400;
  display: table;
}

.burger-menu-icon {
  position: absolute;
  z-index: 15;
  top: 24px;
  left: 32px;
  width: 30px;
  height: 30px;
  float: left;
  cursor: pointer;
}

.burger-exit-icon {
  position: relative;
  top: 23px;
  left: 23px;
  float: left;
  z-index: 15;
  cursor: pointer;
}

.exit-icon {
  width: 22px;
  height: 22px;
}

.burger-menu-container-closed {
  display: none;
}

.burger-menu-container-opened {
  display: table;
  background: rgba(0, 0, 0, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 14;
}

.phantom-logo {
  position: absolute;
  display: inline-block;
  float: right;
  right: 7%;
  top: 18px;
}

.logo-image {
  width: 100%;
  height: auto;
}

.list-entries {
  position: relative;
  display: inline-block;
  top: 80px;
  width: 100%;
  height: auto;
  margin-bottom: 100px;
  padding-left: 0;
}

.entries {
  position: relative;
  color: $white;
  height: 38px;
  width: 100%;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  cursor: pointer;
  align-items: center;
  text-decoration: none;

  &:hover {
    color: $pink;
    clip-path: polygon(96% 0, 100% 40%, 100% 100%, 0 100%, 0 0);
    border-left: 6px solid $pink;
    padding-left: 24px;
  }
}

.entries-open {
  position: relative;
  color: $white;
  height: 38px;
  width: 100%;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  padding-left: 45px;
  cursor: pointer;
  align-items: center;
  text-decoration: none;

  &:hover {
    color: $pink;
    clip-path: polygon(96% 0, 100% 40%, 100% 100%, 0 100%, 0 0);
    border-left: 6px solid $pink;
    padding-left: 39px;
  }
}

.entries-open.hovered {
  color: $pink;
}

.entries-open.disabled:hover {
  color: $white;
}

.entries-hover {
  position: relative;
  color: $pink;
  height: 38px;
  width: 100%;
  font-size: 16px;
  display: flex;
  padding-left: 24px;
  cursor: pointer;
  align-items: center;
  clip-path: polygon(96% 0, 100% 40%, 100% 100%, 0 100%, 0 0);
  border-left: 6px solid $pink;
}

.social-entries:hover .social-component-main-container .top-menu-dropdown-list-item.SOCIALS{
  cursor: pointer;
  color: $pink;
}

.social-entries .social-component-main-container .top-menu-dropdown-list-item.SOCIALS{
  background-color: transparent;
}

.submenu-entries-opened {
  position: relative;
  color: $white;
  height: 38px;
  width: 100%;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  padding-left: 69px;
  cursor: pointer;
  align-items: center;
  text-decoration: none;

  &:hover {
    color: $pink;
    clip-path: polygon(96% 0, 100% 40%, 100% 100%, 0 100%, 0 0);
    border-left: 6px solid $pink;
    padding-left: 63px;
  }
}

.entries-closed {
  display: none;
}

.bottom-container {
  position: relative;
  margin-top: 10px;
  padding-bottom: 25px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.burger-connect-wallet-button {
  top: 15%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#connect-button .button {
  width: 100%;
  margin-left: 12.5%;
  margin-right: 12.5%;
  position: relative;
}

.burger-line-separator {
  width: 100%;
  text-align: center;
}

.burger-line-separator-img {
  width: 85%;
}

.arrow-up {
  position: absolute;
  transform: rotate(180deg);
  left: 3%;
}

.arrow-down {
  position: absolute;
  left: 2%;
  filter: invert(41%) sepia(87%) saturate(6173%) hue-rotate(328deg) brightness(86%) contrast(93%);
}

.entries-open .arrow-down{
  left: 8%;
}

.entries-open .arrow-right{
  left: 7.5%;
}

.arrow-right {
  position: absolute;
  left: 3%;
  transform: rotate(-90deg);
}

.entries:hover .arrow-right {
  left: 1.2%;
  filter: invert(41%) sepia(87%) saturate(6173%) hue-rotate(328deg) brightness(86%) contrast(93%);
}

.entries-open:hover .arrow-right {
  left: 5.6%;
  filter: invert(41%) sepia(87%) saturate(6173%) hue-rotate(328deg) brightness(86%) contrast(93%);
}

.entries-open:hover .arrow-down{
  left: 6.2%;
}

.nav-container-position {
  width: 100%;
  max-width: 280px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 9px;
  margin-bottom: 13px;
}

.discord-logo {
  background-color: transparent;
  cursor: pointer;
  height: 17px;
  mix-blend-mode: normal;
  pointer-events: auto;
  transition: all .2s ease;
  width: 17px
}

.discord-logo:hover {
  transform: scale(1.1)
}

.paper-plane-logo {
  background-color: transparent;
  cursor: pointer;
  height: 17px;
  mix-blend-mode: normal;
  pointer-events: auto;
  transition: all .2s ease;
  width: 17px
}

.paper-plane-logo:hover {
  transform: scale(1.1)
}

.twitter-logo {
  background-color: transparent;
  cursor: pointer;
  height: 17px;
  mix-blend-mode: normal;
  pointer-events: auto;
  transition: all .2s ease;
  width: 17px
}

.twitter-logo:hover {
  transform: scale(1.1)
}

.instagram-logo {
  background-color: transparent;
  cursor: pointer;
  height: 17px;
  mix-blend-mode: normal;
  pointer-events: auto;
  transition: all .2s ease;
  width: 17px
}

.instagram-logo:hover {
  transform: scale(1.1)
}

.facebook-logo {
  background-color: transparent;
  cursor: pointer;
  height: 17px;
  mix-blend-mode: normal;
  pointer-events: auto;
  transition: all .2s ease;
  width: 8px
}

.facebook-logo:hover {
  transform: scale(1.1)
}

.twitch-logo {
  background-color: transparent;
  cursor: pointer;
  height: 25px;
  mix-blend-mode: normal;
  pointer-events: auto;
  transition: all .2s ease;
  width: 25px
}

.twitch-logo:hover {
  transform: scale(1.1)
}

.youtube-logo {
  background-color: transparent;
  cursor: pointer;
  mix-blend-mode: normal;
  pointer-events: auto;
  transition: all .2s ease;
}

.youtube-logo:hover {
  transform: scale(1.1)
}

.medium-logo {
  background-color: transparent;
  cursor: pointer;
  height: 17px;
  mix-blend-mode: normal;
  pointer-events: auto;
  transition: all .2s ease;
  width: 17px
}

.medium-logo:hover {
  transform: scale(1.1)
}


@media (min-width: 1025px) {
  .burger-menu-container {
    display: none;
  }


}

@media (min-width: 769px) and (max-width: 1024px) {
  .burger-menu-container {
    width: 337px;
  }

}

@media (max-width: 376px) {
  .burger-menu-container {
    width: 100%;
  }

  .download-remove {
    display: none;
  }

  .burger-exit-icon {
    top: 39px;
  }
}

@media (min-width: 376px) and (max-width: 768px) {
  .burger-menu-container {
    width: 301px;
  }
  .burger-line-separator-img{
    width: initial;
  }
  .download-remove {
    display: none;
  }

  .burger-exit-icon {
    top: 20px;
  }

}

.connect-button-element {
  width: 222px;
  height: 37px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
}

.connect-wallet-button {
  position: absolute;
  background: $pink;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1px 1px;
  height: 37px;
  clip-path: polygon(2% 0, 98% 0, 100% 12%, 100% 88%, 98% 100%, 2% 100%, 0% 88%, 0 12%);
}

.connect-button-border {
  position: relative;
  background-color: $dark-pink;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  clip-path: polygon(2% 0, 98% 0, 100% 12%, 100% 88%, 98% 100%, 2% 100%, 0% 88%, 0 12%);
  width: 222px;
  height: 37px;
  cursor: pointer;
}

.burger-menu-container .wallet-address-design {
  background: white;
  clip-path: polygon(2% 0, 98% 0, 100% 12%, 100% 88%, 98% 100%, 2% 100%, 0% 88%, 0 12%);
  margin: 1px 1px;
}

.connect-wallet {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

