@import "../../../variables";

$images-path: '../../../assets/images/';

.episode-header .genesis-nfts-title h2 {
  font-family: $oswald;
  font-weight: 400;
  font-size: 2.083vw;
  margin-top: -0.5vw;
  margin-bottom: 1.5vw;
}

.episode-header .genesis-nfts-image-tbc {
  width: 41.042vw;
  height: 19.948vw;
  overflow: hidden;
  margin-left: -0.5vw;
}

.genesis-nft-body {
  font-weight: 400;
  line-height: 1.302vw;
  margin-bottom: 0;
  width: 40vw;
  margin-left: -0.521vw;
  margin-top: 2.1vw;
}

.genesis-nft-body .title {
  font-family: $oswald;
  font-size: 1.2rem;
  color: $pink;
  line-height: 1;
}

.genesis-nft-body .body {
  font-family: $sofia;
  font-size: 1rem;
  width: 40vw;
  line-height: 1;
}

.genesis-nft-body .more-info {
  font-family: $oswald;
  font-weight: 400;
  line-height: 1;
  margin-top: 1.24vw;
  text-decoration: none;
  color: $white;
}

.genesis-nfts-panel {
  position: relative;
  border-top: $white 1px solid;
  margin-top: 1.479vw;
}

.genesis-nfts-panel:before {
  background-image: url(#{$images-path}section-bottom.png);
  content: '';
  background-size: 3.490vw 1.771vw;
  background-repeat: no-repeat;
  width: 3.490vw;
  height: 1.771vw;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1.771vw;
}

.description-open-sea,
.description-open-sea:hover {
  color: $white;
}

.mint-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 3.958vw;
}

.poster-mint-button {
  font-family: $oswald;
  font-size: 0.833vw;
  width: 9.948vw;
  height: 1.927vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;

  background-color: $dark-cherry-background;
  -webkit-clip-path: polygon(0.365vw 0, 0 0.365vw, 0 calc(100% - 0.365vw), 0.365vw 100%, calc(100% - 0.365vw) 100%, 100% calc(100% - 0.365vw), 100% 0.365vw, calc(100% - 0.365vw) 0);
  clip-path: polygon(0.365vw 0, 0 0.365vw, 0 calc(100% - 0.365vw), 0.365vw 100%, calc(100% - 0.365vw) 100%, 100% calc(100% - 0.365vw), 100% 0.365vw, calc(100% - 0.365vw) 0);
  cursor: pointer;

  margin-left: 0.938vw;

  &.disabled {
    background-color: $stack;
    color: $white;
    cursor: auto;
  }
}

.astrafer-mint-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 10.052vw;
  height: 1.927vw;

  font-size: 0.833vw;
  font-family: $oswald;

  background-color: $white;
  color: $pink;

  -webkit-clip-path: polygon(0.365vw 0, 0 0.365vw, 0 calc(100% - 0.365vw), 0.365vw 100%, calc(100% - 0.365vw) 100%, 100% calc(100% - 0.365vw), 100% 0.365vw, calc(100% - 0.365vw) 0);
  clip-path: polygon(0.365vw 0, 0 0.365vw, 0 calc(100% - 0.365vw), 0.365vw 100%, calc(100% - 0.365vw) 100%, 100% calc(100% - 0.365vw), 100% 0.365vw, calc(100% - 0.365vw) 0);
  cursor: pointer;

  margin-right: 0.938vw;

  &.disabled {
    background-color: $stack;
    color: $white;
    cursor: auto;
  }
}

.astrafer-mint-button img {
  width: 1.042vw;
  height: 1.146vw;
  margin-right: 0.573vw;
}


.buy-on-opensea-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 10.052vw;
  height: 1.927vw;

  font-size: 0.833vw;
  font-family: $oswald;

  background-color: $light-ocean-blue;
  color: white;

  -webkit-clip-path: polygon(0.365vw 0, 0 0.365vw, 0 calc(100% - 0.365vw), 0.365vw 100%, calc(100% - 0.365vw) 100%, 100% calc(100% - 0.365vw), 100% 0.365vw, calc(100% - 0.365vw) 0);
  clip-path: polygon(0.365vw 0, 0 0.365vw, 0 calc(100% - 0.365vw), 0.365vw 100%, calc(100% - 0.365vw) 100%, 100% calc(100% - 0.365vw), 100% 0.365vw, calc(100% - 0.365vw) 0);
  cursor: pointer;

  margin-right: 0.938vw;
}

.buy-on-opensea-button img {
  width: 1.042vw;
  height: 1.146vw;
  margin-right: 0.573vw;
}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .episode-header {
    width: 660px;
  }

  .episode-header .genesis-nfts-title h2 {
    font-weight: 400;
    font-size: 5.208vw;
    margin-top: -0.75vw;
    margin-bottom: 5.208vw;
    width: 85.938vw;
    text-align: center;
  }

  .episode-header .genesis-nfts-image-tbc {
    width: 91.146vw;
    height: 44.271vw;
    margin-bottom: 3.906vw
  }

  .genesis-nft-body {
    width: 90%;
  }

  .genesis-nft-body .title {
    font-size: 1.2rem;
    line-height: 1;
  }

  .genesis-nft-body .body {
    font-size: 1rem;
    width: 83.333vw;
    margin-bottom: 4vw;
  }

  .genesis-nft-body .more-info {
    font-size: 1rem;
    line-height: 1;
  }

  .genesis-nfts-panel {
    margin-top: 2.479vw;
  }

  .genesis-nfts-panel:before {
    background-size: 8.724vw 4.427vw;
    width: 8.724vw;
    height: 4.427vw;
    top: 0;
    left: 50%;
    margin-left: -4.25vw;
  }

  .mint-buttons-container {
    margin-top: 11.25vw;
    height: 4.818vw;
    margin-bottom: 8vw;
  }

  .poster-mint-button {
    font-size: 2.083vw;
    width: 25.130vw;
    height: 4.818vw;
    margin-left: 3.9vw;

    -webkit-clip-path: polygon(0.911vw 0, 0 0.911vw, 0 calc(100% - 0.911vw), 0.911vw 100%, calc(100% - 0.911vw) 100%, 100% calc(100% - 0.911vw), 100% 0.911vw, calc(100% - 0.911vw) 0);
    clip-path: polygon(0.911vw 0, 0 0.911vw, 0 calc(100% - 0.911vw), 0.911vw 100%, calc(100% - 0.911vw) 100%, 100% calc(100% - 0.911vw), 100% 0.911vw, calc(100% - 0.911vw) 0);
  }

  .astrafer-mint-button {
    font-size: 2.083vw;
    width: 25.130vw;
    height: 4.818vw;

    -webkit-clip-path: polygon(0.911vw 0, 0 0.911vw, 0 calc(100% - 0.911vw), 0.911vw 100%, calc(100% - 0.911vw) 100%, 100% calc(100% - 0.911vw), 100% 0.911vw, calc(100% - 0.911vw) 0);
    clip-path: polygon(0.911vw 0, 0 0.911vw, 0 calc(100% - 0.911vw), 0.911vw 100%, calc(100% - 0.911vw) 100%, 100% calc(100% - 0.911vw), 100% 0.911vw, calc(100% - 0.911vw) 0);
  }

  .astrafer-mint-button img {
    width: 2.474vw;
    height: 2.865vw;
    margin-right: 1.25vw;
  }

  .buy-on-opensea-button {
    font-size: 2.083vw;
    width: 25.130vw;
    height: 4.818vw;

    -webkit-clip-path: polygon(0.911vw 0, 0 0.911vw, 0 calc(100% - 0.911vw), 0.911vw 100%, calc(100% - 0.911vw) 100%, 100% calc(100% - 0.911vw), 100% 0.911vw, calc(100% - 0.911vw) 0);
    clip-path: polygon(0.911vw 0, 0 0.911vw, 0 calc(100% - 0.911vw), 0.911vw 100%, calc(100% - 0.911vw) 100%, 100% calc(100% - 0.911vw), 100% 0.911vw, calc(100% - 0.911vw) 0);
  }

  .buy-on-opensea-button img {
    width: 2.474vw;
    height: 2.865vw;
    margin-right: 1.25vw;
  }
}

@media screen and (max-device-width: 375px) {
  .genesis-tabs-container .episode-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .genesis-tabs-container .episode-header .genesis-nfts-title h2 {
    font-size: 8vw;
    margin-top: 0;
    margin-bottom: 8vw;
    text-align: center;
    padding: 0 4vw;
  }
  .genesis-tabs-container .episode-header .genesis-nfts-image-tbc {
    width: 91.467vw;
    height: 44.267vw;
    margin-left: 0;
  }

  .genesis-nft-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    line-height: 7.200vw;
    margin-top: 13.333vw;
    padding: 0 4.267vw;
  }

  .genesis-nft-body .title {
    font-size: 1.2rem;
    width: 100%;
    margin-bottom: 4vw;
  }

  .genesis-nft-body .body {
    font-size: 1rem;
    width: 100%;
  }
  .genesis-nft-body .more-info {
    font-size: 4.800vw;
    line-height: 7.200vw;
    margin-top: 0;
    text-align: center;
    margin-bottom: 6.933vw;
  }
  .genesis-nfts-panel {
    margin-top: 0;
  }

  .genesis-nfts-panel:before {
    background-size: 17.867vw;
    width: 17.867vw;
    height: 9.067vw;
    margin-left: -9.067vw;
  }
  .mint-buttons-container {
    flex-direction: column;
    margin-top: 20.267vw;
  }
  .poster-mint-button {
    font-size: 4.267vw;
    width: 51.467vw;
    height: 9.867vw;
    -webkit-clip-path: polygon(1.600vw 0, 0 1.600vw, 0 calc(100% - 1.600vw), 1.600vw 100%, calc(100% - 1.600vw) 100%, 100% calc(100% - 1.600vw), 100% 1.600vw, calc(100% - 1.600vw) 0);
    clip-path: polygon(1.600vw 0, 0 1.600vw, 0 calc(100% - 1.600vw), 1.600vw 100%, calc(100% - 1.600vw) 100%, 100% calc(100% - 1.600vw), 100% 1.600vw, calc(100% - 1.600vw) 0);
    margin-left: 0;
  }

  .astrafer-mint-button {
    font-size: 4.267vw;
    width: 51.467vw;
    height: 9.867vw;
    -webkit-clip-path: polygon(1.600vw 0, 0 1.600vw, 0 calc(100% - 1.600vw), 1.600vw 100%, calc(100% - 1.600vw) 100%, 100% calc(100% - 1.600vw), 100% 1.600vw, calc(100% - 1.600vw) 0);
    clip-path: polygon(1.600vw 0, 0 1.600vw, 0 calc(100% - 1.600vw), 1.600vw 100%, calc(100% - 1.600vw) 100%, 100% calc(100% - 1.600vw), 100% 1.600vw, calc(100% - 1.600vw) 0);
    margin-right: 0;
    margin-bottom: 11.467vw;
  }

  .astrafer-mint-button img {
    width: 5.067vw;
    height: 5.600vw;
    margin-right: 2.933vw;
  }

  .buy-on-opensea-button {
    font-size: 4.267vw;
    width: 51.467vw;
    height: 9.867vw;
    -webkit-clip-path: polygon(1.600vw 0, 0 1.600vw, 0 calc(100% - 1.600vw), 1.600vw 100%, calc(100% - 1.600vw) 100%, 100% calc(100% - 1.600vw), 100% 1.600vw, calc(100% - 1.600vw) 0);
    clip-path: polygon(1.600vw 0, 0 1.600vw, 0 calc(100% - 1.600vw), 1.600vw 100%, calc(100% - 1.600vw) 100%, 100% calc(100% - 1.600vw), 100% 1.600vw, calc(100% - 1.600vw) 0);
    margin-right: 0;
    margin-bottom: 11.467vw;
  }

  .buy-on-opensea-button img {
    width: 5.067vw;
    height: 5.600vw;
    margin-right: 2.933vw;
  }

}
