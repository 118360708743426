@import "../../../../variables";

.claim-info-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.available-amount-container,
.remaining-amount-main-container,
.next-date-main-container{
  width: 326px;
  margin: 0 19px 0 19px;
}

.remaining-amount-main-container.mobile{
  display: none;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .available-amount-container,
  .remaining-amount-main-container,
  .next-date-main-container{
    width: 246px;
    margin: 0 16px 0 16px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .available-amount-container,
  .remaining-amount-main-container,
  .next-date-main-container{
    width: 18.555vw;
    margin: 0 1.172vw 0 1.172vw;
  }
}
@media screen and (min-width: 376px) and (max-width: 768px) {
  .available-amount-container,
  .remaining-amount-main-container,
  .next-date-main-container{
    width: 25.130vw;
    margin: 0 1.693vw 0 1.693vw;
  }
}

@media screen and (max-width: 375px) {
  .available-amount-container,
  .remaining-amount-main-container,
  .next-date-main-container{
    width: 26.933vw;
    margin: 0 1.867vw 0 1.867vw;
  }
  .remaining-amount-main-container{
    display: none;
  }
  .remaining-amount-main-container.mobile {
  display: block;
    }
  }
