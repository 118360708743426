@import "../../../variables";

.claim-reward-content-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: $claim-rewards-background;
  margin-bottom: 100px;
}

