@import "../../variables";

$elem-z-index: 1;
$video-z-index: 0;

.downward-vee-kyc{
  margin-top: -120px !important;
  margin-bottom: -95px;
}

.kyc-main-body{
  position: relative;
  margin-top: 2px;
  background: gray;
  width: 100%;
  height: 1050px;
  min-height: 550px;
  padding-top: 68px;
  padding-bottom: 60px;
  clip-path: $main-element-clip-path;
  transform: translate3d(0,0,0);
}

.kyc-description-box1{
  width: 1000px;
  margin: 0 auto;
}

.kyc-description-box2{
  width: 550px;
  margin: 0 auto;
}

.kyc-delimiter-plus-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 68px;
  margin-bottom: 68px;
  z-index: $elem-z-index;
}

.kyc-delimiter-plus-image{
  width: 150px;
  z-index: $elem-z-index;
}

.kyc-connect-wallet-button-container{
  width: 200px;
  height: 42px;
  margin: 20px auto;
}

.kyc-verify-button-container{
  width: 150px;
  height: 42px;
  margin: 32px auto 2px;
}

.kyc-verify-container{
  margin-top: 68px;
  margin-bottom: 20px;
}

.kyc-verify-check-mark-container{
  display: flex;
  justify-content: center;
  align-items: center;

  img{
    width: 55px;
    z-index: $elem-z-index;
  }
}

.kyc-video-container{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: $video-z-index;
  top: 0;
}

.kyc-video-inside{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.kyc-overlay-content{
  position: absolute;
  width: 100%;
  height: 600px;
}

#connectButton .button{
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d42758;
  -webkit-clip-path: polygon(5% 0, 100% 0, 95% 100%, 0% 100%);
  clip-path: polygon(5% 0, 100% 0, 95% 100%, 0% 100%);
  font-family: "Oswald", Helvetica;
  font-size: 20px;
  color: white;
  cursor: pointer;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 42px;
}

#blockpassButton .blockpass-button{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 42px;
  background: #d42758;
  -webkit-clip-path: polygon(5% 0, 100% 0, 95% 100%, 0% 100%);
  clip-path: polygon(5% 0, 100% 0, 95% 100%, 0% 100%);
  font-family: "Oswald", Helvetica;
  font-size: 20px;
  color: white;
  cursor: pointer;
  border: 0px;
}

.main-kyc-container{
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media only screen and (max-width: 1000px) {
  .kyc-description-box1{
    width: 100% !important;
  }
}
