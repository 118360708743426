.page-header {
  text-align: center;
  padding-bottom: 100px;
  margin-top: 20px;
  font-size: 45px ;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 400px;
}

.page-header .page-header-text {
  position: absolute;
  width: 80%
}
.page-mobile-header-text{
  position: absolute;
  width: 100%;
  font-size: 45px;
  font-family: Oswald;
  margin-bottom: 31px;
}
.page-mobile-header-text-no-margin{
  @extend .page-mobile-header-text;
  margin-bottom: 0;
}

.psg-border-img-pc{
  display: none;
  overflow: hidden;
  max-width: 100%;
}
.psg-border-img-mobile{
  display: none;
  width: 100%;
  z-index: 10;
}
.psg-border-img-tablet{
  display: none;
  width: 100%;
  z-index: 10;
}
.psg-border-img-mini-screen{
  width: 100%;
  display: none;
}

@media screen  and (min-width: 1025px) {

.psg-border-img-pc{
  display: flex;
}
}

@media screen  and (min-width: 769px)   and (max-width: 1024px){
  .psg-border-img-mini-screen{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
  }
  .page-header{
    margin-top: -18px;
  }
}
@media screen and (min-width: 376px) and (max-width: 768px) {
  .psg-border-img-tablet{
    display: flex;
  }
  .page-header{
    padding-bottom: 13.021vw;
    margin-top: 2.604vw;
    font-size: 5.859vw;
    height: 52.083vw;
  }
}

@media screen and (max-width: 425px) {
  .page-mobile-header-text {
    font-size: 25px;
    margin-bottom: 20px;
  }
}

@media screen and (max-device-width: 375px) {
  .psg-border-img-mobile{
    display: flex;
  }
  .page-header{
    padding-bottom: 26.667vw;
    margin-top: 5.333vw;
    font-size: 12vw;
    height: 106.667vw;
  }

}


