@import '../../../variables';

.asset-not-found {
  display: flex;
  font-family: $oswald;
  flex-direction: column;
  text-align: center;

  @media (min-width: 576px) {
    position: relative;
  }

  &__blackhole {
    width: 200%;
    position: relative;
    right: 90%;

    @media (min-width: 576px) {

      width: 100%;
      max-width: none;
      height: auto;
      right: 0;
    }
  }

  &__image {
    position: absolute;
    z-index: 1;
    text-align: center;
    animation: rotateAndScale 10s linear forwards, circleAround 20s linear forwards, fadeOut 10s linear forwards;

    @keyframes rotateAndScale {
      0% {
        transform: scale(0.2) rotate(0deg);
      }
      100% {
        transform: scale(0) rotate(360deg);
      }
    }
    @keyframes circleAround {
      0% {
        right: 45%;
        top: 30%;
      }
      25% {
        right: 25%;
        top: 25%;
      }
      50% {
        right: 15%;
        top: 20%;
      }
      75% {
        right: 5%;
        top: 15%;
      }
      100% {
        right: -5%;
        top: 15%;
      }
    }

    @keyframes fadeOut {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    @media (min-width: 576px) {
      position: absolute;
      z-index: 1;
      text-align: center;
      @keyframes circleAround {
        0% {
          right: 30%;
          top: 40%;
        }
        25% {
          right: 20%;
          top: 20%;
        }
        50% {
          right: 15%;
          top: 15%;
        }
        75% {
          right: 10%;
          top: 10%;
        }
        100% {
          right: 5%;
          top: 5%;
        }
      }
    }
    @media (min-width: 768px) {
      @keyframes rotateAndScale {
        0% {
          transform: scale(0.4) rotate(0deg);
        }
        100% {
          transform: scale(0) rotate(360deg);
        }
      }
      @keyframes circleAround {
        0% {
          right: 30%;
          top: 60%;
        }
        25% {
          right: 20%;
          top: 30%;
        }
        50% {
          right: 15%;
          top: 20%;
        }
        100% {
          right: 5%;
          top: 30%;
        }
      }
    }

    @media (min-width: 992px) {
      @keyframes rotateAndScale {
        0% {
          transform: scale(0.6) rotate(0deg);
        }
        100% {
          transform: scale(0) rotate(360deg);
        }
      }
    }
  }

  &__message {
    font-size: 1rem;
    color: $white;
    width: 80%;
    margin: 0 auto;
    padding-bottom: 1rem;

    @media (min-width: 576px) {
      font-size: 1rem;
      color: $white;
      width: 40%;
      padding: 2rem 0.5rem 0;
      top: 50%;
      left: 25%;
      transform: translate(-50%, -50%);
      position: absolute;
      z-index: 1;
      text-align: center;
    }

    @media (min-width: 768px) {
      width: 30%;
      font-size: 1.5rem;
    }

    @media (min-width: 992px) {
      width: 25%;
      font-size: 2rem;
    }

    @media (min-width: 992px) {
      width: 25%;
      font-size: 2rem;
    }
  }
  
}
