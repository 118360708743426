@import "../variables";

.download-button-main-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 16px;
}

.download-page__button-container {
  display: flex;
  justify-content: center;
  gap: 1rem;

  .astrafer-mint-button {
    font-size: 1rem;
    width: 190px;
    height: 33px;
    color: $black;
    margin-right: 0;

    .download-hyper-play {
      margin-top: 6px;
    }
  }
}


.download-section-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);

  padding-bottom: 40px;
}

.download-code-container {
  width: 505px;
}

.download-code-tab {
  color: #FFFFFF80;
  display: flex;
  font-family: $oswald;
  font-size: 24px;
  line-height: 25px;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;

  &__text-alpha {
    cursor: pointer;

    &--selected {
      color: #fff;
      cursor: pointer;
    }
  }

  &__text-beta {
    cursor: pointer;

    &--selected {
      color: #fff;
      cursor: pointer;
    }
  }
}

.download-code-error {
  margin: 0;
  color: $orange-error;
}

.adjust-download-header {
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

.download-button-for-os {
  text-decoration: none;
  color: white;
  min-width: 200px;
  transition: 0.2s;
  font-family: $oswald;
  font-size: 16px;
}

.download-button-for-os:hover {
  color: white;
  transition: 0.2s;
  transform: scale(1.1);
}

.download-section-main-container {
  .psg-border-img-mini-screen {
    width: 100%;
    position: initial;
  }

  .page-header {
    height: initial;
    padding-bottom: 50px;
  }
}
.align-download-page-boxes{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.adjust-game-download-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

}

.center-the-download-button-value {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.download-button-container {
  background-color: $pink;
  margin-bottom: 5px;
  font-size: 24px;
  width: 226px;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0)
}

.hyper-play {
  background-color: $white;
  background-image: url("../assets/images/hyper-play-button.png");
  background-position: center;
  background-size: 65% 65%;
  background-repeat: no-repeat;
}

.download-code-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;


  .download-code-tooltip {
    margin-top: 12px;
    margin-bottom: 8px;
  }

  .download-code-error {
    margin-bottom: 16px;
  }

  .generate-token-button{
    font-family: $oswald;
    font-size: 18px;
    width: 300px;
    height: 37px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 16px;

    background-color: $pink;
    --webkit-clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
    clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
    cursor: pointer;
  }
}

.content-of-the-code-element {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 15px;
  padding-top: 15px;
}

.content-of-the-code-element .accessToken {
  background-color: $white;
  border: none;
  color: $black;
  padding: 5px;
  width: 100%;
  margin-bottom: 1rem;
  text-decoration: none;
  overflow: clip;
  white-space: nowrap;
}

.download-buttons-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 40px 0;
}

.element-separator-with-margin {
  margin-bottom: 20px;
}

.set-the-download-text-design {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.download-top-description{
  width: 610px;
  font-family: $sofia;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 38px;
}

.download-top-description p{
  margin-bottom: 0;
}

.download-bottom-description p{
  margin-bottom: 16px;
}

.download-top-description li a{
  color: $white;
}
.download-top-description li {
  font-size: 16px;
  text-underline-offset: initial;
}

.download-top-description p span a{
  color: $white;
}

.download-bottom-description{
  font-family: $sofia;
  font-size: 16px;
  line-height: 24px;
  width: 610px;
  margin-top: 42px;
  margin-bottom: 80px;
}

.align-download-page-boxes .code-panel{
  margin-top: 15px;
  margin-bottom: 0;
}


.access-requirement-link{
  color: $white;
}

.code-panel.download{
  padding-top: 24px;
  padding-bottom: 0;
}

.align-download-page-boxes .custom-connect-wallet{
  width: 361px;
  height: 46px;
  clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
  align-items: center;
}

.align-download-page-boxes .connect-wallet-text{
  font-size: 24px;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .download-section-main-container .page-mobile-header-text-no-margin {
    font-size: 3.418vw;
  }
  .download-code-panel {
    max-width: 49.316vw;
  }
  .set-the-download-text-design {
    font-size: 1.563vw;

  }
  .download-button-for-os {
    min-width: 19.531vw;
    font-size: 1.563vw;
  }
  .accessToken {
    height: 3.385vw;
    font-size: 1.563vw;
    margin-bottom: 1rem;
  }
  .download-section-main-container .top-crown {
    width: 6.380vw;
    height: 3.255vw;
  }
  .download-section-main-container .bottom-crown {
    width: 6.380vw;
    height: 3.255vw;
  }
  .content-of-the-code-element {
    padding-bottom: 2.930vw;
    padding-top: 2.930vw;
  }
}

@media screen and (min-width: 375px) and (max-width: 768px) {
  .download-section-main-container .page-mobile-header-text-no-margin {
    font-size: 3.646vw;
  }

  .set-the-download-text-design {
    font-size: 1.563vw;

  }
  .download-button-for-os {
    min-width: 26.042vw;
    font-size: 1.563vw;
  }
  .accessToken {
    height: 3.385vw;
    font-size: 1.563vw;
    margin-bottom: 1rem;
  }
  .download-section-main-container .top-crown {
    width: 6.380vw;
    height: 3.255vw;
  }
  .download-section-main-container .bottom-crown {
    width: 6.380vw;
    height: 3.255vw;
  }
  .download-code-panel {
    max-width: 52.734vw;
  }
  .content-of-the-code-element {
    padding-bottom: 2.604vw;
    padding-top: 2.604vw;
  }
}

@media screen and (max-width: 375px) {
  .download-section-main-container .page-mobile-header-text-no-margin {
    font-size: 7.467vw;
  }

  .set-the-download-text-design {
    font-size: 2.933vw;

  }
  .download-button-for-os {
    min-width: 40vw;
    font-size: 11px;
  }
  .accessToken {
    height: 6.933vw;
    font-size: 11px;
    margin-bottom: 1rem;

  }
  .download-section-main-container .top-crown {
    width: 9.600vw;
    height: 5.333vw;
  }
  .download-section-main-container .bottom-crown {
    width: 9.600vw;
    height: 5.333vw;
  }
  .download-code-panel {
    max-width: 79vw;
  }
  .content-of-the-code-element {
    padding-bottom: 5.333vw;
    padding-top: 5.333vw;
  }
}
