@import "../../variables";

.main-leaderboard-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  clip-path: $section-polygon-top-inside-cut-1920;
}

.leader-board-page-background {
  object-fit: cover;
  z-index: -3;
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url("../../assets/images/LeaderBoard/repetitive-background.png");
  background-repeat: repeat-y;
  background-size: 100%;
}

.leader-board-section-title {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
  margin-bottom: 22px;
}


.leaderboard-page-container-alignment {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 111px;
}

.content-of-the-leaderboard-page {
  width: 765px;
  position: relative;
}

.leaderboard-header-distance {
  margin-top: 137px;
  width: 100%;
  min-height: 1000px;
}

.please-check-again-later{
  margin-top: 20px;
}

.leader-board-page-table-background {
  position: absolute;
  z-index: -2;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 70%);
  clip-path: polygon(5px 0, 0 5px, 0 calc(100% - 5px), 5px 100%, calc(100% - 5px) 100%, 100% calc(100% - 5px), 100% 5px, calc(100% - 5px) 0);
}

.leaderboard-background-control {
  padding-top: 27px;
  padding-bottom: 12px;
}

.infinite-loading-background {
  min-height: 700px;
}

.leader-board-page-table-header-container {
  width: 100%;
  height: 86px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 21px;
  padding-right: 22px;
}
.leaderboard-page-no-data-main-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 51px;
  padding-top: 51px;
  font-size: 16px;
  font-family: $sofia;
}
.leader-board-page-row-container {
  width: 100%;
  padding-left: 23px;
  padding-right: 23px;

}


.leader-board-page-row-container::-webkit-scrollbar {
  width: 6px;
  border-top: 20px;
}

/* Track */
.leader-board-page-row-container::-webkit-scrollbar-track {
  background: $scroll-bar-dark-gray;
  border-radius: 20px;
}

.leader-board-page-row-container::-webkit-scrollbar-track:hover {
  background: $scroll-bar-hover-gray;
  border-radius: 20px;
}

/* Handle */
.leader-board-page-row-container::-webkit-scrollbar-thumb {
  background: $thumb-bar-dark-gray;
  border-radius: 20px;
}

/* Handle on hover */
.leader-board-page-row-container::-webkit-scrollbar-thumb:hover {
  background: $thumb-bar-hover-gray;
}


.content-of-the-leaderboard-page .leader-board-special-row-container {
  width: 780px;
  left: -7px;
}

.leader-board-ornament {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.leader-board-ornament-width {
  width: 713px;
}

.prev-page {
  fill: $scroll-down-arrow-gray;
  cursor: pointer;
  transform: rotate(90deg);
}

.next-page {
  fill: $scroll-down-arrow-gray;
  cursor: pointer;
  transform: rotate(-90deg);
}

.active-pagination-button {
  fill: white;
}


.page-number-design {
  font-size: 20px;
  min-width: 82px;
  padding-right: 10px;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  font-family: $oswald;

}

.center-the-elements {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.center-container-button {
  width: 100%;
  @extend .center-the-elements;
  margin-top: 20px;
}

.rank-header-element {
  margin-bottom: 0px;
  padding-right: 45px;
  font-size: 20px;
  font-family: $oswald;
}

.player-header-element {
  margin-bottom: 0px;
  padding-left: 36px;
  font-size: 20px;
  font-family: $oswald;
}

.completion-header-element {
  margin-bottom: 0px;
  padding-left: 79px;
  font-size: 20px;
  font-family: $oswald;
}

.time-header-element {
  margin-bottom: 0px;
  padding-left: 52px;
  font-size: 20px;
  font-family: $oswald;
}

.loading-hexagon {
  width: 30px;
  height: 30px;
}

.leaderboard-background-control .last-element-in-row.add-font-bold {
  padding-left: 62px;
}

@media screen and (min-width: 1921px) {
  .leader-board-page-background {
    width: 100%;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .leaderboard-header-distance {
    margin-top: 61px;
  }
  .leader-board-section-title .page-header {
    padding-bottom: 0;
    height: initial;
  }
  .leader-board-section-title {
    margin-top: 0;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {

  .main-leaderboard-container {
    clip-path: $section-polygon-top-inside-cut-1024;
  }
  .leaderboard-header-distance {
    margin-top: 0px;
  }
  .leader-board-section-title {
    margin: 2.930vw 0 0.391vw;
  }
  .leader-board-section-title .page-header {
    padding-bottom: 0;
    height: 39.063vw;
  }
  .content-of-the-leaderboard-page {
    width: 74.707vw;
  }
  .leaderboard-page-container-alignment .leader-board-special-row-container {
    width: 76.172vw;
  }
  .main-leaderboard-container .page-mobile-header-text-no-margin {
    font-size: 3.613vw;
  }
  .leader-board-page-row-container {
    padding-left: 2.246vw;
    padding-right: 2.246vw;
  }
  .rank-header-element {
    font-size: 1.953vw;
    padding-right: 4.395vw;
  }
  .player-header-element {
    font-size: 1.953vw;
    padding-left: 3.516vw;
  }
  .completion-header-element {
    font-size: 1.953vw;
    padding-left: 7.715vw;
  }
  .time-header-element {
    font-size: 1.953vw;
    padding-left: 5.078vw;
  }
  .leader-board-page-table-header-container {
    height: 8.398vw;
  }
}



@media screen and (min-width: 376px) and (max-width: 768px) {
  .main-leaderboard-container {
    clip-path: $section-polygon-top-inside-cut-768;
  }
  .leaderboard-page-container-alignment .completion-hover-element {
    left: 11.854vw;
  }

  .leaderboard-page-container-alignment{
    .first-element-in-row, .player-element-in-row, .completion-element-in-row, .last-element-in-row {
      font-size: 2.083vw;
    }
  }


  .leaderboard-background-control .last-element-in-row.add-font-bold {
    padding-left: 8.073vw;
  }
  .leaderboard-header-distance {
    margin-top: 0px;
  }
  .content-of-the-leaderboard-page {
    width: 81.120vw;
  }
  .leaderboard-page-container-alignment .leader-board-special-row-container {
    width: 82.813vw;
  }
  .main-leaderboard-container .page-mobile-header-text-no-margin {
    font-size: 3.613vw;
  }
  .leader-board-page-row-container {
    padding-left: 2.246vw;
    padding-right: 2.246vw;
  }
  .rank-header-element {
    font-size: 2.344vw;
    padding-right: 7.395vw;
  }
  .player-header-element {
    font-size: 2.344vw;
    padding-left: 1.516vw;
  }
  .leaderboard-page-no-data-main-container{
    font-size: 2.083vw;
    padding-bottom: 6.641vw;
    padding-top: 6.641vw;
  }
  .completion-header-element {
    font-size: 2.344vw;
    padding-left: 5.678vw;
  }
  .time-header-element {
    font-size: 2.344vw;
    padding-left: 6.078vw;
  }
  .leader-board-page-table-header-container {
    height: 8.398vw;
    padding-left: 2.734vw;
    padding-right: 2.865vw;
  }
  .page-number-design {
    font-size: 2.344vw;
    padding-right: 1.302vw;
    padding-left: 1.302vw;
    min-width: 10.677vw;
  }
  .next-page {
    width: 1.823vw;
    height: 1.302vw;
  }
  .prev-page {
    width: 1.823vw;
    height: 1.302vw;
  }
  .center-container-button {
    margin-top: 2.604vw;
    height: 3.516vw;
  }
  .loading-hexagon {
    width: 2.734vw;
    height: 2.734vw;
  }
  .leaderboard-page-container-alignment {
    margin-bottom: 3.776vw;
  }
  .leader-board-section-title .page-header {
    padding-bottom: 0;
    height: initial;
  }
  .leaderboard-background-control {
    padding-top: 3.516vw;
    padding-bottom: 1.563vw;
  }
  .content-of-the-leaderboard-page .last-element-in-row {
    padding-left: 8.125vw;
    justify-content: center;
  }
  .content-of-the-leaderboard-page .completion-element-in-row {
    margin-left: 6.025vw;
  }
}

@media screen and (max-width: 375px) {
  .main-leaderboard-container{
    clip-path: $section-polygon-top-inside-cut-375;
  }
  .leaderboard-header-distance{
    margin-top: 0px;
  }
  .leaderboard-page-no-data-main-container{
    font-size: 14px;
  }
  .leader-board-section-title{
    margin-top:20px;
    margin-bottom: 10px;
  }

  .leader-board-section-title .page-header {
    padding-bottom: 0;
    height: initial;
  }
  .leaderboard-page-container-alignment{
    margin-bottom: 48px;
  }
  .leaderboard-background-control .last-element-in-row.add-font-bold {
    padding-left: 0;
  }
  .content-of-the-leaderboard-page{
    width: 346px;
  }
  .content-of-the-leaderboard-page .leader-bord-margin-box{
    width: initial;
    height: initial;
  }
  .leader-board-page-row-container{
    padding-left: 21px;
    padding-right: 21px;
  }
  .content-of-the-leaderboard-page .leader-board-special-row-container{
    width: 356px;
    left: -4px;
  }
  .leader-board-page-table-header-container{
    height: initial;
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .rank-header-element {
    font-size: 13px;
    padding-right: 23px;
  }
  .player-header-element {
    font-size: 13px;
    padding-left: 0;
  }
  .completion-header-element {
    font-size: 13px;
    padding-left: 0;
  }
  .time-header-element {
    font-size: 13px;
    padding-left: 0;
  }
  .center-container-button{
    margin-top: 13px;
  }
  .page-number-design {
    font-size: 16px;
    padding-left: 0px;
    padding-right: 0px;
    min-width: 80px;
  }
  .next-page {
    width: 14px;
    height: 10px;
  }
  .prev-page {
    width: 14px;
    height: 10px;
  }
  .loading-hexagon {
    width: 24px;
    height: 24px;
  }
}
