@import 'src/variables';

.batch-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .add-catalyst-title-container {
    display: flex;
    margin-bottom: 20px;

    .add-catalyst-title {
      font-size: 2.8125rem;
      font-family: $oswald;
      font-weight: 400;
      margin-right: 10px;
      flex-shrink: 0;
      @media only screen and (min-width: 769px) and (max-width: 1024px) {
        font-size: 1.875rem;
      }
      @media screen and (min-width: 376px) and (max-width: 768px) {
        font-size: 1.875rem;
      }
      @media screen and (max-device-width: 375px) {
        font-size: 1.5rem;
      }
    }
  }

  .add-catalyst-title-container.disabled-title-add {
    filter: opacity(50%);
  }

  .add-catalyst-list {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    @media screen and (min-width: 376px) and (max-width: 768px) {
      justify-content: center;
      flex-wrap: wrap;
      width: 72.917vw;
      gap: 40px 20px;
    }
    @media screen and (max-device-width: 375px) {
      margin-bottom: 20px;
    }

    .catalyst-link {
      position: absolute;
      left: 730px;
      top: 160px;

      @media only screen and (min-width: 1441px) and (max-width: 1920px) {
        left: 38.021vw;
        top: 8.333vw;
      }
    }
  }
  @supports (-webkit-touch-callout: none) {
    @media screen and (min-width: 376px) and (max-width: 768px) {
      .add-catalyst-title-container{
        margin-bottom: 0;
      }
      .add-catalyst-list {
        gap: 0 0;
      }
      .planet-catalyst{
        margin: 20px 15px;
      }
    }
  }
}


