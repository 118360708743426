@import "../../../variables";

.genesis-nft-combination-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 0.833vw;
  margin-bottom: 5.885vw;
  flex-wrap: wrap;
}


.genesis-nft-combination-plus{
  position: relative;
  top: -1vw;
  margin: 0 0.521vw;
  width: 1.302vw;
  height: 1.302vw;
}

.genesis-nft-combination-plus:nth-child(8){
  display: none;
}

.genesis-nft-combination-equals{
  position: relative;
  top: -1.563vw;
  margin: 1.021vw;
  width: 1.510vw;
}

.combination-result-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1.2vw;
}

.mintapass-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.042vw;
}

.mintpass-image-container{
  width: 18.750vw;
  height: 9.115vw;
}

.mintpass-image-container img{
  width: 100%;
  height: 100%;
}

.claim-mintpass-button-container{
  position: relative;
  top: 2.083vw;
  width: 9.948vw;
  height: 1.927vw;
  font-size: 0.833vw;
  font-family: $oswald;
}

.claim-mintpass-button{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px;
  margin-top: -0.529vw;
  background-color: $dark-cherry-background;
  -webkit-clip-path: polygon(0.365vw 0, 0 0.365vw, 0 calc(100% - 0.365vw), 0.365vw 100%, calc(100% - 0.365vw) 100%, 100% calc(100% - 0.365vw), 100% 0.365vw, calc(100% - 0.365vw) 0);
  clip-path: polygon(0.365vw 0, 0 0.365vw, 0 calc(100% - 0.365vw), 0.365vw 100%, calc(100% - 0.365vw) 100%, 100% calc(100% - 0.365vw), 100% 0.365vw, calc(100% - 0.365vw) 0);
  cursor: pointer;
}

.claim-mintpass-button.disabled {
  cursor: initial;
  background-color: $stack;
}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .genesis-nft-combination-container{
    position: relative;
    width: 100%;
    padding: 0;
  }

  .genesis-nft-combination-plus{
    position: absolute;
    width: 3.203vw;
    height:3.203vw;
  }

  .genesis-nft-combination-plus:nth-child(2){
    top: 15.5vw;
  }
  .genesis-nft-combination-plus:nth-child(4){
    left: 21vw;
    top: 42.867vw;
  }
  .genesis-nft-combination-plus:nth-child(6){
    top: 62.6vw;
  }
  .genesis-nft-combination-plus:nth-child(8){
    display: block;
    right: 20.5vw;
    top: 42.867vw;
  }

  .genesis-nft-combination-container:nth-child(1),
  .genesis-nft-combination-container:nth-child(3){
    margin-bottom: 6.400vw;
  }

  .nft-combination-element-container:nth-child(1),
  .nft-combination-element-container:nth-child(5){
    margin-right: 18.490vw;
  }

  .nft-combination-element-container:nth-child(1),
  .nft-combination-element-container:nth-child(3){
    margin-bottom: 9vw;
  }

  .genesis-nft-combination-equals{
    margin-top: -1.75vw;
    margin-right: 2.5vw;
    width: 3.816vw;
  }

  .combination-result-container{
    margin-left: -6.510vw;
    margin-top: 14.5vw;
  }

  .mintpass-image-container{
    width: 46.875vw;
    height: 22.805vw;
  }

  .claim-mintpass-button-container{
    top: 5.5vw;
    width: 24.870vw;
    height: 4.818vw;
    font-size: 2.083vw;
  }

  .claim-mintpass-button{
    -webkit-clip-path: polygon(0.911vw 0, 0 0.911vw, 0 calc(100% - 0.911vw), 0.911vw 100%, calc(100% - 0.911vw) 100%, 100% calc(100% - 0.911vw), 100% 0.911vw, calc(100% - 0.911vw) 0);
    clip-path: polygon(0.911vw 0, 0 0.911vw, 0 calc(100% - 0.911vw), 0.911vw 100%, calc(100% - 0.911vw) 100%, 100% calc(100% - 0.911vw), 100% 0.911vw, calc(100% - 0.911vw) 0);
  }
}

@media screen and (max-device-width: 375px) {
  .genesis-nft-combination-container {
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .genesis-nft-combination-plus{
    top: 0;
    margin: 0;
    width: 8.267vw;
    height: 7.467vw;
  }
  .combination-result-container{
    flex-direction: column;
    width: 100%;
    margin-top: 0;
  }
  .mintapass-container {
    margin-bottom: 20vw;
  }
  .mintpass-image-container{
    width: 96.267vw;
    height: 46.667vw;
    margin-bottom: 9.867vw;
  }
  .genesis-nft-combination-equals{
    position: relative;
    top: 0;
    margin-bottom: 4.400vw;
    width: 10vw;
  }
  .claim-mintpass-button-container{
    position: relative;
    top: 0;
    width: 50.933vw;
    height: 9.867vw;
    font-size: 4.267vw;
  }

  .claim-mintpass-button{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.533vw;
    margin-top: 0;
    background-color: $dark-cherry-background;
    clip-path: polygon(1.600vw 0, 0 1.600vw, 0 calc(100% - 1.600vw), 1.600vw 100%, calc(100% - 1.600vw) 100%, 100% calc(100% - 1.600vw), 100% 1.600vw, calc(100% - 1.600vw) 0);
    -webkit-clip-path: polygon(1.600vw 0, 0 1.600vw, 0 calc(100% - 1.600vw), 1.600vw 100%, calc(100% - 1.600vw) 100%, 100% calc(100% - 1.600vw), 100% 1.600vw, calc(100% - 1.600vw) 0);
    cursor: pointer;
  }
  }
