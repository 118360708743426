@import '../../../../variables';

.nft-combination-element-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: -0.833vw;
  height: 14.844vw;
}

.nft-combination-element-name-container{
  text-align: center;
  width: 13.208vw;
  height: 2.656vw;
  position: relative;
  top: -0.521vw;
  margin-bottom: 10px;
}

.nft-combination-element-name{
  font-size: 0.990vw;
  font-family: $sourceCodePro;
  line-height: 1.250vw;
  position: absolute;
  bottom: 0;
  left: 0.260vw;
  right: 0.260vw;
  white-space: pre-line;
}

.nft-combination-element-nr-owned{
  font-size: 0.990vw;
  font-family: $sourceCodePro;
  line-height: 1.250vw;
  text-align: center;
  width: 13.208vw;
  margin-top: 0.3vw;
  margin-bottom: 1.119vw;
}

.nft-combination-element-image{
  width: 11.823vw;
  height: 6.615vw;
}

.nft-combination-element-image img{
  width: auto;
  height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.nft-combination-open-sea-container .buy-on-opensea,
.nft-combination-open-sea-container .buy-on-opensea:hover{
  font-family: $oswald;
  font-size: 0.833vw;
  width: 7.865vw;
  height: 1.927vw;
  clip-path: polygon(0.365vw 0, 0 0.365vw, 0 calc(100% - 0.365vw), 0.365vw 100%, calc(100% - 0.365vw) 100%, 100% calc(100% - 0.365vw), 100% 0.365vw, calc(100% - 0.365vw) 0);
}

.nft-combination-open-sea-container .buy-on-opensea img{
  width: 1.667vw;
  height: 1.667vw;
}

.nft-combination-element-container .genesis-nft-disable-img{
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .nft-combination-element-container {
    height: 40.25vw;
  }

  .nft-combination-element-name-container{
    width: 36.458vw;
    height: 6.5vw;
    top: -0.521vw;
    margin-bottom: 0;
  }

  .nft-combination-element-name{
    font-size: 2.334vw;
    line-height: 2.947vw;
    top: 1vw;
  }

  .nft-combination-element-nr-owned{
    font-size: 2.334vw;
    line-height: 2.947vw;
    width: 36.458vw;
    margin-top: 3vw;
    margin-bottom: 2.119vw;
  }

  .nft-combination-element-image{
    width: 36.458vw;
    height: 20.443vw;
  }
  .nft-combination-open-sea-container .buy-on-opensea:hover{
    font-size: 2.083vw;
    width: 20.443vw;
    height: 4.818vw;

    -webkit-clip-path: polygon(0.911vw 0, 0 0.911vw, 0 calc(100% - 0.911vw), 0.911vw 100%, calc(100% - 0.911vw) 100%, 100% calc(100% - 0.911vw), 100% 0.911vw, calc(100% - 0.911vw) 0);
    clip-path: polygon(0.911vw 0, 0 0.911vw, 0 calc(100% - 0.911vw), 0.911vw 100%, calc(100% - 0.911vw) 100%, 100% calc(100% - 0.911vw), 100% 0.911vw, calc(100% - 0.911vw) 0);

  }

  .nft-combination-open-sea-container .buy-on-opensea{
    font-size: 2.083vw;
    width: 20.443vw;
    height: 4.818vw;

    -webkit-clip-path: polygon(0.911vw 0, 0 0.911vw, 0 calc(100% - 0.911vw), 0.911vw 100%, calc(100% - 0.911vw) 100%, 100% calc(100% - 0.911vw), 100% 0.911vw, calc(100% - 0.911vw) 0);
    clip-path: polygon(0.911vw 0, 0 0.911vw, 0 calc(100% - 0.911vw), 0.911vw 100%, calc(100% - 0.911vw) 100%, 100% calc(100% - 0.911vw), 100% 0.911vw, calc(100% - 0.911vw) 0);
  }

  .nft-combination-open-sea-container .buy-on-opensea img{
    width: 4.167vw;
    height: 4.167vw;
  }
}
