.cw-principle-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  z-index: 6;

}
.cw-container{
  width: 407px;
  height: 262px;
  background-image: url("../../../../assets/images/PlanetSalePagePrivate/connect-wallet-background.png");
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: relative;
}
.cw-center-button{
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rush-event-title-container .cw-principle-container {
  margin-top: 0;
}
.connect-wallet-dimensions{
  width: 80%;
  height: 37px;
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .cw-container{
    width: 39.746vw;
    height: 25.586vw;
    font-size: 1.563vw;
  }
  .cw-principle-container img {
    width: 39.746vw;
  }
  .connect-wallet-dimensions{
    height: 3.613vw;
  }
}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .cw-container{
    width: 52.995vw;
    height: 34.115vw;
    font-size: 2.083vw;
  }
  .cw-principle-container img {
    width: 52.995vw;
  }
  .connect-wallet-dimensions{
    height: 4.818vw;
  }
}
@media screen and (max-width: 375px) {
  .cw-container{
    width: 75.200vw;
    height: 69.867vw;
    font-size: 4.267vw;
  }
  .cw-principle-container img {
    width: 75.200vw;
  }
  .connect-wallet-dimensions{
    height: 9.867vw;
  }
}