@import "../../variables";

.remove-modal-limitations .modal-dialog {
  max-width: initial;
  min-width: initial;
}

.genesis-nft-available-modal .modal-content {
  background-color: transparent;
}

.genesis-nft-available-modal {
  max-width: initial;
  background-color: transparent;
}

.genesis-nft-available-modal-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.for-nft-main-content {
  background-color: rgba(0, 0, 0, 80%);
  width: 770px;
  height: 505px;
  border: 2px solid white;
  position: relative;
  padding-left: 37px;
  padding-right: 37px;
  padding-top: 16px;
  transition: all 0.4s ease;
}

.announcement-popup-title {
  font-family: $oswald;
  font-size: 48px;
  color: #fff;
  width: 415px;
  line-height: 56px;
}

.for-nft-content-container {
  width: 100%;
  margin-top: 16px;
}

.main-text-container-posters {
  font-family: $sofia;
  font-size: 14px;
  width: 385px;
  line-height: 22px;
}

.mint-button-holder {
  margin-top: 24px;
}

.mint-now-poster-button {
  font-size: 18px;
  font-family: $oswald;
  background-color: $pink;
  width: 115px;
  height: 37px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);

  &.starfighters {
    width: 160px;
  }
}

.featured-image-container {
  position: absolute;
  top: -55px;
  left: 430px;
}

.adjust-z-index-from-alignment {
  position: relative;
  z-index: 1;
}

.close-button-element-alignment {
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
  z-index: 2;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .main-text-container-posters {
    font-size: 14px;
    width: 314px;
  }
  .for-nft-main-content {
    width: 584px;
    height: 576px;
    padding-top: 24px;
  }
  .featured-image-container {
    position: absolute;
    top: -24px;
    left: 348px;
  }
}

.mint-button-container {
  display: flex;
  column-gap: 1rem;
  flex-direction: column;

  @media screen and (min-width: 769px) {
    flex-direction: row;
  }
}

@media screen and (max-width: 768px) {
  .for-nft-main-content {
    width: 294px;
    height: 565px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
  }
  .announcement-popup-title {
    font-size: 28px;
    width: 230px;
    line-height: 34px;
  }
  .for-nft-content-container {
    margin-top: 12px;
  }
  .main-text-container-posters {
    width: 204px;
    font-size: 14px;
    line-height: 18px;
  }
  .mint-button-holder {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
  }
  .featured-image-container {
    position: absolute;
    top: -45px;
    left: 70px;
    transform: rotate(-11deg);
  }
  .featured-image-overlay {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 36px;
      left: 53px;
      width: 59%;
      height: 89%;
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
      -webkit-backdrop-filter: blur(1px);
      transform: rotate(11deg);
      border-radius: 5px;
      opacity: 0.8;
    }
  }
}
