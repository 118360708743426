@import '../variables';

.account-body {
  background-color: #000000;
}

.account-provider-list {
  display: flex;
  row-gap: 1rem;
  flex-direction: column;
}

.account-provider-entry {
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.05);
  color: #ffffff;
  font-family: $oswald;
  font-style: normal;
  font-weight: 400;
  flex-direction: row;
}

.account-provider-entry a {
  color: #ffffff;
  text-decoration: none;
}

.account-provider-icon {
  flex-shrink: 0;
  height: 40px;
}

.account-auth-button {
  background: $medium-grey;
  color: $light-red-background;
  text-align: center;
  font-family: $oswald;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;

  &--link {
    background: $white;
    .button-text {
      color: $light-red-background;
    }

    &:hover {
      .button-text {
        color: $white;
      }
    }
  }
  &--unlink {
    &.disabled {
      .button-text {
        color: $white;
      }
    }

    &:hover {
      .button-text {
        color: $white;
      }
    }
    .button-text {
      color: $light-red-background;
    }
  }
}

.account-auth-button-border {
  background: rgba(175, 16, 61, 1);
  z-index: 0;
}

.account-auth-button:hover {
  background: rgba(212, 39, 88, 1);
}

.account-provider__label {
  margin-bottom: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
