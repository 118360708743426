@import "../../../variables";

.exploration-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .exploration-image-container {
    width: 1486px;
    margin-top: -64px;
    margin-bottom: 100px;

    @media only screen and (max-width: 1920px) {
      width: 77.396vw;
      margin-top: -70px;
    }
    @media only screen and (max-width: 1440px) {
      width: 86.875vw;
    }
    @media only screen and (max-width: 1024px) {
      width: 87.500vw;
      margin-top: -100px;
    }
    @media only screen and (max-width: 768px) {
      width: 91.536vw;
      margin-top: -12vw;
      margin-bottom: 40px;
    }
    @media only screen and (max-device-width: 375px) {
      display: none;
    }
  }

  .exploration-image-container-mobile {
    display: none;

    @media only screen and (max-device-width: 375px) {
      display: block;
      margin-top: -35vw;
      margin-bottom: 10vw;
    }
  }

  .exploration-text-container {
    font-size: 1rem;
    line-height: 25px;
    font-weight: 100;
    font-family: $sofia;
    width: 1486px;
    margin-bottom: 13px;

    background-color: $black-night-bg;
    padding: 20px 65px;
    border-radius: 4px;

    @media only screen and (max-width: 1920px) {
      width: 77.396vw;
    }
    @media only screen and (max-width: 1440px) {
      width: 86.875vw;
    }
    @media only screen and (max-width: 1024px) {
      width: 87.500vw;
      font-size: 1.125rem;
      padding: 20px 40px;
    }
    @media only screen and (max-width: 768px) {
      width: 85.938vw;
      font-size: 0.875rem;
    }
    @media only screen and (max-device-width: 375px) {
      font-size: 0.935rem;
      padding: 20px 30px;
    }
  }

  .exploration-title-container {
    .psg-border-img-pc{
      display: none;
    }
    .psg-border-img-mini-screen {
      @media screen and (min-width: 1025px) {
        display: flex;
      }
      width: 100%;
    }
    @media screen and (max-width: 1440px) {
      .page-mobile-header-text {
        font-size: clamp(1.875rem, 3.125vw, 2.8125rem);
      }
    }

    @media only screen and (max-width: 1024px) {
      .page-mobile-header-text {
        font-size: 1.875rem;
      }
    }
    .page-mobile-header-text{
      margin-bottom: -10px;
    }
    @media screen and (max-device-width: 375px) {
      display: none;
    }
  }
  .exploration-title-container-mobile {
    display: none;
    @media screen and (max-device-width: 375px) {
      display: flex;
    }
    .page-mobile-header-text{
      font-size: 1.875rem;
      margin-bottom: -10px;
    }
  }

  .exploration-auth-container {
    margin-top: 100px;
  }

  .exploration-mint-container {
    padding: 20px 0;
    margin-bottom: 72px;
  }

  .exploration-reauthenticate-link {
    color: inherit;
  }

  .exploration-buttons-container {
    display: flex;
    width: 588px;
    justify-content: space-between;
    margin-top: 100px;
    margin-bottom: 37px;

    @media only screen and (max-width: 1440px) {
      margin-top: 53px;
    }

    @media only screen and (max-width: 1024px) {
      width: 440px;
      margin-top: 37px;
      margin-bottom: 27px;
    }

    @media only screen and (max-width: 768px) {
      width: 330px;
      margin-top: 42px;
      margin-bottom: 20px;
    }

    @media only screen and (max-width: 375px) {
      width: 219px;
      flex-direction: column;
      height: 112px;
      margin-top: 48px;
      margin-bottom: 48px;
    }

    .exploration-new-mission-button {
      width: 274px;
      height: 50px;

      color: white;
      font-family: $oswald;
      font-weight: 400;
      font-size: 1.75rem;

      background-color: $pink;
      border: none;
      clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
      transition-duration: 0.3s;

      @media only screen and (max-width: 1024px) {
        width: 205px;
        height: 37px;
        font-size: 1.3125rem;
      }

      @media only screen and (max-width: 768px) {
        width: 154px;
        height: 28px;
        font-size: 1rem;
      }

      @media only screen and (max-width: 375px) {
        width: 219px;
        height: 40px;
        font-size: 1.4375rem;
      }

      @supports (-webkit-touch-callout: none) {
        @media only screen and (max-width: 768px) {
          padding-left: 14px;
        }

        @media only screen and (max-width: 375px) {
          padding-left: 17px;
        }
      }
    }

    .exploration-new-mission-button:hover {
      color: rgba(255, 255, 255, 0.5);
    }

    .exploration-view-missions-button-border {
      width: 274px;
      height: 51px;

      background-color: $pink;
      clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);

      @media only screen and (max-width: 1024px) {
        width: 205px;
        height: 37px;
      }

      @media only screen and (max-width: 768px) {
        width: 154px;
        height: 28px;
      }

      @media only screen and (max-width: 375px) {
        width: 219px;
        height: 40px;
      }

      .exploration-view-missions-button {
        width: 272px;
        height: 48px;

        color: white;
        font-family: $oswald;
        font-weight: 400;
        font-size: 1.75rem;

        margin: 1px;
        border: none;
        background-color: $black;
        clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
        transition-duration: 0.3s;

        @media only screen and (max-width: 1024px) {
          width: 203px;
          height: 35px;
          font-size: 1.3125rem;
        }

        @media only screen and (max-width: 768px) {
          width: 152px;
          height: 26px;
          font-size: 1rem;
        }

        @media only screen and (max-width: 375px) {
          width: 217px;
          height: 38px;
          font-size: 1.4375rem;
        }
      }
    }

    .exploration-view-missions-button-border.disabled {
      background-color: $disable-gray;
    }

    .exploration-view-missions-button:hover {
      color: rgba(255, 255, 255, 0.5);
    }

    .exploration-view-missions-button.disabled {
      color: $disable-gray-text;
      background-color: $disable-gray;
      cursor: default;
    }
  }

  .exploration-border-container {
    width: 807.994px;
    margin-bottom: 30px;

    @media only screen and (max-width: 768px) {
      margin-bottom: 10px;
      width: 100%;
    }
  }

  .planet-exploration-missions-main-container-landing {
    width: 1172px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 59px;
    margin-bottom: 200px;

    @media only screen and (max-width: 1440px) {
      width: 84vw;
      margin-bottom: 13.889vw;
    }

    @media only screen and (max-width: 1024px) {
      width: unset;
      display: flex;
      align-items: center;
      margin-bottom: 22vw;
    }

    .planet-exploration-missions-landing-container {
      display: flex;
      flex-wrap: wrap;

      overflow: auto;
      scrollbar-width: thin;

      @media only screen and (max-width: 1024px) {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(18.555vw, 18.555vw));
        column-gap: 2.604vw;
        padding-right: 2.604vw;
        justify-content: center;
        width: 87vw;
        max-height: 158px;
      }

      .mission-tab-main-container {
        width: 271px;
        height: 71px;

        @media only screen and (max-width: 1440px) {
          width: 18.819vw;
        }

        @media only screen and (max-width: 1024px) {
          width: 18.819vw;
        }
      }

      .mission-tab-left-decor {
        height: 71px;
      }
    }
  }

  .planet-exploration-missions-main-container-landing ::-webkit-scrollbar {
    width: 6px;
    height: 410px;
    background: $scroll-bar-dark-gray;
    border-top: 20px;
  }

  .planet-exploration-missions-main-container-landing ::-webkit-scrollbar-track {
    background: $scroll-bar-dark-gray;
  }

  .planet-exploration-missions-main-container-landing ::-webkit-scrollbar-thumb {
    background: $thumb-bar-dark-gray;
    padding: 10px;
    height: 98px;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 1024px) {
  .exploration-main-container {
    .planet-exploration-missions-main-container-landing {
      .planet-exploration-missions-landing-container {
        .mission-tab-title {
          font-size: clamp(0.75rem, 1.367vw , 0.875rem);
        }
        .mission-tab-content {
          font-size: clamp(1rem, 1.758vw, 1.125rem);
        }

      }
    }
  }
}

@media only screen and (max-width: 768px) {

  .exploration-main-container {

    .planet-exploration-missions-main-container-landing {
      width: 97%;
      height: unset;

      .planet-exploration-missions-landing-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(27.344vw, 27.344vw));
        column-gap: 8px;
        padding-right: 2.604vw;
        justify-content: center;
        width: 95%;
        max-width: 95%;
        max-height: 16.146vw;

        .planet-exploration-mission-tab {
          width: 27.344vw;
          height: 7.161vw;
          margin-bottom: 1.1vw;

          .mission-tab-main-container {
            width: 27.344vw;
            height: 7.161vw;

            .mission-tab-left-decor {
              height: 7.161vw;
            }

            .mission-tab-right-container {
              margin-top: 0.8vw;
              margin-left: 1.5vw;

              @media screen and (max-width: 460px) {
                margin-top: 0;
              }

              .mission-tab-title {
                font-size: clamp(0.1rem, 1.823vw, 0.875rem);
                height: 2vw;
              }

              .mission-tab-content {
                font-size: clamp(0.1rem, 2.083vw, 1rem);
                line-height: 2.865vw;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-device-width: 375px) {

  .exploration-main-container {

    .planet-exploration-missions-main-container-landing {
      width: 97%;

      .planet-exploration-missions-landing-container {
        max-width: 95vw;
        height: 57vw;
        max-height: 213px;
        width: 90.667vw;

        grid-template-columns: repeat(auto-fill, minmax(40vw, 40vw));
        column-gap: 10px;
        padding-right: 9px;

        .planet-exploration-mission-tab {
          width: 40vw;
          height: 13.333vw;

          .mission-tab-main-container {
            width: 40vw;
            height: 13.333vw;

            .mission-tab-left-decor {
              height: 13.333vw;
            }

            .mission-tab-right-container {
              margin-top: 2.133vw;

              .mission-tab-title {
                font-size: clamp(0.1rem, 3.0vw, 0.75rem);
                height: 4vw;
              }

              .mission-tab-content {
                font-size: clamp(0.1rem, 4.0vw, 1rem);
              }
            }
          }
        }
      }
    }
  }
}
