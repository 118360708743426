@import '../../../variables';

.avatar-traits-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 90px;
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    margin-bottom: 4.883vw;
  }
  @media screen and (max-device-width: 375px) {
    margin-bottom: 45px;
  }
}

.avatar-traits-title-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  margin-bottom: 40px;
  @media screen and (max-device-width: 375px) {
    margin-bottom: 20px;
  }
}

.avatar-traits-title-container.disabled-title-trait {
  filter: opacity(50%);
}

.avatar-traits-title-text {
  font-family: $oswald;
  font-size: 2.5rem;
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    font-size: 1.375rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 1.375rem;
    margin-right: 8px;
  }

}



.avatar-traits-grid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 4rem 2rem;
  width: 65vw;


  @media screen and (min-width: 376px) and (max-width: 768px) {
    flex-wrap: wrap;
    width: 83.333vw;
    gap: 4rem 2rem;
  }
  @media screen and (max-device-width: 375px) {
    flex-wrap: wrap;
    width: 90vw;
    gap: 4rem 2rem;
  }
}

@supports (-webkit-touch-callout: none) {
  @media screen and (min-width: 376px) and (max-width: 768px) {
    .avatar-traits-title-container{
      margin-bottom: 15px;
    }
    .planet-trait-container,
    .no-biome-trait {
      margin: 20px 10px !important;
    }
  }
  @media screen and (max-device-width: 375px) {
    .avatar-traits-title-container{
      margin-bottom: 15px;
    }
    .planet-trait-container,
    .no-biome-trait {
      margin: 7px 0 !important;
    }
  }
}

.avatar-traits-grid .planet-trait-border.disabled-cursor{
  cursor: initial;
}

.no-biome-trait .planet-trait-image {
  width: 50px !important;
  @media screen and (min-width: 1441px) and (max-width: 1920px) {
    width: 2.604vw !important;
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    width: 3.516vw !important;
  }
  @media screen and (min-width: 376px) and (max-width: 768px) {
    width: 26px !important;
  }
  @media screen and (max-device-width: 375px) {
    width: 6.667vw !important;
  }
}

