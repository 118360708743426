/* FONT STACKS */
$oswald: 'Oswald', Helvetica, serif;
$sofia: sofia-pro, Verdana, sans-serif;
$raleway: 'Raleway';
$michroma: 'Michroma', sans-serif;
$oswald-light: 'Oswald-light', Helvetica, serif;

@font-face {
  font-family: 'SourceCodePro';
  src: url('../src/assets/fonts/SourceCodePro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald-light';
  src: url('../src/assets/fonts/Oswald-VariableFont_wght.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

$sourceCodePro: SourceCodePro;

/* COLOURS */
$black: #000;
$white: #fff;
$darkgrey: #343434;
$mist-gray: #c4c4c4;
$stack: #8a8a8a;
$pink: #d42758;
$dark-pink: #a90a39;
$banner-background: #262626;
$cherry: #801433;
$ocean-blue: #2a3a70;
$light-ocean-blue: #2081E2;
$disable-gray: #595959;
$disable-gray-text: #acacac;
$very-light-gray: #808080;
$extra-light-gray: #979797;
$drop-down-gray: #2a2a2a;
$background-gray: #494949;
$dark-blue: #0a58ca;
$dark-gray: #282b2f;
$dark-gray-border: #534F5B;
$semi-dark-black: #151515;
$simple-gray: #D9D9D9;
$dark-blue-background: #0D38A6;
$medium-blue-background: #3363DE;
$light-blue-background: #5574E1;
$light-gray-background: #AAAAAA;
$dark-gray-background: #646464;
$dark-green-background: #176374;
$light-green-background: #189FBC;
$dark-red-background: #873149;
$light-red-background: #D42758;
$light-purple-background: #93ABFF;
$light-pink-background: #FF6B95;
$dark-cherry-background: #D22657;
$pink-button-border: #AF103D;
$scroll-bar-dark-gray: #4D5258;
$grey-tab-background: #FFFFFF26;
$scroll-bar-hover-gray: #6F767D;
$thumb-bar-dark-gray: #757F84;
$thumb-bar-hover-gray: #B1BABF;
$scroll-down-arrow-gray: #818588;
$claim-rewards-background: #0B0808;
$gray-text: #B3B3B3;
$gray-decoration: #797979;
$gray-scrollbar: #3E3E3E;
$gray-licence-text-box: #1D1D1D;
$orange-error: #D46527;
$tab-gray: #242424;
$gray-slider: #ADADAD;
$button-gray: #3F3F3F;
$button-gray-darker: #585858;
$header-grey: #272727;
$gray-dropdown: #AFAFAF;
$black-night-bg: #0D0D0D;
$asset-card-dark-grey-bg: #252525;
$toggle-switch-grey-bg: #ccc;
$toggle-switch-white-bg: #fff;
$gray-text: #828282;
$medium-grey: #0a0a0a;
$pg-midnight-grey: #1a1a1a;

/* DIMENSIOUNS */
$polygon-width: 212px;
$polygon-height: 163px;
$banner-width: 587px;
$banner-height: 96px;
$converting5x-width: 862px;
$converting5x-height: 548px;

/* UTILITIES */
$images-path: './images/';

//clip pats
$header-clip-path: polygon(50% 100%, 100% 56%, 100% 0, 0 0, 0 56%);
$main-element-clip-path: polygon(0 0, 50% 8%, 100% 0, 100% 92%, 50% 100%, 0 92%);
$footer-clip-path: polygon(50% 8%, 100% 0, 100% 100%, 0 100%, 0 0);
$section-polygon-bottom-outside-cut-1920: polygon(0 0, 50% 0, 100% 0, 100% calc(100% - 95px), 50% 100%, 0 calc(100% - 95px));
$section-polygon-bottom-outside-cut-1024: polygon(0 0, 50% 0, 100% 0, 100% calc(100% - 6.445vw), 50% 100%, 0 calc(100% - 6.445vw));
$section-polygon-bottom-outside-cut-768: polygon(0 0, 50% 0, 100% 0, 100% calc(100% - 6.51vw), 50% 100%, 0 calc(100% - 6.51vw));
$section-polygon-bottom-outside-cut-375: polygon(0 0, 50% 0, 100% 0, 100% calc(100% - 10.667vw), 50% 100%, 0 calc(100% - 10.667vw));
$section-polygon-top-bottom-down-cut-1920: polygon(0 0, 50% 95px, 100% 0, 100% calc(100% - 95px), 50% 100%, 0 calc(100% - 95px));
$section-polygon-top-bottom-down-cut-1024: polygon(0 0, 50% 6.445vw, 100% 0, 100% calc(100% - 6.445vw), 50% 100%, 0 calc(100% - 6.445vw));
$section-polygon-top-bottom-down-cut-768: polygon(0 0, 50% 6.51vw, 100% 0, 100% calc(100% - 6.51vw), 50% 100%, 0 calc(100% - 6.51vw));
$section-polygon-top-bottom-down-cut-375: polygon(0 0, 50% 10.667vw, 100% 0, 100% calc(100% - 10.667vw), 50% 100%, 0 calc(100% - 10.667vw));
$section-polygon-top-bottom-inside-cut-1920: polygon(0 0, 50% 95px, 100% 0, 100% 100%, 50% calc(100% - 95px), 0 100%);
$section-polygon-top-bottom-inside-cut-1024: polygon(0 0, 50% 6.445vw, 100% 0, 100% 100%, 50% calc(100% - 6.445vw), 0 100%);
$section-polygon-top-bottom-inside-cut-768: polygon(0 0, 50% 6.51vw, 100% 0, 100% 100%, 50% calc(100% - 6.51vw), 0 100%);
$section-polygon-top-bottom-inside-cut-375: polygon(0 0, 50% 10.667vw, 100% 0, 100% 100%, 50% calc(100% - 10.667vw), 0 100%);
$section-polygon-top-inside-cut-1920: polygon(0 0, 50% 95px, 100% 0, 100% 100%, 50% 100%, 0 100%);
$section-polygon-top-inside-cut-1024: polygon(0 0, 50% 6.445vw, 100% 0, 100% calc(100%), 50% 100%, 0 calc(100%));
$section-polygon-top-inside-cut-768: polygon(0 0, 50% 6.51vw, 100% 0, 100% calc(100%), 50% 100%, 0 calc(100%));
$section-polygon-top-inside-cut-375: polygon(0 0, 50% 10.667vw, 100% 0, 100% calc(100%), 50% 100%, 0 calc(100%));

/* BREAKPOINTS */

// .tsx react components should be using mobile first approach - below should be used with min-width breakpoints
// breakpoints below are taken from React Bootstrap -> Layout -> Breakpoints

$xs-breakpoint: 375px;
// ≥576px
$sm-breakpoint: 576px;
// ≥768px
$md-breakpoint: 768px;
// ≥992px
$lg-breakpoint: 992px;
// ≥1200px
$xl-breakpoint: 1200px;

