@import "../../variables";

.color-hexagon {
  display: flex;
  clip-path: polygon(50% 0%, 0 25%, 0 75%, 50% 100%, 100% 75%, 100% 25%);
  width: 26px;
  height: 29px;
}

.chart-text-main-container .color-hexagon {
  margin-left: 16px;
}

.chart-text-main-container-right .color-hexagon {
  margin-right: 16px;
}

.dark-blue-background {
  background-color: $dark-blue-background;
}

.medium-blue-background {
  background-color: $medium-blue-background;
}

.light-blue-background {
  background-color: $light-blue-background;
}

.light-purple-background {
  background-color: $light-purple-background;
}

.light-pink-background {
  background-color: $light-pink-background;
}

.dark-green-background {
  background-color: $dark-green-background;
}

.light-green-background {
  background-color: $light-green-background;
}

.dark-red-background {
  background-color: $dark-red-background;
}

.light-red-background {
  background-color: $light-red-background;
}

.chart-text-main-container-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.chart-text-opacity {
  opacity: 0.25;
}

.chart-text-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 20px;
  font-family: $sofia;
}

.chart-text-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.pie-chart-main-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: initial;
  margin-top: 76px;
  margin-bottom: 40px;
}

.pie-chart-container {
  width: 494px;
  height: 494px;
  margin-left: 80px;
  margin-right: 80px;
  transform: rotate(-16deg);
}

.chart-text-main-container-right .the-percentage-value-design {
  font-weight: 700;
  margin: 0px 16px 0px 0px;
}

.the-percentage-value-design {
  font-weight: 700;
  margin: 0px 0px 0px 16px;
}

.height-complementary-class {
  height: 70px;
}

.second-pie-chart {
  display: none;
}

.alignment-text-chart-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.low-resolution-pie-chart {
  display: none;
}

.device-pie-chart {
  display: none;
}

.chart-inside-text{
  position: absolute;
  padding-top: 2.604vw;
  font-size: 2.083vw;
  font-family: $oswald;
  text-align: center;
}

.tool-tip-box{
  padding: 1.302vw 1.302vw 1.302vw 1.302vw;
  border-radius: 8px;
  position: absolute;
  background-color: #151515;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2.083vw;
  font-family: $sofia;
}

.triangle-arrow-from-pie-chart{
  position: absolute;
  bottom: -6px;

}
.rotate-chart{
  transform: rotate(-16deg);
}
.marketing-position{
  left: 0vw;
  top: 7.302vw;
}
.launch-contributions{
  bottom: 7.813vw;
  left: 3vw;
}
.listings-liquidity-pools{
  right: -1.302vw;
  top: 3.906vw;
  text-align: center;
}
.PvP-rogue-planets{
  left: 7.8vw;
  top: -5.208vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.planet-emissions{
  bottom: 7.161vw;
  right: 2.604vw;
}
.blowfish-team{
  left: 2.510vw;
  top: 0vw;
}
.reserve{
  left: 2.604vw;
  bottom: 12vw;
}
.PvE-chart{
  top: 1vw;
  right: 9.813vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.ecosystems-fund{
  right: -3.906vw;
  bottom: 20.833vw;
}
.align-tool-tip-text{

}

@media screen and (min-width: 1025px) and (max-width: 1440px) {

  .pie-chart-container {
    width: 34.306vw;
    height: 34.306vw;
  }
  .chart-text-container {
    margin-top: 1.389vw;
    margin-bottom: 1.389vw;
    font-size: 1.389vw;
  }
  .pie-chart-container {
    margin-right: 5.556vw;
    margin-left: 5.556vw;
  }

}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .pie-chart-main-container {
    flex-direction: column;
  }
  .pie-chart-container {
    width: 48.242vw;
    height: 48.242vw;
    display: none;
  }
  .second-pie-chart {
    width: 48.242vw;
    height: 48.242vw;
    transform: rotate(-16deg);
    display: flex;
  }
  .alignment-text-chart-container {
    margin-top: 60px;
    justify-content: space-around;
  }
}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .low-resolution-pie-chart {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .low-resolution-chart-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 7.161vw;
  }
  .pie-chart-main-container {
    flex-direction: column;
    margin: 0px;
  }
  .pie-chart-container {
    width: 48.242vw;
    height: 48.242vw;
    display: none;
  }
  .chart-text-main-container {
    display: none;
  }
  .chart-text-main-container-right {
    display: none;
  }
  .second-pie-chart {
    display: none;
  }
  .alignment-text-chart-container {
    margin-top: 24px;
    justify-content: space-around;
  }

  .device-pie-chart {
    position: relative;
    width: 38.802vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .chart-text-container {
    margin: 0px;
    font-size: 2.083vw;
  }

  .color-hexagon{
    width: 2.214vw;
    height: 2.474vw;
  }
  .the-percentage-value-design{
    margin: 0px 3px 0px 10px;
  }

}

@media screen and (max-device-width: 375px) {
  .low-resolution-pie-chart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .low-resolution-chart-text {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 0;
  }
  .pie-chart-main-container {
    flex-direction: column;
    margin-top: -9.867vw;
    margin-bottom: 0;
  }
  .pie-chart-container {
    width: 48.242vw;
    height: 48.242vw;
    display: none;
  }
  .chart-text-main-container {
    display: none;
  }
  .chart-text-main-container-right {
    display: none;
  }
  .second-pie-chart {
    display: none;
  }
  .alignment-text-chart-container {
    margin-top: 24px;
    justify-content: space-around;
  }

  .device-pie-chart {
    position: relative;
    width: 78.933vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .chart-text-container {
    margin: 0px;
    font-size: 4.267vw;
  }

  .color-hexagon{
    width: 4.533vw;
    height: 4.533vw;
  }
  .the-percentage-value-design{
    margin: 0px 3px 0px 10px;
  }

  .chart-inside-text{
    font-size: 4.267vw;
    padding-top:6.667vw;

  }

  .marketing-position{
    top: 17.333vw;
    left: 5.333vw;
  }
  .launch-contributions{
    bottom: 14.133vw;
    left: 6.666vw;
  }
  .listings-liquidity-pools{
    right: -0.8vw;
    top: 9.600vw;
    text-align: center;
  }
  .PvP-rogue-planets{
    left: 15.467vw;
    top: -13.6vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .planet-emissions{
    bottom: 14.667vw;
    right: 8vw;
  }
  .blowfish-team{
    left: 5.333vw;
    top: 0.800vw;
  }
  .reserve{
    left: 5.333vw;
    bottom: 24vw;
  }
  .PvE-chart{
    top: 5.333vw;
    right: 21.333vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .ecosystems-fund{
    right: -8.533vw;
    bottom: 45.333vw;
  }
  .tool-tip-box{
    font-size: 4.267vw;
  }
}
