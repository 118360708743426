@import "../../../variables";

.play-on-behalf-exploration-main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 140px 0 0 0;

  .play-on-behalf-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 505px;

    .play-on-behalf-text {
      height: 27px;
      margin-top: 24px;
      font-size: 16px;
      font-family: $sofia;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: relative;
      text-align: center;
    }

    .wallet-address-input-container {
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;

      .submit-area-input {
        margin-bottom: 34px;
        margin-top: 26px;
        position: relative;
        width: 100%;

        .input-submit-value {
          text-decoration: none;
          outline: none;
          border: 0;
          height: 42px;
          width: 100%;
          clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
          font-family: $sofia;
          color: $stack;
          padding-left: 28px;
          padding-right: 28px;
        }
      }
    }
  }
}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .play-on-behalf-exploration-main-container {
    margin: 18.229vw 0 0 0;

    .play-on-behalf-content {
      max-width: 65.755vw;

      .play-on-behalf-text {
        margin-top: 1.693vw;
        height: 3.516vw;
        font-size: clamp(0.55rem, 2.083vw, 1rem);
      }

      .top-crown,
      .bottom-crown {
        width: 6.380vw;
        height: 3.125vw;
      }

      .wallet-address-input-container {
        padding-right: 2vw;
        padding-left: 2vw;

        .submit-area-input {
          margin-top: 3vw;
          margin-bottom: 5vw;

          .input-submit-value {
            font-size: clamp(0.5rem, 2.083vw, 1rem);
            height: 5vw;
            padding-right: 2.604vw;
            padding-left: 2.604vw;
            clip-path: polygon(1.042vw 0, 0 1.042vw, 0 calc(100% - 1.042vw), 1.042vw 100%, calc(100% - 1.042vw) 100%, 100% calc(100% - 1.042vw), 100% 1.042vw, calc(100% - 1.042vw) 0);
          }
        }
      }
    }
  }
}

@media screen and (max-device-width: 375px) {
  .play-on-behalf-exploration-main-container {

    .play-on-behalf-content {
      max-width: 100%;
      margin-bottom: 3.733vw;
      margin-top: -10vw;

      .play-on-behalf-text {
        font-size: clamp(0.5rem, 3vw, 0.6875rem);
        margin-top: 4.267vw;
      }

      .top-crown,
      .bottom-crown {
        width: 12.267vw;
        height: 6.133vw;
      }

      .wallet-address-input-container {
        padding-right: 3.333vw;
        padding-left: 3.333vw;

        .submit-area-input {
          margin-bottom: 7vw;
          margin-top: 4vw;

          .input-submit-value {
            height: 7.733vw;
            font-size: 2.933vw;
            padding-left: 18px;
            padding-right: 18px;
          }
        }
      }
    }
  }
}
