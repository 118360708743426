@import '../../../variables';
@import '../../../functions';

.asset-card:hover {
  border: 2px solid $grey-tab-background;
}

.asset-card,
.asset-card--selected {
  align-items: center;
  background-color: $asset-card-dark-grey-bg;
  cursor: pointer;
  flex-direction: row;
  gap: 10px;
  padding: 8px 5px;
  width: 100%;
  // This prevents other elements from moving when the card is selected
  border: 2px solid transparent;

  @media screen and (min-width: $sm-breakpoint) {
    width: 100%;
    flex-wrap: wrap;
  }

  &--selected {
    border: 2px solid $white;
  }

  &__fav {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 3px;
    margin: 4px;
    z-index: 1;

    @media screen and (min-width: $sm-breakpoint) {
      order: 1;
      width: 30px;
      height: 29px;
    }
  }

  &__image {
    border: 2px solid #000;
    border-radius: 0;
    height: 45px;
    position: relative;
    width: 45px;

    @media screen and (min-width: $sm-breakpoint) {
      height: 100%;
      width: 9.25em;
      object-fit: cover;
      object-position: center center;
      width: 100%;
    }
  }

  &__image-container {
    &--locked,
    &--unlocked,
    &--game-locked {
      position: relative;

      @media screen and (min-width: $sm-breakpoint) {
        order: 5;
        width: 100%;
      }
    }

    &--locked::after,
    &--unlocked::after,
    &--game-locked::after {
      content: '';
      position: absolute;
      top: 11px;
      left: 14px;
      background-size: cover;
      width: 14px;
      height: 18px;

      @media screen and (min-width: $sm-breakpoint) {
        width: 21px;
        height: 27px;
      }
    }

    &--locked::after {
      background-image: url('../../../assets/images/icons/disabled-padlock.svg');
    }

    &--unlocked::after {
      background-image: url('../../../assets/images/icons/unlocked.svg');
    }

    &--game-locked::after {
      background-image: url('../../../assets/images/icons/locked-icon.png');
    }
  }

  &__id {
    display: flex;
    align-items: center;
    font-family: $oswald-light;
    overflow: auto;
    font-size: 1rem;
    width: 70%;
    margin: 0;
    text-transform: uppercase;
    min-height: 5rem;

    @media screen and (min-width: $sm-breakpoint) {
      order: 2;
    }

    @media screen and (min-width: $lg-breakpoint) {
      width: 50%;
      min-height: 3rem;
    }
  }

  &__status {
    display: flex;
    font-size: pxToRem(12px);

    @media screen and (min-width: $sm-breakpoint) {
      order: 4;
      font-size: pxToRem(18px);
      width: 100%;
    }
    //
    //@media screen and (min-width: $xl-breakpoint) {
    //  font-size: pxToRem(px);
    //}
  }

  &__status-label {
    font-family: $sofia;
    font-weight: lighter;
    line-height: 1.2;

    @media screen and (min-width: $xs-breakpoint) {
      line-height: 1;
    }
  }

  &__status-value {
    font-family: $sofia;
    color: $light-red-background;
    font-weight: bold;
    margin-left: 10px;
    line-height: 1;
  }

  &__checkbox {
    cursor: pointer;
    margin: 4px 4px 4px auto;
    padding: 3px;
    z-index: 1;

    @media screen and (min-width: $sm-breakpoint) {
      order: 3;
    }
  }

  &-tooltip-mask {
    position: absolute;
    bottom: 2px;
    right: 4px;
    padding: 22px;
    z-index: 2;
  }
}

.asset-dashboard {
  &__items {
    background-color: $black-night-bg;
    min-height: 90px;
    margin-bottom: 50px;
    max-width: 1500px;
    padding: 20px 0;

    @media screen and (min-width: $sm-breakpoint) {
      margin: 0 auto 50px auto;
      padding: 20px;
    }

    @media screen and (min-width: $lg-breakpoint) {
      padding: 50px 30px;
    }

    @media screen and (min-width: $xl-breakpoint) {
      padding: 80px 75px;
    }

    &-header {
      display: flex;
      margin-bottom: 18px;

      @media screen and (min-width: $md-breakpoint) {
        margin-bottom: 36px;
      }
    }

    &-title {
      font-family: $oswald-light;
      font-size: pxToRem(24px);
      margin-left: 20px;
      margin-bottom: 0;
      padding-left: 1rem;

      @media screen and (min-width: $md-breakpoint) {
        margin-left: 0;
        font-size: pxToRem(32px);
      }
    }

    &-toggle {
      margin-left: auto;
    }
  }
}
