@import "../../variables";

.leader-board-row-container {
  width: 100%;
  height: 42px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.player-name {
  display: block;
}

.player-name-375 {
  display: none;
}

.light-white-background {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  background-color: rgba(255, 255, 255, 15%);
  clip-path: polygon(5px 0, 0 5px, 0 calc(100% - 5px), 5px 100%, calc(100% - 5px) 100%, 100% calc(100% - 5px), 100% 5px, calc(100% - 5px) 0);
}

.row-elements-box-for-alignment {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 19px;
  padding-right: 19px;
}


.add-white-border-from-row {
  position: absolute;
  background-color: white;
  z-index: -2;
  width: 100%;
  height: 100%;
  clip-path: polygon(5px 0, 0 5px, 0 calc(100% - 5px), 5px 100%, calc(100% - 5px) 100%, 100% calc(100% - 5px), 100% 5px, calc(100% - 5px) 0);
}


.add-font-bold {
  font-weight: 700;
}

.leader-table-element-description {
  font-family: $sofia;
  width: 100%;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  pointer-events: revert;
}

.first-element-in-row {
  padding-right: 73px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.player-element-in-row:hover .copy-hover-element {
  display: flex;
}

.last-element-in-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 79px;
}

.player-element-in-row {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  padding-left: 28px;
  align-items: center;
  cursor: pointer;
}

.copy-hover-element {
  display: none;
  position: absolute;
  bottom: 28px;
  left: 17px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: $sofia;
  font-size: 14px;
}

.copy-hover-element-bottom {
  display: none;
  position: absolute;
  bottom: 29px;

  left: 17px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: $sofia;
  font-size: 14px;
}

.rotate-copy-text {
  margin-bottom: 0px;
}

.completion-hover-element {
  display: none;
  position: absolute;
  bottom: 36px;
  left: 130px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: $sofia;
  font-size: 14px;
}

.completion-hover-element-bottom {
  display: none;
  position: absolute;
  z-index: 2;
  bottom: -56px;

  left: 130px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: $sofia;
  font-size: 14px;
}

.player-element-in-row:hover .copy-hover-element {
  display: flex;
}

.player-element-in-row:hover .copy-hover-element-bottom {
  display: flex;
}

.copy-progress-hover-triangle {
  position: absolute;
  bottom: -7px;
}


.completion-element-in-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 90px;
  cursor: pointer;
}

.completion-element-in-row:hover .completion-hover-element {
  display: flex;
}

.completion-element-in-row:hover .completion-hover-element-bottom {
  display: flex;
}

.scale-copy-box {
  background-color: $semi-dark-black;
  padding: 7px 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: initial;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .leader-board-row-container {
    height: 4.102vw;
  }
  .copy-hover-element {
    bottom: 3.034vw;
    left: 1.660vw;
  }
  .copy-hover-element-bottom {
    bottom: 2.832vw;
  }
  .first-element-in-row {
    justify-content: left;
    padding-left: 3.516vw;
    padding-right: 0;
  }
  .leader-table-element-description {
    font-size: 1.758vw;
  }
  .completion-element-in-row {
    margin-left: 8.789vw;
  }
  .last-element-in-row {
    padding-left: 7.3vw;
  }
  .completion-element-in-row.add-font-bold {
  }
  .last-element-in-row.add-font-bold {
    padding-left: 6.836vw;
  }
  .completion-hover-element {
    left: 12.695vw;
    bottom: 3.516vw;
  }
  .player-element-in-row {
    padding-left: 2.334vw;
  }
  .completion-hover-element-bottom {
    bottom: -6.069vw;
    left: 12.695vw;
  }
  .scale-copy-box {
    padding: 0.684vw 1.563vw;
    font-size: 1.563vw;
  }
}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .completion-hover-element {
    left: 8.854vw;
    bottom: 3.646vw;
  }
  .copy-hover-element {
    left: 0.781vw;
    bottom: 3.646vw;
  }
  .scale-copy-box{
    padding: 0.911vw 2.083vw;
    border-radius: 1.042vw;
    font-size: 1.823vw;
  }
  .row-elements-box-for-alignment {
    padding-right: 2.474vw;
    padding-left: 2.474vw;
  }
  .player-element-in-row {
    padding-left: 0;
  }
  .player-element-in-row.add-font-bold {
    padding-left: 0.911vw;
  }
  .completion-element-in-row {
    margin-left: 3.125vw;
  }
  .completion-element-in-row.add-font-bold {
    margin-left: 3.906vw;
  }
  .last-element-in-row {
    padding-left: 3.125vw;
  }
  .last-element-in-row.add-font-bold {
    justify-content: center;
    padding-left: 4.125vw;
  }
  .first-element-in-row, .player-element-in-row, .completion-element-in-row, .last-element-in-row {
    font-size: 1.823vw;
  }
  .leader-board-row-container {
    height: 3.906vw;
  }
  .first-element-in-row {
    padding-right: 10.807vw;
  }
}

@media screen and (max-width: 375px) {
  .copy-hover-element {
    left: 0.533vw;
  }
  .completion-hover-element {
    left: 10.667vw;
  }
  .leader-board-row-container {
    height: 9.333vw;
  }
  .first-element-in-row, .player-element-in-row, .completion-element-in-row, .last-element-in-row {
    font-size: 3.733vw;
    padding: 0;
    margin: 0;
  }
  .first-element-in-row {
    position: relative;
    left: -3.467vw;
  }
  .player-element-in-row {
  }
  .row-elements-box-for-alignment {
    padding-right: 0;
    padding-left: 0;
  }
  .leader-table-element-description {
    font-size: 4.800vw;
  }
  .last-element-in-row.add-font-bold {
    padding-right: 5.800vw;
    width: 100%;
  }
  .completion-element-in-row.add-font-bold {
    padding-right: 1.067vw;
  }
  .player-name {
    display: none;
  }

  .player-name-375 {
    display: block;
  }
  .first-element-in-row.add-font-bold {
    padding-left: 6.133vw;
  }
  .player-element-in-row.add-font-bold {
    padding-left: 2.667vw;
  }
}
