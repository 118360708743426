@use '/src/variables.scss' as *;
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';

$images-path: '../assets/images/';

.episode-tabs {}

.episode-tabs .nav-link {
  padding: 0;
  color: #fff;
}

.episode-tabs .nav-link:hover {
  padding: 0;
  color: #D42758;
}

.episode-tabs .nav-link.active {
  position: relative;
  border-left: 1px solid #fff;
  border-radius: 0;
  background: none;
}

.episode-tabs .nav-link.active:before {
  content: url(#{$images-path}section-left.png);
  height: 100%;
  position: absolute;
  top: 50%;
  left: -34px;
  margin-top: -34px;
}

.episode-tabs .nav-link > .episode-tab-container {
  width: 100%;
  height: 100%;
  padding: 40px;
}

.episode-tabs .nav-link.active > .episode-tab-container {
  background: $darkgrey;
  clip-path: polygon(90% 0px, 100% 15%, 100% 100%, 0px 100%, 0px 0px);
}

.episode-tabs .episode-tab-image {
  max-width: 420px;
  max-height: 220px;
  border-left: 2px solid #fff;
  overflow: hidden;
}

.episode-tabs .episode-tab-image img {
  width: 420px;
}

.episode-tabs .episode-coming-soon {
  width: 420px;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #D42758;
  border: 1px solid #534F5B
}

.vertical-rule-container {
  padding-left: 120px;
  @media screen and (max-width: 769px) {
    display: none;
  }
}

.vertical-rule-container > .vertical-rule-sticky-out-thingy {
  background-color: #fff;
  clip-path: polygon(0 0, 6px 10%, 6px 90%, 0% 100%);
  height: 50px;
  position: absolute;
  margin-top: -2px;
  margin-left: -1px
}

.vertical-rule-container > .vertical-rule {
  width: 2px;
  height: 100%;
  background-color: #fff
}

.episode-detail {}

.episode-detail .episode-header {
  padding-left: 0;
}

.episode-detail .episode-header-image {
  max-width: 730px;
  max-height: 380px;
  border: 1px solid #D42758;
  overflow: hidden;
}

.episode-detail .episode-header-image img {
  width: 730px;
}

.episode-detail .episode-body {
  padding-top: 50px;
  padding-left: 0;
}

.symbols-mobile-adjustments{
  font-size: 24px;
  padding-top: 10px;
  padding-bottom: 21px;
}

 .nfts-panel {
  position: relative;
  padding: 48px 0;
  padding-bottom: 0px;
  border-top: $white 1px solid;
  margin-top: 40px;
   column-gap: 5rem;
}

 .nfts-panel:before {
  content: url(#{$images-path}section-top.png);
  width: auto;
  position: absolute;
  top: -34px;
  left: 50%;
  margin-left: -34px;
}

.episode-detail .nfts-panel .nft-container {
  display: flex;
  flex-direction: column;
  padding-right: 0px;
  height: 250px;
  width: 190px;

}

.episode-detail .nfts-panel .nft-container .nft-type {
  flex: 0;
  text-transform: uppercase
}

.episode-detail .nfts-panel .nft-container .nft-image-container {
  height: 190px;
  min-height: 190px;
  border-left: 2px solid $pink;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
width: 189px;
}

.episode-detail .nfts-panel .nft-container .nft-image-container .nft-unlocked {}

 .nft-unlocked-image {
   width: 190px;
   max-width: 190px;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.episode-detail .nfts-panel .nft-container .nft-image-container .nft-unlocked .nft-unlocked-image img {
  display: flex;
  width: 191px;
  margin: 0 auto;
}

.ep1-disabled-button{
  @extend .disabled-button;
  margin-top: 0;
  cursor: initial;
}

.nft-unlocked-image-overlay-container {
  display: block;
  position: absolute;
  width: 100%;
  height: 55px;
  bottom: 0
}
.poster-NFT-opacity{
  height: 30px;
}

.nft-unlocked-image-overlay {
  background: black;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  clip-path: polygon(85% 0px, 100% 45%, 100% 100%, 0px 100%, 0px 0px)
}

.nft-unlocked-image-overlay-text {
  padding: 10px;
  padding-left: 15px
}

.episode-detail .nfts-panel .nft-container .nft-image-container .nft-locked {
  position: relative;
  width: 190px;
  height: 190px
}

 .nft-locked-image-overlay-container {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0
}

 .nft-locked-image-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center
}

.set-tablet-width{
  width: 100%;
}
 .nft-locked-image-overlay-text {
  padding: 10px;
  padding-left: 15px;
  color: $pink;
  text-align: center
}

.episode-detail .nfts-panel .nft-claim-poster-button, .episode-detail .nfts-panel .nft-claim-badge-button {
  text-align: center;
  cursor: pointer;
}

.episode-detail .nfts-panel .nft-claim-button-placeholder {
  min-height: 37px;
  margin-top: 20px;
}

.plus-symbol {
  padding:0px;
  padding-right: 21px;
  padding-left: 21px;
  flex: 0 0 10px;
  display: flex;
  align-items: center;
  font-size: xxx-large;
}

 .equals-symbol {
  padding: 0;
  padding-right: 21px;
   padding-left: 21px;
  flex: 0 0 10px;
  display: flex;
  align-items: center;
  font-size: xxx-large
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple-sm {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple-sm div {
  position: absolute;
  border: 2px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple-sm 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple,.lds-ripple-sm div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@keyframes lds-ripple-sm {
  0% {
    top: 18px;
    left: 18px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 36px;
    height: 36px;
    opacity: 0;
  }
}

.nft-more-info-button{
  width: 142px;
  height: 37px;
  margin-bottom: 20px;
}

.nft-unlucked-poster-nft-img{
  width: 100%;
  height: 30px;
}

.nft-unlocked-image-adjustment{
 height: 100px;
}
.claim-medal-desing{
  width: 100%;
}
.claim-medal-dimension{
  margin-top: 22px;
  display: flex;
}
.claim-medal-container{
  width: 142px;
  text-align: center;
  cursor: pointer;
}

.disabled-button{
  text-align: center;
  width: 142px;
  height:37px;
  background-color: $disable-gray;
  clip-path: polygon(6% 0%, 100% 0%, 94% 100%, 0% 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Oswald;
  color: $disable-gray-text;
  cursor: pointer;
}
.buy-on-opensea-medal{
  @extend .claim-medal-dimension;
}
.buy-poster-opensea{
  margin-top: 24px;
}

.nft-disable-img{
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}

@media only screen and (max-width: 1504px) {
  .nft-type h4{
    white-space: nowrap;
  }

  .nft-disable-img{
    width: 100%;
  }

  .nft-image-container{
    width: 100%;
  }

  .plus-symbol{
    padding-left: 25px;
  }

  .episode-detail .nfts-panel .nft-container{
    width: 120px;
  }

  .episode-detail .nfts-panel{
    padding-right: 28px;
  }

  .nft-unlocked{
    width: 100%;
  }

  .claim-medal-desing a {
    margin-left: 0;
  }

  .claim-medal-desing a:hover {
    margin-left: 0;
  }

  .episode-tabs .nav-link > .episode-tab-container{
    padding-top: 20px;
  }

  .nav-link.disabled .episode-tab-container .episode-tab-image{
    border-left: 0;
  }

  .vertical-rule-container{
    width: 13%;
  }

  //episodes
  .episode-tabs .episode-tab-image{
    max-width: 360px;
    max-height: 200px;
  }

  .episode-tabs .episode-tab-image img{
    width: 100%;
  }

  .episode-tabs{
    display: grid;
    flex-direction: column;
    grid-gap: 36px;
  }

  .episode-tabs .episode-coming-soon {
    width: 100%;
    height: 200px;
  }

}
.nft-footer-show{
  height: 700px;
}
.nft-desktop-1440{
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.nft-tablet-1024{
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.nft-small-tablet-768{
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.nft-mobile-375{
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen  and (min-width: 1440px)  {
  .nft-desktop-1440{
    display: flex;
  }
}
@media screen  and (min-width: 1024px)  and (max-width: 1439px){
  .nft-tablet-1024{
    display: flex;
  }
}
@media screen  and (min-width: 768px)  and (max-width: 1023px){
  .nft-small-tablet-768{
    display: flex;
  }
  .dropdown-button-container-on-content{
    max-width: 636px;
  }
  .poster-nft-resolution-design{
    height: 165px;
  }
  .set-tablet-width{
    width: 85%;
  }
  .nft-unlocked-image-overlay{
   width: 90%;
  }
  .adjustpop{
    width: 85%;
  }
  .nft-enable-img-dimension{
    width: 85%;
  }
  .nft-disable-img{
    width: 85%;
  }
  .symbol-height-adjustment{
    height: 210px;
  }
}
@media screen  and (max-width: 767px){
  .nft-mobile-375{
    display: flex;
  }
  .nft-page-connect-wallet-button{
    width: 89%;
  }
}

.nfts-panel-data-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 5rem;
}
.overlay-design-orientation{
  position: relative;
  margin-left:2px;
}
.see-the-dimensions{
  width: 70%;
}
.poster-nft-resolution-design{
  height: 190px;
  width: 190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-left:1px solid $pink;
}
.nft-completion-badge-resolution-design{
  width: 190px;
  height: 190px;
}
.symbol-height-adjustment{
  display: flex;
  height: 250px;
}
.nft-container-adjustment{
}
.episode-elements-distance{
  margin-bottom: 70px;
  width: 100%;
}
.nft-enable-img-dimension{
  width: 190px;
}
.nfts-panel-data-mobile-container{
  @extend .nfts-panel-data-container;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 3rem;
}
.set-width-of-background-img{
  width: 225%;
}
.nft-container{
  width: 100%;

  @media screen and (max-width: 375px) {
    justify-content: center;
  }
}
.mobile-img-size{
  object-fit: contain;
  width: 100%;
}
.align-more-info-button{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}
.episode-elements-distance-mobile{
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.mobile-left-alignment{
  width: 100%;
}
.nft-container-width{
  max-width: 1600px;
  margin-top: 100px;
}
.nft-adjust-error-content{
  margin-bottom: 100px;
}
.nft-completion-badge-padding-adjustment{
  padding: 20px 40px;
}
.nft-row-add-margin{
  margin-bottom: 100px;
}

.nft-error-container{
  >p{
    text-align: center;
  }
}
