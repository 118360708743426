@import "../../../../variables";

.ps-main-container{
  width: 100%;
  height: 990px;
  background-image: url("../../../../assets/images/PlanetSalePagePrivate/compensation-page-BG.png");
  position: relative;
  clip-path: $main-element-clip-path;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.ps-set-header{
  width: 100%;
  position: absolute;
  top: 0px;
}
.ps-adjust-border-img{
  position: absolute;
  width: 100%;
  top: 100px;
}
