@import "../../../variables";

.double-icon-card {
  display: flex;
  align-items: center;
  width: 100%;
  color: $white;
  font-size: 1rem;

  &__main-text {
    color: $gray-text;
    padding-left: 0.5rem;
  }

  &__sub-text {
    padding-left: 0.5rem;
  }

  &__value {
    padding-left: 0.5rem;
  }
}

