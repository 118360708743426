@import "../../../variables";

.mintpass-modal-main-container .modal-content{
  background-color: transparent;
}

.mintpass-modal{
  width: 1108px ;
  max-width: none;
}

.mintpass-header-decoration{
  position: relative;
  top: -18px;
}

.mintpass-bottom-decoration{
  position: relative;
  bottom: -18px;
}


.mintpass-container{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1108px ;
  height: 1226.58px ;
  margin-top: -5px;
}

.mintpass-close-button{
  position: absolute;
  right: 23px;
  top: 0;
  cursor: pointer;
}

.mintpass-note{
  font-family: $sofia;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  width: 841px;
  margin-top: 44px;
  margin-bottom: 45px;
}

.mintpass-note .bold{
  font-weight: 800;
}

.mintpass-result-container{
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.mintpass-nft-image{
  width: 360px;
  height: 175px;
}

.mintpass-licence-agreement-title {
  text-align: center;
  width: 633.41px;
  height: auto;
  margin-top: 18px;
  font-family: $sofia;
  line-height: 30px;
}

.mintpass-licence-agreement-title .header {
  font-size: 32px;
  font-weight: 600;
}

.mintpass-licence-agreement-title .body {
  font-size: 24px;
  font-weight: 300;
  margin-top: -10px;
}

.mintpass-licence-text-box-container {
  width: 1007.93px;
  height: 330px;
  background: $gray-licence-text-box;
  margin-bottom: -8px;
}

.mintpass-licence-text-box{
  overflow-y: scroll;
  margin-right: 6.5px;
  margin-top: 20px;
}

.mintpass-licence-text-box-shadow {
  top: -80px;
  width: 1007.88px;
  height: 98.02px;
  position: relative;
  background: linear-gradient(transparent, black 70%);
}

.mintpass-licence-text-box-text {
  width: 928.97px;
  height: 292px;
  margin-left: 39px;
  background: none;
  font-size: 18px;
  font-family: $sofia;
  font-weight: 400;
}

.mintpass-licence-text-box-text .licence-header {
  display: none;
}

.mintpass-licence-text-box-text.licence-sublist-alphabetic {
  list-style-type: lower-alpha;
}

.mintpass-licence-text-box-text .licence-sublist-alphabetic li:before{
  display: none;
}

.mintpass-licence-text-box-text .licence-sublist-romans {
  list-style-type: lower-roman;
}

.mintpass-licence-text-box-text .licence-sublist-romans li:before {
  display: none;
}

.mintpass-licence-text-box-container ::-webkit-scrollbar {
  width: 3.32px;
  background: none;
  border-top: 20px;
}

.mintpass-licence-text-box-container ::-webkit-scrollbar-thumb {
  background: $thumb-bar-dark-gray;
  width: auto;
  height: 42.15px;
}

.mintpass-confirmation-text{
  font-size: 20px;
  line-height: 30px;
  text-align: center;
}

.mintpass-buttons-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 17px;
}

.mintpass-yes-button,
.mintpass-no-button{
  font-family: $oswald;
  font-size: 20px;
  width: 159px;
  height: 37px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background-color: $pink;
  -webkit-clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
  clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
  cursor: pointer;
}

.mintpass-yes-button{
margin-right: 21px;
}

.mintpass-no-button{
  margin-left: 21px;
}

.mintpass-combinations{
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mintpass-combination-equals-table{
  display: block;
  position: absolute;
  left: -52px;
  width: 36px;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .mintpass-modal{
    width: 76.944vw;
  }

  .mintpass-header-decoration{
    top: -1.250vw;
  }

  .mintpass-bottom-decoration{
    bottom: -1.250vw;
  }

  .mintpass-container{
    width: 76.944vw ;
    height: 83.333vw;
  }

  .mintpass-close-button{
    right: 1.597vw;
  }

  .mintpass-note{
    font-size: 1.250vw;
    line-height: 1.875vw;
    width: 58.403vw;
    margin-top: 3.056vw;
    margin-bottom: 3.125vw;
  }

  .mintpass-result-container{
    margin-bottom: 2.083vw;
  }

  .mintpass-combination-equals-table {
    left: -3.611vw;
    width: 2.500vw;
  }

  .mintpass-nft-image{
    width: 25vw;
    height: 12.153vw;
  }

  .mintpass-confirmation-text{
    font-size: 1.389vw;
    line-height: 2.083vw;
    margin-bottom: 1.042vw;
  }

  .mintpass-yes-button,
  .mintpass-no-button{
    font-size: 1.389vw;
    width: 11.042vw;
    height: 2.569vw;

    background-color: $pink;
    -webkit-clip-path: polygon(0.486vw 0, 0 0.486vw, 0 calc(100% - 0.486vw), 0.486vw 100%, calc(100% - 0.486vw) 100%, 100% calc(100% - 0.486vw), 100% 0.486vw, calc(100% - 0.486vw) 0);
    clip-path: polygon(0.486vw 0, 0 0.486vw, 0 calc(100% - 0.486vw), 0.486vw 100%, calc(100% - 0.486vw) 100%, 100% calc(100% - 0.486vw), 100% 0.486vw, calc(100% - 0.486vw) 0);
    cursor: pointer;
  }

  .mintpass-yes-button{
    margin-right: 1.458vw;
  }

  .mintpass-no-button{
    margin-left: 1.458vw;
  }

  .mintpass-combinations{
    margin-bottom: 1.389vw;
  }
  .mintpass-buttons-container{
    margin-top: 0;
  }
  .mintpass-licence-agreement-title {
    width: 43.958vw;
    margin-top: 0.556vw;
    line-height: 2.083vw;
  }

  .mintpass-licence-agreement-title .header {
    font-size: 2.222vw;
  }

  .mintpass-licence-agreement-title .body {
    font-size: 1.667vw;
    margin-top: -0.694vw;
  }

  .mintpass-licence-text-box-container {
    width: 74.306vw;
    height: 21.944vw;
    margin-bottom: 0.556vw;
  }

  .mintpass-licence-text-box{
    margin-right: 0.451vw;
    margin-top: 1.389vw;
  }

  .mintpass-licence-text-box-shadow {
    top: -4.958vw;
    width: 74.306vw;
    height: 6.806vw;
  }

  .mintpass-licence-text-box-text {
    width: 68.750vw;
    height: 19.306vw;
    margin-left: 2.708vw;
    font-size: 1.250vw;
  }
  .mintpass-licence-text-box-container ::-webkit-scrollbar {
    width: 3.32px;
    border-top: 1.389vw;
  }

  .mintpass-licence-text-box-container ::-webkit-scrollbar-thumb {
    height: 2.917vw;
  }

}

@media screen and (min-width: 376px) and (max-width: 1024px) {
  .mintpass-modal {
    width: 90.918vw;
  }
  .mintpass-header-decoration {
    height: 1.172vw;
    top: -1.172vw;
  }
  .mintpass-bottom-decoration {
    height: 1.172vw;
    bottom: -1.172vw;
  }
  .mintpass-container {
    width: 90.918vw;
    height: auto;
    gap: 10px;
  }
  .mintpass-close-button {
    width: 1.758vw;
    height: 1.758vw;
    right: 1.660vw;
  }
  .mintpass-note{
    font-size: 1.758vw;
    line-height: 2.148vw;
    width: 65.453vw;
    margin-top: 3.906vw;
    margin-bottom: 1.953vw;
  }
  .mintpass-result-container {
    margin-bottom: 2.441vw;
  }
  .mintpass-combination-equals {
    display: none;
  }
  .mintpass-nft-image{
    width: 29.590vw;
    height: 14.355vw;
  }
  .mintpass-confirmation-text{
    font-size:  1.823vw;
    line-height: 1.465vw;
    margin-bottom: 0.977vw;
  }
  .mintpass-yes-button,
  .mintpass-no-button{
    font-size: 1.953vw;
    width: 15.365vw;
    height: 3.516vw;
    -webkit-clip-path: polygon(0.586vw 0, 0 0.586vw, 0 calc(100% - 0.586vw), 0.586vw 100%, calc(100% - 0.586vw) 100%, 100% calc(100% - 0.586vw), 100% 0.586vw, calc(100% - 0.586vw) 0);
    clip-path: polygon(0.586vw 0, 0 0.586vw, 0 calc(100% - 0.586vw), 0.586vw 100%, calc(100% - 0.586vw) 100%, 100% calc(100% - 0.586vw), 100% 0.586vw, calc(100% - 0.586vw) 0);

  }
  .mintpass-yes-button {
  margin-right: 2.051vw;
  }
  .mintpass-no-button{
    margin-left: 2.051vw;
  }
  .mintpass-combinations {
    margin-bottom: 1.465vw;
  }
  .mintpass-combination-equals-table{
    width: 2.930vw;
    height: 1.977vw;
    left: -3.711vw;
  }
  .mintpass-buttons-container{
    margin-top: 2.604vw;
    margin-bottom: 3.255vw;
  }
  .mintpass-licence-agreement-title {
    width: 44.922vw;
    margin-top: 0;
    line-height: 2.148vw;
  }

  .mintpass-licence-agreement-title .header {
    font-size: 2.344vw;
  }

  .mintpass-licence-agreement-title .body {
    font-size: 1.758vw;
    margin-top: -0.977vw;
  }

  .mintpass-licence-text-box-container {
    width: 87.988vw;
    height: 22.949vw;
    margin-bottom: 1.693vw;
  }

  .mintpass-licence-text-box{
    margin-right: 0.635vw;
    margin-top: 1.953vw;
  }

  .mintpass-licence-text-box-shadow {
    top: -3.809vw;
    width: 87.988vw;
    height: 7.129vw;
  }

  .mintpass-licence-text-box-text {
    width: 82.129vw;
    height: 20.215vw;
    margin-left: 2.832vw;
    font-size: 1.823vw;
  }
  .mintpass-licence-text-box-container ::-webkit-scrollbar {
    width: 3.32px;
    border-top:  1.953vw;
  }

  .mintpass-licence-text-box-container ::-webkit-scrollbar-thumb {
    height: 4.102vw;
  }
  }

@media screen and (max-device-width: 375px) {
  .mintpass-modal {
    width: 98.133vw;
  }
  .mintpass-header-decoration {
    height: 1.600vw;
    top: -1.600vw;
  }
  .mintpass-bottom-decoration {
    height: 1.600vw;
    bottom: -1.600vw;
  }
  .mintpass-container {
    width: 98.133vw;
    height: auto;
  }
  .mintpass-close-button {
    width: 1.867vw;
    height: 1.867vw;
    right: 2.667vw;
    top: 0.800vw;
  }
  .mintpass-note{
    font-size: 3.733vw;
    line-height: 4.800vw;
    width: 88vw;
    margin-top: 1.333vw;
    margin-bottom: 3.133vw;
  }
  .mintpass-result-container {
    margin-bottom: 2.667vw;
    position: relative;
  }
  .mintpass-combination-equals {
    display: none;
  }
  .mintpass-nft-image{
    width: 73.333vw;
    height: 35.733vw;
  }
  .mintpass-confirmation-text{
    font-size: 3.733vw;
    line-height: 3.733vw;
    margin-bottom: 5.067vw;
  }
  .mintpass-yes-button,
  .mintpass-no-button{
    font-size: 3.733vw;
    width: 25.867vw;
    height: 7.200vw;
    -webkit-clip-path: polygon(1.600vw 0, 0 1.600vw, 0 calc(100% - 1.600vw), 1.600vw 100%, calc(100% - 1.600vw) 100%, 100% calc(100% - 1.600vw), 100% 1.600vw, calc(100% - 1.600vw) 0);
    clip-path: polygon(1.600vw 0, 0 1.600vw, 0 calc(100% - 1.600vw), 1.600vw 100%, calc(100% - 1.600vw) 100%, 100% calc(100% - 1.600vw), 100% 1.600vw, calc(100% - 1.600vw) 0);

  }
  .mintpass-yes-button {
    margin-right: 4vw;
  }
  .mintpass-no-button{
    margin-left: 4vw;
  }
  .mintpass-combinations {
    margin-bottom: 3.200vw;
  }
  .mintpass-combination-equals-table{
    width: 5.333vw;
    height: 5.133vw;
    left: -8vw;
  }
  .mintpass-licence-agreement-title {
    width: 95.733vw;
    margin-top: 0;
    line-height: 4.533vw;
  }

  .mintpass-licence-agreement-title .header {
    font-size: 4.800vw;
  }

  .mintpass-licence-agreement-title .body {
    font-size: 3.733vw;
    margin-top: -2.667vw;
  }

  .mintpass-licence-text-box-container {
    width: 90.400vw;
    height: 70.933vw;
    margin-bottom: 0.800vw;
  }

  .mintpass-licence-text-box{
    margin-right: 1.333vw;
    margin-top: 4vw;
  }

  .mintpass-licence-text-box-shadow {
    top: -19.333vw;
    width: 90.400vw;
    height: 19.467vw;
  }

  .mintpass-licence-text-box-text {
    width: 81.867vw;
    height: 63.467vw;
    margin-left: 4.267vw;
    font-size: 3.733vw;
  }
  .mintpass-licence-text-box-container ::-webkit-scrollbar {
    width: 3.32px;
    border-top:  5.333vw;
  }

  .mintpass-licence-text-box-container ::-webkit-scrollbar-thumb {
    height: 8.267vw;
  }
  .mintpass-buttons-container{
    margin-top: 0;
    margin-bottom: 6.667vw;
  }
}
