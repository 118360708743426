@import "../../../variables";
.track-main-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.track-title{
  background: #51CBFF;
  background: linear-gradient(180deg, #f1f5f7 0%, #96afbc 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 82px;
  font-family: $oswald;
  letter-spacing: 7px;
  font-weight: 500;
  line-height: 78px;
}
.track-second-title{
  background: #51CBFF;
  background: linear-gradient(180deg, #8ba5b3 0%, #567180 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 82px;
  font-family: $oswald;
  font-weight: 500;
  line-height: 78px;
  margin-bottom: -5px;

}


.track-content{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 40px;
  margin-left: 0;
}

.track-container,
.save-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.save-container{
  position: relative;
  top: -18px;
}

.track-container{
  margin-right: 166px;
}

.track-container .track-button-container{
  margin-top: 41px;
}

.save-container .track-button-container{
  position: relative;
  top: 77px;
}

.save-cycle-container{
  position: relative;
}

.pointer-image{
  position: absolute;
  height: 34px;
  width: 27px;
  top: 35px;
  left: 102px;
}

.track-button-container{
  width: 109px;
}

.track-description{
  font-family: $oswald;
  font-size: 20px;
  line-height: 24px;
  margin-top: 40px;
}

.track-title-375,
.track-second-title-375,
.track-third-title-375{
  display: none;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .track-main-container{
    margin-top: 6.738vw;
  }
  .track-title {
    font-size: 6.738vw;
    line-height: 6.348vw;
    letter-spacing: 0.293vw;
  }
  .track-second-title{
    font-size: 6.250vw;
    line-height: 5.859vw;
  }
  .track-content {
      margin-top:2.637vw;
  }
  .track-image{
    width: 13.281vw !important;
    height: 11.426vw;
  }
  .save-progress-image{
    width: 16.992vw !important;
    height: 3.516vw;
  }
  .pointer-image{
    width: 2.637vw;
    height: 3.320vw;
    top: 2.832vw;
    left: 9.180vw;
  }
  .save-container {
    top: -1.758vw;
  }
  .track-container{
    margin-right: 9.570vw;
  }
  .track-container .track-button-container{
    margin-top:2.344vw;
  }

  .save-container .track-button-container{
    position: relative;
    top: 6.836vw;
  }
  .track-button-container {
    width: 10.352vw;
  }
  .track-description {
    font-size: 1.563vw;
    line-height: 2.344vw;
    position: relative;
    margin-top: 4.883vw;
  }
  }

@media screen and (min-width: 376px) and (max-width: 768px) {
  .track-main-container{
    margin-top: 8.984vw;
  }
  .track-title {
    font-size: 8.984vw;
    line-height: 8.464vw;
    letter-spacing: 0.391vw;
  }
  .track-second-title{
    font-size: 8.333vw;
    line-height: 7.813vw;
  }
  .track-content {
    margin-top: 3.516vw;
  }
  .track-image{
    width: 17.708vw !important;
    height: 15.234vw;
  }
  .save-progress-image{
    width: 22.656vw !important;
    height: 4.688vw;
  }
  .pointer-image{
    width: 3.516vw;
    height:4.427vw;
    top: 3.776vw;
    left: 12.240vw;
  }
  .save-container {
    top: -2.344vw;
  }
  .track-container{
    margin-right: 12.760vw;
  }
  .track-container .track-button-container{
    margin-top: 3.125vw;
  }

  .save-container .track-button-container{
    position: relative;
    top:9.115vw;
  }
  .track-button-container {
    width: 13.802vw;
  }
  .track-description {
    font-size: 2.083vw;
    line-height: 3.125vw;
    position: relative;
    margin-top: 6.510vw;
  }
}

@media screen and (max-width: 375px) {
  .track-title-375,
  .track-second-title-375,
  .track-third-title-375{
    display: block;
    font-family: $oswald;
  }
  .track-title,
  .track-second-title{
    display: none;
  }
  .track-title-375{
    font-size: 11.867vw;
    font-weight: 700;
    line-height: 11.200vw;
    letter-spacing: 1.867vw;
    background: linear-gradient(180deg, #f2f6f7 0%, #b0c3cc 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .track-second-title-375{
    font-size: 11.867vw;
    font-weight: 700;
    line-height: 11.200vw;
    letter-spacing: 1.867vw;
    background: linear-gradient(180deg, #a5bbc4 0%, #73909c 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .track-third-title-375{
    font-size: 11.867vw;
    font-weight: 700;
    line-height: 11.200vw;
    letter-spacing: 2.400vw;
    background: linear-gradient(180deg, #6a8894 0%, #415f6c 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .track-main-container{
    margin-top: 12vw;
  }
  .track-content{
    flex-direction: column;
    margin-top: 5.600vw;
  }
  .track-button-container{
    width: 27.200vw;
  }
  .track-container .track-button-container{
    margin-top: 4.800vw;
  }
  .track-image{
    width: 24.267vw !important;
    height: 21.867vw;
  }
  .track-container{
    margin-right: 0;
  }
  .save-container{
    top: 5.800vw;
  }
  .save-container .track-button-container{
    top: 5.800vw;
  }
  .save-progress-image{
    width: 35.467vw !important;
    height: 8.267vw;
  }
  .pointer-image{
   width: 5.333vw !important;
    height: 6.933vw;
    top: 6.667vw;
    left: 20.267vw;
  }
  .track-description {
    font-size: 4.267vw;
    line-height: 5.333vw;
    position: relative;
    width: 59.467vw;
    top: 12vw;
  }

}

