.lec-principle-container {
  width: 234px;
  height: 384px;
  background-image: url("../../../../../assets/images/PlanetSalePagePrivate/list-element-background.png");
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 18px;
  padding-right: 18px;
  position: relative;
}

.lec-horns-design {
  width: 233px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.lec-description {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.curency-icon{
  width: auto;
  height: auto;
  margin-right: 3px;
}
.lec-text-alignment {
  font-family: Oswald;
  font-size: 16px;
  margin-bottom: 2px;
}
.lec-value-alignment{
  font-size: 14px;
  margin-bottom: 2px;
}
.lec-bottem-horns{
  position: absolute;
  bottom: -10px;
}
.lec-curency-text{
  display: flex;
  flex-direction: row;
}
