@import "../../variables";

.vault-astrafer {
  &__container {
    font-family: $sofia;
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-direction: column;

    p {
      margin: 0;
    }
  }

  &__form {
    display: flex;
  }

  &__label {
    font-size: 1.5rem;
  }

  &__input {
    width: 100%;
  }

  &__error-text {
    color: $light-pink-background;
  }

  &__modal-text-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    text-align: center;
    font-size: 1.25rem;
  }
}

