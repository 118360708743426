@import "../../../variables";

.congratulations-popup-container {
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .congratulations-popup-border {
    display: flex;
    justify-content: space-between;

    .congratulations-popup-border-icon-small {
      display: none;
    }
  }

  .congratulations-popup-content {
    width: 1033.26px;
    height: 654px;

    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;

    .congratulations-popup-title, .congratulations-popup-text {
      font-size: 2.25rem;
      font-family: $sofia;
      font-weight: 700;
      width: 656.84px;
      text-align: center;
      margin-bottom: 40px;
      overflow: hidden;
    }

    .congratulations-popup-title {
      margin-top: 50px;
    }

    .congratulations-popup-text {
      br {
        display: none;
      }
    }

    .congratulations-popup-button {
      width: 182.23px;
      height: 37px;

      font-size: 1.25rem;
      font-family: $oswald;
      font-weight: 400;
    }
  }

  .congratulations-popup-sub-text {
    font-size: 1rem;
    font-weight: normal;
    text-align: center;
    margin: 0 10px 20px;
  }

}

@media screen and (max-width: 1440px) {
  .congratulations-popup-container {

    .congratulations-popup-border {
      width: 71.754vw;

      .congratulations-popup-border-icon {
        width: 49%;
      }
    }

    .congratulations-popup-content {
      width: 71.754vw;
      height: 45.417vw;

      .congratulations-popup-title, .congratulations-popup-text {
        font-size: clamp(1.6rem, 2.500vw, 2.25rem);
        width: 45.614vw;
      }

      .congratulations-popup-title {
        margin-top: 50px;
      }

      .congratulations-popup-button {
        width: 12.655vw;
        height: 2.569vw;

        font-size: clamp(0.88rem, 1.389vw, 1.25rem);
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .congratulations-popup-container {

    .congratulations-popup-border {
      width: 75.678vw;
    }

    .congratulations-popup-content {
      width: 75.678vw;
      height: 47.900vw;

      .congratulations-popup-title, .congratulations-popup-text {
        font-size: clamp(1.25rem, 2.637vw, 1.6875rem);
        width: 48.108vw;
      }

      .congratulations-popup-title {
        margin-top: 50px;
      }

      .congratulations-popup-button {
        width: 13.347vw;
        height: 2.710vw;

        font-size: clamp(0.7rem, 1.465vw, 0.9375rem);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .congratulations-popup-container {

    .congratulations-popup-border {
      width: 60.016vw;

      .congratulations-popup-border-icon {
        display: none;
      }

      .congratulations-popup-border-icon-small {
        display: block;
        width: 49%;
      }
    }

    .congratulations-popup-content {
      width: 60.016vw;
      height: 80.613vw;

      .congratulations-popup-title {
        font-size: clamp(1.1475rem, 1.5rem, 2.34375rem);
        width: 42.044vw;
        margin-top: 50px;
        margin-bottom: 30px;
      }

      .congratulations-popup-text, {
        font-size: clamp(0.6875rem, 2.930vw, 1.4rem);
        font-weight: 400;
        line-height: 4.557vw;
        width: 100%;

        br {
          display: block;
        }
      }

      .congratulations-popup-button {
        width: 28.951vw;
        height: 4.366vw;

        font-size: clamp(0.55375rem, 2.359vw, 1.1325rem);

        .base-button,
        .base-button-not-filled,
        .base-button-filled {
          clip-path: polygon(1vw 0, 0 1vw, 0 calc(100% - 1vw), 1vw 100%, calc(100% - 1vw) 100%, 100% calc(100% - 1vw), 100% 1vw, calc(100% - 1vw) 0);
        }
      }
    }
  }
}

@media screen and (max-device-width: 425px) {
  .congratulations-popup-container {

    .congratulations-popup-border {
      width: 98.328vw;
    }

    .congratulations-popup-content {
      width: 98.328vw;
      height: 132vw;

      .congratulations-popup-title {
        font-size: clamp(1.25rem, 8vw, 1.875rem);
        width: 68.800vw;
        margin-top: 50px;
        margin-bottom: 10vw;
      }

      .congratulations-popup-text, {
        font-size: clamp(0.625rem, 4.8vw, 1.125rem);
        font-weight: 400;
        line-height: 7.2vw;
        width: 100%;
        margin-bottom: 50px;
      }

      .congratulations-popup-button {
        width: 47.432vw;
        height: 7.152vw;

        font-size: clamp(0.5rem, 3.867vw, 0.9rem);

        .base-button,
        .base-button-not-filled,
        .base-button-filled {
          clip-path: polygon(2vw 0, 0 2vw, 0 calc(100% - 2vw), 2vw 100%, calc(100% - 2vw) 100%, 100% calc(100% - 2vw), 100% 2vw, calc(100% - 2vw) 0);
        }
      }
    }
  }
}
