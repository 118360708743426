.multiple-main-container{
  width: 52px;
  height: 52px;
  position: absolute;
  right: 25px;
  top: 53px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.remove-margin{
  z-index: 1;
  font-family:system-ui ;
  font-size: 28px;
  margin-bottom: 0px;
}
.transparent-background{
  position: absolute;
  background-color: black;
  opacity: 0.7;
  width: 100%;
  z-index: 0;
  height: 100%;
}
