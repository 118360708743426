@import "../../../variables";

.CM-Dimension {
  width: 483px;
  max-width: initial;

  @media screen and (max-width: 425px) {
    width: 100%;
  }
}

.modal-content {
  border: 0px;
  background-color: black;
}

.CM-close-button {
  width: auto;
  height: auto;
  cursor: pointer;
}

.CM-modal-container {
  background-color: black;
  text-align: center;
}

.CM-confirmation-button {
  width: 137px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.CM-img-container {
  margin-right: 11px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.CM-header-horns {
  width: auto;
  position: absolute;
  top: -10px;
}

.CM-bottem-horns {
  width: auto;
  position: absolute;
  bottom: -9px;
}
.CM-button-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 61px;
}
.CM-text-container{
  margin-top: 20px;
  margin-bottom: 40px;
}
