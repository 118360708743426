@import "../../../variables";

.claim-rewards-button-container {
  background-color: $pink;
  font-family: $oswald;
  font-size: 27px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
  transform: scale(1);
  clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
}

  .claim-border {
    --b: 2px; /* border */
    --s: 6px; /* size of the cut */
    clip-path: polygon(6px 0, calc(100% - 6px) 0, 100% 11%, 100% calc(100% - 6px), calc(100% - 6px) 100%, 6px 100%, 0 calc(100% - 6px), 0 6px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    width: 100%;
    height: 100%;
    aspect-ratio: 1.5;
    z-index: 0;
  }

  .claim-border:before {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    background: $pink;
    --g1: #000 var(--b), #0000 0 calc(100% - var(--b)), #000 0;
    --g2: #0000 calc(0.707 * var(--s)),
    #000 0 calc(0.707 * var(--s) + var(--b)),
    #0000 0 calc(100% - 0.707 * var(--s) - var(--b)),
    #000 0 calc(100% - 0.707 * var(--s)),
    #0000 0;
    -webkit-mask: linear-gradient(45deg, var(--g2)),
    linear-gradient(-45deg, var(--g2)),
    linear-gradient(90deg, var(--g1)) 50%/100% calc(100% - 2 * var(--s)) no-repeat,
    linear-gradient(180deg, var(--g1)) 50%/calc(100% - 2*var(--s)) 100% no-repeat;
  }

.claim-limit {
  position: relative;
  z-index: -3;
  width: calc(100% - 0px);
  height: calc(100% - 0px);
  background-color: rgba(0, 0, 0, 50%);
  clip-path: polygon(6px 0, 0 6px, 0 calc(100% - 6px), 6px 100%, calc(100% - 6px) 100%, 100% calc(100% - 6px), 100% 6px, calc(100% - 6px) 0);

}

.claim-button-label {
  position: absolute;
  color: white;
  opacity: 0.5;
  margin: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 27px;
  font-family: $oswald;
  margin-bottom: 5px;
}

.claim-rewards-button-container a{
  text-decoration: none;
  color: white;

}

.claim-rewards-button-container:hover {
  transform: scale(1.1);
  color: white;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .claim-rewards-button-container,
  .claim-button-label{
  font-size: 23px;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .claim-button-label,
  .claim-rewards-button-container{
    font-size: 1.465vw !important;
    line-height: 2.051vw;
    margin-bottom: 0;
  }
  .claim-rewards-button-container,
  .claim-border,
  .claim-limit{
    clip-path: polygon(0.488vw 0, calc(100% - 0.488vw) 0, 100% 0.488vw, 100% calc(100% - 0.488vw) , calc(100% - 0.488vw) 100%, 0.488vw 100%, 0 calc(100% - 0.488vw), 0 0.488vw);
  }
  .claim-border {
    --b: 2.2px;
    --s: 3px;
  }
}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .claim-button-label,
  .claim-rewards-button-container{
    font-size: 2.214vw;
    line-height: 3.255vw;
    margin-bottom: 0;
  }
  .claim-rewards-button-container,
  .claim-border,
  .claim-limit{
    clip-path: polygon(0.651vw 0, 0 0.651vw, 0 calc(100% - 0.651vw), 0.651vw 100%, calc(100% - 0.651vw) 100%, 100% calc(100% - 0.651vw), 100% 0.651vw, calc(100% - 0.651vw) 0);
  }
  .claim-border {
    --b: 2.2px;
    --s: 3px;
  }
  }

@media screen and (max-width: 375px) {
  .claim-button-label,
  .claim-rewards-button-container{
    font-size: 4.267vw;
    line-height: 6.400vw;
    margin-bottom: 0;
  }
  .claim-rewards-button-container,
  .claim-border,
  .claim-limit{
    clip-path: polygon(1.333vw 0, 0 1.333vw, 0 calc(100% - 1.333vw), 1.333vw 100%, calc(100% - 1.333vw) 100%, 100% calc(100% - 1.333vw), 100% 1.333vw, calc(100% - 1.333vw) 0);
  }
  .claim-border {
    --b: 2.2px;
    --s: 3px;
  }
}
