@import '../../../variables';

.claim-astrafer-main-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.claim-astrafer-image{
  width: 1241px;
  height: 1241px;
  margin-top: 27px;
  margin-bottom: 27px;
}

.token-id-container{
  width: 326px;
  margin-bottom: 10px;
}

.claim-astrafer-button-container{
  width: 320px;
  height: 55px;
  margin-bottom: 72px;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
    .claim-astrafer-button-container {
    width: 242px;
    height: 43px;
  }
  .claim-astrafer-image{
    width: 977px;
    height: 938px;
    margin-top: 38px;
    margin-bottom: 24px;
  }
  .token-id-container{
    width: 246px;
    margin-bottom: 10px;
  }
  }

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .claim-astrafer-button-container {
    width: 19.629vw;
    height:3.516vw;
    margin-bottom: 4.785vw;
  }
  .claim-astrafer-image{
    width: 73.730vw;
    height: 70.801vw;
    margin-top: 2.930vw;
    margin-bottom: 1.758vw;
  }
  .claim-astrafer-main-container .token-id-container{
    width: 18.555vw;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .claim-astrafer-button-container {
    width: 22.786vw;
    height: 4.036vw;
    margin-bottom: 4.427vw;
  }
  .claim-astrafer-image{
    width: 85.677vw;
    height: 82.292vw;
    margin-top: 3.385vw;
    margin-bottom: 2.604vw;
  }

  .claim-astrafer-main-container .token-id-container{
    width: 25.130vw;
    margin-bottom: 1.302vw;
  }
}

@media screen and (max-width: 375px)  {
  .claim-astrafer-button-container {
    width: 43.200vw;
    height: 7.733vw;
    margin-bottom: 8.800vw;
  }
  .claim-astrafer-image {
    width: 94.400vw;
    height: 94.400vw;
    margin-top: 8.800vw;
    margin-bottom: 8.800vw;
  }
  .claim-astrafer-main-container .token-id-container{
    width: 43.200vw;
    margin-bottom: 1.302vw;
  }
  .token-id-container .claim-info-text-border{
    width: 43.733vw;
    margin-top: 5.600vw;
  }

}

