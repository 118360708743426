.presaleA-main-body{
  width: 100%;
  height: 980px;
  padding-top: 50px;
  padding-bottom: 50px;
  background: red;
  clip-path: polygon(50% 8%, 100% 0, 100% 100%, 50% 92%, 0 100%, 0 0);
  background: url("../../../../assets/images/PlanetSalePagePrivate/compensation-page-BG.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

.pgs-presaleA-border-img-container .psg-border-img{
  width: 1350px;
}

#presaleA-list-element .le-principle-container{
  margin-top: 36px;
}
