@import "../../../variables";

.planet-minting-popup-container {
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .planet-minting-popup-border {
    display: flex;
    justify-content: space-between;
  }

  .planet-minting-popup-border-icon-small {
    display: none;
  }

  .planet-minting-popup-content {
    width: 1033px;
    height: 654px;

    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;

    .planet-minting-popup-title {
      font-size: 2.25rem;
      font-family: $sofia;
      font-weight: 400;
      width: 653.19px;
      text-align: center;
      margin-bottom: 70px;
      margin-top: 70px;
      line-height: 40px;
    }

    .planet-minting-popup-grid {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 70px;

      .generative-planet-component {
        margin: 0 20px;

        .generative-planet-border {
          background-image: url("../../../assets/images/GenerativePlanetMinting/planet-mintpass-border-popup.svg");
          width: 137px;

          .generative-planet-component-image {
            width: 105px;
            height: 105px;
          }
        }

        .generative-planet-component-stock {
          font-size: 1rem;
          opacity: 0.8;
          margin: -9px 0 0 0;
          z-index: 1;
        }

        .generative-planet-component-title {
          font-size: 1.375rem;
          margin-top: 10px;
        }
      }
    }

    .planet-minting-popup-text {
      font-size: 1.5rem;
      font-family: $sofia;
      font-weight: 400;
      margin-bottom: 39px;
    }


    .planet-minting-popup-buttons {
      display: flex;
      justify-content: space-between;
      width: 332px;
    }

    .planet-minting-popup-button {
      width: 153px;
      height: 39px;

      font-size: 1.25rem;
      font-family: $oswald;
      font-weight: 400;
    }
  }
}

@media screen and (max-width: 1440px) {
  .planet-minting-popup-container {

    .planet-minting-popup-border-icon {
      width: 49%;
    }

    .planet-minting-popup-content {
      width: 71.736vw;
      height: 45.417vw;

      .planet-minting-popup-title {
        font-size: clamp(1.5625rem, 2.500vw, 2.25rem);
        width: 45.347vw;
        margin-bottom: 4.861vw;
        margin-top: 4.861vw;
        line-height: 2.778vw;
      }

      .planet-minting-popup-grid {
        margin-bottom: 4.861vw;

        .generative-planet-component {
          margin: 0 1.389vw;

          .generative-planet-border {
            width: 9.514vw;
            height: 9.444vw;
            margin-bottom: -1.528vw;

            .generative-planet-component-image {
              width: 7.292vw;
              height: 7.292vw;
            }
          }

          .generative-planet-component-stock {
            font-size: clamp(0.6875rem, 1.111vw, 1rem);
            margin: -0.625vw 0 0 0;
          }

          .generative-planet-component-title {
            font-size: clamp(0.9375rem, 1.528vw, 1.375rem);
            margin-top: 0.7vw;
            margin-bottom: 0.7vw;
          }
        }
      }

      .planet-minting-popup-text {
        font-size: clamp(1.0625rem, 1.667vw, 1.5rem);
        margin-bottom: 2.708vw;
      }


      .planet-minting-popup-buttons {
        width: 23.056vw;
      }

      .planet-minting-popup-button {
        width: 10.625vw;
        height: 2.708vw;

        font-size: clamp(0.82rem, 1.389vw, 1.25rem);
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .planet-minting-popup-container {

    .planet-minting-popup-content {
      width: 75.684vw;
      height: 47.852vw;

      .planet-minting-popup-title {
        font-size: clamp(1.25rem, 2.637vw, 1.6875rem);
        width: 50vw;
        margin-bottom: 5.371vw;
        margin-top: 5.371vw;
        line-height: 2.930vw;
      }

      .planet-minting-popup-grid {
        margin-bottom: 4.590vw;

        .generative-planet-component {
          margin: 0 0.879vw;

          .generative-planet-border {
            width: 10.082vw;
            height: 10.766vw;
            margin-bottom: -0.977vw;

            .generative-planet-component-image {
              width: 7.617vw;
              height: 7.617vw;
            }
          }

          .generative-planet-component-stock {
            font-size: clamp(0.54rem, 1.133vw, 0.725rem);
            margin: -1.625vw 0 0 0;
          }

          .generative-planet-component-title {
            font-size: clamp(0.75rem, 1.611vw, 1.03rem);
          }
        }
      }

      .planet-minting-popup-text {
        font-size: clamp(0.8125rem, 1.758vw, 1.125rem);
        margin-bottom: 3.418vw;
      }


      .planet-minting-popup-buttons {
        width: 24.414vw;
      }

      .planet-minting-popup-button {
        width: 11.035vw;
        height: 2.734vw;

        font-size: clamp(0.6875rem, 1.465vw, 0.9375rem);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .planet-minting-popup-container {

    .planet-minting-popup-border-icon {
      display: none;
    }

    .planet-minting-popup-border-icon-small {
      display: block;
      width: 49%;
    }

    .planet-minting-popup-content {
      width: 60.026vw;
      height: 80.599vw;

      .planet-minting-popup-title {
        font-size: clamp(0.75rem, 3.255vw, 1.5625rem);
        width: 52.474vw;
        margin-bottom: 6.510vw;
        margin-top: 6.510vw;
        line-height: 3.906vw;
      }

      .planet-minting-popup-grid {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(auto-fit, minmax(33%, 33%));
        margin-bottom: 5vw;
        margin-left: 2vw;

        .generative-planet-component {

          @supports (-webkit-touch-callout: none) {
            margin: -22px 2.083vw;
          }

          margin: 0 2.083vw;

          .generative-planet-border {
            width: 14.2vw;
            height: 16vw;
            margin-bottom: -0.977vw;

            .generative-planet-component-image {
              width: 10.938vw;
              height: 10.938vw;
            }
          }

          .generative-planet-component-stock {
            font-size: clamp(0.4rem, 1.302vw, 0.625rem);
            margin: -2.7vw 0 0 0;
          }

          .generative-planet-component-title {
            font-size: clamp(0.5rem, 2.279vw, 1.09rem);
          }
        }
      }

      .planet-minting-popup-text {
        font-size: clamp(0.53rem, 2.279vw, 1.09rem);
        margin-bottom: 4.297vw;
      }

      .planet-minting-popup-buttons {
        width: 33.594vw;

        .base-button,
        .base-button-not-filled,
        .base-button-filled {
          clip-path: polygon(1vw 0, 0 1vw, 0 calc(100% - 1vw), 1vw 100%, calc(100% - 1vw) 100%, 100% calc(100% - 1vw), 100% 1vw, calc(100% - 1vw) 0);
        }
      }

      .planet-minting-popup-button {
        width: 16.2vw;
        height: 4.6vw;

        font-size: clamp(0.7rem, 2.344vw, 1.125rem);
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .planet-minting-popup-container {

    .planet-minting-popup-content {
      width: 98.400vw;
      height: 132vw;

      .planet-minting-popup-title {
        font-size: clamp(0rem, 5.333vw, 1.25rem);
        width: 80vw;
        margin-bottom: 5vw;
        margin-top: 5.333vw;
        line-height: 6.4vw;
      }

      .planet-minting-popup-grid {
        margin-bottom: 10vw;
        margin-left: 3vw;

        .generative-planet-component {
          margin: 0 3.467vw;

          .generative-planet-border {
            width: 24vw;
            height: 25vw;
            margin-bottom: -3vw;

            .generative-planet-component-image {
              width: 17.867vw;
              height: 17.867vw;
            }
          }

          .generative-planet-component-stock {
            font-size: clamp(0.4rem, 2.667vw, 0.625rem);
            margin: -3.7vw 0 0 0;
          }

          .generative-planet-component-title {
            font-size: clamp(0.4rem, 3.733vw, 0.875rem);
            margin-top: 2vw;
          }
        }
      }

      .planet-minting-popup-text {
        font-size: clamp(0.4rem, 3.733vw, 0.875rem);
        margin-bottom: 6.933vw;
      }

      .planet-minting-popup-buttons {
        width: 55.467vw;
      }

      .planet-minting-popup-button {
        width: 26.6vw;
        height: 7.7vw;

        font-size: clamp(0.4rem, 3.867vw, 0.9rem);
      }
    }
  }
}
