@import "../../../variables";

.starfighter-minting-popup-container {
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .starfighter-minting-popup-border {
    display: flex;
    justify-content: space-between;
  }

  .starfighter-minting-popup-border-icon-small {
    display: none;
  }

  .starfighter-minting-popup-content {
    width: 100%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    gap: 30px;

    .starfighter-minting-popup-title {
      font-size: 2.25rem;
      font-family: $sofia;
      font-weight: 400;
      width: 653.19px;
      text-align: center;
      line-height: 40px;
    }

    .starfighter-minting-popup-grid {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      width: 87vw;

      .generative-starfighter-component {
        margin: 0 20px;
        position: relative;

        .generative-starfighter-border {
          background-image: url("../../../assets/images/GenerativePlanetMinting/planet-mintpass-border-popup.svg");
          width: 137px;

          .generative-starfighter-component-image {
            width: 105px;
            height: 105px;
          }
        }

        .generative-starfighter-component-stock {
          font-size: 1rem;
          opacity: 0.8;
          z-index: 1;
          position: absolute;
          top: 102px;
        }

        .generative-starfighter-component-title {
          font-size: 1.25rem;
          text-align: center;
        }
      }
    }

    .starfighter-minting-popup-text {
      font-size: 1.5rem;
      font-family: $sofia;
      font-weight: 400;
    }


    .starfighter-minting-popup-buttons {
      display: flex;
      justify-content: space-between;
      width: 332px;
    }

    .starfighter-minting-popup-button {
      width: 153px;
      height: 39px;

      font-size: 1.25rem;
      font-family: $oswald;
      font-weight: 400;
    }
  }
}

@media screen and (max-width: 1440px) {
  .starfighter-minting-popup-container {

    .starfighter-minting-popup-border-icon {
      width: 49%;
    }

    .starfighter-minting-popup-content {
      width: 95vw;

      .starfighter-minting-popup-title {
        font-size: clamp(1.5625rem, 2.500vw, 2.25rem);
        width: 45.347vw;
        line-height: 2.778vw;
      }

      .starfighter-minting-popup-grid {

        .generative-starfighter-component {
          margin: 0 1.389vw;

          .generative-starfighter-border {
            width: 9.514vw;
            height: 9.444vw;

            .generative-starfighter-component-image {
              width: 7.292vw;
              height: 7.292vw;
            }
          }

          .generative-starfighter-component-stock {
            font-size: clamp(0.6875rem, 1.111vw, 1rem);
            top: 108px;
          }

          .generative-starfighter-component-title {
            font-size: clamp(0.9375rem, 1.528vw, 1.375rem);
            margin-bottom: 0.7vw;
          }
        }
      }

      .starfighter-minting-popup-text {
        font-size: clamp(1.0625rem, 1.667vw, 1.5rem);
      }


      .starfighter-minting-popup-buttons {
        width: 23.056vw;
      }

      .starfighter-minting-popup-button {
        width: 10.625vw;
        height: 2.708vw;

        font-size: clamp(0.82rem, 1.389vw, 1.25rem);
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .starfighter-minting-popup-container {

    .starfighter-minting-popup-content {

      .starfighter-minting-popup-title {
        font-size: clamp(1.25rem, 2.637vw, 1.6875rem);
        width: 50vw;
        line-height: 2.930vw;
      }

      .starfighter-minting-popup-grid {
        width: 100vw;

        .generative-starfighter-component {
          margin: 0 0.879vw;

          .generative-starfighter-border {
            width: 10.082vw;
            height: 10.766vw;

            .generative-starfighter-component-image {
              width: 7.617vw;
              height: 7.617vw;
            }
          }

          .generative-starfighter-component-stock {
            font-size: clamp(0.54rem, 2vw, 1rem);
            top: 76px;
          }

          .generative-starfighter-component-title {
            font-size: clamp(0.75rem, 1.611vw, 1.03rem);
          }
        }
      }

      .starfighter-minting-popup-text {
        font-size: clamp(0.8125rem, 1.758vw, 1.125rem);
      }


      .starfighter-minting-popup-buttons {
        width: 24.414vw;
      }

      .starfighter-minting-popup-button {
        width: 11.035vw;
        height: 2.734vw;

        font-size: clamp(0.6875rem, 1.465vw, 0.9375rem);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .starfighter-minting-popup-container {

    .starfighter-minting-popup-border-icon {
      display: none;
    }

    .starfighter-minting-popup-border-icon-small {
      display: block;
      width: 49%;
    }

    .starfighter-minting-popup-content {
      height: 100%;
      padding: 20px 0;

      .starfighter-minting-popup-title {
        font-size: clamp(0.75rem, 3.255vw, 1.5625rem);
        width: 52.474vw;
        line-height: 3.906vw;
      }

      .starfighter-minting-popup-grid {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 20px;
        overflow-y: scroll;

        .generative-starfighter-component {

          @supports (-webkit-touch-callout: none) {
            margin: -22px 2.083vw;
          }

          margin: 0 2.083vw;

          .generative-starfighter-border {
            width: 14.2vw;
            height: 16vw;

            .generative-starfighter-component-image {
              width: 10.938vw;
              height: 10.938vw;
            }
          }

          .generative-starfighter-component-stock {
            font-size: clamp(0.4rem, 2vw, 1rem);
            top: 93px;
          }

          .generative-starfighter-component-title {
            font-size: clamp(0.5rem, 2vw, 1.09rem);
          }
        }
      }

      .starfighter-minting-popup-text {
        font-size: clamp(0.53rem, 2.279vw, 1.09rem);
      }

      .starfighter-minting-popup-buttons {
        width: 33.594vw;

        .base-button,
        .base-button-not-filled,
        .base-button-filled {
          clip-path: polygon(1vw 0, 0 1vw, 0 calc(100% - 1vw), 1vw 100%, calc(100% - 1vw) 100%, 100% calc(100% - 1vw), 100% 1vw, calc(100% - 1vw) 0);
        }
      }

      .starfighter-minting-popup-button {
        width: 16.2vw;
        height: 4.6vw;

        font-size: clamp(0.7rem, 2.344vw, 1.125rem);
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .starfighter-minting-popup-container {

    .starfighter-minting-popup-content {
      width: 98vw;
      max-height: 92vh;
      padding-bottom: 2rem;

      .starfighter-minting-popup-title {
        font-size: clamp(0rem, 5.333vw, 1.25rem);
        width: 80vw;
        line-height: 6.4vw;
      }

      .starfighter-minting-popup-grid {

        .generative-starfighter-component {
          margin: 0 3.467vw;
          gap: 8px;

          .generative-starfighter-border {
            width: 24vw;
            height: 25vw;

            .generative-starfighter-component-image {
              width: 17.867vw;
              height: 17.867vw;
            }
          }

          .generative-starfighter-component-stock {
            font-size: 0.8rem;
            top: 87px;
          }

          .generative-starfighter-component-title {
            font-size: clamp(0.4rem, 3.733vw, 0.875rem);
            text-align: center;
            margin-top: 4px;
          }
        }
      }

      .starfighter-minting-popup-text {
        font-size: clamp(0.4rem, 3.733vw, 0.875rem);
      }

      .starfighter-minting-popup-buttons {
        width: 55.467vw;
      }

      .starfighter-minting-popup-button {
        width: 26.6vw;
        height: 7.7vw;

        font-size: clamp(0.4rem, 3.867vw, 0.9rem);
      }
    }
  }
}
