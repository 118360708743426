@import "../../../variables";

.select {

  &__title {
    font-family: $oswald-light;
    font-size: 1rem;
    color: $light-gray-background
  }

  @media screen and (min-width: $sm-breakpoint) {
    font-size: 1.25rem;
  }

  &__name {
    display: flex;
    align-items: center;
    font-size: 1rem;

    @media screen and (min-width: $sm-breakpoint) {
      font-size: 1.5rem;
    }

    span {
      font-family: $oswald-light;
    }

    img {
      width: 14px;
      margin-left: 30px;
      margin-top: 5px;
    }
  }
}
