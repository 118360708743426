@import '../../../variables';


.token-input {

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    -moz-box-align: center;
    font-family: $oswald-light;
    padding: 1rem;
    background-color: $pg-midnight-grey;

    .token-input {
      &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1.25rem;

        .form-label {
          margin-bottom: 0;
          color: $light-gray-background
        }
      }

      &__right-content {
        display: flex;
        align-items: flex-end;
        height: 80%;
      }

      input {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;

        &:hover {
          -webkit-appearance: none;
        }

        &:disabled {
          background-color: transparent;
        }
      }
    }

    // disable default features of input box
    input[type=number] {
      -moz-appearance: textfield; /* Firefox */

      &::-webkit-inner-spin-button {
        -webkit-appearance: none; /* Chrome */
      }

      &::-webkit-outer-spin-button {
        -webkit-appearance: none; /* Chrome */
      }

      &:hover {
        -moz-appearance: textfield; /* Firefox */
      }
    }
  }

  &__box {
    background-color: transparent;
    color: $white;
    border-color: transparent;
    width: 80%;
    font-size: 1.75rem;

    @media screen and (min-width: $sm-breakpoint) {
      width: 60%;
    }

    &:focus {
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
      color: $white;
    }
  }

  &-max {

    &__button {
      border: 0;
      font-size: 0.75rem;
      border-radius: 15px;
      background-color: transparent;
      color: $white;
      cursor: pointer;
      display: flex;
      padding: 8px;
      margin-top: 50px;
      text-align: left;
      margin-right: 8px;
      height: 38px;
    }
  }
}
