@import "../../../variables";

.rush-event-title-container {
  width: 100%;
  height: 1078px;
  position: relative;
  overflow: hidden;
}

.title-elements-container .cw-principle-container {
  margin-top: 0;
  bottom: 100px;
  position: absolute;
}

.claim-button-container{
  width: 226px;
  height: 55px;
  z-index: 5;
  margin-top: 79px;
}



.rush-event-title-started {
  @extend .rush-event-title-container;
  clip-path: $section-polygon-top-bottom-down-cut-1920;
  height: 811px;
}

.rush-event-title-background {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0px;
  object-fit: fill;

}

.rush-event-title-background-started-tablet {
  display: none;
}

.rush-event-title-background-started {
  @extend .rush-event-title-background;
  object-fit: cover;
  height: 100%;
}


.rush-event-title-header {
  position: absolute;
  background-color: black;
  width: 100%;
  height: 189px;
  top: -77px;
  clip-path: polygon(0 0, 0 40%, 50% 80%, 100% 40%, 100% 0, 50% 40%);
}

.clip-path-design-bottom {
  position: absolute;
  z-index: 5;
  background-color: black;
  width: 100%;
  height: 220px;
  bottom: -135px;
  clip-path: polygon(0 0, 0 40%, 50% 80%, 100% 40%, 100% 0, 50% 40%);
}

.show-the-carousel {
  position: relative;
  z-index: 4;
}

.space-ship-adjustment {
  position: absolute;
  top: -35px;
  left: 92px;
  z-index: 3;
  width: 900px;
  height: 703px;
}

.astrafite-title {
  width: 100%;
  margin-top: 60px;
  position: relative;
  z-index: 4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.event-started-description-text {
  @extend .rush-event-description-text;
}

.rush-event-timer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  top: 300px;
}

.title-elements-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.rush-event-description-text {
  max-width: 766px;
  width: 100%;
  margin-top: -80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.paragraph-background-design {
  width: 100%;
  margin-bottom: 5px;
  padding: 4px 27px 4px 27px;
  border-radius: 10px;
  text-align: center;
  z-index: 5;
  position: relative;
  background-color: rgba(0, 0, 0, 70%);
  font-family: $sofia;
  line-height: 25px;
}

@media screen and (min-width: 1025px) {

  .padding-adjustment-for-text-alignment {
    padding-left: 67px;
    padding-right: 67px;
  }
}

.contributors-text-description {
  font-size: 24px;
  margin-top: 4px;
  margin-bottom: 4px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.line-separator {
  width: 100%;
  background-color: white;
  height: 1px;
}

.game-roles-description{
  text-decoration: underline;
  cursor: pointer;
  color: $white;
}
.game-roles-description:hover{
  color: $white;
}

.pirate-img-position {
  width: 1421px;
  top: -55px;
  right: -375px;
  z-index: -1;
  height: 1391px;
  position: absolute;
}

.timer-size-container {
  width: 100%;
  height: 224px;
  margin-top: 34px;
}

.pirate-img-position-event-started {
  @extend .pirate-img-position;
  z-index: 4;
  top: -300px;
  width: 1501px;
  right: -415px;
}

.astrafite-episode-number {
  font-family: $oswald;
  font-size: 45px;
  position: absolute;
  top: 190px;
}

.rush-event-title-background-started-mobile {
  display: none;
}

@media screen and (min-width: 1441px) {

}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .claim-button-container {
    margin-top: 59px;
  }
    .rush-event-title-started {
    height: 711px;
  }
  .pirate-img-position-event-started {
    right: -477px;
    top: -240px;
    width: 1346px;
    height: 1173px;

  }

  .pirate-img-position {

  }
  .space-ship-adjustment {
    top: -92px;
    left: 13px;
  }
  .astrafite-title .page-mobile-header-text {
    font-size: 35px;
  }
  .astrafite-episode-number {
    font-size: 35px;
    top: 190px
  }
  .astrafite-title {
    margin-top: 32px;
    width: 82%;
  }

  .event-started-description-text {
    margin-top: -129px;
  }

}

@media screen and (min-width: 1025px) and (max-width: 1280px) {

}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .astrafite-title .page-header{
    margin-top: -1.758vw;
    padding-bottom: 9.766vw;
    font-size: 4.395vw;
    height: 39.063vw;
  }
  .claim-button-container {
    width: 17.676vw;
    height: 3.613vw;
    margin-top: 11.961vw;
  }
  .claim-rewards-button-container{
    font-size: 1.563vw !important;
  }
  .event-started-description-text {
    max-width: 46.094vw;
    margin-top: -10.840vw;
    width: 100%;
  }

  .rush-event-title-started {
    clip-path: $section-polygon-top-bottom-down-cut-1024;
  }

  .title-elements-container .cw-principle-container {
    bottom: 19.531vw;
  }

  .padding-remover {
    padding-left: 0.684vw;
    padding-right: 0.684vw;
  }
  .astrafite-title .page-mobile-header-text {
    font-size: 3.418vw;
  }
  .astrafite-title .astrafite-episode-number {
    font-size: 3.418vw;
    top:13.965vw;
  }
  .astrafite-title {
    width: 100%;
  }
  .astrafite-title {
    margin-top: 4.492vw;
  }
  .rush-event-title-started {
    height: 87.891vw;
  }
  .rush-event-title-background-started {
    width: auto;
    transform: translate(-26.367vw, 0px);
    height: 100%;
    object-fit: cover;
  }
  .space-ship-adjustment {
    position: absolute;
    top: 7.129vw;
    left: -4.297vw;
    z-index: 3;
    width: 75.293vw;
    height: 61.914vw;
  }
  .pirate-img-position-event-started {
    z-index: 4;
    top: -6.273vw;
    width: 123.047vw;
    height: 118.645vw;
    right: -48.047vw;
  }
  .paragraph-background-design {
    font-size: 1.563vw;
    word-spacing: -1px;
    border-radius: 0.977vw;
    padding: 0.391vw 2.637vw 0.391vw 2.637vw;
    line-height: 2.441vw;
  }
}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .claim-button-container {
    width: 19.661vw;
    height: 4.818vw;
    margin-top: 6.510vw;
  }
  .rush-event-title-started .claim-rewards-button-container{
    font-size:2.083vw;
  }

  .astrafite-title {
    margin-top: -1.187vw;
  }
  .event-started-description-text {
    max-width: 61.458vw;
    margin-top: -17.417vw;
  }
  .paragraph-background-design {
    padding: 0.521vw 1.953vw 0.521vw 1.953vw;
    margin-bottom: 0.651vw;
    border-radius: 1.302vw;
    font-size: 2.083vw;
    line-height: 3.255vw;
  }
  .rush-event-title-started {
    height: 95.573vw;
    clip-path: $section-polygon-top-bottom-down-cut-768;
  }

  .rush-event-title-background-started {
    display: none;
    width: auto;
    transform: translate(-48.047vw, 0px);
  }
  .rush-event-title-background-started-tablet {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
  }
  .pirate-img-position-event-started {
    top: 12.109vw;
    right: -39.844vw;
    width: 104.167vw;
    height: 104.167vw;
  }
  .title-elements-container .cw-principle-container {
    bottom: 18.229vw;
  }
  .padding-remover {
    padding: 0.521vw 1.172vw 0.521vw 1.172vw;
  }
  .space-ship-adjustment {
    position: absolute;
    top: 7.422vw;
    left: -5.990vw;
    z-index: 3;
    width: 92.057vw;
    height: 72.396vw;
  }

  .title-elements-container .page-mobile-header-text {
    font-size: 3.646vw;
  }
  .title-elements-container .astrafite-episode-number {
    font-size: 3.646vw;
    top: 23.177vw;
    z-index: 10;
  }

  .event-started-description-text {
  }
}

@media screen and (max-device-width: 375px) {
  .claim-button-container {
    width: 40.267vw;
    height: 9.867vw;
    margin-top: 28vw;
  }
  .claim-rewards-button-container{
    font-size:4.267vw !important;
  }
  .astrafite-title {
    margin-top: -12vw;
  }
  .rush-event-title-started {
    height: 239.200vw !important;
    clip-path: $section-polygon-top-bottom-down-cut-375;
  }
  .rush-event-description-text {
    margin-top: -113px;
  }
  .title-elements-container .cw-principle-container {
    bottom: 220px;
  }
  .astrafite-title .astrafite-episode-number {
    font-size: 7.467vw;
  }
  .astrafite-episode-number {
    top: 48.667vw;
  }
  .astrafite-title .page-mobile-header-text {
    font-size: 7.467vw;
  }
  .event-started-description-text {
    max-width: 332px;
  }
  .paragraph-background-design {
    padding-right: 8px;
    padding-left: 8px;
  }
  .padding-adjustment-for-text-alignment {
    padding-right: 11px;
    padding-left: 11px;
  }


  .rush-event-title-background-started {
    display: none;
  }
  .rush-event-title-background-started-mobile {
    display: block;
    position: absolute;
  }
  .space-ship-adjustment {
    top: 66.667vw;
    width: 133.333vw;
    height: 106.667vw;
    left: initial;
  }
  .pirate-img-position-event-started {
    top: 134vw;
    width: 133.333vw;
    height: 133.333vw;
    right: initial;
  }
  .title-elements-container {

  }
}
