@import "../../variables";

.token-metrics-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.token-metrics-title{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.supply-container {
  font-size: 40px;
  line-height: 60px;
  font-family: $oswald;
}
.token-metrics-main-container{
  margin-bottom: 55px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.token-content-title {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .token-metrics-title .page-header{
    margin-top: 17.480vw;
    padding-bottom: 0px;
    height: initial;
  }
  .token-metrics-title .psg-border-img-mini-screen{
    width: 1300px;
    overflow-x: hidden;
    overflow-y: visible;
  }
  .token-content-title{
    margin-top: 155px;
  }
}
@media screen and (min-width: 376px) and (max-width: 768px) {
  .token-metrics-main-container{
    margin-bottom:12.370vw ;
  }
  .token-metrics-title{
    margin-top: 2.344vw;
  }
  .token-metrics-title .psg-border-img-mobile{
    display: none;
  }

  .token-metrics-title .psg-border-img-tablet{
    display: initial;
  }
  .token-metrics-title .page-mobile-header-text-no-margin{
    font-size:2.604vw;
  }
  .token-metrics-title .page-header{
    padding: 0px;
    height: initial;
  }
  .token-metrics-title .psg-border-img-tablet{
    width: 70%;
  }
  .token-content-title{
    display: none;
  }
}

@media screen  and (max-width: 375px) {
  .token-metrics-main-container{
    overflow-x: hidden;
    overflow-y: visible;
  }
  .token-metrics-title .psg-border-img-mobile{
    display: none;
  }

  .token-metrics-title .psg-border-img-tablet{
    display: initial;
  }
  .token-metrics-title .page-mobile-header-text-no-margin{
    font-size:6.667vw;
  }
  .token-metrics-title .page-header{
    padding: 0px;
    height: initial;
    margin-top: -3.467vw;
  }
  .token-metrics-title .psg-border-img-tablet{
    width: 184%;
  }
  .token-content-title{
    display: none;
  }
}
