@import "../../../../variables";

.mintpass-combinations-nfts{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.mintpass-nft-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mintpass-nft-title,
.mintpass-nft-owned{
  font-family: $sourceCodePro;
  font-size: 16px;
  line-height: 20px;
}

.mintpass-nft-title-container{
  text-align: center;
  position: relative;
  width: 220px;
  height: 55px;
}

.mintpass-nft-title-container .mintpass-nft-title{
  margin-bottom: 10px;
  position: absolute;
  bottom: 0;
  left: 0.260vw;
  right: 0.260vw;
  white-space: pre-line;
}

.mintpass-nft-image-container{
  width: 190px;
  height: 107px;
  margin-bottom: 14px;
}

.mintpass-nft-image-container img{
  width: auto;
  height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.mintpass-nft-combination-plus{
  width: 21px;
  height: 19px;
  margin: 0 6px;
}

.mintpass-nft-combination-plus:nth-child(8){
  display: none;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .mintpass-nft-title,
  .mintpass-nft-owned{
    font-size: 1.111vw;
    line-height: 1.389vw;
  }

  .mintpass-nft-title-container{
    width: 15.278vw;
    height: 3.819vw;
  }

  .mintpass-nft-title-container .mintpass-nft-title{
    margin-bottom: 0.694vw;
    left: 0.260vw;
    right: 0.260vw;
  }

  .mintpass-nft-image-container{
    width: 13.194vw;
    height: 7.431vw;
    margin-bottom: 1.042vw;
  }

  .mintpass-nft-combination-plus{
    width: 1.458vw;
    height: 1.319vw;
    margin: 0 0.417vw;
  }
}

@media screen and (min-width: 376px) and (max-width: 1024px) {
  .mintpass-nft-title,
  .mintpass-nft-owned{
    font-size: 1.367vw;
    line-height: 1.660vw;
  }
  .mintpass-nft-title-container {
    width: 15.625vw;
    height: 3.223vw;
  }
  .mintpass-nft-title-container .mintpass-nft-title {
    width: 100%;
    margin-bottom: 0.977vw;

  }
  .mintpass-nft-image-container {
    width: 15.625vw;
    height: 8.789vw;
    margin-bottom: 1.172vw;
  }
  .mintpass-nft-combination-plus {
    width: 1.660vw;
    height: 1.465vw;
    margin: 0 0.293vw 0.488vw 0.293vw;
  }
  }


@media screen and (max-device-width: 375px) {
  .mintpass-nft-container{
    position: relative;
    margin: 0 4vw;
  }
  .mintpass-nft-combination-plus{
    position: absolute;
  }
  .mintpass-nft-combination-plus:nth-child(2){
    top: 40.800vw;
  }
  .mintpass-nft-combination-plus:nth-child(4){
    left: 26.933vw;
    top: 60.800vw;
  }
  .mintpass-nft-combination-plus:nth-child(6){
    top: 80.533vw;
  }
  .mintpass-nft-combination-plus:nth-child(8){
    display: block;
    right: 26.933vw;
    top: 60.800vw;
  }
  .mintpass-nft-container:nth-child(1),
  .mintpass-nft-container:nth-child(3){
    margin-bottom: 6.400vw;
  }
  .mintpass-nft-title,
  .mintpass-nft-owned{
    font-size: 2.667vw;
    line-height: 3.200vw;
  }
  .mintpass-nft-title-container {
    width: 33.333vw;
    height: 5.333vw;
  }
  .mintpass-nft-title-container .mintpass-nft-title {
    margin-bottom: 2.400vw;

  }
  .mintpass-nft-image-container {
    width: 32.800vw;
    height: 18.400vw;
    margin-bottom: 2.133vw;
  }
  .mintpass-nft-combination-plus {
    width: 3.733vw;
    height: 3.733vw;
    margin: 0 ;
  }
}
