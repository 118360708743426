@import "../variables";

.roadmap-image {
  display: flex;
  margin: auto;
  @media (min-width: 1440px){
    padding-top: 260px;
  }
    
  @media (min-width: 1024px){
    padding-top: 280px;
  }

  @media (min-width: 768px){
    padding-top: 280px;
  }

  @media (min-width: 375px){
    padding-top: 280px;
  }
}

.twitch-icon {
  position: absolute;
  width: 35px;
  height: 35px;
  left: 293px;
  top: calc(50% - 33px / 2);
}
.add-blackground-color{
  background-color: black !important;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .twitch-icon {
    position: absolute;
    width: 35px;
    height: 35px;
    left: 313px;
    top: calc(50% - 33px / 2);
  }
}

.home-connect-wallet-button{
  width: 100%;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;

}
.tablet-align-join-discord{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.discord-button-adjust-from-tablet{
  width: 100%;
display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}
.home-connect-wallet-button-1920{
  width: 362px;
}
.home-connect-wallet-button-tablet{
  width: 65%;
}

.home-connect-wallet-button-mobile{
  width: 71.5%;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}
.set-page-dimensions{
  min-height:0px;
  height: 5200px;
  min-width: 1300px;
}
.set-the-page-dimension{
  min-height: 6676px;

}
.mobile-screen-adjustment{
  min-height: 10569px;
}
.adjust-page-dimension{
  min-height: 7690px;

}
.connect-wallet-general-text-design{
  font-size: 16px;
  width: 100%;
}
@media (max-width: 767px) {
  .connect-wallet-general-text-design{
    max-width: 262px;
  }
}
.discord-button-design{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: $pink;
  width: 65%;
  height: 37px;
  clip-path: polygon(6% 0, 100% 0%, 95% 100%, 0 100%, 1% 100%);
}

.discord-button-alignment{
  width: 100%;
}

.discord-button-text-design{
  color: white;
  font-size: 20px;
  font-family: $oswald;

}
.discord-button-text-design:hover{
  color: white;
}
.center-discord-button{
  margin-top: 239px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 767px) {
.center-discord-button{
  margin-top: 27px !important;
}
  .discord-button-design{
    width: 77%;
  }
  .home-connect-wallet-button-mobile{
    width: 77%;
  }
}

.mint-buttons-container.wishlist-button-container {
  min-height: 180px;
  gap: 2rem;
  flex-direction: column;
  margin-top: 0;

  @media screen and (min-width: 768px) {
    min-height: fit-content;
    top: 940px;
    left: 356px;
    flex-direction: row;
    position: absolute;
  }

  @media screen and (min-width: 1024px) {
    min-height: fit-content;
    top: 500px;
    left: 70px;
  }

  @media screen and (min-width: 1440px) {
    min-height: fit-content;
    top: 400px;
    left: 188px;
  }

  @media screen and (min-width: 2000px) {
    min-height: fit-content;
    top: 390px;
    left: 170px;
  }

  .astrafer-mint-button {
    padding: 1rem;
    width: 180px;
    margin-bottom: 0;

    @media screen and (min-width: 320px) {
      font-size: 1rem;
      width: 220px;
    }

    img {
      width: auto;
      height: 1.5rem;
    }
  }

  .home_play_button {
    color: $black;
  }
}
