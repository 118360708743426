@import '../../variables';

.info-dropdown-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  .info-dropdown-header {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
    padding: 30px 40px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 13px;
    cursor: pointer;

    @media screen and (max-width: 1440px) {
      margin-bottom: 11px;
    }

    @media screen and (max-width: 1024px) {
      padding: 23px 31px;
      margin-bottom: 11px;
    }

    @media screen and (max-width: 768px) {
      padding: 30px 41px;
      margin-bottom: 13px;
    }

    @media screen and (max-device-width: 375px) and (max-width: 375px) {
      padding: 5px 4px;
      margin-bottom: 9px;
    }

    .info-dropdown-arrow {
      width: 19px;
      height: 10px;
      transform: rotate(-90deg);
      margin-right: 10px;
      color: $simple-gray;

      &.rotate {
        transform: rotate(0deg);
      }
    }

    .info-dropdown-header-text {
      font-family: $oswald;
      font-size: 1.5rem;
      color: $simple-gray;
    }
  }

  .info-dropdown-text {
    font-family: $sofia;
    font-size: 1rem;
    line-height: 22px;
    color: $gray-dropdown;
    font-weight: lighter;
    margin-bottom: 13px;

    width: 100%;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 20px 40px;
    border-radius: 5px;

    @media screen and (max-width: 1440px) {
      margin-bottom: 11px;
    }

    @media screen and (max-width: 1024px) {
      padding: 17px 31px;
      margin-bottom: 11px;
    }

    @media screen and (max-width: 768px) {
      padding: 20px 40px;
      margin-bottom: 9px;
    }

    @media screen and (max-device-width: 375px) and (max-width: 375px) {
      padding: 0 31px;
      margin-bottom: 10px;
      font-size: 0.875rem;
    }
  }
}
