@import '../../../../variables';

.claim-other-info-main-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 110px;
}

.total-available-container,
.total-remaining-container,
.next-date-container{
  width: 326px;
  margin: 0 19px 0 19px;
}

.other-nft-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .total-available-container,
  .total-remaining-container,
  .next-date-container{
    width: 246px;
    margin: 0 16px 0 16px;
  }
  .claim-other-info-main-container {
    margin-bottom: 66px;
  }
  }

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .total-available-container,
  .total-remaining-container,
  .next-date-container{
    width: 18.555vw;
    margin: 0 1.172vw 0 1.172vw;
  }
  .claim-other-info-main-container {
    margin-bottom: 4.883vw;
  }
}
@media screen and (min-width: 376px) and (max-width: 768px) {
  .total-available-container,
  .total-remaining-container,
  .next-date-container{
    width: 25.130vw;
    margin: 0 1.693vw 0 1.693vw;
  }
  .claim-other-info-main-container {
    margin-bottom: 6.771vw;
  }

}

@media screen and (max-width: 375px){
  .total-available-container,
  .total-remaining-container,
  .next-date-container{
    width: 26.933vw;
    margin: 0 1.693vw 0 1.693vw;
  }
  .claim-other-info-main-container {
    margin-bottom: 17.067vw;
  }
  .claim-other-info-main-container .claim-info-title {
    width: 24vw;
  }
}
