@import '../../variables';

.tilted-button-container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: $pink;
  clip-path: polygon(5% 0, 100% 0, 95% 100%, 0% 100%);
  font-family: 'Oswald', Helvetica;
  font-size: 20px;
  color: white;
  cursor: pointer;
}

.tilted-button-container-disabled{
  @extend .tilted-button-container;
  background: #8A8A8A;
  cursor: initial;
}

.tilted-button-icon {
  padding-right: 8px;
}
