@import "../../../variables";
@import "../../../functions";

.icon-card {
  display: flex;
  background-color: black;
  color: white;
  border: 1px solid $black-night-bg;
  border-radius: 2px;
  padding: 4px;
  align-items: center;
  font-family: $oswald;
  font-size: 1rem;
  justify-content: flex-start;
  gap: 0.5rem;

  @media screen and (min-width: 768px) {
    padding: 8px 16px;
    font-size: 1rem;
  }

  &__main-text, &__sub-text {
    margin-bottom: 0;
  }

  &__image {
    width: 20px;
    height: 20px;
    padding: 4px;

    @media screen and (min-width: 768px) {
      width: 30px;
      height: 30px;
      padding: 4px;
    }
  }

  &__button {
    margin-left: auto;
    margin-right: 0;
    width: auto;
    padding: 0.5rem 0;
    line-height: 1;
    height: auto;

    .button-text {
      height: auto;
      line-height: 1;
    }

    @media screen and (min-width: 426px) {
      padding: 0.425rem 1rem;
    }
  }
}
