@import "../../../variables";

.top-menu-button-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 17px;
  text-decoration: none;
  color: $white;
}
.top-menu-button-container:hover{
  color: $white;}
.top-menu-button{
  font-family: $oswald;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.top-menu-underline{
  width: 0;
  height: 3px;
  background-color: $pink;
  transition: width 0.3s ease-in-out;
}

.top-menu-button-container:hover .top-menu-underline{
  width: 100%;
}

.top-menu-button img {
  height: 19px;
  margin-left: 5px;
}
