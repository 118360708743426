@use '/src/variables.scss' as *;
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins';

$images-path: '../../../assets/images/';


.genesis-nft-container .vertical-bar{
  width: 2px;
  height: 100%;
  position: absolute;
  right: -282px;
}

.genesis-tabs-container .episode-tabs .episode-tab-image img{
  width: 19.792vw;
  height: 9.635vw;
}

.genesis-tabs-container .episode-tabs .nav-link {
  position: relative;
  padding: 0;
  color: $white;
}

.genesis-tabs-container .episode-tabs .episode-tab-image{
  margin-right: 0;
  max-width: none;
  max-height: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-left: none;
}

.genesis-tabs-container .episode-tabs .nav-link > .episode-tab-container{
  width: 100%;
  height: 100%;
  padding: 2.604vw 3.438vw 2.969vw 3.385vw;
}


.genesis-tabs-container .episode-tabs {}


.genesis-tabs-container .episode-tabs .nav-link:hover {
  padding: 0;
  color: $pink;
}
.genesis-tabs-container .episode-tabs .nav-link:focus,
.genesis-tabs-container .episode-tabs .nav-link.active:focus {
  outline: 0;
}

.genesis-tabs-container .episode-tabs .nav-link:focus-visible,
.genesis-tabs-container .episode-tabs .nav-link.active:focus-visible{
  outline: 0;
}

.genesis-tabs-container .episode-tabs .nav-link.active {
  position: relative;
  border-left: 1px solid $white;
  border-radius: 0;
  left: -1px;
  background: none !important;
  text-decoration: none;
  outline: 0;
}

.genesis-tabs-container .episode-tabs .nav-link.active:before {
  background-image: url(#{$images-path}section-left.png);
  content: '';
  background-size: 1.771vw 3.490vw;
  background-repeat: no-repeat;
  height: 3.490vw;
  width: 1.771vw;
  position: absolute;
  top: 50%;
  left: -1.771vw;
  margin-top: -1.771vw;
}


.genesis-tabs-container .episode-tabs .nav-link.active > .episode-tab-container {
  background: $grey-tab-background !important;
  clip-path: polygon(90% 0px, 100% 15%, 100% 100%, 0px 100%, 0px 0px);
}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .genesis-tabs-container .episode-tabs{
    display: flex;
    flex-direction: row;
    height: 20.313vw;
    width: 31.380vw;
    margin-bottom: 6.901vw;
  }

  .genesis-tabs-container .episode-tabs .nav-link > .episode-tab-container{
    padding: 1.3vw 3.438vw 2.969vw 4.1vw;
  }

  .genesis-tabs-container .episode-tabs .nav-link{
    height: 20.313vw;
    width: 31.380vw;
    margin-right: -0.781vw;
  }

  .genesis-tabs-container .episode-tabs .nav-link.active {
    border-left: none;
    left: 0;
  }

  .genesis-tabs-container .episode-tabs .nav-link.active:before {
    display: none;
  }

}
@media screen and (max-device-width: 375px) {
  .genesis-tabs-container .episode-tabs {
    display: flex;
    flex-direction: row;
    margin-bottom: 16vw;
  }

    .genesis-tabs-container .episode-tabs .nav-link.active {
    border-left: none;
    left: 0;
    }

  .genesis-tabs-container .episode-tabs .nav-link.active:before {
    display: none;
  }
  .genesis-tabs-container .episode-tabs .nav-link > .episode-tab-container {
    padding: 3.604vw 3.933vw 3.669vw 4.533vw;
  }
  .genesis-tabs-container .episode-tabs .nav-link.active > .episode-tab-container{
    clip-path: polygon(87% 0px, 100% 30%, 100% 100%, 0px 100%, 0px 0px);
  }
}
