@import "../../variables";

.select-token-input {
  &__container {
    display: flex;
    align-items: center;
    margin-top: 70px;
    background-color: $pg-midnight-grey;
    width: 100%;
  }
}
