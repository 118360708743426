@import "src/variables";

.rewards-cell-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.margin {
  margin-bottom: 111px;
  @media screen and (max-width: 768px) {
    margin-bottom: 80px;
  }
  @media screen and (max-device-width: 375px) {
    margin-bottom: 30px;

  }
}

.rewards-cell-title-container {
  font-family: $sofia;
  font-size: 1rem;
  line-height: 19px;
  text-align: center;
}

.rewards-cell-data-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 225px;
}

@supports (-webkit-touch-callout: none) {
  .rewards-cell-container {
    @media screen and (min-width: 1025px) {
      margin: 0 9px;

      .rewards-cell-data-container {
        width: 166px;
      }
      .rewards-cell-data {
        font-size: 1.3125rem;
        line-height: 29px;
      }
      .rewards-cell-title-container {
        font-size: 0.75rem;
      }

      .rewards-cell-title-container {
        font-size: 0.75rem;
        margin-bottom: 11px;
      }

      .rewards-cell-left-decoration,
      .rewards-cell-right-decoration {
        height: 33px;
      }
    }
    @media screen and (max-width: 1024px) {
      margin: 25px 15px;
    }
    @media screen and (max-width: 768px) {
      margin: 12.5px 6.5px;
    }
    @media screen and (max-device-width: 375px) and (max-width: 375px) {
      margin: 13px 6px;
    }
  }
}

.rewards-cell-left-decoration,
.rewards-cell-right-decoration {
  height: 48px;
}

.rewards-cell-data {
  font-family: $michroma;
  font-size: 1.75rem;
  line-height: 40px;
  text-align: center;
}

.big-cell {
  position: relative;

  .rewards-cell-title-container {
    font-size: 1.5rem;
    position: absolute;
    top: 20px;

    @media screen and (max-width: 768px) {
      font-size: clamp(0.8125rem, 2.344vw, 1.125rem);
      top: 10px;
    }
    @media screen and (max-width: 375px) {
      font-size: clamp(0.4rem, 3.467vw, 0.8125rem);
      top: 7px;
    }
  }

  .rewards-cell-data-container {
    width: 326px;

    @media screen and (max-width: 768px) {
      width: 34.053vw;
      height: 12.173vw;
    }

    @media screen and (max-width: 375px) {
      width: 42.091vw;
      height: 15.045vw;
    }
  }

  .rewards-cell-left-decoration,
  .rewards-cell-right-decoration {
    height: 124px;

    @media screen and (max-width: 768px) {
      height: 12.173vw;
    }

    @media screen and (max-width: 375px) {
      height: 15.045vw;
    }
  }

  .rewards-cell-data {
    font-size: 2.25rem;
    margin-bottom: -30px;

    @media screen and (max-width: 768px) {
      font-size: clamp(0.9375rem, 3.646vw, 1.75rem);
    }

    @media screen and (max-device-width: 375px) and (max-width: 375px) {
      font-size: clamp(0.4rem, 4vw, 0.9375rem);
    }
  }
}

@media screen and (max-width: 1024px) {

  .rewards-cell-title-container {
    font-size: 0.75rem;
    margin-bottom: 11px;
  }

  .rewards-cell-data-container {
    width: 166px;
  }

  .rewards-cell-data {
    font-size: 1.3125rem;
    line-height: 29px;
  }
  .rewards-cell-left-decoration,
  .rewards-cell-right-decoration {
      height: 33px;
  }

}

@media screen and (max-width: 768px) {
  .rewards-cell-title-container {
    font-size: 0.75rem;
  }
  .rewards-cell-data-container {
    width: 161px;
  }
  .rewards-cell-data {
    font-size: 0.9375rem;
    line-height: 21px;
  }
  .rewards-cell-left-decoration,
  .rewards-cell-right-decoration {
    height: 31px;
  }
}

@media screen and (max-device-width: 375px)  and (max-width: 375px) {
  .rewards-cell-data {
    font-size: 0.9375rem;
    line-height: 30px;
  }
  .rewards-cell-title-container {
    font-size: 0.875rem
  }
  .rewards-cell-data-container {
    width: 161px;
  }
  .rewards-cell-left-decoration,
  .rewards-cell-right-decoration {
    height: 31px;
  }
}
