@import "../../../variables";

.reveal-info-main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}

.reveal-img-container {
  background-color: #54AFE2;
  clip-path: polygon(38px 0, 0 38px, 0 calc(100% - 42px), 38px 100%, calc(100% - 54px) 100%, 100% calc(100% - 46px), 100% 42px, calc(100% - 38px) 0);
  width: 400px !important;
  min-width: 400px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: -41px;
}

.reveal-text-container {

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.reveal-device-main-container {
  display: none;
}

.reveal-text-box {
  position: relative;
  z-index: -1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 38px;
  width: 636px;
  height: 300px;
  background-color: rgba(0, 0, 0, 0.5);
  margin-left: -54px;
  margin-right: -54px;
  clip-path: polygon(15px 0, calc(100% - 50px) 0, calc(100% - 11px) 44px, calc(100% - 12px) calc(100% - 47px), calc(100% - 68px) 100%, 0 100%, 52px calc(100% - 45px), 52px 40px);
}

.reveal-paragraph-1 {
  margin: 0px;
  width: 468px;
  font-family: $sofia;
  font-size: 16px;
  line-height: 25px;
}

.reveal-paragraph-2 {
  margin: 0px;
  width: 433px;
  font-size: 16px;
  line-height: 25px;
  font-family: $sofia;
}

.reveal-device-img-container {
  width: 32.031vw;
  height: 25.391vw;
  margin-bottom: -2.604vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #54AFE2;
  clip-path: polygon(4.297vw 0, 0 4.297vw, 0 calc(100% - 4.297vw), 4.297vw 100%, calc(100% - 4.297vw) 100%, 100% calc(100% - 4.297vw), 100% 4.297vw, calc(100% - 4.297vw) 0);
}

.reveal-device-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  clip-path:polygon(calc(0% + 0.391vw) -1px, 4.557vw 4.167vw, calc(100% - 4.557vw) 4.297vw, calc(100% - 0.130vw) -1px, 100% calc(100% - 4.297vw), calc(100% - 4.297vw) 100%, 4.297vw 100%, 0 calc(100% - 4.297vw));
}

.dont-show-the-text{
  display: none;
  transition: all 2s ease;
}

.animation-container{
  position: relative;
  z-index: -1;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  width: 100%;
  animation: drop-down-animation 0.6s ease-in-out 0s 1 normal forwards;
}
.drop-down-border{
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.hide-animation-container{
  position: relative;
  z-index: -1;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  width: 100%;
  animation: hide-drop-down-animation 0.6s ease-in-out 0s 1 normal forwards;
}

.reveal-device-text-box {
  background-color: rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: -1;
  margin-top: -4.427vw;
  margin-bottom: -3.255vw;
  padding: 9.115vw 2.083vw 3.906vw;
  clip-path: polygon(0 0, 4.557vw 4.167vw, calc(100% - 4.297vw) 4.297vw, 100% 0, 100% calc(100% - 4.297vw), calc(100% - 4.297vw) 100%, 4.297vw 100%, 0 calc(100% - 4.297vw));
}

@keyframes drop-down-animation {
  from {
   transform: translate(0, -100%);
  }
  to {
    transform: translate(0, 0);
  }
}
@keyframes hide-drop-down-animation {

  0%   { transform: translate(0, 0);height: initial;opacity: 1;}
  99%  { transform: translate(0,  calc(-100% ));  height: initial;opacity: 1;}
  100% {  height: 0px;opacity: 0}
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .reveal-img-container {
    min-width: 35.352vw;
    height: 270px;
    width: initial !important;
  }
  .reveal-content-img {
    width: 20.898vw;
  }

  .reveal-text-box {
    padding-left: 38px;
    width: 56.543vw;
    height: 270px;
  }

  .text-container-border{
    height: 270px;
  }
  .reveal-paragraph-1 {
    font-size: 1.563vw;
    line-height: 2.441vw;
    width: 41.016vw;
  }
  .reveal-paragraph-2 {
    font-size: 1.563vw;
    line-height: 2.441vw;
    width: 37.988vw;
  }

}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .reveal-info-main-container {
    display: none;
  }
  .reveal-device-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 32.031vw;
  }
  .reveal-device-img-container{
    height: 25.391vw;
    width:32.031vw;
  }
  .reveal-device-text-box {
    height: 43.620vw;
  }
    .reveal-content-img {
    width: 26.172vw;
  }
  .reveal-paragraph-1 {
    margin: initial;
    width: initial;
    font-family: $sofia;
    font-size: 1.823vw;
    line-height: 2.734vw;
    text-align: center;
  }
  .reveal-paragraph-2 {
    margin: initial;
    width: initial;
    font-size: 1.823vw;
    line-height: 2.734vw;
    font-family: $sofia;
    text-align: center;
  }
}

@media screen  and (max-device-width: 375px) {

  .reveal-device-img-container{
    width: 77.600vw;
    height: 61.333vw;
    margin-bottom: -8vw;
    clip-path: polygon(11.467vw 0, 0 11.467vw, 0 calc(100% - 11.467vw), 11.467vw 100%, calc(100% - 11.467vw) 100%, 100% calc(100% - 11.467vw), 100% 11.467vw, calc(100% - 11.467vw) 0);
  }
  .reveal-info-main-container {
    display: none;
  }
  .reveal-device-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 77.600vw;
  }
  .reveal-content-img {
    width: 63.467vw;
  }
  .reveal-paragraph-1 {
    margin: initial;
    width: initial;
    font-family: $sofia;
    font-size: 4.267vw;
    line-height: 6.667vw;
    text-align: center;
  }
  .reveal-paragraph-2 {
    margin: initial;
    width: initial;
    font-size: 4.267vw;
    line-height: 6.667vw;
    font-family: $sofia;
    text-align: center;
  }

  .reveal-device-text-box {
    background-color: rgba(0, 0, 0, 0.5);
    position: relative;
    z-index: -1;
    margin-top: -10.667vw;
    margin-bottom: -7.200vw;
    padding: 24vw 5.333vw 8.533vw;
    clip-path: polygon(0 0, 10.400vw 10.400vw, calc(100% - 10.400vw) 10.400vw, 100% 0, 100% calc(100% - 10.400vw), calc(100% - 10.400vw) 100%, 10.400vw 100%, 0 calc(100% - 10.400vw));
  }

  .reveal-device-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    clip-path: polygon(calc(0% + 0.800vw) -1px, 11.467vw 10.133vw, calc(100% - 10.933vw) 10.400vw, calc(100% - 0.800vw) -1px, 100% calc(100% - 10.400vw), calc(100% - 10.400vw) 100%, 10.400vw 100%, 0 calc(100% - 10.400vw))
  }

}
