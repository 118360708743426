@import "../../../variables";

.converting-poster-modal-parent {
  width: $converting5x-width;
  height: $converting5x-height;
  max-width: initial;
}

.converting-poster-modal-body {
  color: white;
  position: relative;
  width: 100%;
  background-color: black;
}

.converting-poster-modal-banner-control {
  width: $banner-width;
  height: $banner-height;
  position: absolute;
}


.converting-poster-header-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.converting-poster-modal-close-button {
  cursor: pointer;
  margin-right: 21px;
  margin-top: 20px;
}


.converting-poster-modal-banner-elements-container {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.converting-poster-modal-agreement-container {
  text-align: center;
  padding: 20px;
  padding-bottom: 45px;
}

.converting-poster-modal-buttons-size {
  width: 187px;
  height: 37px;
}

.converting-poster-modal-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 13px;
  padding-right: 160px;
  padding-left: 160px;
}

.converting-poster-modal-agreement-text {
  font-size: 20px !important;
}

.converting-poster-modal-header-horns {
  position: absolute;
  top: -13px;
  z-index: 1;
  width: 100%;
}

.converting-poster-modal-bottem-horns {
  position: absolute;
  bottom: -13px;
  z-index: 1;
  width: 100%;
}

.converting-poster-modal-content {
  width: 100%;
  font-size: 18px;
  text-align: center;
  font-size: 18px;
}



