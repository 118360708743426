@import "../../variables";

.bridge-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 360px;
  row-gap: 1rem;

  @media screen and (min-width: $sm-breakpoint) {
    width: 575px;
  }

  .token-bridge-form__divider {
    display: flex;
    position: relative;
    width: 60px;
    background-color: $black;

    @media screen and (min-width: $sm-breakpoint) {
      width: 60px;
    }

    .token-bridge-form__arrow {
      position: absolute;
      width: 30px;
      transform: rotate(270deg);

      &.opaque {
        top: 50px;
        left: 30px;
      }

      &.faded {
        top: 50px;
        left: 16px;
        opacity: 75%;
      }

      &.very-faded {
        top: 50px;
        left: 2px;
        opacity: 45%;
      }
    }
  }

  .token-bridge-network {
    &__container {
      background-color: $pg-midnight-grey;
      display: flex;
      width: 100%;

      .select-network__button {
        background-color: inherit;
      }
    }
  }

  .token-bridge__error-text {
    margin-top: 32px;
    color: $pink;
    margin-bottom: 0;
  }

  .token-bridge__send-button {
    margin-top: 32px;
  }
}

.token {
  &__container {
    border: $dark-gray-border;
    border-radius: 20px;
    overflow: hidden;
    width: 100%;
  }

  &__options {
    display: flex;
  }
}

.select-token {
  &__button {
    min-width: 250px;
  }
}

.select-network {
  &__button {
    border-radius: 0 20px 0 0;
    margin-left: 1px;
  }
}

.token-bridge {
  &__modal-text-container {
    text-align: center;
    padding-left: 2rem;
    padding-right: 2rem;

    .success-message {
      text-align: center;

      .token-bridge__success-link {
        cursor: pointer;
        color: $white;
        text-decoration: underline;
      }
    }
  }

  &__error-modal-body {
    gap: 1rem;
  }

  &__error-modal-icon {
    width: 50px;
  }

  &__error-modal-title {
    margin-bottom: 0;
  }

  &__error-modal-text {
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    overflow-y: auto;
  }
}
