@import "../variables";

.planet-detail {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: $oswald;
  color: $white;
  padding-bottom: 10rem;
  margin: 0 auto;
  max-width: 560px;

  &__planet-id-type {
    font-size: 2rem;
    margin-bottom: 2rem;
    @media screen and (min-width: 768px) {
      font-size: 2.5rem;
    }
  }
}

.reward-status {
  color: $pink
}
.planet-image-container {
  position: relative;
  width: 100%;
  padding: 0 1rem 2rem;

  .planet-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .share-button {
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 1;
  }
}

.planet-info {
  display: flex;
  width: 100%;
  gap: 1rem;
  flex-direction: column;
  padding: 0 1rem;

  &__link {
    padding: 2rem;
    text-decoration: none;
    color: inherit;
  }
}

.pg-planet-accordion {
  .accordion-list {
    .accordion-button {
      font-size: 1rem;
      padding-left: 50px;

      &::after {
        left: 20px;
      }
    }

    .accordion-header {
      margin: 0;
    }

    .accordion-body {
      padding: 0.5rem 1rem;
      margin-bottom: 0;
    }

    &__items {
      padding-left: 2rem;
      font-family: $oswald-light;
      color: $white;
      line-height: 1.5rem;

      .title {
        color: $gray-text;
      }
    }

    &__link {
      color: inherit;
      font-size: 15px
    }

  }
}



