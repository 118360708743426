.exploration-selection-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}

.exploration-selection-title-container .page-header .page-mobile-header-text {
  @media screen and (max-width: 1440px) {
    font-size: clamp(1.875rem, 3.125vw, 2.8125rem);
  }
  @media screen and (max-device-width: 1024px) {
    font-size: 1.875rem;
  }
}

.exploration-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1347px;
  margin-bottom: 165px;

  @media screen and (max-width: 1440px) {
    width: 90%;
    max-width: 1167px;
  }

  @media screen and (max-width: 1024px) {
    width: 90%;
    max-width: 797px;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
    max-width: 614px;
  }

  @media screen and (max-device-width: 375px) and (max-width: 375px) {
    width: 93.6vw;
    margin-bottom: 120px;
  }

  .page-mobile-header-text,
  .page-mobile-header-text-no-margins {
    margin-bottom: 0;
  }

  .page-header {
    width: 100vw;
    padding-bottom: 60px;

    @media screen and (max-width: 1024px) {
      padding-bottom: 40px;
    }
    @media screen and (max-width: 768px) {
      padding-bottom: 0;
    }
    @media screen and (max-device-width: 375px) and (max-width: 375px) {
      margin-bottom: -23.467vw;
    }

    .psg-border-img-pc {
      @supports (-webkit-touch-callout: none) {
        object-fit: contain;
      }
    }
  }
}
.exploration-select-planet-loading-spinner {
  margin-top: 100px;
}
