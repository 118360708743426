.modal-close-button{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
}
.modal-close-button-icon{
  margin-right: 11px;
  margin-top: 11px;
  width: 22px;
  height: 22px;
  cursor: pointer;
}
