$elem-z-index: 1;

.kyc-boarder-container{
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $elem-z-index;
}

.kyc-boarder-image{
  width: 1200px;
  z-index: $elem-z-index;
}

.kyc-boarder-title{
  font-family: Oswald;
  font-size: 36px;
  position: absolute;
  z-index: $elem-z-index;
}

@media only screen and (max-width: 1500px) {
  .kyc-boarder-image{
    width: 100% !important;
  }
}
