@import "../../../../variables";

.button-outfit {
  width: 365.72px;
  height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px;
  cursor: pointer;
  color: white;
  transition: all 0.2s ease;
  transform: scale(1);
  clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
}

.connect-button-active:hover {
  transform: scale(1.1);
  color: white;
}

.connect-button-active{
  background-color: $light-red-background;
  border: 1px solid $light-red-background;
}
.connect-button-inactive{
  cursor: initial;
  background-color: transparent;
  width: 369.72px;
  height: 59px;
}

.button-pink-border {
  --b: 3px; /* border */
  --s: 6px; /* size of the cut */
  clip-path: polygon(6px 0, calc(100% - 6px) 0, 100% 11%, 100% calc(100% - 6px), calc(100% - 6px) 100%, 6px 100%, 0 calc(100% - 6px), 0 6px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;

  width: 100%;
  height: 100%;
  aspect-ratio: 1.5;
  z-index: 0;
}

.button-pink-border:after {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: $light-red-background;
  --g1: #000 var(--b), #0000 0 calc(100% - var(--b)), #000 0;
  --g2: #0000 calc(0.707 * var(--s)),
  #000 0 calc(0.707 * var(--s) + var(--b)),
  #0000 0 calc(100% - 0.707 * var(--s) - var(--b)),
  #000 0 calc(100% - 0.707 * var(--s)),
  #0000 0;
  -webkit-mask: linear-gradient(45deg, var(--g2)),
  linear-gradient(-45deg, var(--g2)),
  linear-gradient(90deg, var(--g1)) 50%/100% calc(100% - 2 * var(--s)) no-repeat,
  linear-gradient(180deg, var(--g1)) 50%/calc(100% - 2*var(--s)) 100% no-repeat;

}

.connect-button-text {
  position: absolute;
  font-family: $oswald;
  font-style: normal;
  font-size: 30px;
  line-height: 44px;
  text-align: center;
  color: $white;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .button-outfit {
    width: 299px;
    height: 43px;
  }
  .button-outfit.connect-button-inactive{
    width: 303px;
    height: 47px;
  }
  .connect-button-text {
    font-size: 23px;
    line-height: 34px;
  }
  }

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .button-outfit {
    width: 24.219vw;
    height: 3.516vw;
  }
  .button-outfit.connect-button-inactive{
    width: 24.610vw;
    height: 3.907vw;
  }
  .connect-button-text {
    font-size: 1.855vw;
    line-height: 2.539vw;
    clip-path: polygon(0.651vw 0, 0 0.651vw, 0 calc(100% - 0.651vw), 0.651vw 100%, calc(100% - 0.651vw) 100%, 100% calc(100% - 0.651vw), 100% 0.651vw, calc(100% - 0.651vw) 0);
  }
}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .button-outfit {
    width: 28.125vw;
    height: 4.036vw;
  }
  .button-outfit.connect-button-inactive{
    width: 28.646vw;
    height: 4.557vw;
  }
  .connect-button-text {
    font-size: 2.214vw;
    line-height: 3.255vw;
    clip-path: polygon(0.651vw 0, 0 0.651vw, 0 calc(100% - 0.651vw), 0.651vw 100%, calc(100% - 0.651vw) 100%, 100% calc(100% - 0.651vw), 100% 0.651vw, calc(100% - 0.651vw) 0);
  }
  }

@media screen and (max-width: 375px) {
  .button-outfit {
    width: 53.600vw;
    height: 7.733vw;
  }
  .button-outfit.connect-button-inactive{
    width: 54.667vw;
    height: 8.800vw;
  }
  .connect-button-text {
    font-size: 4.267vw;
    line-height: 6.400vw;

    clip-path: polygon(1vw 0, 0 1vw, 0 calc(100% - 1vw), 1vw 100%, calc(100% - 1vw) 100%, 100% calc(100% - 1vw), 100% 1vw, calc(100% - 1vw) 0);
  }
}

