@import '../variables';

.page-header {
  padding-bottom: 40px;
}

.page-description {
    width: 100%;
    text-align: justify;
    padding: 0 20px;

  @media screen and (min-width: $sm-breakpoint) {
    width: 70%;
    margin: 0 auto;
  }
}
