@import 'src/variables';

.bottom-component-main-container{
  width: 100%;
  height: 147px;
  background-color: $pink;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;
}
.animcoa-link-adjustment{
  position: relative;
  z-index: 1;
}
.footer-buttons-container{
  width: 370px;
  height: 80%;
  margin-right: 15px;
  position: relative;
  z-index: 0;
}
.footer-link-buttons{
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
}
.footer-brand-button{
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.blowfish-logo-img{
  width: 208px;
}
.privacy-policy {
}
.underline-elimination{
  text-decoration: none;
  color: white;
  font-family: $oswald;
  font-size: 14px;
  border-bottom: 0px;
}
.underline-elimination:hover{
  color: white;
}
.footer-developed-by{
  color: white;
  font-family: 'Sofia Pro-Regular', Helvetica;
  font-size: 12px;
}
.footer-show-the-year{
  font-family: $oswald;
  font-size: 10px;
  width: 100%;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding-right: 15px;
  margin-top: 8px;
}
.footer-development-company-row{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
}
.animoca-logo-img{
  width: 178px;
  padding-left: 32px;
}
.bottom-component-main-container-mobile{
  @extend .bottom-component-main-container;
  display: none;
}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .bottom-component-main-container{
    position: relative;
    height: 19.141vw;
  }
  .blowfish-logo-img{
    width: 27vw;
  }
  .animoca-logo-img{
    width: 27vw;
    padding-left: 24px;
  }
  .underline-elimination{
    font-size: 1.823vw;
  }
  .footer-developed-by{
    font-size: 1.563vw;
  }
  .footer-show-the-year{
    font-size: 1.302vw;
    margin-top: 0;
  }
  .footer-buttons-container{
    width: 48.021vw;
  }
}

@media screen and (max-device-width: 375px) {
  .bottom-component-main-container{
    display: none;
  }
  .bottom-component-main-container-mobile{
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-start;
    justify-content: center;
  }
  .footer-buttons-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .footer-development-company-row{
    flex-direction: column;
    align-items: end;
    height: auto;
    margin-top: 0px;
    margin-right: 12px;
  }
  .footer-developed-by{
    margin-bottom: 0px;
    width: 100%;
    font-size: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 37px;
  }
.footer-show-the-year{
  justify-content: right;
  padding-right: 9px;
  margin-top: 0;
}
  .footer-brand-button{
    align-items: end;
  }
  .footer-link-buttons{
    max-width: 375px;
    padding-right: 13px;
    padding-left: 20px;
  }
  .blowfish-logo-img{
    width: 37vw;
  }
  .animoca-logo-img{
    width: 37vw;
    padding-left: 24px;
  }
}


