@import "../../variables";

.check-in-tab-main-container{
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 53px;
  height: 110px;
}

.check-in-tab-main-container-progress{
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 287px;
  height: 110px;
  background-color: white;
  border: 1px solid $pink;
}

.check-in-tab-main-container.locked{
  background-color: $stack;
}

.check-in-tab-main-container.completed{
  background-color: $tab-gray;
}

.check-in-tab-main-container.ended{
  background-color: $tab-gray;
}

.check-in-tab-text-container{
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin: 18px 0 0 26px;
}

.check-in-tab-title{
  font-size: 1.34rem;
  font-weight: 400;
  line-height: 25.5px;
  font-family: $sofia;
  color: $pink;
  margin: 0 0 13px 0;
}

.check-in-tab-title.number{
  color: white;
  margin-bottom: 35px;
}

.check-in-tab-content{
  font-size: 1.72rem;
  font-weight: 400;
  line-height: 34px;
  font-family: $oswald;
  color: $pink;
  margin: 0;
}

.check-in-tab-left-decor{
  width: 8px;
  height: 110px;
  background-color: $pink;
}

.check-in-tab-right-decor{
  display: flex;
  flex-direction: column;
  margin: 18px 0 0 15px;
}

.check-in-tab-locked{
  width: 13px;
  height: 17px;
}

.check-in-tab-completed-ended{
  width: 19px;
  height: 19px;
  margin-left: -2px;
}

.check-in-tab-progress{
  width: 17px;
  height: 17px;
  margin: 79px 0 0 93px;
}

.check-in-tab-title-progress, .checkin-tab-loading-icon, .check-in-tab-left-decor.progress-decor {
  display: none;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .check-in-tab-main-container{
    width: 3.681vw;
    height: 7.639vw;
  }

  .check-in-tab-main-container-progress{
    width: 19.931vw;
    height: 7.639vw;
  }

  .check-in-tab-text-container{
    width: 13.5vw;
    margin: 1.250vw 0 0 1.806vw;
  }

  .check-in-tab-title{
    font-size: clamp(1.125rem, 1.493vw, 1.34rem);
    line-height: 1.771vw;
    margin: 0 0 0.903vw 0;
  }

  .check-in-tab-title.number{
    margin-bottom: 2.431vw;
  }

  .check-in-tab-content{
    font-size: clamp(1.438rem, 1.920vw, 1.72rem);
    line-height: 2.361vw;
    margin: 0;
  }

  .check-in-tab-left-decor{
    width: 0.556vw;
    height: 7.639vw;
  }

  .check-in-tab-right-decor{
    margin: 1.250vw 0 0 1.042vw;
  }

  .check-in-tab-locked{
    width: 0.903vw;
    height: 1.181vw;
  }

  .check-in-tab-completed-ended{
    width: 1.319vw;
    height: 1.319vw;
    margin-left: -0.139vw;
  }

  .check-in-tab-progress{
    width: 1.181vw;
    height: 1.181vw;
    margin: 5.486vw 0 0 2.458vw;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .check-in-tab-main-container{
    width: 4.297vw;
    height: 8.984vw;
  }

  .check-in-tab-main-container-progress{
    width: 23.340vw;
    height: 8.984vw;
  }

  .check-in-tab-text-container{
    width: 17.5vw;
    margin: 1.250vw 0 0 1.806vw;
  }

  .check-in-tab-title{
    font-size: clamp(1.125rem, 1.493vw, 1.34rem);
    line-height: 2.2vw;
    margin: 0 0 0.903vw 0;
  }

  .check-in-tab-title.number{
    margin-bottom: 2.8vw;
  }

  .check-in-tab-content{
    font-size: clamp(1.438rem, 1.920vw, 1.72rem);
    line-height: 2.361vw;
    margin: 0;
  }

  .check-in-tab-left-decor{
    width: 0.684vw;
    height: 8.984vw;
  }

  .check-in-tab-right-decor{
    margin: 1.250vw 0 0 1.042vw;
  }

  .check-in-tab-locked{
    width: 0.903vw;
    height: 1.181vw;
    margin-left: 0.1vw;
  }

  .check-in-tab-completed-ended{
    width: 1.319vw;
    height: 1.319vw;
    margin-left: 0.1vw;
  }

  .check-in-tab-progress{
    width: 1.181vw;
    height: 1.181vw;
    margin: 6.286vw 0 0 1.458vw;
  }
}

@media screen and (max-width: 768px) {
  .check-in-tab-main-container {
    width: 35.5px;
    height: 73px;
  }

  .check-in-tab-main-container-progress {
    width: 191px;
    height: 73px;
  }

  .check-in-tab-text-container {
    margin: 13px 0 0 17px;
  }

  .check-in-tab-title {
    font-size: 0.89rem;
    line-height: 1rem;
    margin: 0 0 7px 0;
  }

  .check-in-tab-title.number {
    margin-bottom: 22px;
    margin-left: -1px;
  }

  .check-in-tab-content {
    font-size: 1.15rem;
    line-height: 1.43rem;
  }

  .check-in-tab-left-decor {
    width: 5.5px;
    height: 73px;
  }

  .check-in-tab-right-decor {
    margin: 13px 0 0 12px;
  }

  .check-in-tab-locked {
    width: 9px;
    height: 11px;
    margin-left: -2px;
  }

  .check-in-tab-completed-ended {
    width: 13px;
    height: 13px;
    margin-left: -2px;
  }
}

@media screen and (max-device-width: 375px) {
  .check-in-tab-main-container {
    width: 49px;
    height: 53px;
  }

  .check-in-tab-main-container-progress {
    width: 49px;
    height: 53px;
  }

  .check-in-tab-title {
    display: none;
  }

  .check-in-tab-title-progress {
    display: block;
    color: $pink;
    font-size: 1.25rem;
    font-weight: 400;
    margin: 0 0 0 3px;
  }

  .checkin-tab-loading-icon {
    display: block;
  }

  .check-in-tab-text-container {
    margin: -3px 0 0 11px;
  }

  .check-in-tab-title.number {
    display: block;
    margin-bottom: 10px;
    font-size: 1.25rem;
  }

  .check-in-tab-content {
    display: none;
  }

  .check-in-tab-left-decor {
    width: 7.75px;
    height: 53px;
  }

  .check-in-tab-left-decor.progress-decor {
    display: block;
    height: 51px;
  }

  .check-in-tab-right-decor {
    margin: 4px 0 0 17px;
  }

  .check-in-tab-completed-ended {
    width: 18px;
    height: 18px;
    margin-left: -5px;
  }
}
