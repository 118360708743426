@import "../../../src/variables";

.generic-button-container {
  position: relative;
  height: 39px;
  width: 100%;
}

.generic-button-border {
  background-color: $white;
  height: 39px;
  width: 100%;
  padding: 0 6px;
  clip-path: polygon(6px 0, 0 6px, 0 calc(100% - 6px), 6px 100%, calc(100% - 6px) 100%, 100% calc(100% - 6px), 100% 6px, calc(100% - 6px) 0);
}

.generic-button-bordered {
  position: absolute;
  top: 1px;
  left: 1px;
  width: calc(100% - 2px) !important;
}

.button{
  background-color: $pink;
  line-height: 37px;
  height: 37px;
  width: 200px;
  padding: 0 6px;
  cursor: pointer;
  transition: all .2s ease;
  clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);

  &.disabled{
    background-color: $disable-gray;
    cursor: none;
    pointer-events: none;
  }
}

.button-text{
  position: relative;
  color: $white;
  font-family: $oswald;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 37px;
  height: 37px;
  width: 100%;
  text-align: center;
  padding: 0 6px;
  cursor: pointer;
  transition: all .2s ease;
}
.button:hover{
  transform: scale(1.1);
}
