.code-panel{
    margin-top: 15px;
}

.code-panel p{
    margin-bottom: 20px;
}

.connect-wallet-button.switch-network{
    position: relative;
    margin-bottom: 0;
}
@media screen and (min-width: 1025px) and (max-width: 1440px) {
    .code-panel{
        margin-top: -30px;
        margin-bottom: 20px;
    }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .code-panel{
        margin-bottom: 110px;
    }
}
@media screen and (min-width: 376px) and (max-width: 768px) {
    .code-panel{
        margin-bottom: 11.068vw;
        padding: 6.250vw 0;
        margin-top: -4.818vw;
    }
    .code-panel p{
        font-size: 2.083vw;
        margin-bottom: 2.083vw;
    }
    .connect-wallet-button.switch-network{
        height: 4.818vw;
        font-size: 2.083vw;
        margin-top: 2.083vw;
        align-items: center;

    }
    .connect-wallet-button.switch-network span{
        position: relative;
    }
}
@media screen and (max-width: 375px){
    .code-panel{
        max-width: 91.467vw;
        min-width: 91.467vw;
        padding: 12.800vw 0;
        margin-bottom: 155px;
    }
    .code-panel p{
        font-size: 3.733vw;
    }
    .connect-wallet-button.switch-network span{
        font-size: 3.733vw;
    }
}
