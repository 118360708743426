@import "../../../variables";
.dropdown-button-container {
  height: 300px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  transition: 0.4s;
  max-width: 768px;
}
.dropdown-button-container-mobile {
  width: 100%;
  height: 204px;
  transition: 0.4s;
  max-width: 360px;
}

.dropdown-button-container:hover{
  color:$pink;
}

.dropdown-button-content {
  height: 100%;
  cursor: pointer;
}

.dropdown-button-text {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.episode-name {
  margin: 0px;
  font-family: Oswald;
  font-size: 24px;
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.episode-img-container {
  width: 100%;
  height: 260px;
  border: 1px solid $dark-gray-border;
  border-left: 3px solid $dark-gray-border;
}

.episode-img-container-mobile{
@extend .episode-img-container;
  height: 174px;
}

.episode-description-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  &-set-img-border{
    @extend .episode-description-img;
    border: 1px solid $pink;
  }

}

.dropdown-button-container-on {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.dropdown-button-container-on-content {
  height: auto;
  cursor: pointer;
}
.dropdown-button-container-on-content-mobile{
  @extend .dropdown-button-container-on-content;
  max-width: 375px ;
}

.selected-episode-design {
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 32px;
  background-color: $banner-background;
  clip-path: polygon(93% 0px, 100% 15%, 100% 100%, 0px 100%, 0px 0px);
}

.selected-episode-design-mobile{
  @extend .selected-episode-design;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 28px;
}

.selected-dropdown-button-text {
  @extend .dropdown-button-text;
  width: 96%;
  height: 60px;
}
.selected-dropdown-button-text-mobile{
  @extend .dropdown-button-text;
  height: 40px;
  width: 94%;
}

.line-separator {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.line-separator-design {
  width: 100%;
  height: 7px;
  background-color: $pink;
  clip-path: polygon(0 100%, 1% 0, 5% 0, 6% 50%, 100% 50%, 100% 100%);
}
.selected-episode-img-design{
  margin-top: 14px;
  width: 100%;
  height: 100%;
}
.selected-episode-img-design-mobile{
  @extend .selected-episode-img-design;
  margin-top: 0px;
}
.selected-episode-text-design{
  margin-top:10px;
}
.selected-episode-text-design-mobile{
  @extend .selected-episode-text-design;
  max-width: 305px;
}
.missing-episode-name{
  @extend .episode-name;
  color: $very-light-gray;
}
.missing-episode-text{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $pink;

}
.disable-cursor{
  cursor: auto;
}
.made-transparent{
  opacity: 0.0;
}
.show-img-border{
  border-left: 2px solid $pink;
}

@media screen  and (min-width: 768px)  and (max-width: 1023px){
  .dropdown-button-container {
    max-width: 636px;
  }
  .dropdown-button-container-on-content{
    max-width: 636px;
  }
  .episode-description-img-set-img-border{
    max-height: 300px;
  }
  .selected-episode-text-design{
    max-width: 636px;
  }
}
@media screen  and (min-width: 1024px){
  .dropdown-button-container-on-content{
    max-width: 725px;
  }
  .selected-episode-text-design{
    max-width: 725px;
  }
}


@media screen  and (max-width: 767px){
  .dropdown-button-container-on{
    align-items: center;
  }
}

