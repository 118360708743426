@import "../../../variables";

.planet-minting-popup-container {

  .planet-minting-popup-border-icon-small {
    display: none;
  }

  .planet-minting-popup-title-catalyst {
    font-size: 2.25rem;
    font-family: $sofia;
    font-weight: 400;
    width: 653.19px;
    text-align: center;
    margin-bottom: 60px;
    margin-top: 70px;
  }

  .planet-minting-popup-list {
    margin-bottom: 15px;
    display: flex;

    .generative-planet-component {
      margin: 0;
      position: relative;

      .generative-planet-border {
        width: 137px;
        height: 136.35px;
        background-image: url("../../../assets/images/GenerativePlanetMinting/planet-mintpass-border-popup.svg");
        margin-right: 10px;

        .generative-planet-component-image {
          width: 50px;
          height: 50px;

          &.random {
            width: 76px;
            height: 76px;
            margin-bottom: 33px;

            &.none{
              opacity: 0.5;
              margin-bottom: 27px;
            }
          }
        }
      }

      .generative-planet-component-stock {
        display: none;
      }

      .generative-planet-component-title {
        margin-bottom: 0;
        margin-top: 20px;
        font-size: 1.25rem;
        width: 130px;
        height: 60px;
        text-align: center;
        margin-left: -11px;
      }

      .generative-planet-component-title.disabled-trait-name {
        opacity: 0.5;
      }

      .generative-trait-title {
        opacity: 0.8;
        position: absolute;
        bottom: 70px;
        margin-right: 10px;
        width: 80%;
        text-align: center;
        font-family: $oswald;
        font-size: 0.96rem;
      }

      .generative-trait-title.disabled-trait-name {
        opacity: 0.5;
      }

      .generative-trait-title-invisible {
        display: none;
      }
    }
  }

  .planet-minting-popup-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;

    .generative-planet-component {
      margin: 0 20px;

      .generative-planet-border {
        width: 149px;
        height: 136.35px;

        .generative-planet-component-image {
          width: 107.5px;
          height: 107.5px;

          &.random {
            width: 76px;
            height: 76px;
          }
        }
      }

      .generative-planet-component-stock {
        margin: 35px 0 0 0;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .planet-minting-popup-container {

    .planet-minting-popup-border-icon {
      width: 49%;
    }

    .planet-minting-popup-title-catalyst {
      font-size: clamp(1.5625rem, 2.500vw, 2.25rem);
      width: 45.347vw;
      margin-bottom: 4.167vw;
      margin-top: 4.861vw;
    }

    .planet-minting-popup-list {
      margin-bottom: 1.042vw;

      .generative-planet-component {

        .generative-planet-border {
          width: 9.514vw;
          height: 9.469vw;
          margin-right: 0.694vw;
          margin-bottom: -1.7vw;

          .generative-planet-component-image {

            &.random {
              width: 5.278vw;
              height: 5.278vw;
              margin-bottom: 2.5vw;
            }
          }

        }

        .generative-planet-component-title {
          margin-top: 1.5vw;
          font-size: clamp(0.82rem, 1.389vw, 1.25rem);
          width: 9.028vw;
          height: 4.167vw;
          margin-left: -8px;
        }

        .generative-trait-title {
          bottom: 4.9vw;
          margin-right: 0.694vw;
          font-size: clamp(0.68rem, 1.076vw, 0.96rem);
        }

        @supports (-webkit-touch-callout: none) {
          .generative-trait-title.disabled-trait-name {
            margin-left: -0.4vw;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .planet-minting-popup-container {

    .planet-minting-popup-title-catalyst {
      font-size: clamp(1.25rem, 2.637vw, 1.6875rem);
      line-height: 2.930vw;
      margin-bottom: 4vw;
      margin-top: 4vw;
    }

    .planet-minting-popup-list {
      margin-bottom: 2vw;

      .generative-planet-component {

        .generative-planet-border {
          width: 10.082vw;
          height: 11vw;
          margin-right: 0.879vw;
          margin-bottom: -2.3vw;

          .generative-planet-component-image {

            &.random {
              width: 5.566vw;
              height: 5.566vw;
              margin-bottom: 1.223vw;

              &.none{
                margin-bottom: 1.223vw;
              }
            }
          }
        }

        .generative-planet-component-title {
          margin-top: 1.7vw;
          font-size: clamp(0.5rem, 1.611vw, 1.03rem);
        }

        .generative-trait-title {
          bottom: 5vw;
          margin-right: 0.694vw;
          font-size: clamp(0.4rem, 1.076vw, 0.96rem);
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .planet-minting-popup-container {

    .planet-minting-popup-border-icon {
      display: none;
    }

    .planet-minting-popup-border-icon-small {
      display: block;
      width: 49%;
    }

    .planet-minting-popup-title-catalyst {
      font-size: clamp(0.68rem, 3.255vw, 1.5625rem);
      line-height: 3.906vw;
      margin-bottom: 3.906vw;
      margin-top: 3.906vw;
      width: 58.594vw;
    }

    .planet-minting-popup-list {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(auto-fit, minmax(33%, 33%));
      margin-bottom: 6vw;
      margin-left: 2vw;

      .generative-planet-component {

        @supports (-webkit-touch-callout: none) {
          margin: -22px 2.083vw;
        }

        margin: 0 1.302vw;

        .generative-planet-border {
          width: 15.021vw;
          height: 14.063vw;
          margin-bottom: -3vw;

          .generative-planet-component-image {
            top: 1.3vw;
            margin-bottom: 2.734vw;

            &.random {
              width: 7.943vw;
              height: 7.943vw;
              margin-bottom: 4.523vw;

              &.none {
                margin-bottom: 4vw;
              }
            }
          }
        }

        .generative-planet-component-title {
          margin-top: 2.5vw;
          font-size: clamp(0.5rem, 2.279vw, 1.09rem);
          width: 13vw;
          height: 7vw;
          margin-left: -5px;
        }

        .generative-trait-title {
          bottom: 7.7vw;
          margin-right: 0.694vw;
          font-size: clamp(0.375rem, 1.628vw, 0.78125rem);

          @supports (-webkit-touch-callout: none) {
            margin-left: 0.4vw;
          }
        }

        @supports (-webkit-touch-callout: none) {
          .generative-trait-title.disabled-trait-name {
            margin-left: 0.5vw;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .planet-minting-popup-container {

    .planet-minting-popup-title-catalyst {
      font-size: clamp(0.5rem, 5.333vw, 1.25rem);
      line-height: 6.400vw;
      margin-bottom: 4vw;
      margin-top: 6vw;
      width: 95%;
    }

    .planet-minting-popup-list {
      margin-bottom: 9.067vw;
      margin-left: 8vw;

      .generative-planet-component {
        margin: 0 2vw;

        .generative-planet-border {
          width: 23.333vw;
          height: 24.933vw;
          margin-bottom: -4vw;
          margin-top: 0;

          .generative-planet-component-image {
            top: 1vw;

            &.random {
              width: 13.333vw;
              height: 13.333vw;
              margin-bottom: 5.3vw;

              &.none {
                margin-bottom: 4.5vw;
              }
            }
          }
        }

        .generative-planet-component-title {
          margin-top: 2vw;
          font-size: clamp(0.5rem, 3.733vw, 0.875rem);
          width: 20vw;
          height: 11vw;
          margin-left: -3px;
        }

        .generative-trait-title {
          bottom: 12.5vw;
          margin-right: 0.694vw;
          font-size: clamp(0.375rem, 2.667vw, 0.625rem);
        }

        @supports (-webkit-touch-callout: none) {
          .generative-trait-title.disabled-trait-name {
            margin-left: -0.4vw;
          }
        }
      }
    }
  }
}
