@import "../../../../variables";

.token-image-container {
  margin-bottom: 2.089rem;
  width: 100%;

  img {
    height: auto;
    width: 100%;
  }
}

