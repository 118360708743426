@import "../../variables";

.planet-resource-list {
  display: flex;
  list-style: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-family: $oswald-light;
  background-color: $black-night-bg;

  [class^="pg-icon-"], [class*=" pg-icon-"] {
    padding-left: 0.5rem;
    font-size: 1.5rem;
  }
}


