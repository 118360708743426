@import '../../../variables';

.planet-catalyst {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 38px;

  @media only screen and (min-width: 1441px) and (max-width: 1920px) {
    margin: 0 1.979vw;
  }

  @media screen and (min-width: 1025px) and (max-width: 1440px) {
    margin: 0 1.389vw;
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    margin: 0 0.977vw;
  }

  @media screen and (min-width: 376px) and (max-width: 768px) {
    margin: 0;
  }

  @media screen and (max-device-width: 375px) {
    margin: 0;
  }

  .planet-catalyst-border.selected {
    background-image: url("../../../assets/images/GenerativePlanetMinting/planet-mintpass-border-clickable.svg");
  }

  .planet-catalyst-border.clickable:hover {
    background-image: url("../../../assets/images/GenerativePlanetMinting/planet-mintpass-border-hover.svg");
  }

  .planet-catalyst-border {
    width: 180px;
    height: 200px;
    background-image: url("../../../assets/images/GenerativePlanetMinting/planet-mintpass-border.svg");
    background-position: center;
    background-size: 90% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media only screen and (min-width: 1441px) and (max-width: 1920px) {
      width: 180px;
      height: 200px;
    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
      width: 140px;
      height: 18.056vw;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
      width: 110px;
      height: 17.090vw;
    }
    @media screen and (min-width: 376px) and (max-width: 768px) {
      width: 168px;
      height: 175px;
    }
    @media screen and (max-device-width: 375px) {
      width: 18.667vw;
      height: 22.667vw;
    }

    .catalyst-image,
    .catalyst-image.disabled-icon{
      width: 50px;

      @media screen and (min-width: 1441px) and (max-width: 1920px) {
        width: 2.604vw;
      }
      @media screen and (min-width: 769px) and (max-width: 1024px) {
        width: 3.516vw;
      }
      @media screen and (min-width: 376px) and (max-width: 768px) {
        width: 26px;
      }
      @media screen and (max-device-width: 375px) {
        width: 6.667vw;
      }
    }

    .catalyst-trait-image {
      width: 113.43px;
      margin-bottom: 21px;
      position: relative;
      top: 8px;
      @media screen and (min-width: 1441px) and (max-width: 1920px) {
        width: 113.43px;
        margin-bottom: 1.094vw;
        top: 0.417vw;
      }
      @media screen and (min-width: 1025px) and (max-width: 1440px) {
        width: 85px;
      }
      @media screen and (min-width: 769px) and (max-width: 1024px) {
        width: 63px;
        margin-bottom: 0.488vw;
        top: unset;
      }
      @media screen and (min-width: 376px) and (max-width: 768px) {
        width: 63px;
      }
      @media screen and (max-device-width: 375px) {
        width: 38.65px;
        top: 2.400vw;
      }
    }

    .catalyst-image.disabled-image,
    .catalyst-trait-image.disabled-image {
      filter: opacity(30%);
    }
  }

  .planet-catalyst-border.disabled-cursor {
    cursor: initial;
  }

  .planet-catalyst-title {
    font-size: 1.75rem;
    font-family: $oswald;
    font-weight: 400;
    color: white;
    cursor: pointer;

    @media only screen and (min-width: 1441px) and (max-width: 1920px) {
      font-size: clamp(1.3125rem, 1.458vw, 1.75rem);
    }
    @media screen and (min-width: 1025px) and (max-width: 1440px) {
      font-size: 1.375rem;
    }
    @media screen and (min-width: 769px) and (max-width: 1024px) {
      font-size: 1rem;
    }
    @media screen and (min-width: 376px) and (max-width: 768px) {
      font-size: 1rem;
    }
    @media screen and (max-device-width: 375px) {
      font-size: clamp(0.5rem, 3.733vw, 0.875rem);
      width: 17.600vw;
      text-align: center;
      height: 8.533vw;
    }
  }

  .planet-catalyst-title.disabled-catalyst {
    opacity: 0.3;
    cursor: initial;
  }
}
