@import '../../../variables';

.other-title{
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.other-title .page-header{
  margin-top: 45px;
  padding-bottom: 0;
}

.claim-all-button {
  width: 320px;
  height: 55px;
  margin-bottom: 58px;
}

.claim-other-main-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.other-nft-container.mobile{
  display: none;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .other-title .page-header{
    margin-top: 9px;
  }
  .claim-all-button {
    width: 242px;
    height: 43px;
    margin-bottom: 64px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .other-title .psg-border-img-mini-screen{
    width: 100%;
  }
  .other-title .page-mobile-header-text-no-margin{
    font-size: 3.613vw;
  }
  .other-title .page-header {
    height: 22.461vw;
    margin-bottom: 8.789vw;
  }
  .claim-all-button {
    width: 19.629vw;
    height: 3.516vw;
    margin-bottom: 5.176vw;
    margin-top: 0;
  }
  .claim-all-button .claim-rewards-button-container,
  .claim-all-button .claim-button-label {
    font-size: 1.855vw !important;
    line-height: 2.734vw;
  }
  }

@media screen and (min-width: 376px) and (max-width: 768px) {
  .other-title .page-header {
    height: 26.042vw;
    margin-bottom: 0;
  }
  .claim-all-button {
    width: 24.740vw;
    height: 4.427vw;
    margin-bottom: 6.771vw;
    margin-top: 4.688vw;
  }

}

@media screen and (max-width: 375px)  {
  .other-title .page-header {
    height: 42.667vw;
    margin-bottom: 9.067vw;
    margin-top: 8.267vw;
  }
  .claim-all-button {
    width: 43.200vw;
    height: 8vw;
    margin-bottom: 9.867vw;
    margin-top: 0;
  }
  .other-nft-container.mobile {
    display: block !important;
  }
  .other-nft-container{
    display: none !important;
  }


  }


