@import "../../../variables";

.lock-load-maine-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.lock-load-title{
  font-family: $oswald;
  font-size: 82px;
  line-height: 78px;
  background: #51CBFF;
  background: linear-gradient(180deg, #FFFFFF 0%, #506f7e 65%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.lock-load-content{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 80px;
}

.asteroid-onboarding{
  margin-right: 30px;
  width: 217px;
  height: 174px;
}

.pirateship-onboarding{
  width: 416px;
  height: 234px;
}

.lock-load-button-container{
  width: 109px;
  height: 47px;
}

.mine-content,
.destroy-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mine-content{
  margin-right: 15px;
}

.mine-content .lock-load-button-container{
  position: relative;
  top: 29px;
  left: 0;
}
.destroy-content .lock-load-button-container{
  position: relative;
  right: 23px;
}

.lock-load-description{
  font-family: $oswald;
  font-size: 20px;
  line-height: 24px;
  margin-top: 61px;
  width: 691px;
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .lock-load-title {
    font-size: 6.738vw;
    line-height: 6.348vw;
    margin-top: 3.711vw;
  }
  .lock-load-content {
    margin-left: calc(8.789vw + 1rem);
    margin-top: 0.684vw;
  }
  .asteroid-onboarding {
    width: 16.992vw !important;
    height: 13.672vw;
  }
  .pirateship-onboarding {
    width: 32.617vw !important;
    height: 18.262vw;
  }
  .lock-load-button-container {
    width: 9.766vw;
  }
  .mine-content{
    margin-right: 0;
  }
  .destroy-content{
    margin-right: 2.441vw;
  }

  .mine-content .lock-load-button-container{
    top: 2.246vw;
  }
  .destroy-content .lock-load-button-container{
    position: relative;
    right: 2.246vw;
    top: 0;
  }

  .lock-load-description {
    width: 46.289vw;
    font-size: 1.563vw;
    line-height: 1.953vw;
    margin-top: 4.883vw;
  }
  }

@media screen and (min-width: 376px) and (max-width: 768px) {
  .lock-load-title {
    font-size: 8.984vw;
    line-height: 8.464vw;
    margin-top: 4.948vw;
  }
  .lock-load-content {
    margin-left: calc(11.719vw + 1rem);
    margin-top: 0.911vw;
  }
  .asteroid-onboarding {
    width: 22.656vw !important;
    height: 18.229vw;
  }
  .pirateship-onboarding {
    width: 43.490vw !important;
    height: 24.349vw;
  }
  .lock-load-button-container {
    width:13.021vw;
  }

  .mine-content{
    margin-right: 0;
  }
  .destroy-content{
    margin-right: 3.255vw;
  }

  .destroy-content .lock-load-button-container{
    position: relative;
    right: 2.995vw;
    top: 0;
  }

  .mine-content .lock-load-button-container{
    top: 2.995vw;
  }

  .lock-load-description {
    width: 61.719vw;
    font-size: 2.083vw;
    line-height: 2.604vw;
    margin-top: 6.510vw;
  }
}

@media screen and (max-width: 375px) {
  .lock-load-title {
    font-size: 12.800vw;
    line-height: 12vw;
    margin-top: 4.267vw;
  }
  .lock-load-content {
    flex-direction: column;
  }
  .asteroid-onboarding {
    width: 41.067vw !important;
    height:33.067vw ;
  }
  .pirateship-onboarding {
    width: 81.600vw !important;
    height: 45.867vw;
  }
  .lock-load-button-container {
    width:28.267vw;
  }
  .mine-content .lock-load-button-container{
    top: 1.333vw;
  }
  .destroy-content .lock-load-button-container{
    right: 0;
    margin-top: -5.867vw;
  }
  .lock-load-content{
    margin-left: 0;
  }
  .mine-content{
    margin-right: 0;
  }
  .destroy-content{
    margin-right: 0;
  }
  .lock-load-description {
    width:74vw;
    font-size: 4.267vw;
    line-height:4.533vw;
    margin-top: 8.533vw;
  }
  }
