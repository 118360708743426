@import "../../../variables";

.salvage-carousel-main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.salvage-carousel-title {
  background: #51CBFF;
  background: linear-gradient(180deg, #f1f5f7 0%, #96afbc 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 82px;
  font-family: $oswald;
  font-weight: 700;
  line-height: 90px;
  margin-bottom: -5px;
  margin-left: 17px;
  letter-spacing: 17px;
}

.salvage-carousel-second-title {
  background: #51CBFF;
  background: linear-gradient(180deg, #8ba5b3 0%, #567180 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 75px;
  font-family: $oswald;
  font-weight: 700;
  line-height: 69px;
}

.salvage-drop-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  width: 100%;
  margin-top: 7px;
}

.adjust-salvage-main-drop-img {
  width: 169px !important;
  margin-top: 20px;
}

.left-parachute {
  position: absolute;
  top: 30px;
  left: 250px;
}

.right-top-small-parachute {
  position: absolute;
  top: 8px;
  right: 320px;
}

.right-bottom-big-parachute {
  position: absolute;
  top: 66px;
  right: 276px;
}

.airdropped-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}

.airdropped-content-container {
  width: 198px;
}

.salvage-container-content-text {
  width: 100%;
  margin-top: 20px;
  padding-right: 72px;
  padding-left: 71px;
  font-family: $oswald;
  font-size: 20px;
}

.break-on-1024 {
  display: none;
}
.salvage-carousel-second-title-mobile{
  display: none;
}
.salvage-carousel-third-title-mobile{
  display: none;
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .salvage-carousel-title {
    font-size: 6.738vw;
    letter-spacing: 1.270vw;
    line-height: 6.348vw;
    margin-bottom: 0;
  }
  .salvage-carousel-second-title {
    font-size: 6.152vw;
    line-height: 6.250vw;
  }
  .adjust-salvage-main-drop-img {
    width: 15.918vw !important;
    height: 15.918vw !important;
  }
  .left-parachute {
    position: absolute;
    top: 2.930vw;
    left: 8.789vw;
  }
  .header-title-salvage-carousel {
    margin-top: 2.539vw;
  }
  .right-top-small-parachute {
    position: absolute;
    top: 1.953vw;
    right: 14.648vw;
  }
  .airdropped-button-container {
    margin-top: 1.660vw;
  }
  .right-bottom-big-parachute {
    position: absolute;
    top: 6.836vw;
    right: 10.352vw;
  }
  .salvage-container-content-text {
    padding-left: initial;
    padding-right: initial;
    font-size: 1.563vw;
  }
  .break-on-1024 {
    display: block;
  }
  .airdropped-content-container {
    width: 12.988vw;
  }
}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .salvage-carousel-title {
    font-size: 8.984vw;
    letter-spacing: 1.693vw;
    line-height: 8.464vw;
    margin-bottom: 0;
    margin-left: 0;
  }
  .salvage-carousel-second-title {
    font-size: 8.333vw;
    line-height: 7.813vw;
  }
  .adjust-salvage-main-drop-img {
    width: 21.224vw !important;
    height: 21.224vw !important;
    margin-top: 2.604vw;
  }
  .left-parachute {
    position: absolute;
    top: 2.930vw;
    left: 10.789vw;
    width: 9.245vw;
  }
  .header-title-salvage-carousel {
    margin-top: 3.385vw;
  }
  .right-top-small-parachute {
    position: absolute;
    top: 1.953vw;
    right: 18.648vw;
    width: 3.604vw;
  }
  .airdropped-button-container {
    margin-top: 2.214vw;
  }
  .right-bottom-big-parachute {
    position: absolute;
    top: 6.836vw;
    right: 14.352vw;
    width: 5.859vw;
  }
  .salvage-container-content-text {
    padding-left: initial;
    padding-right: initial;
    font-size: 2.083vw;
    margin-top: 2.604vw;
  }
  .break-on-1024 {
    display: block;
  }
  .airdropped-content-container {
    width: 17.318vw;
  }
  .salvage-drop-container {
    margin-top: 0.911vw;
  }
}

@media screen and (max-width: 375px) {
  .salvage-carousel-title {
    font-size: 48px;
    line-height: 45px;
    letter-spacing: initial;
    margin-bottom: initial;
    margin-left: 0;
  }
  .salvage-carousel-second-title {
    display: none;
  }
  .salvage-carousel-second-title-mobile {
    background: linear-gradient(180deg, #8ba5b3 0%, #567180 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 44px;
    font-family: $oswald;
    font-weight: 700;
    line-height: 42px;
    letter-spacing:8.5px;
    text-align: center;
    width: 100%;
    margin-left: 5px;
    display: block;
  }
  .salvage-carousel-third-title-mobile {
    background: linear-gradient(180deg, #8ba5b3 0%, #567180 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 44px;
    font-family: $oswald;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 13.5px;
    margin-left: 16px;
    display: block;
  }
  .left-parachute {
    position: absolute;
    top: 0;
    left: 0;
    width: 82px;
  }
  .right-top-small-parachute {
    position: absolute;
    top: 0;
    right: 13px;
    width: 40px;
  }
  .right-bottom-big-parachute {
    position: absolute;
    top: 85px;
    right: -1px;
    width: 65px;
  }
  .adjust-salvage-main-drop-img {
    margin-top: 86px;
  }
  .salvage-container-content-text {
    padding-left: initial;
    padding-right: initial;
    font-size: 16px;
  }
  .airdropped-content-container {
    width: 120px;
  }
  .header-title-salvage-carousel{
    margin-top: 20px;
  }
}
