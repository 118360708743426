@import "../../../../../variables";
.USDC-principel-container{
  width: 115px;
  height: 37px;
  background-color: $pink;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: Oswald;
  clip-path: polygon(8% 0%, 100% 0%, 92% 100%, 0% 100%);
}
.USDC-principel-container-disabled{
  width: 115px;
  height: 37px;
  background-color: $stack;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: Oswald;
  clip-path: polygon(8% 0%, 100% 0%, 92% 100%, 0% 100%);
  pointer-events: none;
}
.USDC-currency-text{
  margin: 0px;
  margin-left: 10px;
  font-size: 16px;
}
