@import "../../../../variables";

.public-sale-main-body{
  width: 100%;
  height: 900px;
  padding-top: 50px;
  clip-path: $main-element-clip-path;
  background: url("../../../../assets/images/PlanetSalePagePrivate/planet-sale-public-bg.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

.public-sale-countdown-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
}

.pgs-public-sale-border-img-container .psg-border-img{
  width: 1350px;
}
