@import "../../variables";

.about-main-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  clip-path: $section-polygon-top-bottom-down-cut-1920;

}

.about-main-container-animation {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  clip-path: polygon(0 0, 50% 6.51vw, 100% 0, 100% calc(100% - 6.51vw), 50% 100%, 0 calc(100% - 6.51vw));
  height: 116.844vw;
  -webkit-transition: height 0.6s linear;
  -moz-transition: height 0.6s linear;
  -ms-transition: height 0.6s linear;
  -o-transition: height 0.6s linear;
  transition: height 0.6s linear;
}

.about-img-background {
  position: absolute;
  z-index: -1;
  object-fit: cover;
}

.about-content-container {
  margin-top: 128px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reveal-info-adjustments {
  margin-bottom: 140px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.reveal-info-adjustments-margin {
  margin-bottom: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.about-title {
  width: 100%;

}

.reveal-info-size-container {
  width: 100%;
  margin-bottom: 101px;
  margin-top: 39px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-title .page-header {
  padding: 0;
  height: 253px;
  margin-bottom: 92px;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .about-main-container {
    clip-path: $section-polygon-top-bottom-down-cut-1024;
  }
  .about-title .psg-border-img-mini-screen {
    width: 1300px;
  }

}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .about-main-container {
    clip-path: $section-polygon-top-bottom-down-cut-768;
    -webkit-transition: height 0.8s linear;
    -moz-transition: height 0.8s linear;
    -ms-transition: height 0.8s linear;
    -o-transition: height 0.8s linear;
    transition: height 0.8s linear;
    height: 76.402vw;
  }
  .about-title .psg-border-img-mobile {
    display: none;
  }
  .about-title .psg-border-img-tablet {
    display: initial;
    width: 73%;
  }
  .about-title .page-mobile-header-text-no-margin {
    font-size: 2.604vw;
  }
  .about-content-container {
    margin-top: 11px;
  }
  .about-title .page-header {
    margin-top: 3.255vw;
    margin-bottom: 0px;
    height: initial;
  }
  .reveal-info-size-container {
    flex-direction: row;
    align-items: flex-start;
    margin-top: 29px;
  }
  .reveal-info-adjustments {
    margin-bottom: 0px;
    margin-right: 8.594vw;
  }
  .reveal-info-adjustments-margin {
    margin-bottom: 0px;
    margin-left: 8.594vw;
  }

}

@media screen and (max-device-width: 375px) {
  .reveal-info-size-container{
    margin-bottom: 16vw;
  }
  .about-main-container {
    display: none;
  }
  .reveal-info-adjustments-margin {

    display: initial;
    flex-direction: initial;
    justify-content: initial;
    align-items: initial;
  }
  .animation-reveal-info-adjustments-margin .reveal-device-text-box{
    padding: 24vw 5.333vw 10.667vw;
  }
  .reveal-info-adjustments{
    display: initial;
    flex-direction: initial;
    justify-content: initial;
    align-items: initial;
  }

}
