@use '/src/variables.scss' as *;
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';

$images-path: '../assets/images/';

.compensation {
  position: relative;
  max-width: 440px;
}

.compensation p {
  padding: 5px 10px;
  background-color: #000000;
  text-align: center;
}

.compensation p.spacer {
  background-color: transparent;
  font-size: x-large;
}

.compensation p.spacer:after {
  content: "+\00a0\00a0\00a0\00a0\00a0+\00a0\00a0\00a0\00a0\00a0+\00a0\00a0\00a0\00a0\00a0+"
}

img.compensation-page-bg {
  position: absolute;
  padding: 0;
  height: 100%;
  z-index: -10;
  max-height: 1900px;
  object-fit: cover;
}

.compensation .form-panel {
  background-color: #00000099;
  margin-top: 60px;
}

.compensation h3 {
  text-transform: uppercase;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.compensation .form-panel .border-top {
  border-top: none !important;
  position: relative;
}

.compensation .form-panel .border-top:before {
  content: "";
  background: url(#{$images-path}panel-border-top-left.png) top left no-repeat;
  display: inline-block;
  height: 15px;
  width: 210px;
  position: absolute;
  left: 0;
  top: -15px;
}

.compensation .form-panel .border-top:after {
  content: "";
  background: url(#{$images-path}panel-border-top-right.png) top left no-repeat;
  display: inline-block;
  height: 15px;
  width: 210px;
  position: absolute;
  right: 0;
  top: -15px;
}

.compensation .form-panel .border-bottom {
  border-bottom: none !important;
}

.compensation .form-panel .border-bottom:before {
  content: "";
  background: url(#{$images-path}panel-border-bottom-left.png) bottom left no-repeat;
  display: inline-block;
  height: 15px;
  width: 210px;
  position: absolute;
  left: 0;
  bottom: -15px;
}

.compensation .form-panel .border-bottom:after {
  content: "";
  background: url(#{$images-path}panel-border-bottom-right.png) bottom left no-repeat;
  display: inline-block;
  height: 15px;
  width: 210px;
  position: absolute;
  right: 0;
  bottom: -15px;
}

.compensation .form-panel form {
  padding: 0 60px;
}

.compensation .form-panel input[type=email] {
  border-radius: 0;
  transform: skewX(-15deg);
  padding-left: 20px;
  padding-right: 20px;
}

.compensation .form-panel label {
  cursor: pointer;
}

.compensation .form-panel input[type=radio] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.compensation .form-panel input[type=radio] + label:before {
  content: "";
  display: inline-block;
  height: 25px;
  width: 18px;
  vertical-align: middle;
  margin: 6px 20px 10px 10px;
}

.compensation .form-panel input[type=radio]:checked + label:before {
  background: url(#{$images-path}radio-checked.png) center left no-repeat;
}

.compensation .form-panel input[type=radio]:not(:checked) + label:before {
  background: url(#{$images-path}radio-unchecked.png) center left no-repeat;
}

.compensation .form-panel .submit {
  padding-top: 30px;
  padding-bottom: 50px !important;
  text-align: center;
}

.compensation .form-panel .button {
  padding: 0 50px;
  border-radius: 0;
  border: 0;
  font-size: large;
}

.compensation .form-panel .button:hover, .compensation .form-panel .button:active, .compensation .form-panel .button:focus {
  color: #0a58ca;
  background-color: #d42758;
  box-shadow: none;
}

.compensation-man img {
  //background: url(.././images/compensation-man.png) center center no-repeat;
  //height: 976px;
  //width: 740px;
  //margin-top: -190px;
  //margin-bottom: -10px;
  //margin-left: -40px;
  width: 100%;
  max-width: 100%;
}

@include media-breakpoint-up(lg) {
  .compensation-man img {
    margin-top: -280px;
  }
}
