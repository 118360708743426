@import "../../../../variables";
.converting-poster-modal-img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 117px;
  height: $banner-height;
}
.converting-poster-modal-flame{
  width: 26px;
}
.converting-poster-modal-flame-dimension {
  width: 22px;
  height: 26px;
}
.converting-poster-modal-right-arrows {
  width: 46px;
  height: 45px;
  image-rendering: pixelated;
}
.converting-poster-modal-arrow-dimension {
  width: 100%;
  height: 100%;
}
