@import "../../variables";

.license-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.license-content .page-mobile-header-text-no-margin{
  margin-top: 40px;
}


.license-text-container{
  margin: -69px 156px 0 156px;
}

.license-list-1 li:nth-child(2){
  margin-top: 12px;
}

.license-sublist-alphabetic {
  list-style-type: lower-alpha;
}

.license-bold {
  font-weight: 600;
}

.license-sublist-alphabetic li:before{
  display: none;
}

.license-sublist-romans {
  list-style-type: lower-roman;
}

.license-sublist-romans li:before {
  display: none;
}

.break-between-paragraphs{
  display: block;
  margin: 30px 0;
  content: "";
}

.break-between-chapters{
  display: block;
  margin: 60px 0;
  content: "";
}

.license-text-container,
.license-text-container li{
  font-family: $sofia;
  font-size: 20px;
  line-height: 30px;
}

.license-text-container .license-header{
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 0;
}

.license-accept-container{
  width: 505px;
  height: 127px;
  border-top: 1px solid $white;
  border-bottom: 1px solid $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 100px;
}

.license-accept-decoration-top{
  position: absolute;
  top: -34px;
}

.license-accept-decoration-bottom{
  position: absolute;
  bottom: -34px;
}

.license-accept-button{
  font-family: $oswald;
  font-size: 18px;
  width: 193px;
  height: 37px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background-color: $pink;
  -webkit-clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
  clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
  cursor: pointer;
}

.license-signed-text{
  font-family: $sofia;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 0;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .license-text-container{
    margin: -69px 80px 0 80px;
  }

  .license-content .page-header {
    height: 400px;
    overflow: hidden;
  }

  .license-content .page-header .psg-border-img-pc{
    width: 100%;
    height: 400px;
    object-fit: cover;
  }

}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .license-text-container{
    margin: -69px 78px 0 78px;
  }
  .license-text-container,
  .license-text-container li{
    font-size: 18px;
    line-height: 27px;
  }
  .license-content .page-header .psg-border-img-mini-screen {
    display: none;
  }
  .license-content .page-header {
    height: 400px;
    overflow: hidden;
    margin-top: 74px;
  }

  .license-content .page-header .psg-border-img-pc{
    display: flex;
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  .license-text-container .license-header{
    font-size: 22px;
    line-height: 33px;
  }
  .license-accept-container{
    width: 458px;
    height: 116px;
    margin-top: 100px;
  }
  .license-accept-button{
    font-size: 16px;
    width: 175px;
    height: 33px;
    -webkit-clip-path: polygon(5px 0, 0 5px, 0 calc(100% - 5px), 5px 100%, calc(100% - 5px) 100%, 100% calc(100% - 5px), 100% 5px, calc(100% - 5px) 0);
    clip-path: polygon(5px 0, 0 5px, 0 calc(100% - 5px), 5px 100%, calc(100% - 5px) 100%, 100% calc(100% - 5px), 100% 5px, calc(100% - 5px) 0);
  }
  .license-accept-decoration-top,
  .license-accept-decoration-bottom{
    width: 60px;
    height: 30px;
  }
  .license-accept-decoration-top{
    top: -31px;
  }

  .license-accept-decoration-bottom{
    bottom: -31px;
  }
  .break-between-paragraphs {
    margin: 26px 0;
  }
  .break-between-chapters {
    margin: 52px 0;
  }
}




@media screen and (min-width: 376px) and (max-width: 768px) {
  .license-content .page-mobile-header-text-no-margin{
    margin-top: 0px;
    font-size: 31px;
  }
  .license-content .page-header .psg-border-img-tablet {
    display: none;
  }
  .license-content .page-header {
    height: 400px;
    overflow: hidden;
    margin-top: -10px;
    padding-bottom: 13px;
    font-size: 31px;
  }

  .license-content .page-header .psg-border-img-pc{
    display: flex;
    width: 100%;
    height: 280px;
    object-fit: cover;
  }
  .license-text-container{
    margin: -69px 50px 0 50px;
  }
  .license-text-container,
  .license-text-container li{
    font-size: 16px;
    line-height: 24px;
  }

  .license-text-container .license-header{
    font-size: 18px;
    line-height: 27px;
  }
  .license-accept-container{
    width: 53.516vw;
    height: 13.542vw;
    margin-top: 50px;
    margin-bottom: 10px;
  }
  .license-accept-button{
    font-size: 14px;
    width: 157px;
    height: 30px;
    -webkit-clip-path: polygon(5px 0, 0 5px, 0 calc(100% - 5px), 5px 100%, calc(100% - 5px) 100%, 100% calc(100% - 5px), 100% 5px, calc(100% - 5px) 0);
    clip-path: polygon(5px 0, 0 5px, 0 calc(100% - 5px), 5px 100%, calc(100% - 5px) 100%, 100% calc(100% - 5px), 100% 5px, calc(100% - 5px) 0);
  }
  .license-accept-decoration-top,
  .license-accept-decoration-bottom{
    width: 54px;
    height: 27px;
  }
  .license-accept-decoration-top{
    top: -28px;
  }
  .license-accept-decoration-bottom{
    bottom: -28px;
  }
  .break-between-paragraphs {
    margin: 22px 0;
  }
  .break-between-chapters {
    margin: 46px 0;
  }
  .license-signed-text{
    font-size: 2.344vw;
    line-height: 2.344vw;
  }
}

@media screen and (max-width: 375px) {
  .license-content .page-mobile-header-text-no-margin{
    margin-top: 0px;
  }
  .license-text-container{
    margin: -80px 26px 0 26px;
  }
  .license-text-container,
  .license-text-container li{
    font-size: 14px;
    line-height: 21px;
  }

  .license-text-container .license-header{
    font-size: 16px;
    line-height: 24px;
  }
  .license-accept-container{
    width: 92vw;
    height: 87px;
    margin-top: 38px;
    margin-bottom: 45px;
  }
  .license-accept-button{
    font-size: 12px;
    width: 131px;
    height: 25px;
    -webkit-clip-path: polygon(5px 0, 0 5px, 0 calc(100% - 5px), 5px 100%, calc(100% - 5px) 100%, 100% calc(100% - 5px), 100% 5px, calc(100% - 5px) 0);
    clip-path: polygon(5px 0, 0 5px, 0 calc(100% - 5px), 5px 100%, calc(100% - 5px) 100%, 100% calc(100% - 5px), 100% 5px, calc(100% - 5px) 0);
  }
  .license-accept-decoration-top,
  .license-accept-decoration-bottom{
    width: 45px;
    height: 23px;
  }
  .license-accept-decoration-top{
    top: -24px;
  }
  .license-accept-decoration-bottom{
    bottom: -24px;
  }
  .break-between-paragraphs {
    margin: 18px 0;
  }
  .break-between-chapters {
    margin: 40px 0;
  }
  .license-content .page-header {
    margin-top: -63px;
    padding-bottom: 0px;
  }
  .license-content .page-header .psg-border-img-mobile {
    display: none;
  }
  .license-content .page-header {
    height: 400px;
    overflow: hidden;
  }

  .license-content .page-header .psg-border-img-pc{
    display: flex;
    width: 100%;
    height: 66.667vw;
    object-fit: cover;
  }
  .license-content .page-mobile-header-text-no-margin{
    margin-top: 3.733vw;
  }
}

.license-ordered-list-upper-alpha {
  list-style-type: upper-alpha;
  li:before {
    content: '';
  }
}
