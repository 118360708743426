@import "../../../../../variables";
.icon-element-img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: $banner-height;
}
.icon-element-container{
  width: 26px;
}
.icon-element-dimension {
  width: auto;
  height: auto;

}
