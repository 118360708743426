.adjustpop{
  display: block;
  position: absolute;
  width: 100%;
  height: 38px;
  bottom: 0
}
.img-ovelay {
  background: black;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  clip-path: polygon(85% 0px, 100% 45%, 100% 100%, 0px 100%, 0px 0px)
}

.img-overlay-text{
  padding-left: 10px;
  padding-top: 6px;
}
