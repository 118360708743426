@import "../../../variables";

.planers-filter-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 90px;
  margin-bottom: 60px;
  @media only screen and (max-width: 1920px) {
    margin-top: 4.688vw;
    margin-bottom: 3.125vw;
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    margin-bottom: 50px;
  }
  @media screen and (max-width: 768px) and (min-width: 376px) {
    margin-bottom: 85px;
  }
  @media screen and (max-device-width: 375px) {
    margin-bottom: 40px;
  }
}

.planets-filter-buttons-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

}
