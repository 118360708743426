@import "../../../../variables";

.polygon-border{
  position: relative;
  clip-path: polygon(12% 0%, 0 12%, 0 100%, 88% 100%, 100% 88%, 100% 0%);
  width: 15%;
  height: 59.6%;
  border:0px;
  margin: 0px;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.medal-drow-background{
  position: relative;
  clip-path: polygon(12% 0%, 0 12%, 0 100%, 88% 100%, 100% 88%, 100% 0%);
  width: 163px;
  height: $polygon-height;
  background-color: $ocean-blue;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.medal-content-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 14px;
}

.medal-text-adjustment{
  line-height: 15px;
  font-size: 16px;
  text-align: center;
}

.medal-img-dimension{
  width: 120px;
  height: auto;
  border: transparent;
  outline: none;
  image-rendering: pixelated;
}
