@import "../../../variables";

$dialog-height: 629px;
$dialog-width: 1108px;

.nft-boost-dialog-content {
  height: 80vh;
  width: $dialog-width;
  max-width: initial;
  border: 0px;
}

.cem-header-horn {
  position: absolute;
  top: -18px;
  z-index: 1;
  width: 100%;
}

.claiming-episode-main-modal {
  display: flex;
  flex-direction: column;
  border: red;
}

.claiming-episode-modal-header {
  margin-top: 68px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 841.45px;
  height: 107.91px;
  margin-left: auto;
  margin-right: auto;
}

.claiming-episode-modal-header-text {
  text-align: center;
  font-size: 18px;
}

.claiming-episode-modal-header-text-subtext {
  margin-top: 16px;
  margin-bottom: 0;
}


.claiming-episode-modal-body {
  width: 100%;
  height: 280px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.cem-banner{
  position: absolute;
  width: 1063px;
  height: $banner-height;
}
.claiming-episode-modal-footer {
margin-bottom: 52px;
}

.modal-content {
  border-radius: 0;
}

.modal {
  padding-right: 0 !important;
}

.red-linear-shape-up-container {
  height: 50px;
  width: 100%;
  background: transparent;
  display: flex;
  flex-direction: row;

  .linear-shape-up {
    height: 100%;
    width: 14px;
    background: red;
    transform: skew(-60deg);
  }
}

.claiming-test {
  width: 100%;
  height: 106%;
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  position: absolute;
  bottom: -22px;
  background: url("../../../assets/images/vectors/primary_info_square_1.png");
}

.claiming-episode-close-button {
  position: absolute;
  width: 23px;
  height: 23px;
  right: 22px;
  top: 21px;
  cursor: pointer;
}

.claiming-episode-modal-footer-buttons {
  margin-top: 38.54px;
  display: flex;
  flex-direction: row;
  width: 60%;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
}

.claiming-episode-modal-tilted-button {
  width: 228.1px;
  height: 47.53px;
  cursor: pointer;
}

.cem-bottem-horn {
  position: absolute;
  z-index: 1;
  width: 100%;
  bottom: -20px;
}
.adjust-confirmation-text-size{
  font-size: 20px !important;
}
