@import "../../variables";

.base-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
  background-color: $pink-button-border;
  font-family: inherit;
  font-size: inherit;
  color: $white;

  width: 100%;
  height: 100%;
}

.base-button.scale {
  transition: transform 0.2s ease-in-out;
}

.base-button-not-filled,
.base-button-filled {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
}

.base-button-not-filled {
  background-color: $black;
}

.base-button-filled {
  background-color: $pink;
}

@media (hover: hover) {
  .base-button.no-scale:hover {
    color: rgba(255, 255, 255, 0.5);
  }
}


.base-button.scale:hover {
  transform: scale(1.1);
}

.base-button.disabled {
  pointer-events: none;
  cursor: default;
  background-color: $stack;
}
