@import "../../../variables";

.error-modal-parent-default-error {
  border: 0;
  height: 400px;
  width: 378.34px;

  .error-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 10px;
  }

  .error-modal-exclamation-icon {
    width: 50px;
  }

  .error-modal-header-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: right;
  }


  .error-modal-header-horns {
    position: absolute;
    top: -7px;
    z-index: 1;
    width: 100%;
  }

  .error-modal-header-horns-bottom {
    position: absolute;
    bottom: -9px;
    z-index: 1;
    width: 100%;
  }

  .error-modal-close-button {
    position: absolute;
    right: 10px;
    top: 8px;
    cursor: pointer;
  }

  .error-modal-title {
    text-align: center;
    font-size: 16px;
    line-height: 25px;
    font-family: $sofia;
    margin-top: 40px;
  }

  .error-modal-text {
    text-align: center;
    font-size: 16px;
    line-height: 25px;
    font-family: $sofia;
    margin-top: 8px;
  }

  .button.error-modal-button {
    margin-top: 24px;
  }
}

.modal-dialog {
  margin: 1.75rem auto !important;
}
.modal-dialog-centered {
  min-height: calc(100% - 3.5rem);
}
@media screen and (max-width: 375px) {
  .error-modal-parent-default-error {
    width: 271px;
    height: 160.54px;
    .error-modal-body {
      width: 271px;
      height: 160.54px;

    }
  }
}

