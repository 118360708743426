@import '../../../variables';

.select-network-option {
  &__button {
    background-color: transparent;
    width: 100%;
    border-radius: 20px;

    &:hover {
      background-color: $dark-gray;
    }
  }

  &__button.selected {
    background-color: $grey-tab-background;

    &:hover {
      background-color: $grey-tab-background;
    }
  }

  &__container {
    display: flex;

    img {
      width: 100%;
    }
  }

  &-image__container {
    position: relative;
    width: 50px;

    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__text {
    font-size: 1rem;
    color: $white;
    margin-left: 16px;
    font-family: $sofia;

    :first-child {
      color: $extra-light-gray;
    }

    p {
      margin-bottom: 0;
      line-height: 2;
    }
  }
}
