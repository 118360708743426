.presaleB-main-body{
  width: 100%;
  height: 940px;
  background: red;
  clip-path: polygon(50% 0, 100% 8%, 100% 92%, 50% 100%, 0 92%, 0 8%);
  background: url("../../../../assets/images/PlanetSalePagePrivate/presale-b-bg.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

.pgs-presaleB-border-img-container .psg-border-img{
  width: 1350px;
}

.pgs-presaleB-border-img-container .pgs-circle-container{
  top: 45px !important;
}
.presaleB-list{
  margin-top: 70px;
}
.bsale-list-design{
  margin-top: 65px;
}
