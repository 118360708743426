.preview-modal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 14;


  .modal-close-button {
    position: absolute;
    right: 54px;
    top: 54px;
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
  .preview-set-img-dimension{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 1302px;
    min-height: 732px;
  }
  .preview-modal-content {
    max-width: 1302px;
    max-height: 732px;
    height: auto;
    width: auto;
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .preview-modal-content {
      max-width: 700px;
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    .preview-modal-content {
      max-width: 932px;
    }
  }

  .preview-modal-content-video {
    height: auto;
  }
}
