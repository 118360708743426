.purchase-step-row {
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.purchase-step-row p {
  margin-bottom: 0.1rem;
}
