@import "../../../variables";

.pg-modal {
  margin: 0 16px;

  @media screen and (min-width: $sm-breakpoint) {
    margin: 0;
  }

  &-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 32px;
    padding-bottom: 56px;
    height: 100%;
  }

  &-top-horns {
    position: absolute;
    top: -7px;
    z-index: 1;
    width: 100%;
  }

  &-bottom-horns {
    position: absolute;
    bottom: -9px;
    z-index: 1;
    width: 100%;
  }
}
