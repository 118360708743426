@import "../../../variables";

.generative-starfighter-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 270px;

  @media screen and (max-width: 768px) {
    width: 200px;
  }

  @media screen and (min-width: 376px) and (max-width: 768px) {
    margin: 0;
  }
  @media screen and (max-device-width: 375px) {
    margin: 0;
  }
}

.generative-starfighter-border {
  height: 260px;
  background-image: url("../../../assets/images/GenerativePlanetMinting/planet-mintpass-border.svg");
  background-position: center;
  background-size: 90% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 168px;
    height: 175px;
  }
  @media screen and (max-device-width: 375px) {
    transition: width 0.5s ease-in-out,height 0.5s ease-in-out, margin-top 0.5s ease-in-out;
    width: 135px;
    height: 150px;
    margin-top: 18px;
  }
}

.swiper-slide-next .generative-starfighter-border{
  @media screen and (max-device-width: 375px) {
    margin-top: 0;
    width: 165px;
    height: 180px;
  }
}

.generative-starfighter-border.selected {
  cursor: pointer;
  background-image: url("../../../assets/images/GenerativePlanetMinting/planet-mintpass-border-clickable.svg");
}

.generative-starfighter-border.clickable {
  cursor: pointer;
}

.generative-starfighter-border.clickable:hover {
  background-image: url("../../../assets/images/GenerativePlanetMinting/planet-mintpass-border-hover.svg");
}

.generative-starfighter-counter-input{
  background-color: transparent;
  border: 0 solid;
  height: 30px;
  width: 38px;
  color: $white;
  margin-bottom: 5px;
}

.generative-starfighter-counter-input:focus {
  outline: none;
}

.generative-starfighter-component-image {
  width: 80%;
  margin-bottom: 21px;
  position: relative;
  top: 8px;
  @media screen and (max-device-width: 375px) {
    transition: width 0.5s ease-in-out,height 0.5s ease-in-out, margin-top 0.5s ease-in-out;
    width: 97px;
    }
}

.swiper-slide-next .generative-starfighter-component-image{
  @media screen and (max-device-width: 375px) {
    width: 127px;
  }
}

.generative-starfighter-component-title {
  font-family: $oswald;
  font-size: 1.25rem;
  transition: opacity 0.5s ease-in-out;
  text-align: center;
  width: 80%;

  @media screen and (min-width: 1025px) and (max-width: 1440px) {
    font-size: 1.375rem;
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    margin-bottom: 0;
    font-size: 1rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 0;
  }
}

.generative-starfighter-component-title.clickable {
  cursor: pointer;
}

.generative-starfighter-component-stock {
  font-family: $oswald;
  font-size: 1.25rem;
  margin-bottom: 12px;
  opacity: 0.5;
  transition: opacity 0.5s ease-in-out;

  @media screen and (min-width: 1025px) and (max-width: 1440px) {
    font-size: 1.25rem;
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    font-size: 0.875rem;
    margin-bottom: 0.879vw;
  }
  @media screen and (max-width: 768px) {
    font-size: 0.875rem;
    margin-bottom: 9px;
  }
}

.generative-starfighter-counter-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 144px;
  height: 37px;
  background-color: rgba(255, 255, 255, 0.15);
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    width: 14.063vw;
    height: 3.613vw;
  }
  @media screen and (max-width: 768px) {
    width: 98px;
    height: 25px;
  }

  transition: opacity 0.5s ease-in-out;
}


.swiper-slide.swiper-slide-next .generative-starfighter-counter-container,
.swiper-slide.swiper-slide-next .buy-on-opensea-container,
.swiper-slide.swiper-slide-next .generative-starfighter-component-title{
  opacity: 1;
}

.swiper-slide.swiper-slide-next .generative-starfighter-component-stock{
  opacity: 0.5;
}


.swiper-slide .generative-starfighter-counter-container,
.swiper-slide .buy-on-opensea-container,
.swiper-slide .generative-starfighter-component-stock,
.swiper-slide .generative-starfighter-component-title,
{
  opacity: 0;
}

.generative-starfighter-counter-minus,
.generative-starfighter-counter-plus {
  user-select: none;
  width: 32px;
  height: 32px;
  background: $pink;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.5rem;
  cursor: pointer;
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    width: 3.125vw;
    height: 3.125vw;
  }
  @media screen and (max-width: 768px) {
    width: 22px;
    height: 22px;
    font-size: 1rem;
  }
}

.generative-starfighter-counter-minus p,
.generative-starfighter-counter-plus p {
  margin: 0 0 5px 0;
}

.generative-starfighter-counter-minus {
  margin-right: 15px;
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    margin-right: 1.465vw;
  }
  @media screen and (max-width: 768px) {
    margin-right: 10px;
  }
}

.generative-starfighter-counter-plus {
  margin-left: 15px;
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    margin-left: 1.465vw;
  }
  @media screen and (max-width: 768px) {
    margin-left: 10px;
  }
}

.generative-starfighter-counter-input {
  background-color: transparent;
  border: 0 solid;
  height: 30px;
  width: 38px;
  color: $white;
  margin-bottom: 5px;
  font-family: $sofia;
  font-size: 1.125rem;
  text-align: center;
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    width: 3.711vw;
    height: 2.930vw;
    font-size: clamp(0.5rem, 1.758vw, 1.125rem);
  }
  @media screen and (max-width: 768px) {
    width: 26px;
    height: 20px;
    font-size: 0.75rem;
  }
}

.generative-starfighter-open-sea-container .buy-on-opensea-container{
  clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
  width: 158px;
  height: 38px;
  transition: opacity 0.5s ease-in-out;
  .buy-on-opensea{
    width: 156px;
    height: 36px;
    clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);

  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    width: 13.184vw;
    height: 2.930vw;
    font-size: clamp(0.5rem, 1.270vw, 0.8125rem);
    clip-path: polygon(0.488vw 0, 0 0.488vw, 0 calc(100% - 0.488vw), 0.488vw 100%, calc(100% - 0.488vw) 100%, 100% calc(100% - 0.488vw), 100% 0.488vw, calc(100% - 0.488vw) 0);
    .buy-on-opensea{
      width: 12.988vw;
      height: 2.734vw;
      clip-path: polygon(0.488vw 0, 0 0.488vw, 0 calc(100% - 0.488vw), 0.488vw 100%, calc(100% - 0.488vw) 100%, 100% calc(100% - 0.488vw), 100% 0.488vw, calc(100% - 0.488vw) 0);
    }
    img {
      width: 1.660vw;
      height: 1.367vw;
    }
  }
  @media screen and (min-width: 376px) and (max-width: 768px) {
    width: 134px;
    height: 30px;
    font-size: 0.8125rem;
    clip-path: polygon(5px 0, 0 5px, 0 calc(100% - 5px), 5px 100%, calc(100% - 5px) 100%, 100% calc(100% - 5px), 100% 5px, calc(100% - 5px) 0);

    .buy-on-opensea{
      width: 132px;
      height: 28px;
      clip-path: polygon(5px 0, 0 5px, 0 calc(100% - 5px), 5px 100%, calc(100% - 5px) 100%, 100% calc(100% - 5px), 100% 5px, calc(100% - 5px) 0);
    }
    img {
      width: 17px;
      height: 14px;
    }
  }
  @media screen and (max-device-width: 375px) {
    width: 134px;
    height: 28px;
    font-size: 0.75rem;
    clip-path: polygon(5px 0, 0 5px, 0 calc(100% - 5px), 5px 100%, calc(100% - 5px) 100%, 100% calc(100% - 5px), 100% 5px, calc(100% - 5px) 0);
    .buy-on-opensea{
      width: 132px;
      height: 26px;
      clip-path: polygon(5px 0, 0 5px, 0 calc(100% - 5px), 5px 100%, calc(100% - 5px) 100%, 100% calc(100% - 5px), 100% 5px, calc(100% - 5px) 0);
    }
    img {
      width: 17px;
      height: 14px;
    }
  }
}

.generative-starfighter-open-sea-container--hide-image {
  img {
    display: none;
  }
}

