@import "../../../variables";

.planet-transfer-container {
  .planet-transfer-top-border,
  .planet-transfer-bottom-border {
    position: relative;
    width: 100%;
  }

  .modal-content {
    align-items: center;
  }

  .planet-transfer-top-border {
    top: 3px;
    @media screen and (max-width: 1440px) {
      top: 5px;
    }
    @media screen and (max-width: 1024px) {
      top: 5px;
    }
    @media screen and (max-width: 768px) {
      top: 8px;
    }
    @media screen and (max-device-width: 375px) and (max-width: 375px) {
      top: 7px;
    }
  }

  .planet-transfer-bottom-border {
    bottom: 9px;
    @media screen and (max-width: 1440px) {
      bottom: 10px;
    }
    @media screen and (max-width: 1024px) {
      bottom: 11px;
    }
    @media screen and (max-width: 768px) {
      bottom: 13px;
    }
  }

  .planet-transfer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    background-color: $black;

    padding-top: 80px;
    padding-bottom: 70px;
    @media screen and (max-width: 1440px) {
      width: 100%;
      padding-top: 5.556vw;
      padding-bottom: 4.861vw;
    }
    @media screen and (max-width: 1024px) {
      width: 75.586vw;
      padding-top: 5.859vw;
      padding-bottom: 4.883vw;
    }
    @media screen and (max-width: 768px) {
      width: 59.896vw;
      padding-top: 5.208vw;
      padding-bottom: 7.161vw;
      height: 79.297vw;
    }
    @media screen and (max-device-width: 375px) and (max-width: 375px) {
      width: 98.133vw;
      padding-top: 7.467vw;
      padding-bottom: 11.200vw;
      height: unset;
    }


    .planet-transfer-item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0 80px 55px 80px;
      @media screen and (max-width: 1440px) {
        margin: 0 5.556vw 3.819vw 5.556vw;
      }
      @media screen and (max-width: 1024px) {
        margin: 0 6.055vw 3.906vw 6.055vw;
      }
      @media screen and (max-width: 768px) {
        margin: 0 3.255vw 5.208vw 3.255vw;
      }
      @media screen and (max-device-width: 375px) and (max-width: 375px) {
        margin: 0 4.800vw 8vw 4.800vw;
      }

      .planet-transfer-item-check {
        width: 26px;
        display: inline-block;
        position: relative;
        margin-top: 7px;
        @media screen and (max-width: 1440px) {
          width: 1.805vw;
          margin-top: 0.486vw;
        }
        @media screen and (max-width: 1024px) {
          width: 1.953vw;
          margin-top: 0.586vw;
        }
        @media screen and (max-width: 768px) {
          width: 2.604vw;
          margin-top: 0.911vw;
        }
        @media screen and (max-device-width: 375px) and (max-width: 375px) {
          width: 4.533vw;
          margin-top: 1.333vw;
        }
      }

      .planet-transfer-item-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-left: 28px;

        &.grayed {
          opacity: 0.5;
        }

        @media screen and (max-width: 1440px) {
          margin-left: 1.944vw;
        }

        @media screen and (max-width: 1024px) {
          margin-left: 1.953vw;
        }
        @media screen and (max-width: 768px) {
          margin-left: 1.953vw;
        }
        @media screen and (max-device-width: 375px) and (max-width: 375px) {
          margin-left: 3.200vw;
        }

        .planet-transfer-item-title {
          font-family: $sofia;
          font-size: 1.5rem;
          line-height: 36px;
          font-weight: 700;
          @media screen and (max-width: 1440px) {
            font-size: clamp(1rem, 1.667vw, 1.5rem);
            line-height: 2.5vw;
          }
          @media screen and (max-width: 1024px) {
            font-size: clamp(0.875rem, 1.758vw, 1.125rem);
            line-height: 2.637vw;
          }
          @media screen and (max-width: 768px) {
            font-size: clamp(0.75rem, 2.865vw, 1.375rem);
            line-height: 3.906vw;
          }
          @media screen and (max-device-width: 375px) and (max-width: 375px) {
            font-size: clamp(0.75rem, 4.800vw, 1.125rem);
            line-height: 6.400vw;
          }
        }

        .planet-transfer-item-description {
          font-family: $sofia;
          font-size: 1.25rem;
          line-height: 30px;
          font-weight: 400;
          @media screen and (max-width: 1440px) {
            font-size: clamp(0.75rem, 1.389vw, 1.25rem);
            line-height: 2.5vw;
          }
          @media screen and (max-width: 1024px) {
            font-size: clamp(0.75rem, 1.465vw, 1rem);
            line-height: 2.637vw;
          }
          @media screen and (max-width: 768px) {
            font-size: clamp(0.5rem, 2.344vw, 1.125rem);
            line-height: 3.385vw;
            font-weight: 300;
            padding-right: 6.510vw;
          }
          @media screen and (max-device-width: 375px) and (max-width: 375px) {
            font-size: clamp(0.5rem, 4.800vw, 0.875rem);
            font-weight: 300;
            line-height: 5.600vw;
          }
        }
      }
    }

    .planet-transfer-button-container {
      width: 246px;
      height: 37px;

      margin-top: 25px;

      font-family: $oswald;
      font-size: 1.25rem;
      text-align: center;
      @media screen and (max-width: 1440px) {
        width: 17.083vw;
        height: 2.569vw;
        margin-top: 1.736vw;
        font-size: clamp(0.75rem, 1.389vw, 1.25rem);
        .base-button,
        .base-button-filled {
          clip-path: polygon(0.488vw 0, 0 0.488vw, 0 calc(100% - 0.488vw), 0.488vw 100%, calc(100% - 0.488vw) 100%, 100% calc(100% - 0.488vw), 100% 0.488vw, calc(100% - 0.488vw) 0);
        }
      }
      @media screen and (max-width: 1024px) {
        width: 18.066vw;
        height: 2.734vw;
        margin-top: 1.953vw;
        font-size: clamp(0.75rem, 1.465vw, 1rem);
        .base-button,
        .base-button-filled {
          clip-path: polygon(0.488vw 0, 0 0.488vw, 0 calc(100% - 0.488vw), 0.488vw 100%, calc(100% - 0.488vw) 100%, 100% calc(100% - 0.488vw), 100% 0.488vw, calc(100% - 0.488vw) 0);
        }
      }
      @media screen and (max-width: 768px) {
        width: 28.906vw;
        height: 4.297vw;
        font-size: clamp(0.5rem, 2.344vw, 1.125rem);
        .base-button,
        .base-button-filled {
          clip-path: polygon(0.651vw 0, 0 0.651vw, 0 calc(100% - 0.651vw), 0.651vw 100%, calc(100% - 0.651vw) 100%, 100% calc(100% - 0.651vw), 100% 0.651vw, calc(100% - 0.651vw) 0);
          margin-top: 0;
        }
      }
      @media screen and (max-device-width: 375px) and (max-width: 375px) {
        width: 47.467vw;
        height: 7.200vw;
        margin-top: 7.200vw;
        font-size: clamp(0.5rem, 3.733vw, 0.875rem);
        .base-button,
        .base-button-filled {
          clip-path: polygon(1.067vw 0, 0 1.067vw, 0 calc(100% - 1.067vw), 1.067vw 100%, calc(100% - 1.067vw) 100%, 100% calc(100% - 1.067vw), 100% 1.067vw, calc(100% - 1.067vw) 0);
          margin-top: 0;
        }
      }
    }

  }


}
