@import '../../../variables';


.token-balance {
  &__container {
    display: flex;

    .token-balance {
      &__text {
        font-family: $oswald-light;
        font-size: 1.25rem;
        color: $white;
      }

      &__subtitle {
        margin-right: 8px;
        line-height: 2.3;
        font-size: 0.75rem;
        color: $light-gray-background;
      }
    }

    p {
      margin-bottom: 0;
    }
  }
}
