@import "../../variables";

$images-path: '../../assets/images/';

.topbar-with-background {
  background: url(#{$images-path}topbar-bg.png) top center no-repeat;
  padding-bottom: 100px;
  width: 100%;
  position: relative;
  background: black;
  padding-top: 0;
}


@media (max-width: 1920px) {
  .topbar-with-background::before {
    background-size: auto !important;
  }
}

@media (max-width: 1024px) {
  .topbar-with-background {
    padding-bottom: 175px;
  }
  .topbar-with-background::before {
    background-position-x: 12.5% !important;
  }
}

.topbar-with-background::before {
  background: red;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  clip-path: $section-polygon-bottom-outside-cut-1920;

  background: url(#{$images-path}topbar-bg.png) top center no-repeat;
  background-size: cover;
}

.topbar-no-background {
  background: none;
  padding-bottom: 100px;
  padding-top: 0;
}

#connect-wallet {
  width: 152px;
  margin-right: 20px;
}

.dropdowns-text {
  color: $white;
  font-family: $oswald;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 20px;
  font-size: 14px;
  margin: 0px;
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dropdowns-text:hover {
  color: #E32A5F;
}

.dropdowns-text.disabled:hover {
  color: white;
}


.buttons-header {
  position: relative;
  image-rendering: smooth;
  color: $white !important;
  font-family: $oswald;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 37px;
  height: 37px;
  width: 122px;
  text-align: center;
  padding: 0px !important;
  margin: 0 4px;
  cursor: pointer;
  transition: all .2s ease;
  z-index: 100;
}

.event-dropdown-main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(#FD2F69, #D42758);
  clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
}

.nft-dropdown-menu-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-image: linear-gradient(#FD2F69, #D42758);
  clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);

}

.buttons-header:hover {
  //transform: scale(1.1);
  color: rgba(255, 255, 255, 0.5) !important;

  .arrow, .socials-button-icon {
    filter: opacity(0.4);
  }
}

.socials-buttons-main-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  background-image: linear-gradient(#FD2F69, #D42758);
  clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
}

.button-text-litepaper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0px;
  background-image: linear-gradient(#FD2F69, #D42758);
  clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
}

.linking-container {
  text-decoration: none;
}

.nav_links-buttons-border {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px;
  background-color: #D22657;
  clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
}

.button-text-download {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0px;
  background-image: linear-gradient(#FD2F69, #D42758);
  clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
}

.button-text-events {
  margin: 0px;
}

.events-button-arrow {
  position: absolute;
  left: 93px;
  bottom: 12px;
}

.button-text-nft {
  margin: 0px;
}

.nft-button-arrow {
  position: absolute;
  left: 87px;
  bottom: 12px;
}

.button-text-connect-wallet {
  @extend .button-text-litepaper;
  left: 18px;
  bottom: 12px;
}

.socials-button {
  @extend .buttons-header;
  height: 37px;
  width: 65.11px;
  padding: 0px !important;

}

.socials-button-icon {

}

.socials-button-arrow-closed {
  margin-left: 5px;
}

.socials-button-arrow-opened {
  margin-left: 5px;
  display: none;
}

.icons-header {
  width: 17px;
  height: 17px;
  transition: all .3s ease;
  fill: white;
  margin-top: 7px;
  margin-bottom: 7px;
}

.icons-header:hover {
  fill: #F32D65;
}


.icons-header:hover {
  transform: scale(1.1)
}

.social-icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.discord-logo-header {
  background-color: transparent;
  cursor: pointer;
  height: 16px;
  width: 16px;
  mix-blend-mode: normal;
  pointer-events: auto;
  position: absolute;
}

.paper-plane-logo-header {
  background-color: transparent;
  cursor: pointer;
  height: 16px;
  width: 16px;
  mix-blend-mode: normal;
  pointer-events: auto;
  position: absolute;
}

.twitter-logo-header {
  background-color: transparent;
  cursor: pointer;
  height: 16px;
  width: 16px;
  mix-blend-mode: normal;
  pointer-events: auto;
  position: absolute;
}

.instagram-logo-header {
  background-color: transparent;
  cursor: pointer;
  height: 16px;
  width: 16px;
  mix-blend-mode: normal;
  pointer-events: auto;
  position: absolute;
}

.facebook-logo-header {
  background-color: transparent;
  cursor: pointer;
  height: 16px;
  width: 8px;
  mix-blend-mode: normal;
  pointer-events: auto;
  position: absolute;
}

.twitch-logo-header {
  background-color: transparent;
  cursor: pointer;
  height: 16px;
  width: 16px;
  mix-blend-mode: normal;
  pointer-events: auto;
  position: absolute;
}

.medium-logo-header {
  background-color: transparent;
  cursor: pointer;
  height: 17px;
  width: 17px;
  mix-blend-mode: normal;
  pointer-events: auto;
  position: absolute;
}

.token-coin-link {
  height: 37px;
  width: 32px;
  margin-left: 4px;
  padding: 0px !important;

}

.token-coin-link:hover {
  transform: scale(1.1)
}

.token-coin-header {
  cursor: pointer;
}

@media (min-width: 1025px) {
  #burger-menu {
    display: none;
  }
}

@media (max-width: 1024px) {
  #burger-menu {
    display: block;
  }
}


@media screen and (min-width: 769px) and (max-width: 1024px) {
  .topbar-with-background::before {
    clip-path: $section-polygon-bottom-outside-cut-1024;
  }
}

@media screen and (min-width: 375px) and (max-width: 768px) {
  .topbar-with-background::before {
    clip-path: $section-polygon-bottom-outside-cut-768;
  }
}

@media screen and (max-width: 375px) {
  .topbar-with-background::before {
    clip-path: $section-polygon-bottom-outside-cut-375;
  }
}
