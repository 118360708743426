.planet-purchase-tcs-modal-container {
  width: 8000px;
  height: 350px;
  justify-content: center;
  align-items: center;
}

.planet-purchase-tcs-principle-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.planet-purchase-tcs-header {
  text-align: center;
  margin-bottom: 15px;
}

.planet-purchase-tcs-header-horns {
  position: absolute;
  top: -10px;
  align-items: center;
  justify-content: center;
}

.planet-purchase-tcs-bottom-horns {
  position: absolute;
  bottom: -10px;
  align-items: center;
  justify-content: center;
}

.planet-purchase-tcs-buttons-row {
  margin-left: 100px;
  margin-right: 100px;
}
