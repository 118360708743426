@import "../../../variables";

.mintpass-astrafer-modal{
  width: 731px ;
  max-width: none;
}

.mintpass-astrafer-bottom-decoration{
  position: relative;
  bottom: -12.4px;
  height: 12.5px;
}

.mintpass-astrafer-header-decoration{
  position: relative;
  top: -12.4px;
  height: 12.5px;
}

.mintpass-astrafer-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 731px;
  padding: 0 10px;


  .mintpass-licence-text-box-container {
    width: 700px;
  }

  .mintpass-licence-text-box-text {
    width: 640px;
  }

  .mintpass-licence-text-box-shadow {
    top: -80px;
    width: auto;
  }
}

.mintpass-astrafer-close-button{
  width: 22px;
  height: 22px;
  position: absolute;

  cursor: pointer;

  right: 11px;
  top: 11px;
}

.mintpass-astrafer-text{
  font-family: $sofia;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  width: 450px;
  margin-top: 45px;
  margin-bottom: 40px;
}

.mintpass-astrafer-balance-text {
  font-family: $sofia;
  text-align: center;
}

.mintpass-astrafer-text .bold{
  font-weight: 800;
}

.mintpass-astrafer-confirmation{
  font-family: $sofia;
  font-size: 20px;
  line-height: 30px;

  margin-bottom: 60px;
  text-align: center;
}

.mintpass-astrafer-buttons-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mintpass-astrafer-yes-button,
.mintpass-astrafer-no-button{
  font-family: $oswald;
  font-size: 20px;
  width: 159px;
  height: 37px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background-color: $pink;
  -webkit-clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
  clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
  cursor: pointer;
}

.mintpass-astrafer-yes-button{
  margin-right: 21px;

  &.disabled {
    cursor: initial;
    background-color: $stack;
  }
}

.mintpass-astrafer-no-button{
  margin-left: 21px;
}


@media screen and (max-width: 1024px) {

  .mintpass-astrafer-content{

    .mintpass-licence-text-box-container {
      width:  65.099vw;
    }

    .mintpass-licence-text-box-text {
      width:  55.099vw;
    }


    .mintpass-licence-text-box-shadow {
      top: -5.809vw;
      width: auto;
    }
  }
}

@media screen and (max-width: 768px) {
  .mintpass-astrafer-modal{
    width: 68.099vw;
    height: 38.021vw;
  }

  .mintpass-astrafer-bottom-decoration{
    bottom: -1.3vw;
    width: 100%;
    height: 1.4vw;
  }

  .mintpass-astrafer-header-decoration{
    top: -1.3vw;
    width: 100%;
    height: 1.4vw;
  }

  .mintpass-astrafer-content{
    width: 68.099vw;

    .mintpass-licence-text-box-container {
      width:  66.099vw;
    }

    .mintpass-licence-text-box-text {
      width:  60.099vw;
    }

    .mintpass-licence-text-box-shadow {
      top: -5.809vw;
    }
  }

  .mintpass-astrafer-close-button{
    width: 2.169vw;
    height: 2.169vw;
    right: 1.432vw;
    top: 1.432vw;
  }

  .mintpass-astrafer-balance-text {
    font-size: 0.8rem;
  }

  .mintpass-astrafer-text{
    font-family: $sofia;
    font-size: 0.8rem;
    line-height: 1;
    text-align: center;
    width: 55.339vw;
    margin-top: 5.859vw;
    margin-bottom: 9vw;
  }

  .mintpass-astrafer-confirmation{
    font-size: 0.8rem;
    line-height: 1;
    margin-bottom: 3.906vw;
  }

  .mintpass-astrafer-yes-button,
  .mintpass-astrafer-no-button{
    font-size: 2.604vw;
    width: 17.578vw;
    height: 4.818vw;
    -webkit-clip-path: polygon(0.911vw 0, 0 0.911vw, 0 calc(100% - 0.911vw), 0.911vw 100%, calc(100% - 0.911vw) 100%, 100% calc(100% - 0.911vw), 100% 0.911vw, calc(100% - 0.911vw) 0);
    clip-path: polygon(0.911vw 0, 0 0.911vw, 0 calc(100% - 0.911vw), 0.911vw 100%, calc(100% - 0.911vw) 100%, 100% calc(100% - 0.911vw), 100% 0.911vw, calc(100% - 0.911vw) 0);
    cursor: pointer;
  }

  .mintpass-astrafer-yes-button{
    margin-right: 2.734vw;
  }

  .mintpass-astrafer-no-button{
    margin-left: 2.734vw;
  }
}


@media screen and (max-width: 375px) {

  .mintpass-astrafer-content{

    .mintpass-licence-text-box-container {
      width:  65.099vw;
    }

    .mintpass-licence-text-box-text {
      width:  59.099vw;
    }

    .mintpass-licence-text-box-shadow {
      top: -18.809vw;
    }
  }
}
