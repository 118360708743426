@import '../../../../variables';

.planet-purchase-tcs-content {
  overflow-y: scroll;
  height: 500px;
  background: $darkgrey;
  padding: 20px;
}

ol {
  list-style-type: none;
  counter-reset: list;
}

ol li:before {
  counter-increment: list;
  content: counters(list, ".") ". ";
}

ol.planet-purchase-tcs-list-third-level {
  list-style-type: none;
  counter-reset: list;
}

ol.planet-purchase-tcs-list-third-level li:before {
  counter-increment: list;
  content: counter(list, lower-latin) ") ";
}

li {
  margin-top: 6px;
  margin-bottom: 5px;
}

ol.planet-purchase-tcs-list-first-level > li {
  margin-top: 20px;
  margin-bottom: 5px;
}
