@import "../../../variables";

.planet-exploration-mission-task-main-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 41px;
  margin-bottom: 100px;
  width: 1347px;
}

.planet-exploration-mission-task-title{
  font-size: 2.813rem;
  font-weight: 400;
  line-height: 101px;
  font-family: $oswald;
  color: white;
  margin: 19px 0 32px 0;
}

.planet-exploration-mission-task-content{
  font-size: 1.375rem;
  font-weight: 400;
  line-height: 28px;
  font-family: $sofia;
  color: white;
  margin-bottom: 13px;

  width: 100%;
  background-color: $black-night-bg;
  padding: 20px 45px;
  border-radius: 4px;
}

.planet-exploration-mission-task-content.list{
  line-height: 34px;
  padding-left: 64px;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .planet-exploration-mission-task-main-container {
    margin-top: 6.583vw;
    margin-bottom: 4.2vw;
    width: 96.736vw;
  }

  .planet-exploration-mission-task-title{
    font-size: 2.688rem;
  }

  .planet-exploration-mission-task-content{
    font-size: 1.313rem;
    padding: 20px 55px;
  }

  .planet-exploration-mission-task-content.list{
    padding-left: 70px;
  }
}

@media screen and (max-width: 1024px) {
  .planet-exploration-mission-task-main-container {
    margin-top: 4.4vw;
    margin-bottom: 7.2vw;
    width: 85.840vw;
  }

  .planet-exploration-mission-task-title{
    font-size: 2rem;
    margin: 19px 0 10px 0;
  }

  .planet-exploration-mission-task-content{
    font-size: 1rem;
    padding: 20px 35px;
  }

  .planet-exploration-mission-task-content.list{
    padding-left: 38px;
  }
}

@media screen and (max-width: 768px) {

  .planet-exploration-mission-task-main-container{
    width: 85.938vw;
  }

  .planet-exploration-mission-task-title{
    font-size: 1.5rem;
    height: 94px;
    margin: 19px 0 0 0;
  }

  .planet-exploration-mission-task-content{
    font-size: 0.875rem;
    width: 100%;
    padding: 20px 22px;
  }

  .planet-exploration-mission-task-content.list {
    padding-left: 27px;
  }
}

@media screen and (max-device-width: 375px) {

  .planet-exploration-mission-task-title{
    font-size: 1.5rem;
    line-height: 1rem;
    margin-top: 2.5rem;
    height: 40px;
  }

  .planet-exploration-mission-task-content{
    font-size: 1rem;
    width: 100%;
    padding: 3px 19px;
    margin-bottom: 5px;
  }

  .planet-exploration-mission-task-content.list{
    padding-left: 24px;
  }

  .planet-exploration-mission-task-main-container{
    width: 94.933vw;
    margin-bottom: 77px;
  }
}
