@import "../../../variables";

.kyc-modal-main-container{
  width: 100%;
  height: 100%;
}

.kyc-modal .kyc-dialog{
  max-width: initial;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.kyc-modal .modal-content {
  background-color: $black;
  opacity: 0.9;
  width: 946px;
  height: 505px;
}

.popup-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.kyc-close {
  position: absolute;
  top: 0;
  padding-top: 8.5px;
  right: 0;
  padding-right: 8px;
  cursor: pointer;
}

.kyc-popup-text {
  position: absolute;
  width: 918px;
  height: 425px;
  font-family: $sofia;
  font-style: normal;
  font-size: 14px;
  line-height: 25px;
  right: 14px;
  top: 41px;
  bottom: 11px;
  left: 19px;
  overflow: auto;
}

.popup-title-container {
  text-decoration-line: underline;
}

.popup-container ::-webkit-scrollbar {
  width: 10px;
  height: 410px;
  background: $gray-scrollbar;
  border-top: 20px;

}

.popup-container ::-webkit-scrollbar-track {
  background: $black;
  border: 2px solid $gray-scrollbar;
}

.popup-container ::-webkit-scrollbar-thumb {
  background: #6A6A6A;
  padding: 10px;
}

.upper-border {
  width: 100%;
  position: absolute;
  top: -17px;
}

.lower-border {
  width: 100%;
  position: absolute;
  bottom: -16px;
}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .popup-title-container,
  .kyc-popup-text {
    font-size: 1.823vw;
    line-height: 3.255vw;
  }
  .kyc-popup-text {
    width: 61.4vw;
    height: 55.339vw;
    right: 1.823vw;
    top: 5.339vw;
    bottom: 1.432vw;
    left: 2.474vw;
  }
    .kyc-modal .modal-content {
    width: 65.104vw;
    height: 62.109vw;
  }
  .lower-border{
    bottom: -1.042vw;
  }
  .upper-border{
    top: -1.042vw
  }
  .kyc-close{
    padding-top: 1.042vw;
    padding-right: 1.042vw;
    width: 2.865vw;
    height: 2.865vw;
  }

}

@media screen and (max-width: 376px) {
  .popup-title-container,
  .kyc-popup-text {
    font-size: 3.733vw;
    line-height: 6.667vw;
  }
  .kyc-popup-text {
    width: 73.333vw;
    height: 100vw;
    right: 3.733vw;
    top: 10.933vw;
    bottom: 2.933vw;
    left: 5.067vw;

  }
  .kyc-modal .modal-content {
    width: 81.333vw;
    height: 117.867vw;
  }
  .upper-border{
    top: -1.600vw;
  }
  .lower-border{
    bottom: -1.600vw;
  }
  .kyc-close {
    padding-top: 2.133vw;
    padding-right: 2.267vw;
  }
}

