@import "../../../../variables";

.claim-info-element-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.claim-info-title{
  font-family: $oswald;
  font-size: 21px;
  line-height: 32px;
  color: $stack;
  margin-bottom: 12px;
}

.claim-info-text{
  z-index: 1;
  width: 100%;
  height: 46px;
  text-align: center;
  line-height: 44px;
  color: $white;
  font-family: $oswald;
  font-size: 24px;
  clip-path: polygon(7px 0, calc(100% - 7px) 0, 100% 7px, 100% calc(100% - 7px) , calc(100% - 7px) 100%, 7px 100%, 0 calc(100% - 7px), 0 7px);
  background-color: rgb(0,0,0);
}

.claim-info-text-border{
  position: absolute;
  margin-top: 42px;
  width: 330px;
  height: 50px;
  clip-path: polygon(7px 0, calc(100% - 7px) 0, 100% 7px, 100% calc(100% - 7px) , calc(100% - 7px) 100%, 7px 100%, 0 calc(100% - 7px), 0 7px);
  background-color: $pink;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .claim-info-title {
    font-size: 16px;
    line-height: 25px;
  }
  .claim-info-text {
    line-height: 36px;
    font-size: 18px;
    height: 36px;
  }
  .claim-info-text-border {
    height: 40px;
    margin-top: 35px;
    width: 250px;
  }

  }

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .claim-info-title {
    font-size:1.270vw;
    line-height: 1.855vw;
    margin-bottom: 0.977vw;
  }
  .claim-info-text {
    font-size:1.367vw;
    line-height: 2.734vw;
    height: 2.734vw;
    clip-path: polygon(0.488vw 0, calc(100% - 0.488vw) 0, 100% 0.488vw, 100% calc(100% - 0.488vw) , calc(100% - 0.488vw) 100%, 0.488vw 100%, 0 calc(100% - 0.488vw), 0 0.488vw);

  }
  .claim-info-text-border {
    height: 3.125vw;
    margin-top: 2.637vw;
    width: 18.945vw;
    clip-path: polygon(0.488vw 0, calc(100% - 0.488vw) 0, 100% 0.488vw, 100% calc(100% - 0.488vw) , calc(100% - 0.488vw) 100%, 0.488vw 100%, 0 calc(100% - 0.488vw), 0 0.488vw);

  }

}
@media screen and (min-width: 376px) and (max-width: 768px) {
  .claim-info-title {
    font-size: 1.783vw;
    line-height: 3.125vw;
    margin-bottom: 1.042vw;
  }
  .claim-info-text {
    font-size: 2.083vw;
    line-height: 3.646vw;
    height: 3.646vw;
    clip-path: polygon(0.651vw 0, calc(100% - 0.651vw) 0, 100% 0.651vw, 100% calc(100% - 0.651vw) , calc(100% - 0.651vw) 100%, 0.651vw 100%, 0 calc(100% - 0.651vw), 0 0.651vw);

  }
  .claim-info-text-border {
    height: 4.167vw;
    margin-top: 3.906vw;
    width: 25.651vw;
    clip-path: polygon(0.651vw 0, calc(100% - 0.651vw) 0, 100% 0.651vw, 100% calc(100% - 0.651vw) , calc(100% - 0.651vw) 100%, 0.651vw 100%, 0 calc(100% - 0.651vw), 0 0.651vw);
  }

}

@media screen and (max-width: 375px) {
  .claim-info-title {
    text-align: center;
    font-size: 3.733vw;
    line-height: 4.267vw;
    margin-bottom: 1.867vw;
  }
  .claim-info-text {
    font-size: 3.733vw;
    line-height:7.200vw;
    height: 7.200vw;
    clip-path: polygon(1.333vw 0, calc(100% - 1.333vw) 0, 100% 1.333vw, 100% calc(100% - 1.333vw) , calc(100% - 1.333vw) 100%, 1.333vw 100%, 0 calc(100% - 1.333vw), 0 1.333vw);
  }
  .claim-info-text-border {
    height: 8.267vw;
    margin-top: 9.867vw;
    width: 28vw;
    clip-path: polygon(1.333vw 0, calc(100% - 1.333vw) 0, 100% 1.333vw, 100% calc(100% - 1.333vw) , calc(100% - 1.333vw) 100%, 1.333vw 100%, 0 calc(100% - 1.333vw), 0 1.333vw);
  }
}
