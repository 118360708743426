@import "../../../variables";

.start-carousel-main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.start-carousel-title {
  background: #51CBFF;
  background: linear-gradient(180deg, #f1f5f7 0%, #96afbc 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 82px;
  font-family: $oswald;
  font-weight: 700;
  line-height: 90px;
  margin-bottom: -5px;
}

.start-carousel-second-title {
  background: #51CBFF;
  background: linear-gradient(180deg, #8ba5b3 0%, #567180 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 75px;
  font-family: $oswald;
  font-weight: 700;
  line-height: 69px;
}

.adjust-wallet-icon {
  width: 100px !important;
  height: 100px;
}

.wallet-icon-img-adjustment {
  image-rendering: pixelated;
}

.start-carousel-content-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.content-box-element {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 39px;
  margin-right: 39px;
}

.content-container-button {
  width: 198px;
}

.wallet-icon-adjustment {
  opacity: 0.6;
}

.console-icon {
  opacity: 0.6;
}

.img-box-for-alignment {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 121px;
  margin-bottom: 13px;
}

.start-carousel-description-text {
  width: 100%;
  font-family: $oswald;
  font-size: 18px;
  margin-top: 36px;
  padding-left: 100px;
  padding-right: 99px;
}

.original-nft-location {
  text-decoration: underline;
  cursor: pointer;
  color: white;
}

.original-nft-location:hover {
  color: white;
}

.under-1024 {
  display: none;
}


@media (min-width: 769px) and (max-width: 1024px) {
  .start-carousel-title {
    font-size: 6.348vw;
    margin-bottom: -1.563vw;
    line-height: 8.789vw;
  }
  .header-title-start-carousel {
    margin-top: 1.953vw;
  }
  .start-carousel-second-title {
    font-size: 6.152vw;
    line-height: 6.738vw;
  }
  .start-carousel-description-text {
    font-size: 1.563vw;
    padding-left: 0.577vw;
    padding-right: 0.577vw;
    margin-top: 4.980vw;
  }
  .wallet-icon-adjustment {
    width: 7.813vw !important;
  }
  .console-icon {
    width: 7.617vw !important;
  }
  .download-cloud {
    width: 8.008vw !important;
  }
  .content-box-element {
    margin-left: 3.613vw;
    margin-right: 3.613vw;
  }
  .content-container-button {
    width: 13.184vw;
  }

  .img-box-for-alignment {
    height: 8.008vw;
  }
  .start-carousel-content-container {
    margin-top: 4.395vw;
  }
  .upper-1024 {
    display: none;
  }
  .under-1024 {
    display: block;
  }
}

@media (min-width: 376px) and (max-width: 768px) {
  .start-carousel-title {
    font-size: 8.464vw;
    margin-bottom: -2.083vw;
    line-height: 11.719vw;
  }
  .header-title-start-carousel {
    margin-top: 2.604vw;
  }
  .start-carousel-second-title {
    font-size: 8.203vw;
    line-height: 8.984vw;
  }
  .start-carousel-description-text {
    font-size: 2.083vw;
    padding-left: 1.002vw;
    padding-right: 1.002vw;
    margin-top: 6.641vw;
  }
  .wallet-icon-adjustment {
    width: 10.417vw !important;
  }
  .console-icon {
    width: 10.156vw !important;
  }
  .download-cloud {
    width: 10.677vw !important;
  }
  .content-box-element {
    margin-left: 4.818vw;
    margin-right: 4.818vw;
  }
  .content-container-button {
    width: 17.578vw;
  }

  .img-box-for-alignment {
    height: 10.677vw;
  }
  .start-carousel-content-container {
    margin-top: 5.859vw;
  }
  .upper-1024 {
    display: none;
  }
  .under-1024 {
    display: block;
  }
}

@media (max-width: 375px) {
  .start-carousel-title {
    font-size: 12.800vw;
    margin-bottom: -8.800vw;
    line-height: 24vw;
  }
  .start-carousel-second-title {
    font-size: 11.733vw;
    line-height: 18.400vw;
  }
  .content-box-element {
    flex-direction: row;
    margin: 0;
    margin-bottom: 9.333vw;
  }
  .start-carousel-content-container {
    flex-direction: column;
    margin-top: 6.133vw;
  }
  .wallet-icon-adjustment {
    width: 15.467vw !important;
  }
  .console-icon {
    width: 14.933vw !important;
  }
  .download-cloud {
    width: 15.200vw !important;
  }
  .img-box-for-alignment {
    height: initial;
    margin-bottom: 0;
    margin-right: 4.267vw;
  }
  .start-carousel-description-text {
    padding-left: initial;
    padding-right: initial;
    font-size: 4.267vw;
    margin-top: 0;
  }
  .content-container-button{
    width: 32vw !important;
    margin-left: 4.267vw;
  }
}























