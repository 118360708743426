@import "../../variables";

.custom-connect-wallet{
    position: relative;
    background-color: $pink;
    color: $white;
    font-family: $oswald;
    text-decoration: none;
    text-transform: uppercase;
    line-height: 37px;
    height: 37px;
    width: 100%;
    text-align: center;
    padding: 0 6px;
    cursor: pointer;
  transition: all .2s ease;
  clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
}
.custom-connect-wallet:hover{
  transform: scale(1.1);
  color: white;
}

.wallet-address{
 @extend .custom-connect-wallet;
  cursor: auto;
}

.wallet-address:hover{
  transform: none;
}

.connect-wallet-text{
  font-family: "Oswald", Helvetica;
  font-size: 20px;
}
.connect-wallet-text:hover{
  color: white;
}
