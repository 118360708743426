@import "../../../variables";

.genesis-tabs-container .episode-tab-header h2{
  font-size: 1.302vw;
  font-family: $oswald;
  font-weight: 400;
  margin-bottom: 0.521vw;
}

.episode-tab-container .episode-border {
  width: 2px;
  height: 12.500vw;
  position: absolute;
  background-color: $white;
  left: 1.979vw;
  top: 2.396vw;
}

.nft-tab-image{
  width: 19.792vw;
  height: 9.635vw;
}

.nft-tab-image img{
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .episode-tab-container .episode-border {
    width: 0.130vw;
    height: 15.495vw;
    left: 2.5vw;
    top: 2.396vw;
  }

  .genesis-tabs-container .episode-tab-header h2{
    font-size: 1.823vw;
    width: 20vw;
  }

  .nft-tab-image{
    width: 24.609vw;
    height: 11.979vw;
  }
}

@media screen and (max-device-width: 375px) {
  .nft-tab-image{
  display: none;
  }
  .genesis-tabs-container .episode-tab-header h2{
    font-size: 3.200vw;
    line-height: 4vw;
    margin-bottom: 0;
  }
  .episode-tab-container .episode-border{
    width: 1px;
    height: 12.267vw;
    top: 3.200vw;
    left: 2.133vw;
  }
  .genesis-tabs-container .episode-tab-header{
    width:22.667vw;
    height: 12.267vw;
  }
  }
