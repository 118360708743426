@import '../../../variables';

.dashboard-planet-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.dashboard-planet-image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 197px;
  height: 213px;
  background-image: url('../../../assets/images/ExplorationDashboard/planet-container-border.png');
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;

  &.selected {
    background-image: url('../../../assets/images/ExplorationDashboard/planet-container-selected-border.png');
  }

  @media only screen and (max-width: 1920px) {
    width: 10.26vw;
    height: 11.094vw;
  }
  @media screen and (min-width: 1025px) and (max-width: 1440px) {
    width: 13.681vw;
    height: 14.792vw;
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    width: 15.918vw;
    height: 17.285vw;
  }
  @media screen and (min-width: 376px) and (max-width: 768px) {
    width: 15.885vw;
    height: 17.839vw;
  }
  @media screen and (max-device-width: 375px) {
    width: 30.4vw;
    height: 32.8vw;
  }
}

.dashboard-planet-image-container.clickable:hover {
  cursor: pointer;
  background-image: url('../../../assets/images/planet-border-hover.svg');
}

.dashboard-planet-image-container.selected:hover {
  background-image: url('../../../assets/images/ExplorationDashboard/planet-container-selected-border.png');
}

.dashboard-planet-image {
  position: relative;
  width: 166px;

  @media only screen and (max-width: 1920px) {
    width: 8.646vw;
  }
  @media screen and (min-width: 1025px) and (max-width: 1440px) {
    width: 11.528vw;
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    width: 13.672vw;
  }
  @media screen and (min-width: 376px) and (max-width: 768px) {
    width: 13.542vw;
  }
  @media screen and (max-device-width: 375px) {
    width: 25.867vw;
  }
}

.dashboard-planet-buttons {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  left: 20px;
  top: 23px;
  height: 75%;

  @media screen and (max-device-width: 768px) {
    left: 15px;
    top: 20px;
    height: 73%;
  }
}

.dashboard-planet-favorite-icon {
  width: 20px;
  height: 21px;
  // TODO: Remove this to display favorite icons
  display: none;

  @media only screen and (max-width: 1920px) {
    width: 1.042vw;
    height: 1.094vw;
  }
  @media screen and (min-width: 1025px) and (max-width: 1440px) {
    width: 1.389vw;
    height: 1.458vw;
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    width: 1.953vw;
    height: 2.051vw;
  }
  @media screen and (min-width: 376px) and (max-width: 768px) {
    width: 2.344vw;
    height: 2.474vw;
  }
  @media screen and (max-device-width: 375px) {
    width: 4vw;
    height: 4vw;
  }
}

.dashboard-planet-lock {
  width: 17px;
  height: 23px;

  @media screen and (max-width: 1024px) {
    width: 11px;
    height: 16px;
  }
}

.dashboard-planet-overlay {
  position: absolute;
  width: 54px;
  height: 86px;
  @media screen and (min-width: 376px) and (max-width: 768px) {
    width: 7.031vw;
    height: 11.198vw;
  }
  @media screen and (max-device-width: 375px) {
    width: 50%;
    height: 50%;
  }
}

.dashboard-planet-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 9px 20px;
  background-color: transparent;

  @media only screen and (max-width: 1920px) {
    padding: 0.469vw 1.042vw;
  }
  @media screen and (min-width: 1025px) and (max-width: 1440px) {
    padding: 0.625vw 1.389vw;
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    padding: 0.879vw 1.953vw;
  }
  @media screen and (min-width: 376px) and (max-width: 768px) {
    padding: 1.042vw 2.344vw;
  }
  @media screen and (max-device-width: 375px) {
    padding: 0.8vw 2.3vw;
  }
}

.dashboard-planet-info {
  font-family: $oswald;
  font-weight: 400;
  font-size: 1.625rem;
  @media only screen and (max-width: 1920px) {
    font-size: clamp(1.25rem, 1.354vw, 1.625rem);
  }
  @media screen and (min-width: 1025px) and (max-width: 1440px) {
    font-size: clamp(1.125rem, 1.806vw, 1.625rem);
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    font-size: clamp(0.93rem, 1.953vw, 1.25rem);
  }
  @media screen and (min-width: 376px) and (max-width: 768px) {
    font-size: clamp(0.4rem, 1.823vw, 0.875rem);
  }
  @media screen and (max-device-width: 375px) {
    font-size: clamp(0.4rem, 3.733vw, 0.875rem);
  }
}

.dashboard-planet-id,
.dashboard-planet-location {
  font-size: 0.875rem;
  @media screen and (min-width: 1025px) and (max-width: 1440px) {
    font-size: clamp(0.75rem, 0.972vw, 0.875rem);
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    font-size: 0.875rem;
  }
  @media screen and (min-width: 376px) and (max-width: 768px) {
    font-size: clamp(0.5rem, 1.823vw, 0.875rem);
  }
  @media screen and (max-device-width: 375px) {
    font-size: 0.375rem;
  }
}

.dashboard-planet-focus {
  cursor: pointer;
  width: 27px;

  @media only screen and (max-width: 1920px) {
    width: 1.406vw;
  }
  @media screen and (min-width: 1025px) and (max-width: 1440px) {
    width: 2.292vw;
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    width: 2.93vw;
  }
  @media screen and (min-width: 376px) and (max-width: 768px) {
    width: 3.385vw;
  }
  @media screen and (max-device-width: 375px) {
    width: 4.533vw;
  }
}
