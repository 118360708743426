.currency-modal-container{
  width: 483px;
  height: 350px;
}
.currency-principle-container{
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.currency-text-description{
  text-align: center;
}
.currency-header-horns{
  position: absolute;
  top:-10px
}
.currency-bottem-horns{
  position: absolute;
  bottom: -10px;
}
.currency-buttons{
  width: 100%;
  margin-top: 50px;
  margin-bottom: 27px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.currency-usdc{
  margin-right: 40px;
}
.currency-usdt{
  margin-left: 40px;
}
.currency-usdt-balance{
  margin-left: 10px;
  font-family: Oswald;
  font-size: 14px;
}
.currency-usdc-balance{
  margin-left: 10px;
  font-family: Oswald;
  font-size: 14px;
}