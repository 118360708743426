@import "../../../variables";

.announcement-content-container {
  width: 100%;
  height: 100%;
}

.announcement-modal {
  width: 770px;
  height: 506px;
  max-width: none;
}

.announcement-modal .modal-content {
  background-color: transparent;
}


.announcement-background {
  width: 770px;
  height: 506px;
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid white;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.announcement-content {
  width: 600px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.announcement-title {
  position: relative;
  font-size: 48px;
  font-family: $oswald;
  width: 297px;
  text-align: center;
  line-height: 59px;
  margin-top: 75px;
}
.announcement-text {
  position: relative;
  font-size: 16px;
  font-family: $sofia;
  width: 321px;
  line-height: 24px;
  text-align: center;
  margin-top: 24px;
}
.announcement-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 115px;
  height: 37px;
  background-color: $pink;
  font-family: $oswald;
  font-size: 16px;
  clip-path: polygon(6px 0, 0 6px, 0 calc(100% - 6px), 6px 100%, calc(100% - 6px) 100%, 100% calc(100% - 6px), 100% 6px, calc(100% - 6px) 0);
  margin-top: 49px;
  cursor: pointer;
}
.announcement-asteroid {
  position: absolute;
  top: -130px;
  left: -378px;
  height: 760px;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .announcement-modal {
    width: 53.472vw;
    height: 35.139vw;
  }
  .announcement-background {
    width: 53.472vw;
    height: 35.139vw;
  }
  .announcement-content {
    width: 41.667vw;
  }
  .announcement-title {
    font-size: 3.333vw;
    width: 20.625vw;
    line-height: 4.097vw;
    margin-top: 5.208vw;
  }
  .announcement-text {
    font-size: 1.111vw;
    width: 22.292vw;
    line-height: 1.667vw;
    margin-top: 1.667vw;
  }
  .announcement-button {
    width: 7.986vw;
    height: 2.569vw;
    font-size: 1.111vw;
    margin-top: 3.403vw;
  }
  .announcement-asteroid {
    top: -9.028vw;
    left: -26.250vw;
    height: 52.778vw;
  }
  }

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .announcement-modal {
    width: 57.031vw;
    height: 55.566vw;
  }
  .announcement-background {
    width: 57.031vw;
    height: 55.566vw;
  }

  .announcement-content {
    width: 100%;
  }
  .announcement-title {
    font-size: 4.688vw;
    width: 100%;
    line-height: 2.344vw;
    margin-top: 28.320vw;
  }
  .announcement-text {
    font-size: 1.563vw;
    line-height: 2.344vw;
    width: 49.121vw;
    margin-top: 5.273vw;
  }
  .announcement-button {
    width: 11.523vw;
    height: 3.613vw;
    margin-top: 3.906vw;
    font-size: 1.563vw;
  }
  .announcement-asteroid {
    left: -2.344vw;
    top: -20.313vw;
    transform: rotate(-54deg);
    height: 62.500vw;
  }
}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .announcement-modal {
    width: 76.042vw;
    height: 74.089vw;
  }
  .announcement-background {
    width: 76.042vw;
    height: 74.089vw;
  }
  .announcement-content {
    width: 100%;
  }
  .announcement-title {
    font-size: 6.250vw;
    width: 100%;
    line-height: 3.125vw;
    margin-top: 37.760vw;
  }
  .announcement-text {
    font-size: 2.083vw;
    line-height: 3.125vw;
    width: 65.495vw;
    margin-top: 7.031vw;
  }
  .announcement-button {
    width: 15.365vw;
    height: 4.818vw;
    margin-top: 5.208vw;
    font-size: 2.083vw;
  }
  .announcement-asteroid {
    left: -3.125vw;
    top: -27.083vw;
    transform: rotate(-54deg);
    height: 83.333vw;
  }
}

@media screen and (max-width: 375px) {
  .modal-backdrop{
    width: 100%;
    height: 100%;
  }
  .announcement-modal {
    width: 78.400vw;
    height: 131.200vw;
  }
  .announcement-background {
    width: 78.400vw;
    height: 131.200vw;
  }
  .announcement-content {
      width: 100%;
  }
  .announcement-title {
    font-size: 9.600vw;
    line-height:11.733vw;
    margin-top: 28.8vw;
    width: 66.400vw;
  }
  .announcement-text {
    font-size: 4.267vw;
    width: 68.800vw;
    margin-top: 5.333vw;
    line-height: 6.400vw;
  }
  .announcement-button {
    width: 28.800vw;
    height: 9.333vw;
    font-size: 4vw;
    margin-top: 6.400vw;
  }
  .announcement-asteroid {
    left: -8vw;
    top: -39.467vw;
    height: 93.333vw;
    transform: rotate(-58deg);
  }
  }
