@import "../../../variables";

.generative-starfighter-mintpass-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 65px;
  margin-top: 60px;
  @media screen and (max-device-width: 375px) {
    margin-top: 25px;
    margin-bottom: 45px;
  }
}

.generative-starfighter-mintpass-slider{
  display: none;
  width: 600px;
  margin-left: 50px;
  @media screen and (max-device-width: 375px) {
    display: block;
  }
}


.generative-starfighter-mintpass-title{
  font-family: $oswald;
  font-size: 2.8125rem;
  margin-bottom: 8px;

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    font-size: 2.375rem;
  }
  @media screen and (min-width: 376px) and (max-width: 768px) {
    font-size: 1.5rem;
  }
  @media screen and (max-device-width: 375px) {
    font-size: 1.5rem;
  }
}

.generative-starfighter-mintpass-helper{
  font-family: $oswald;
  font-size: 1.25rem;
  margin-bottom: 45px;
  color: $very-light-gray;
  cursor: pointer;
  @media screen and (max-device-width: 375px) {
    font-size: 1.125rem;
    margin-bottom: 10px;
  }
}

.generative-starfighter-mintpass-grid{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 2rem;
  column-gap: 1rem;
  width: 70%;

  @media screen and (max-width: 1440px) {
    width: 80%;
  }

  @media screen and (max-width: 1024px) {
    width: 70%;
  }

  @media screen and (max-width: 768px) {
    column-gap: 1rem;
  }
  @media screen and (max-width: 375px) {
    display: none;
  }

  &.batch {
    @media screen and (max-width: 375px) {
      display: flex;
    }
  }
}

@supports (-webkit-touch-callout: none) {
  @media screen and (min-width: 376px) and (max-width: 768px) {
    .generative-starfighter-mintpass-helper{
      margin-bottom: 0;
    }
    .generative-starfighter-mintpass-grid {
      gap: 0 0;
    }
    .generative-starfighter-border {
      margin: 40px 15px;
    }
  }

}

