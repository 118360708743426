@import "src/variables";

.rewards-planet-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 62px;
  width: 100%;
  @media screen and (min-width: 376px) and (max-width: 768px) {
    width: unset;
    @supports (-webkit-touch-callout: none) {
      width: 380px;
    }
  }

  @media screen and (max-width: 375px) {
    margin-bottom: 51px;
  }
}

.rewards-planet-title-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: $oswald;
  font-size: 1.75rem;
  text-align: center;
  margin-bottom: 40px;
  @media screen and (max-width: 1024px) {
    font-size: 1.3125rem;
    margin-bottom: 25px;
  }
  @media screen and (max-width: 768px) {
    font-size: 1.3125rem;
  }
  @media screen and (max-device-width: 375px) and (max-width: 375px) {
    font-size: 1.25rem;
  }
}

.rewards-planet-data-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px 24px;
  @media screen and (max-width: 1024px) {
    gap: 45px 16px;
  }
  @media screen and (max-width: 768px) {
    gap: 25px 15px;
    width: 350px;
  }
  @media screen and (max-device-width: 375px) and (max-width: 375px){
    gap: 30px 15px;
  }
}

@supports (-webkit-touch-callout: none) {

  @media screen and (min-width: 1025px) {
    .rewards-planet-data-container {
      margin: 0 0 17px 0;
    }

    .rewards-planet-title-container {
      font-size: 1.3125rem;
    }
  }

  .rewards-planet-data-container {
    grid-gap: 0 0;
    gap: 0 0;
  }
}
