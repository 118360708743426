.fighter-lady-main-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 147px;
}

.align-lady-img {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.fighter-lady-img-small {
  display: none;
  position: relative;
  top: 145px;
  opacity: 0;
  animation: circle-fade-in-animation 0.6s ease-in-out 0.6s 1 normal forwards;
}

.fighter-lady-img {
  position: relative;
  top: 145px;
  z-index: 2;
  opacity: 0;
  animation: circle-fade-in-animation 0.6s ease-in-out 0.6s 1 normal forwards;
  pointer-events: none;
}

.fighter-lady-background-circle {
  position: absolute;
  width: 757px;
  height: 402px;
  right: -103px;
  opacity: 0;
  animation: circle-fade-in-animation 1s ease-in-out 0.6s 1 normal forwards;
}


@keyframes fade-in-animation {
  from {
    opacity: 0;
    transform: translate(0, 100px)
  }
  to {
    opacity: 1;
    transform: translate(0, 0)
  }
}

@keyframes circle-fade-in-animation {
  from {
    opacity: 0;
    transform: translate(0, 150px)
  }
  to {
    opacity: 1;
    transform: translate(0, 0)
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .fighter-lady-main-container {
    margin-right: 10.208vw;
  }
  .fighter-lady-img {
  }
}
@media screen and (min-width: 769px) and (max-width: 960px) {
  .fighter-lady-img {
    z-index: 1;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .fighter-lady-main-container {
    margin-right: 0px;
    width: 57.227vw;
  }

  .fighter-lady-background-circle {

  }
}

@media screen and (min-width: 376px) and (max-width: 768px) {

  .fighter-lady-main-container {
    margin: -4px;
  }

  .fighter-lady-img {
    display: none;
  }
  .fighter-lady-img-small {
    display: initial;
    top:15.104vw;
    width: 47.396vw;
    height: 96.745vw;
  }
  .fighter-lady-background-circle {
    width: 58.073vw;
    height:30.990vw;
    right: -6.250vw;
    top: 17.188vw;
  }


}
@media screen and (max-device-width: 375px) {
  .fighter-lady-img {
    display: none;
  }
  .fighter-lady-img-small {
    display: initial;
    top: 118px;
  }
  .fighter-lady-main-container{
    margin: 0px;
  }

  .fighter-lady-background-circle{
    width:509px;
    height: 270px;
    right:initial;
    top: 16px;
    border-radius: 0px;
  }
  .align-lady-img{
    height: 507px;
    margin-top: 60px;
  }
}
