.planet-exploration-timer-counter-main-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 216.8px;
  margin-top: -41px;
}

.planet-exploration-timer-counter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 505px;
  height: 217px;
}

.planet-exploration-timer-counter-container .next-claim-text {
  font-size: 1rem;
  margin-top: 21px;
}

.planet-exploration-timer-counter-container .sale-countdown-container {
  background: none;
  width: 100%;
  height: 92px;
}

.planet-exploration-timer-counter-container .countdown-time-text {
  font-size: 2.25rem;
}

.countdown-time-text-separator {
  @extend .countdown-time-text;
}

.planet-exploration-timer-counter-container .countdown-time-under-text {
  font-size: 0.875rem;
}

.planet-exploration-timer-counter-container .top-decoration,
.planet-exploration-timer-counter-container .bottom-decoration {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.planet-exploration-timer-counter-container .bottom-decoration {
  margin-top: 40px;
}

.planet-exploration-timer-counter-container .top-crown-decoration,
.planet-exploration-timer-counter-container .bottom-crown-decoration {
  width: 67px;
  height: 34px;
}

.planet-exploration-timer-counter-container .line-separator {
  width: 100%;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .planet-exploration-timer-counter-main-container {
    height: 216.8px;
    margin-top: -105px;
  }
}

@media screen and (max-width: 768px) {
  .planet-exploration-timer-counter-main-container {
    height: 28.229vw;
    margin-top: -5.339vw;
  }

  .planet-exploration-timer-counter-container {
    width: 65.755vw;
    height: 28.255vw;
  }

  .planet-exploration-timer-counter-container .next-claim-text {
    font-size: clamp(0.75rem, 2.083vw, 1rem);
    margin-top: 2.734vw;
    margin-bottom: 5px;
  }

  .planet-exploration-timer-counter-container .sale-countdown-container {
    height: 11.979vw;
  }

  .planet-exploration-timer-counter-container .countdown-time-text {
    font-size: clamp(1.3rem, 4.688vw, 2.25rem);
  }

  .planet-exploration-timer-counter-container .countdown-time-under-text {
    font-size: clamp(0.5rem, 1.823vw, 0.875rem);
  }

  .planet-exploration-timer-counter-container .bottom-decoration {
    margin-top: 5.208vw;
  }

  .planet-exploration-timer-counter-container .top-crown-decoration,
  .planet-exploration-timer-counter-container .bottom-crown-decoration {
    width: 8.724vw;
    height: 4.427vw;
  }

  .planet-exploration-timer-counter-container .bottom-decoration {
    padding-top: 14.323vw;
  }
}

@media screen and (max-device-width: 375px) {
  .planet-exploration-timer-counter-main-container {
    height: unset;
    margin-top: -28vw;
  }

  .top-crown-decoration {
    width: 48px;
    height: 24.5px;
  }

  .planet-exploration-timer-counter-container {
    width: 100%;
    height: unset;
  }

  .planet-exploration-timer-counter-container .next-claim-text {
    font-size: 0.72rem;
    margin-top: 25px;
    margin-bottom: 0;
  }

  .planet-exploration-timer-counter-container .sale-countdown-container {
    height: 80px;
  }

  .planet-exploration-timer-counter-container .countdown-time-text {
    font-size: 1.5625rem;
  }

  .planet-exploration-timer-counter-container .countdown-time-under-text {
    font-size: 0.625rem;
  }

  .planet-exploration-timer-counter-container .bottom-decoration {
    margin-top: 50px;
  }

  .planet-exploration-timer-counter-container .top-crown-decoration,
  .planet-exploration-timer-counter-container .bottom-crown-decoration {
    width: 48px;
    height: 24.5px;
  }
}
