@import 'src/variables';

.abort-mission-container {
  position: fixed;
  z-index: 11;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .abort-mission-top-border, .abort-mission-bottom-border {
    display: flex;
    justify-content: space-between;
  }

  .abort-mission-bottom-border {
    margin-top: -1px;
  }

  .abort-mission-top-left, .abort-mission-top-right, .abort-mission-bottom-left, .abort-mission-bottom-right {

    @media screen and (min-width: 376px) and (max-width: 768px) {
      width: 42.969vw;
    }

    @media screen and (max-device-width: 375px) {
      width: 42.675vw;
    }
  }

  .abort-mission-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 683px;
    height: 407.26px;
    background-color: black;

    @media screen and (min-width: 376px) and (max-width: 768px) {
      width: 88.932vw;
      height: 53.029vw;
    }

    @media screen and (max-device-width: 375px) {
      width: 88.555vw;
      height: 61.443vw;
    }

    .abort-mission-icon {
      width: 63.38px;
      height: 70.42px;
      margin-bottom: 20px;

      @media screen and (min-width: 376px) and (max-width: 768px) {
        width: 8.253vw;
        height: 9.169vw;
        margin-bottom: 2.604vw;
      }

      @media screen and (max-device-width: 375px) {
        width: 10.381vw;
        height: 11.533vw;
        margin-bottom: 3.467vw;
      }
    }

    .abort-mission-text {
      font-size: 1.375rem;
      font-family: $sofia;
      text-align: center;
      font-weight: 400;
      line-height: 3.5rem;

      @media screen and (min-width: 376px) and (max-width: 768px) {
        font-size: clamp(0.6875rem, 2.865vw, 1.375rem);
        line-height: 7.292vw;
      }

      @media screen and (max-device-width: 375px) {
        font-size: clamp(0.4rem, 2.933vw, 0.6875rem);
        line-height: 6.400vw;
      }
    }

    .abort-mission-buttons {
      display: flex;
      justify-content: space-between;
      width: 331px;
      margin-top: 26px;

      @media screen and (min-width: 376px) and (max-width: 768px) {
        width: 43.099vw;
        margin-top: 3.385vw;
      }

      @media screen and (max-device-width: 375px) {
        width: 54.133vw;
        margin-top: 4.800vw;
      }

      .abort-mission-confirm-button-border {
        width: 159px;
        height: 37px;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: $pink;
        clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);

        @media screen and (min-width: 376px) and (max-width: 768px) {
          width: 20.703vw;
          height: 4.818vw;
          clip-path: polygon(0.911vw 0, 0 0.911vw, 0 calc(100% - 0.911vw), 0.911vw 100%, calc(100% - 0.911vw) 100%, 100% calc(100% - 0.911vw), 100% 0.911vw, calc(100% - 0.911vw) 0);
        }

        @media screen and (max-device-width: 375px) {
          width: 26.040vw;
          height: 6.059vw;
          clip-path: polygon(0.800vw 0, 0 0.800vw, 0 calc(100% - 0.800vw), 0.800vw 100%, calc(100% - 0.800vw) 100%, 100% calc(100% - 0.800vw), 100% 0.800vw, calc(100% - 0.800vw) 0);
        }

        .abort-mission-confirm-button {
          width: 157px;
          height: 35px;

          color: white;
          font-family: $oswald;
          font-weight: 400;
          font-size: 1.25rem;

          margin: 1px;
          border: none;
          background-color: black;
          clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
          transition-duration: 0.3s;

          @media screen and (min-width: 376px) and (max-width: 768px) {
            width: 20.443vw;
            height: 4.557vw;
            font-size: clamp(0.75rem, 2.604vw, 1.25rem);
            clip-path: polygon(0.911vw 0, 0 0.911vw, 0 calc(100% - 0.911vw), 0.911vw 100%, calc(100% - 0.911vw) 100%, 100% calc(100% - 0.911vw), 100% 0.911vw, calc(100% - 0.911vw) 0);
          }

          @media screen and (max-device-width: 375px) {
            width: 25.507vw;
            height: 5.525vw;
            font-size: clamp(0.4rem, 3.200vw, 0.75rem);
            clip-path: polygon(0.800vw 0, 0 0.800vw, 0 calc(100% - 0.800vw), 0.800vw 100%, calc(100% - 0.800vw) 100%, 100% calc(100% - 0.800vw), 100% 0.800vw, calc(100% - 0.800vw) 0);
          }
        }
      }

      .abort-mission-confirm-button:hover {
        color: rgba(255, 255, 255, 0.5);
      }

      .abort-mission-cancel-button {
        width: 159px;
        height: 37px;

        color: white;
        font-family: $oswald;
        font-weight: 400;
        font-size: 1.25rem;

        background-color: $pink;
        border: none;
        clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
        transition-duration: 0.3s;

        @media screen and (min-width: 376px) and (max-width: 768px) {
          width: 20.703vw;
          height: 4.818vw;
          font-size: clamp(0.75rem, 2.604vw, 1.25rem);
          clip-path: polygon(0.911vw 0, 0 0.911vw, 0 calc(100% - 0.911vw), 0.911vw 100%, calc(100% - 0.911vw) 100%, 100% calc(100% - 0.911vw), 100% 0.911vw, calc(100% - 0.911vw) 0);
        }

        @media screen and (max-device-width: 375px) {
          width: 25.507vw;
          height: 5.525vw;
          font-size: clamp(0.4rem, 3.200vw, 0.75rem);
          clip-path: polygon(0.800vw 0, 0 0.800vw, 0 calc(100% - 0.800vw), 0.800vw 100%, calc(100% - 0.800vw) 100%, 100% calc(100% - 0.800vw), 100% 0.800vw, calc(100% - 0.800vw) 0);
        }
      }

      .abort-mission-cancel-button:hover {
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }
}
