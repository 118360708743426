@import "../../../variables";

.astrafite-rush-progress-main-container {
  position: relative;
  width: 100%;
  min-height: 1500px;
  margin-bottom: 104px;
  z-index: 5;
  overflow: hidden;
  background: #0b0808;
  -webkit-clip-path: polygon(
    0 0,
    50% 95px,
    100% 0,
    100% calc(100% - 95px),
    50% 100%,
    0 calc(100% - 95px)
  );
  clip-path: polygon(
    0 0,
    50% 95px,
    100% 0,
    100% calc(100% - 95px),
    50% 100%,
    0 calc(100% - 95px)
  );
}

.astrafite-rush-progress-content {
  margin-top: 104px;
  margin-bottom: 104px;
  width: 100%;
  height: 100%;
}

.astrafite-rush-progress-title {
  .page-header {
    padding-bottom: 0;
  }
}

.astrafite-rush-progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .astrafite-rush-progress-opensea-button-container {
    width: 13.831rem;
  }

  .astrafite-rush-token-image {
    width: 746px;
  }
}

@media screen and (max-width: 1024px) {
  .astrafite-rush-progress-main-container {
    min-height: 1350px;
  }
  .astrafite-rush-progress-container {
    .astrafite-rush-token-image {
      margin: 0 auto;
      width: 35.279rem;
    }
  }
}

@media screen and (max-width: 765px) {
  .astrafite-rush-progress-container {
    .astrafite-rush-token-image {
      margin: 0 auto;
      width: 21.413rem;
    }
  }
}
