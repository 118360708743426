$elem-z-index: 1;

.rounded-description-box-container{
  display: flex;
  align-items: center;
  text-align: center;
  padding: 8px 88px 8px 88px;
  background: #3B3B3B;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  z-index: $elem-z-index;
}

.rounded-description-box-text{
  width: 100%;
  height: 100%;
  font-size: 14px;
  margin-bottom: 0;
  white-space: pre-wrap;
  z-index: $elem-z-index;
}
