@use '/src/variables.scss' as *;
@import url('https://fonts.googleapis.com/css?family=Oswald:400'); // Oswald from Google Fonts
@import url('https://use.typekit.net/lkd0gjo.css'); // Sofia Pro from Adobe Fonts
@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/mixins';

$images-path: './assets/images/';
$grid-breakpoints: (
  lg: 1025px
);

body {
  background-color: $black;
  color: $white;
  font-family: $sofia;
}

body > iframe {
  pointer-events: none;
}

.main {
  background: url(#{$images-path}side-dots.png) center center no-repeat;
  height: 350px;
}

@include media-breakpoint-up(lg) {
  .main {
    height: 100%;
    margin-bottom: 35px;
  }
}

/* ********************* */
/* ***    NAV BAR    *** */
.navbar-brand {
  margin: 0 auto;
  padding-top: 0;
}

#basic-navbar-nav {
  display: none;
}

@include media-breakpoint-up(lg) {
  .navbar-brand {
    margin: 0;
  }

  #basic-navbar-nav {
    display: block;
  }
}

/* ********************** */
/* ***   CODE PANEL   *** */
.code-panel {
  width: 80%;
  position: relative;
  max-width: 505px;
  min-width: 300px;
  padding: 48px 0;
  border-bottom: $white 1px solid;
  border-top: $white 1px solid;
  z-index: 13;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.code-panel::before {
  content: url(#{$images-path}section-top.png);
  width: auto;
  position: absolute;
  top: -34px;
  left: 50%;
  margin-left: -34px;
}
.code-panel::after {
  content: url(#{$images-path}section-bottom.png);
  position: absolute;
  left: 50%;
  bottom: -39px;
  margin-left: -34px;
}

.code-panel p {
  text-align: center;
}

.code-panel .button {
  margin: 20px auto;
  text-align: center;
  width: 50%;
}

.code-panel .accessToken {
  background-color: $darkgrey;
  border: none;
  color: $white;
  padding: 5px;
  width: 100%;
}

hr.downward-vee {
  position: relative;
  height: 190px;
  background: url(#{$images-path}banner-bottom.png) top center no-repeat;
  border: none;
  margin-top: -95px;
  margin-bottom: -95px;
  z-index: 10;
  opacity: 1;
  width: fit-content;
}

/* *********************** */
/* *** SUBSCRIBE PANEL *** */
.subscribe {
  position: relative;
  padding-top: 55px;
  background: url(#{$images-path}subscribe-background-image.png) center center
    no-repeat;
}
.subscribe h2 {
  position: relative;
  color: $white;
  font-family: $oswald;
  text-transform: uppercase;
  text-align: center;
  width: 350px;
  margin: 100px auto 0;
  padding-top: 20px;
}
.subscribe h2::before {
  content: url(#{$images-path}attention-left.png);
  position: absolute;
  left: 0;
  top: 0;
}
.subscribe h2::after {
  content: url(#{$images-path}attention-right.png);
  position: absolute;
  right: 0;
  top: 0;
}
@include media-breakpoint-up(sm) {
  .subscribe h2 {
    margin: 200px auto 0;
  }
}
@include media-breakpoint-up(lg) {
  .subscribe {
    min-height: 650px;
  }
  .subscribe h2 {
    margin: 300px auto 0;
  }
}

.subscribe h2 span {
  display: block;
  text-transform: lowercase;
}

.subscribe-form {
  margin: 0 auto;
  text-align: center;
}

.subscribe-form label {
  position: relative;
  display: inline-block;
  margin: 40px 0;
  width: 55%;
}
.subscribe-form label::before {
  content: url(#{$images-path}input-left.png);
  position: absolute;
  left: -17px;
}

.subscribe-form label span {
  position: absolute;
  left: -99999px;
}

.subscribe-form input {
  border: none;
  width: 100%;
  height: 37px;
  line-height: 37px;
}

.subscribe-form button {
  border: none;
  margin: 0 0 20px 0;
}

.subscribe h3 {
  color: $white;
  text-transform: lowercase;
  text-align: center;
}

.subscribe .fas,
.subscribe .fab {
  color: $white;
}

.subscribe .icons {
  text-align: center;
  background: url(#{$images-path}subscribe-icons-bottom.png) center bottom
    no-repeat;
  height: 70px;
  margin-bottom: 60px;
}

.subscribe .icons a {
  display: inline-block;
  margin: 0 15px;
}

.lady {
  background: url(#{$images-path}lady_sm.png) bottom left no-repeat;
  width: 375px;
  height: 654px;
}
@include media-breakpoint-up(lg) {
  .lady {
    display: none;
  }
}

/* ********************** */
/* *** FOOTER CONTENT *** */
footer {
  position: relative;
  background-color: $pink;
  height: 147px;
}

.logo {
  display: block;
}
.logo.animoca {
  background: url(#{$images-path}logo-animoca-sm.png) top left no-repeat;
  width: 105px;
  height: 57px;
}
.logo.blowfish {
  background: url(#{$images-path}logo-blowfish-sm.png) top left no-repeat;
  width: 148px;
  height: 49px;
}

.logo span {
  position: absolute;
  left: -1000px;
}

.blowfish-info {
  text-align: right;
}
.blowfish-info p {
  margin: 0;
  color: $white;
}

.lady-lg {
  display: none;
}
@include media-breakpoint-up(lg) {
  .lady-lg {
    display: block;
    background: url(#{$images-path}lady.png) bottom left no-repeat;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 777px;
    height: 816px;
  }
}

/* ********************** */
/* *** UTILITY STYLES *** */
.button {
  position: relative;
  background-color: $pink;
  color: $white;
  font-family: $oswald;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 37px;
  height: 37px;
  width: 130px;
  text-align: center;
  padding: 0 6px;
  margin: 0 18px;
  cursor: pointer;
}

.button::before {
  content: url(#{$images-path}button-left.png);
  position: absolute;
  left: -10px;
  top: 0;
}
.button::after {
  content: url(#{$images-path}button-right.png);
  position: absolute;
  right: -10px;
  top: 0;
}

.smart-layers-pointers,
.smart-layers-pointers * {
  pointer-events: auto;
  visibility: visible;
}

body {
  font-family: $sofia;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $oswald;
}

