.genesis-title{
  margin-top: 2.083vw;
}
.genesis-title .page-mobile-header-text-no-margin{
  margin-bottom: -1.042vw;
  font-size: 2.344vw;
}

.genesis-title .page-header {
  font-size: 2.344vw;
  margin-top: 20px;
  padding-bottom: 5.208vw;
  height: 20.833vw;
}

.genesis-title .psg-border-img-mini-screen{
  width: 100%;
}

.genesis-tabs-container .col-sm-4{
  flex: 0 0 auto;
  width: 33.33333333%;
}
.genesis-tabs-container .col-sm-6 {
  flex: 0 0 auto;
  width: 50%;
}

.genesis-tabs-container .nft-row-add-margin{
  margin-left: 8.073vw;
  margin-right: 8.073vw;
  margin-bottom: 5.208vw;
}

.genesis-tabs-container .vertical-rule-container{
  padding-left: 6.250vw;
  width: 12%;
}

.genesis-tabs-container .episode-tabs{
  grid-gap: 0;
}

.genesis-tabs-container .vertical-rule-sticky-out-thingy{
  height: 2.604vw;
  clip-path: polygon(0 0, 6px 10%, 6px 90%, 0% 100%);
}

.genesis-error {
  width: 30%;
  margin: auto;

  .planet-purchase-error-text {
    margin-left: auto;
  }

  @media screen and (max-device-width: 1024px) {
    width: 60%;
  }
  @media screen and (max-device-width: 425px) {
    width: 100%;
  }
}

.mintpass-info {
  &__images-container {
    display: flex;
    gap: 1rem;
  }

  &__description {
    padding: 2rem 0 4rem;
  }
}

@media screen and (max-device-width: 375px) {
  .genesis-nft-combination-row{
    margin: 0;
  }
  .vertical-rule-sticky-out-thingy,
  .vertical-rule {
    display: none;
  }

  .genesis-title{
    margin-top: 22.667vw;
  }
  .genesis-title .page-header .page-mobile-header-text-no-margin {
    font-size: 8vw;
    margin-bottom: -1.600vw;
  }

  .genesis-tabs-container .nft-row-add-margin{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .genesis-tabs-container .nft-row-add-margin .col-sm-4{
    width: 100%;
  }

  .genesis-tabs-container .nft-row-add-margin .nav {
    flex-wrap: nowrap;
  }
  .genesis-tabs-container{
    margin-top: 16.533vw;
  }
  .genesis-tabs-container .nft-row-add-margin{
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 8vw;
  }
  .genesis-tabs-container .col-sm-6{
    width: 100%;
  }
  .genesis-tabs-container .episode-detail .row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .genesis-tabs-container .container-fluid{
    padding: 0;
  }
}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .genesis-title{
    margin-top: 3.5vw;
    margin-bottom: -1.172vw;
    overflow: hidden;
  }

  .genesis-title .page-header {
    width: 250vw;
    height: 48.177vw;
    margin-left: -75.521vw;
  }

  .genesis-title .page-header .page-mobile-header-text-no-margin {
    font-size: 5.208vw;
    line-height: 8.684vw;
    margin-bottom: -2vw;
  }

  .genesis-title .page-header .psg-border-img-tablet {
    display: none;
  }

  .genesis-title .page-header .psg-border-img-pc{
    display: flex;
    width: 188.802vw;
    height: 48.177vw;
  }

  .genesis-tabs-container{
   margin-bottom: 11.068vw;
  }

  .vertical-rule-sticky-out-thingy,
  .vertical-rule {
    display: none;
  }

  .genesis-tabs-container .nft-row-add-margin{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
  }

  .genesis-tabs-container .nft-row-add-margin .col-sm-4{
    width: 100%;
  }

  .genesis-tabs-container .nft-row-add-margin .nav {
    flex-wrap: nowrap!important;
  }

  .genesis-tabs-container .nft-row-add-margin .col-sm-6{
    width: 93%;
  }
}
.genesis-nft-row {
  margin-bottom: 50px;
  @media screen and (max-width: 768px) {
    margin: 16px;
  }
}
