@import "../../variables";

.top-menu-main-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 77px;
  background-color: black;
  margin-bottom: 17px;
  position: fixed;
  z-index: 10;
  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.top-menu-logo{
  margin-top: 10px;
  margin-left: 12px;
  width: 120px;
  height: 60px;
}
