.planet-purchase-modal-container {
  width: 8000px;
  height: 350px;
  justify-content: center;
  align-items: center;
}

.planet-purchase-principle-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.planet-purchase-steps-container {
  margin: 15px;
}

.planet-purchase-text-description {
  text-align: center;
}

.planet-purchase-header-horns {
  position: absolute;
  top: -10px;
  align-items: center;
  justify-content: center;
}

.planet-purchase-bottom-horns {
  position: absolute;
  bottom: -10px;
  align-items: center;
  justify-content: center;
}

.planet-purchase-steps {
  margin: 10px;
  justify-content: center;
  align-items: center;
  //grid-template-columns: 3fr 1fr;
}

.planet-purchase-buttons {
  width: 60%;
  margin-top: 50px;
  margin-bottom: 27px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
