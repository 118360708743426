@import '../variables';
@import '../functions';

.page-header {
  .planet-dashboard.page-mobile-header-text-no-margin {
    font-size: pxToRem(30px);

    @media screen and (min-width: $xs-breakpoint) {
      font-size: pxToRem(34px);
    }

    @media screen and (min-width: $md-breakpoint) {
      font-size: pxToRem(45px);
      margin-bottom: 24px;
    }
  }
}

.planet-dashboard {
  &__modal-text {
    &-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      font-size: 1rem;
    }
  }

  &__modal-button {
    margin-top: 25px;
  }

  &__modal-success-icon {
    width: 36px;
    margin-bottom: 25px;
  }
}


