@import "../../../../../../../variables";
.mb-principle-body{
  width: 129px;
  height: 34px;
  margin-top: 21px;
  background-color: $pink;
  clip-path: polygon(8% 0%, 100% 0%, 92% 100%, 0% 100%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.mb-principle-body-inactive{
  @extend .mb-principle-body;
  background-color: $stack;
  color:rgba(255, 255, 255, 0.5);
  cursor: auto;
}
.mb-text-adjustment{
  margin: 0px;
  font-family: Oswald;
}
