@import "../../../../variables";

.drow-background{
  position: relative;
  clip-path: polygon(12% 0%, 0 12%, 0 100%, 88% 100%, 100% 88%, 100% 0%);
  width: $polygon-width;
  height: $polygon-height;
  background-color: $cherry;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.polygon-content-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 14px;
}

.plygon-text-adjustment{
  line-height: 15px;
  font-size: 16px;
  text-align: center;
}

.polygon-img-dimension{
  width: auto;
  height: auto;
  border: transparent;
  outline: none;
}
