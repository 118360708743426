@import "../../src/variables";

.connect-wallet-button:hover {
  cursor: pointer;
}

.remove-margin-from-connect-wallet {
  margin-top: 0px !important;
  display: flex;
  padding: 0px !important;
  padding-bottom: 10px !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 305px;
}

.set-connect-wallet-button-design {
  width: 89%;
  max-width: 360px;
}

.set-connect-wallet-font-size {
  font-size: 20px;
}

.adjust-connect-wallet-page {
  position: absolute;
  top: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.connect-wallet-background {
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  clip-path: polygon(0 0, 50% 10%, 100% 0, 100% 92%, 50% 100%, 0 92%);
}

.background-container {
  position: relative;
  width: 100%;
  height: 838px;
  overflow: hidden;
}

.wallet-connect-plus-separator {
  width: 138px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.adjust-connect-wallet-text {
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .adjust-connect-wallet-text {
    width: 287px;
  }
}

.connect-wallet-element-container {
  position: relative;
  top: -130px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.connect-wallet-adjust-page-header {
  padding-top: 100px;
}

.wallet-lef-angel-wing {
  margin-left: 210px;
  position: relative;
  top: -100px;
}

.wallet-right-angel-wing {
  margin-right: 210px;
  position: relative;
  top: -100px;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .wallet-lef-angel-wing {
    margin-left: 59px;
    top: -75px;
  }
  .wallet-right-angel-wing {
    margin-right: 59px;
    top: -75px;
  }

  .connect-wallet-element-container {
    top: -150px;
  }

  .connect-wallet-background {
    content: url("../assets/images/connect-wallet-background-1440.png");
    object-fit: fill;
  }

  .background-container {
    height: 819px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .wallet-lef-angel-wing {
    margin-left: 24px;
    top: -55px;
  }
  .wallet-right-angel-wing {
    margin-right: 24px;
    top: -55px;
  }

  .connect-wallet-element-container {
    top: -155px;
  }

  .connect-wallet-background {
    content: url("../assets/images/connect-wallet-background-1024.png");
    object-fit: fill;
  }

  .background-container {
    height: 801px;
  }
}

@media screen and (width: 768px) {
  .connect-wallet-background {
    content: url("../assets/images/connect-wallet-background-768.png");
    object-fit: fill;
  }

  .wallet-lef-angel-wing {
    margin-left: 24px;
    top: -45px;
    height: 360px;
  }
  .wallet-right-angel-wing {
    margin-right: 24px;
    top: -45px;
    height: 360px;
  }
  .connect-wallet-element-container {
    top: -115px;
  }
  .background-container {
    height: 772px;
  }
}

@media screen and (max-width: 767px) {
  .wallet-lef-angel-wing {
    display: none;
  }
  .wallet-right-angel-wing {
    display: none;
  }
  .connect-wallet-element-container {
    top: -35px;
  }
  .adjust-connect-wallet-text {
    margin-bottom: 4px;
  }
  .connect-wallet-background {
    content: url("../assets/images/connect-wallet-background-768.png");
    object-fit: fill;
  }
  .background-container {
    height: 772px;
  }
}

@media screen and (max-width: 375px) {
  .connect-wallet-background {
    content: url("../assets/images/connect-wallet-background-375.png");
    object-fit: fill;
  }
}

.connect-wallet-button-design {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: $oswald;
  cursor: pointer;
  background-image: linear-gradient(#FD2F69, #D42758);
  clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
}

.connect-wallet-button-design-logged-in {
  @extend .connect-wallet-button-design;
  background-image: initial;
  background-color: white;
  color: #FD2F69;
}


.connect-wallet {
  position: relative;
  z-index: 1;
}

.connect-wallet:hover {
  color: rgba(255, 255, 255, 0.5);
}
