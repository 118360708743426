@import "../../../../variables";

.pspp-main-body {
  width: 100%;
  height: 900px;
  background-color: transparent;
  position: relative;
  clip-path: $main-element-clip-path;
}

.pgs-planet {
  position: absolute;
  right: 0px;
}


.psg-left-shape {
  position: absolute;
  left: 7%;
  top: 570px;
  z-index: -1;
  opacity: 0.5;
}

.gsp-text-container {
  width: 100%;
  position: absolute;
  font-size: 16px;
  top: 410px
}

.gsp-text-section {
  width: 789px;
  margin-left: 18%;
}

.gsp-text-adjustment {
  margin: 10px;
  background-color: rgb(0,0,0,85%);
  border-radius: 10px;
  width: 100%;
}

.gsp-text-verify{
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 10px;
  background-color: black;
}

.gsp-text-container-verify{
  @extend .gsp-text-container;
  top: 332px;
}

.gsp-verify-button{
  width: 150px;
  height: 42px;
  margin-left: 10px;
}

#pgs-border-img .pgs-circle-container{
  top: 15px;
}

@media only screen and (max-width: 1000px) {
  .gsp-text-section{
    width: auto;
    margin: 0 auto;
  }
}
