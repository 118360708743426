@import "../../../variables";

.top-menu-dropdown-list .copy-tooltip-main-container {
  left: 110px;
  top: -22px;
}

.top-menu-dropdown-list .scale-copy-email {
  top: -7px;
  left: 6px;
}

.burger-menu-container .copy-tooltip-main-container{
  top: -11px;
  left: 61px;
}

.copy-tooltip-main-container{
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: $sofia;
  color: $white;
  top: -34px;
  pointer-events: none;
}
.scale-copy-email{
  background-color: $semi-dark-black;
  position: absolute;
  top: 19px;
  left: -27px;
  width: 120px;
  height: 31px;
  font-size: 14px;
  padding: 7px 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: initial;
}
.copy-email-triangle-white{
  display: none;
}

@media screen  and (max-width: 1024px) {
  .copy-email-triangle{
    display: none;
  }
  .copy-email-triangle-white{
    display: block;
    position: absolute;
    left: 94px;
    top: -12px;
  }
  .scale-copy-email {
    background-color: $white;
    color: $black;
    top: -21px;
    left: 100px;
  }

}
