@import "../../../variables";

.terms-and-cond-background {
  background-color: transparent;
  width: 521px;
  height: 521px;
}

.terms-and-cond-modal .modal-content {
  background-color: transparent;
  width: 521px;
  height: 521px;
}

.terms-and-cond-dialog {
  width: 521px;
}

.terms-and-cond-container {
  top: 40px;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.terms-and-cond-title {
  font-size: 55px;
}

.terms-and-cond-text {
  width: 381px;
  font-size: 18px;
  padding-right: 31px;
  margin-top: 3px;
}

.terms-and-cond-list {
  padding-right: 53px;
}
.terms-and-cond-list a{
  color: $white;
  padding-left: 5px;
  font-size: 16px;
  text-underline-offset: 5px;
}

.terms-and-cond-checkbox-container {
  display: flex;
  flex-direction: row;
  width: 350px;
  margin-top: 15px;
}

.terms-and-cond-checkbox-container p {
  margin-left: 17px;
  line-height: 16px;
}

.checkbox-container {
  display: block;
  position: relative;
  left: -15px;
  top: 5px;
  padding-left: 0;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  border: 1px solid white;
  position: absolute;
  top: -2px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #000;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 3px;
  top: 0px;
  width: 7px;
  height: 10px;
  border: solid black;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.terms-and-cond-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 26px;
}

.terms-and-cond-continue,
.terms-and-cond-cancel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 115px;
  height: 37px;
  cursor: pointer;
  font-family: $oswald;
  clip-path: polygon(6px 0, 0 6px, 0 calc(100% - 6px), 6px 100%, calc(100% - 6px) 100%, 100% calc(100% - 6px), 100% 6px, calc(100% - 6px) 0);
  padding: 2px ;
}

.terms-and-cond-continue {
  background-color: $pink;
  margin-right: 21px;
}
.terms-and-cond-continue.disabled {
  background-color: grey;
  pointer-events: none;
}

.terms-and-cond-cancel {
  position: relative;
  border: 1px solid white;
  background-color: white;
  color: white;
}
.terms-and-cond-cancel-border{
  position: absolute;
  background-color: $black;
  z-index: -1;
  width: 100%;
  height: 100%;
  clip-path: polygon(5px 0, 0 5px, 0 calc(100% - 5px), 5px 100%, calc(100% - 5px) 100%, 100% calc(100% - 5px), 100% 5px, calc(100% - 5px) 0);
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .terms-and-cond-background {
    width: 41.992vw;
    height: 41.992vw
  }
  .terms-and-cond-modal .modal-content {
    width: 41.992vw;
    height: 41.992vw
  }
  .terms-and-cond-dialog {
    width: 41.992vw;
    height: 41.992vw
  }
  .terms-and-cond-container {
    top: 2.344vw;
    height: 39.063vw;
  }
  .terms-and-cond-title {
    font-size: 4.199vw;
  }
  .terms-and-cond-text {
    width: 30.664vw;
    font-size: 1.367vw;
    padding-right: 4.883vw;
    margin-top: 0.391vw;
    margin-bottom: 1.367vw;
  }
  .terms-and-cond-list {
    padding-right: 4.004vw;
    padding-left: 0;
  }
  .terms-and-cond-list a,
  .terms-and-cond-list li {
    font-size: 1.172vw;
    text-underline-offset: 0.293vw;
  }
  .terms-and-cond-list li {
    margin-top: 0.488vw;
    margin-bottom: 0.586vw;
  }
  .terms-and-cond-checkbox-container {
    width: 26.074vw;
    margin-top: 1.758vw;
  }
  .terms-and-cond-checkbox-container p {
    font-size: 1.172vw;
    margin-left: 0.488vw;
    line-height: 1.563vw;
    margin-bottom: 1.563vw;
  }
  .checkmark {
    width: 1.172vw;
    height: 1.172vw;
  }
  .checkbox-container .checkmark:after {
    height: 0.781vw;
    width: 0.488vw;
    left: 0.293vw;
  }
  .terms-and-cond-buttons {
    margin-top: 1.758vw;
    margin-bottom: 3.223vw;
  }
  .terms-and-cond-continue,
  .terms-and-cond-cancel {
    width: 9.277vw;
    height: 2.832vw;
    font-size: 1.172vw;
    padding: 2px;
    clip-path: polygon(0.391vw 0, 0 0.391vw, 0 calc(100% - 0.391vw), 0.391vw 100%, calc(100% - 0.391vw) 100%, 100% calc(100% - 0.391vw), 100% 0.391vw, calc(100% - 0.391vw) 0);

  }
  .terms-and-cond-continue {
    margin-right: 1.660vw;
  }
  .terms-and-cond-cancel-border {
    clip-path: polygon(0.293vw 0, 0 0.293vw, 0 calc(100% - 0.293vw), 0.293vw 100%, calc(100% - 0.293vw) 100%, 100% calc(100% - 0.293vw), 100% 0.293vw, calc(100% - 0.293vw) 0);

  }
  .checkbox-container {
    left: -2.539vw;
  }
}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .terms-and-cond-background {
    width: 55.990vw;
    height: 55.990vw;
  }
  .terms-and-cond-modal .modal-content {
    width: 55.990vw;
    height: 55.990vw;
  }
  .terms-and-cond-dialog {
    width: 55.990vw;
    height: 55.990vw;
  }
  .terms-and-cond-container {
    top: 3.906vw;
  }
  .terms-and-cond-title {
    font-size: 5.599vw;
  }
  .terms-and-cond-text {
    width: 40.885vw;
    font-size: 1.823vw;
    padding-right: 5.859vw;
    margin-top: 0.651vw;
    margin-bottom: 0.911vw;
  }
  .terms-and-cond-list {
    padding-right: 4.948vw;
    padding-left: 0;
  }
  .terms-and-cond-list a,
  .terms-and-cond-list li {
    font-size: 1.563vw;
    text-underline-offset: 0.293vw;
  }
  .terms-and-cond-list li {
    margin-top: 0.651vw;
    margin-bottom: 0.781vw;
  }
  .terms-and-cond-checkbox-container {
    width: 35.026vw;
    margin-top: 0.911vw;
  }
  .terms-and-cond-checkbox-container p {
    font-size: 1.563vw;
    line-height: 2.083vw;
    margin-left: 0.911vw;
    margin-bottom: 1.042vw;
  }
  .checkmark {
    width: 1.563vw;
    height: 1.563vw;
    left: -0.911vw;
  }
  .checkbox-container .checkmark:after {
    width: 0.651vw;
    height: 1.042vw;
    left: 0.391vw;
  }
  .terms-and-cond-buttons {
    margin-top: 3.516vw;
    margin-bottom: 0;
  }
  .terms-and-cond-continue,
  .terms-and-cond-cancel {
    width: 12.370vw;
    height: 3.776vw;
    font-size: 1.563vw;
    padding: 2px;
    clip-path: polygon(0.521vw 0, 0 0.521vw, 0 calc(100% - 0.521vw), 0.521vw 100%, calc(100% - 0.521vw) 100%, 100% calc(100% - 0.521vw), 100% 0.521vw, calc(100% - 0.521vw) 0);

  }
  .terms-and-cond-continue {
    margin-right: 2.214vw;
  }
  .terms-and-cond-cancel-border {
    clip-path: polygon(0.391vw 0, 0 0.391vw, 0 calc(100% - 0.391vw), 0.391vw 100%, calc(100% - 0.391vw) 100%, 100% calc(100% - 0.391vw), 100% 0.391vw, calc(100% - 0.391vw) 0);
  }
}

@media screen and (max-device-width: 375px) {
  .terms-and-cond-background {
    width: 89.600vw;
    height: 114.667vw;
  }
  .terms-and-cond-modal .modal-content {
    width: 89.600vw;
    height: 114.667vw;
  }
  .terms-and-cond-dialog {
    width: 89.600vw;
    height: 114.667vw;
  }
  .terms-and-cond-container {
    top: 8vw;
  }
  .terms-and-cond-title {
    font-size: 9.600vw;
  }
  .terms-and-cond-text {
    width: 74.400vw;
    font-size: 3.733vw;
    padding-right: 3.733vw;
    margin-top: 4.800vw;
    margin-bottom: 1.867vw;
  }
  .terms-and-cond-list {
    padding-right: 0;
    padding-left: 0;
  }
  .terms-and-cond-list a,
  .terms-and-cond-list li {
    font-size: 3.733vw;
    text-underline-offset: 0.800vw;
  }
  .terms-and-cond-list li {
    margin-top: 1.333vw;
    margin-bottom: 1.600vw;
  }
  .terms-and-cond-checkbox-container {
    width: 68vw;
    margin-top: 2.400vw;
  }
  .terms-and-cond-checkbox-container p {
    font-size: 3.733vw;
    line-height: 4.267vw;
    margin-left: 1.600vw;
    margin-bottom: 9.600vw;
  }
  .checkmark {
    width: 3.200vw;
    height: 3.200vw;
  }
  .checkbox-container .checkmark:after {
    width: 1.333vw;
    height: 2.133vw;
    left: 0.800vw;
  }
  .terms-and-cond-buttons {
    margin-top: 0;
    margin-bottom: 0;
  }
  .terms-and-cond-continue,
  .terms-and-cond-cancel {
    width: 25.333vw;
    height: 7.733vw;
    font-size: 3.200vw;
    clip-path: polygon(1.067vw 0, 0 1.067vw, 0 calc(100% - 1.067vw), 1.067vw 100%, calc(100% - 1.067vw) 100%, 100% calc(100% - 1.067vw), 100% 1.067vw, calc(100% - 1.067vw) 0);
  }
  .terms-and-cond-continue {
    margin-right: 4.533vw;
  }
  .terms-and-cond-cancel-border {
    clip-path: polygon(0.800vw 0, 0 0.800vw, 0 calc(100% - 0.800vw), 0.800vw 100%, calc(100% - 0.800vw) 100%, 100% calc(100% - 0.800vw), 100% 0.800vw, calc(100% - 0.800vw) 0);
  }
}
