@import "../../../../variables";
@import url('https://fonts.googleapis.com/css2?family=Michroma&display=swap');

.public-sale-countdown-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
}

.sale-countdown-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../../../../assets/images/vectors/countdown-lines-bg.png') no-repeat center;
  background-size: contain;
  width: 100%;
  height: 100%;
}

.sale-countdown-timecells-container {
  position: absolute;
  display: flex;
  flex-direction: row;
}

.countdown-time-text {
  font-family: 'Michroma', sans-serif;
  font-size: 4.5vw;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.countdown-time-text-separator {
  @extend .countdown-time-text;
}
.countdown-time-under-text {
  font-size: 1.1vw;
  text-align: center;
  font-family: $oswald;
}

.bottom-decoration {
  padding-top: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bottom-decoration .bottom-crown-decoration {
  height: 34px;
  width: 67px;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .cycle-end-timer-adjustment .countdown-time-text {
    font-size: 3.516vw;
  }

  .sale-countdown-container .line-separator{
    width: 49.316vw;
  }
  .sale-countdown-container .bottom-crown-decoration {
    width: 6.543vw;
    height: 3.320vw;
  }
  }

@media screen and (min-width: 376px) and (max-width: 768px) {
  .cycle-end-timer-adjustment .countdown-time-text {
    font-size: 3.255vw;
  }
  .cycle-end-timer-adjustment .countdown-time-text-separator {
    font-size: 3.255vw;
  }

  .sale-countdown-container .bottom-decoration {
    top:8.203vw;
  }

  .sale-countdown-container .line-separator {
    width:45.964vw;
  }
  .sale-countdown-container .bottom-crown-decoration {
    width: 6.120vw;
    height: 3.125vw;
  }


}

@media screen and (max-width: 375px) {
  .cycle-end-timer-adjustment .countdown-time-text{
    font-size: 8.800vw;
    width:9.867vw;
  }
  .cycle-end-timer-adjustment .countdown-time-text-separator{
    font-size: 8.800vw;
  }

  .sale-countdown-container .bottom-decoration{
    display: none;
    position: absolute;
  }
}
