@import "../../variables";
.center-element{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.center-element-column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pseb-principel-container{
  width: 100%;
}

.pseb-elements-container{
  width: 483px;
  height: 259px;
  background-color: black;
}

.pseb-text{
  margin: 0px;
  margin-bottom: 5px;
  font-size: 16px;
  font-family: $sofia;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .pseb-elements-container{
    width: 62.891vw;
    height: 33.724vw;
  }

  .pseb-text{
    margin-bottom: 0.651vw;
    font-size: clamp(0.75rem, 2.083vw, 1rem);
  }
}
