
.pirate-img-main-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 226px;
  margin-left: 131px;

}

.pirate-circle-pattern{
  position: absolute;
  top: 195px;
  left: -191px;
  width: 757px;
  height: 402px;
  opacity: 0;
  animation: circle-fade-in-animation 1s ease-in-out 0.6s 1 normal forwards;
}

.footer-pirate-img-small{
  display: none;
}

.footer-pirate-img {
  position: relative;
  top: 145px;
  z-index: 2;
  opacity: 0;
  animation: circle-fade-in-animation 0.6s ease-in-out 0.6s 1 normal forwards;
}

.footer-pirate-img-small{
  position: relative;
  z-index: 2;
  top: 205px;
  opacity: 0;
  animation: circle-fade-in-animation 0.6s ease-in-out 0.6s 1 normal forwards;
  pointer-events: none;
}

@keyframes fade-in-animation {
  from {
    opacity: 0;
    transform: translate(0, 100px)
  }
  to {
    opacity: 1;
    transform: translate(0, 0)
  }
}

@keyframes circle-fade-in-animation {
  from {
    opacity: 0;
    transform: translate(0, 150px)
  }
  to {
    opacity: 1;
    transform: translate(0, 0)
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
.pirate-img-main-container{
  margin-right: 147px;
  margin-left: 131px;
}
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .pirate-img-main-container{
    margin-right: 3.906vw;
    margin-left: 70px;
  }
  .footer-pirate-img{
    display: none;
  }
  .footer-pirate-img-small{
    display: initial;
    width: 450px;
    top: 140px;
  }
  .pirate-circle-pattern{
    left: -153px;
  }
}
@media screen and (min-width: 769px) and (max-width: 980px) {
  .pirate-img-main-container{
    margin-right: 10px;
    margin-left: initial;
  }
  .footer-pirate-img-small{
    z-index: 1;
  }
}
@media screen and (min-width: 376px) and (max-width: 768px) {
  .pirate-img-main-container{
    margin: 0px;
    margin-left: -36px;
  }

  .footer-pirate-img{
    display: none;
  }

  .pirate-circle-pattern{
    width: 58.073vw;
    height:30.990vw;
    top: 15.365vw;
    left: -2.734vw;
  }
  .footer-pirate-img-small{
    display: initial;
    top:6.771vw;
    z-index: 1;
    width:48.828vw;
    height: 91.536vw;
  }
}

@media screen  and (max-device-width: 375px) {
  .pirate-img-main-container{
    margin: 0px;
  }

  .footer-pirate-img{
    display: none;
  }

  .pirate-circle-pattern{
    top: 75px;
    left: -91px;
    width: 562px;
    height: initial;

}
.footer-pirate-img-small{
display: initial;
top:6.771vw;
z-index: 1;
}
}
