@import "../../../variables";

.leader-board-special-row-container {
  z-index: 0;
  height: 42px;
  width: 757px;
  position: relative;
  background-color: initial;
  left: -18px;
  padding-left: 1px;
  padding-right: 1px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.leader-board-special-row-content {
  position: relative;
  width: 100%;
  padding-left: 37px;
  padding-right: 37px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  z-index: 2;
}

.add-background-from-row {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: $pink;
  clip-path: polygon(5px 0, 0 5px, 0 calc(100% - 5px), 5px 100%, calc(100% - 5px) 100%, 100% calc(100% - 5px), 100% 5px, calc(100% - 5px) 0);
}

.add-font-bold {
  font-weight: 700;
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .leader-board-special-row-container{
    height: 3.906vw;
    width: 73.926vw;
    left: -1.758vw;
  }
  .leader-board-special-row-content{
    height: 3.906vw;
    padding-left: 3.613vw;
    padding-right: 3.613vw;
  }
  .add-white-border-from-row{
    height: 4.102vw;
  }
}
@media screen and (min-width: 376px) and (max-width: 768px) {
  .leader-board-special-row-container{
    width: 68.880vw;
    height: 3.906vw;
    margin-left: 0.391vw;
    left: -2.344vw;
  }
  .leader-board-special-row-content{
    height: 3.906vw;
    width: 100%;
    padding-left: 4.818vw;
    padding-right: 4.818vw;
  }
  .add-white-border-from-row{
    height:4.167vw;
  }
  }

@media screen and (max-width: 375px) {
  .leader-board-special-row-container, .leader-board-special-row-content{
    width: 95.200vw;
    height: 9.600vw;

  }
  .leader-board-special-row-content{
    padding-left:0;
    padding-right: 0;
  }
  .add-white-border-from-row{
    height: 10.133vw;
    width:96vw;
  }
}
