@font-face {
  font-family: 'phantom-galaxies-icons';
  src:
    url('fonts/phantom-galaxies-icons.ttf?k8choe') format('truetype'),
    url('fonts/phantom-galaxies-icons.woff?k8choe') format('woff'),
    url('fonts/phantom-galaxies-icons.svg?k8choe#phantom-galaxies-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="pg-icon-"], [class*=" pg-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'phantom-galaxies-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pg-icon-swap_vert:before {
  content: "\e912";
}
.pg-icon-artifact-lost-civilization:before {
  content: "\e900";
}
.pg-icon-artifact-natural-phenomena:before {
  content: "\e901";
}
.pg-icon-biome-aridic:before {
  content: "\e902";
}
.pg-icon-biome-edenic:before {
  content: "\e903";
}
.pg-icon-biome-glacic:before {
  content: "\e904";
}
.pg-icon-feature-lunar:before {
  content: "\e905";
}
.pg-icon-feature-orbital-rings:before {
  content: "\e906";
}
.pg-icon-resource-gas:before {
  content: "\e907";
}
.pg-icon-resource-liquid:before {
  content: "\e908";
}
.pg-icon-resource-radioactive:before {
  content: "\e909";
}
.pg-icon-resource-solid:before {
  content: "\e90a";
}
.pg-icon-resource-stable:before {
  content: "\e90b";
}
.pg-icon-resource-unstable:before {
  content: "\e90c";
}
.pg-icon-steam-logo:before {
  content: "\e90d";
}
.pg-icon-epic-games-logo:before {
  content: "\e90e";
}
.pg-icon-avatar-default:before {
  content: "\e90f";
}
.pg-icon-unselected:before {
  content: "\e910";
}
.pg-icon-selected:before {
  content: "\e911";
}
