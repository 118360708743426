@import "../../../../../../../variables";
.cb-principle-container{
  width: 100%;
  height: 32px;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.cb-container{
  width: 121px;
  height: 100%;
  clip-path: polygon(8% 0%, 100% 0%, 92% 100%, 0% 100%);
  background-color: #282b2f;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.cb-counter-value-design{
  margin:0px;
}
