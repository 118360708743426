@import "../../variables";

.social-component-main-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: 42px;

  .top-menu-dropdown-list-item.SOCIALS {
    width: 150px;
    display: flex;
    justify-content: left;
    align-items: center;
  }

  .top-menu-dropdown-list-item.SOCIALS:hover {
    background-color: transparent;
    border-left: none;
  }
}

.social-component-title {
  margin-left: 23px;
}

.social-component-main-container:hover {
  border-left: 6px solid $pink;
  padding-left: 40px;
}

.social-entries:hover .social-component-main-container .top-menu-dropdown-list-item.SOCIALS{
  cursor: pointer;
  color: $pink;
}

.social-entries:hover .social-component-main-container .top-menu-dropdown-list-item.SOCIALS .top-menu-dropdown-list-text{
  filter: invert(41%) sepia(87%) saturate(6173%) hue-rotate(328deg) brightness(86%) contrast(93%);
}

.top-menu-dropdown-list-item.SOCIALS .top-menu-dropdown-list-text{
  width: 17px;
  height: 17px;
}

.burger-menu-container .social-component-main-container {
  margin-bottom: 7px;
}
