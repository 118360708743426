@import "../../../variables";

.claim-timer-main-container{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 589px;
}

.claim-countdown-container{
  position: relative;
  width: 505px;
  height: 270px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.claim-timer-main-container .countdown-time-text,
.claim-timer-main-container .countdown-time-text-separator{
  font-size: 36px;
}

.claim-timer-main-container .sale-countdown-container{
  height: 130px;
  background: none;
}

.next-claim-text{
  font-size: 20px;
  line-height: 20px;
  font-family: $sofia;
  z-index: 1;
  margin-bottom: 0;
}

.claim-timer-main-container .top-decoration{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 47px;
}

.claim-timer-background{
  position: absolute;
  width: 100%;
  height: 100%;
}

.claim-timer-shadow-element {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.99);
  border-radius: 100%;
  box-shadow: 0 0 165px 178px black;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .claim-timer-main-container{
    height: 47.656vw;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .claim-countdown-container {
    width: 40.820vw;
    height: 22.461vw;
  }
  .claim-timer-main-container .line-separator{
    width: 100%;
  }
  .claim-timer-main-container .top-crown-decoration,
  .claim-timer-main-container .bottom-crown-decoration{
    width: 5.371vw;
    height: 2.734vw;
  }
  .claim-timer-background{
    height: 47.656vw;
    width: 150.391vw;

  }
  .next-claim-text{
       font-size: 1.563vw;
       line-height:1.563vw;
     }
  .claim-timer-main-container .countdown-time-text,
  .claim-timer-main-container .countdown-time-text-separator{
    font-size: 2.930vw;
  }
  .claim-timer-main-container .countdown-time-under-text{
    font-size: 1.074vw;
    line-height: 1.660vw;
  }
  .claim-timer-main-container .top-decoration{
    margin-bottom: 3.418vw;
  }
  .claim-timer-main-container .sale-countdown-container{
    height: 10.742vw;
  }
  .claim-countdown-container  .bottom-decoration{
    top: 6.738vw;
  }
  .claim-timer-shadow-element{
    width: 70%;
    height: 70%;
    box-shadow: 0 1.953vw 9.813vw 14.625vw rgba(0,0,0,0.99);
  }
}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .claim-timer-main-container{
    height: 55.339vw;
  }
  .claim-countdown-container {
    width: 54.036vw;
    height: 28.646vw;
  }
    .claim-timer-background{
    height: 55.339vw;
    width: 100%;
  }
  .claim-timer-main-container .top-crown-decoration,
  .claim-timer-main-container .bottom-crown-decoration{
    width: 7.161vw;
    height: 3.516vw;
  }
  .claim-timer-main-container .top-decoration{
    margin-bottom: 2.604vw;
  }
  .next-claim-text{
    font-size: 2.604vw;
    line-height: 2.604vw;
  }
  .claim-timer-main-container .top-decoration,
  .claim-timer-main-container .bottom-decoration .line-separator{
    width: 54.036vw;
  }
  .claim-timer-main-container .countdown-time-text,
  .claim-timer-main-container .countdown-time-text-separator{
    font-size: 4.167vw;
  }
  .claim-timer-main-container .countdown-time-under-text{
    font-size: 1.823vw;
    line-height: 2.734vw;
  }
  .claim-timer-main-container .sale-countdown-container{
    height: 15.625vw;
  }
  .claim-timer-shadow-element{
    width: 60%;
    height: 50%;
    box-shadow: 0 0 21.484vw 27.177vw black;

  }

}

@media screen and (max-width: 376px) {
  .claim-timer-main-container{
    height: 74.667vw;
    margin-top: -16vw;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .claim-countdown-container {
    width: 83.467vw;
    height: 58.667vw;
    margin-top: 12.333vw;
  }
  .claim-timer-background{
    height: 74.667vw;
    width: 247.733vw;

  }
  .claim-timer-main-container .top-crown-decoration,
  .claim-timer-main-container .bottom-crown-decoration{
    width: 10.933vw;
    height: 5.600vw;
  }
  .claim-timer-main-container .top-decoration{
    margin-bottom: 2.604vw;
  }
  .claim-timer-main-container .bottom-decoration{
    display: flex;
    position: relative;
    top: 13.600vw;
  }
  .next-claim-text{
    font-size:4.267vw;
    line-height: 4.267vw;
  }
  .claim-timer-main-container .top-decoration,
  .claim-timer-main-container .bottom-decoration .line-separator{
    width: 83.467vw;
  }
  .claim-timer-main-container .countdown-time-text,
  .claim-timer-main-container .countdown-time-text-separator{
    font-size: 7.733vw;
  }
  .claim-timer-main-container .countdown-time-under-text{
    font-size: 2.933vw;
    line-height: 4.533vw;
  }
  .claim-timer-main-container .sale-countdown-container{
    margin-top: 5.333vw;
    height: 15.625vw;
  }
  .claim-timer-shadow-element{
    width: 50%;
    height: 50%;
    box-shadow: 0 0 21.484vw 23.177vw black;
  }
}
