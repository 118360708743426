.planet-exploration-check-in-list-container{
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;
  width: 1030px;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.planet-exploration-check-in-list-element{
  margin: 0 13px 13px 0;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .planet-exploration-check-in-list-container{
    width: 71.64vw;
  }

  .planet-exploration-check-in-list-element{
    margin: 0 0.903vw 0.903vw 0;
  }
}

@media screen and (max-width: 1024px) {
  .planet-exploration-check-in-list-container{
    width: 86.5vw;
  }

  .planet-exploration-check-in-list-element{
    margin: 0 1.1vw 0.903vw 0;
  }
}

@media screen and (max-width: 768px) {
  .planet-exploration-check-in-list-container{
    width: 89vw;
    margin-bottom: 9vw;
  }
}

@media screen and (max-device-width: 375px) {
  .planet-exploration-check-in-list-container {
    width: 89.067vw;
    margin-bottom: 9vw;
  }
  .planet-exploration-check-in-list-element{
    margin: 0 6px 10px 0;
  }
}
