@import "src/variables";

.sb-quickswap-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 753px;
  //margin-top: 90px;
}

.address-for-exchange {
  margin-bottom: 27px;
  color: #5C5C5C;
  cursor: default;
  font-family: $sofia;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  position: relative;
}

.content-of-address {
  cursor: pointer;
  margin-bottom: 0;
}

.content-of-address:hover {
  .token-page-copy-text-main-container {
    display: flex;
  }
}

.token-page-copy-text-main-container {
  position: absolute;
  display: none;
  color: white;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: -42px;
  left: 85px;
}

.token-page-copy-text-content-container {
  background-color: #151515;
  padding: 7px 11px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.adjust-hover-triangle-bottom {
  position: absolute;
  bottom: -7px;

}

.toke-description-text {
  margin-bottom: 0;
  font-weight: bold;
}

.sb-swap-background-img-controller {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 589px;
}

.sb-decorative-background-from-quickswap {
  position: absolute;
  z-index: -1;
  height: 100%;
}


.sb-quickswap-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 622px;
}

.sb-center-shadow-element {
  position: absolute;
  width: 20%;
  height: 20%;
  background-color: black;
  border-radius: 100%;
  z-index: -1;
  box-shadow: 0 0 165px 178px black;
}

.sb-quickswap-value-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  //height: 669px;
  padding-bottom: 50px;
  width: 100%;
}

.sb-space-between-the-swap-buttons {
  margin-right: 47px;
}

.sb-quickswap-text {
  font-size: 45px;
  font-family: $oswald;
  margin: 25px 0px 44px;
}

.sb-ethereum-icon-adjustment {
  margin-bottom: 7px;
}


.sb-coin-description-text {
  font-size: 30px;
  font-family: $oswald;
  margin-bottom: 12px;
}

.sb-swap-button {
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding-left: 1rem;
  align-items: center;
  cursor: pointer;
  background-color: $pink;
  width: 178px;
  height: 42px;
  font-family: $oswald;
  font-size: 20px;
  line-height: 35px;
  clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
  transition: all .2s ease;
  transform: scale(1.0);
}

.sb-swap-button:hover {
  transform: scale(1.1);
}

.sb-coin-logo-adjustment {
  margin-right: 18px;
  width: 31px;
  height: 31px;
}

.sb-plus-decoration-img {
  width: 128px;
  margin-top: 43px;
  height: 9px;
  margin-bottom: 35px;
}

.sb-matic-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 16px;
}


@media screen and (min-width: 769px) and (max-width: 1024px) {
  .sb-quickswap-main-container {
    margin-top: 154px;
  }
}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .sb-quickswap-main-container {
    height: 57.682vw;
    margin-top: 11.719vw;
  }
  .sb-quickswap-content {
    width: 80vw;
    //max-width: 364px;
  }
  .sb-swap-background-img-controller {
    height: 44.401vw;
    top: 7.422vw;
  }
  .address-for-exchange {
    font-size: 1.823vw;
    margin-bottom: 1.563vw;
  }
  .sb-token-boarder_detail_top {
    width: 6.120vw;
    height: 3.125vw;
  }
  .sb-center-shadow-element {
    box-shadow: 0 0 15.365vw 16.146vw black;
  }
  .sb-quickswap-value-container {
    height: 51.172vw;
  }
  .address-for-exchange {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 78.125vw;
  }
  .token-page-copy-text-main-container {
    width: initial;
    top: -5.469vw;
    left: 45.068vw;
  }
  .token-page-copy-text-content-container {
    padding: 0.911vw 1.432vw;
    border-radius: 1.042vw;
  }
  .sb-quickswap-text {
    font-size: 2.604vw;
    margin-top: 2.604vw;
    margin-bottom: 1.563vw;
  }
  .sb-ethereum-icon-adjustment {
    margin-bottom: 0.391vw;
    width: 3.516vw;
    height: 3.516vw;
  }
  .sb-coin-description-text {
    font-size: 2.344vw;
  }
  .sb-coin-description-text {
    margin-bottom: 0.521vw;
  }
  .sb-swap-button {
    height: 4.818vw;
    font-size: 2.083vw;
    width: 20.313vw;
    clip-path: polygon(0.911vw 0, 0 0.911vw, 0 calc(100% - 0.911vw), 0.911vw 100%, calc(100% - 0.911vw) 100%, 100% calc(100% - 0.911vw), 100% 0.911vw, calc(100% - 0.911vw) 0);
  }
  .sb-coin-logo-adjustment {
    width: 3.646vw;
    height: 3.646vw;
    margin-right: 1.302vw;
  }
  .sb-plus-decoration-img {
    margin-top: 2.604vw;
    margin-bottom: 2.604vw;
    height: 1.172vw;
    width: 17.969vw;
  }
  .sb-infinite-coin-logo-adjustments {
    margin-bottom: 0.391vw;
    width: 3.516vw;
    height: 3.516vw;
  }
  .sb-space-between-the-swap-buttons {
    margin-right: 6.120vw;
  }
}

@media screen and (max-device-width: 375px) {
  .sb-quickswap-main-container {
    //height: 435px;
    //margin-bottom: 47px;
  }
  .sb-swap-background-img-controller {
    align-items: initial;
    top: 40px;
  }
  .sb-decorative-background-from-quickswap {
    width: 987px;
    height: 57%;
  }
  .token-page-copy-text-main-container{
    left: 0px;
    top: -20px;
  }
  .sb-quickswap-content {
    width: 100%;
    max-width: 332px;
  }
  .sb-token-boarder_detail_top {
    width: 14.133vw;
    height: 7.200vw;
  }
  .address-for-exchange {
    flex-direction: column;
    font-size: 14px;
    margin-bottom: 12px;
    line-height: 19.3px;
  }
  .sb-quickswap-value-container {
    //height: 475px;
  }
  .toke-description-text{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .content-of-address{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .sb-quickswap-text {
    font-size: 25px;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .sb-ethereum-icon-adjustment {
    width: 27px;
    height: 27px;
    margin-bottom: 3px;
  }
  .sb-coin-description-text {
    font-size: 20px;
    margin-bottom: 0;
  }

  .sb-swap-button {
    width: 156px;
    height: 37px;
    font-size: 16px;
  }
  .sb-coin-logo-adjustment {
    width: 6.933vw;
    height: 6.933vw;
    margin-right: 2.933vw;
  }
  .sb-plus-decoration-img {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .sb-infinite-coin-logo-adjustments {
    width: 27px;
    height: 27px;
  }
  .sb-mobile-margin-bottom {
    margin-bottom: 11px !important;
  }
  .sb-matic-buttons-container {
    flex-direction: column;
  }
  .sb-space-between-the-swap-buttons {
    margin-right: 0px;
    margin-bottom: 11px;
  }

}
