@import "../../variables";

.astrafer-main-container{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.astrafer-content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -165px;
}

.astrafer-description-main-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.description-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.description-text-title {
  font-size: 45px;
  font-family: $oswald;
  line-height: 66px;
  margin-bottom: 63px;
  width: 808px;
}

.description-text-content {
  font-size: 16px;
  font-family: $sofia;
  max-width: 760px;
  line-height: 26px;
}

.astrafer-paragraph-alignment {
  margin-bottom: 20px;
}

.circle-patern-adjustment {
  position: absolute;
  top: 45px;
  left: -200px;
  opacity: 0.5;
}

.main-title-for-astrafer {
  margin: 0px;
}

.mobile-title-1-for-astrafer {
  margin: 0px;
  display: none;
}

.mobile-title-2-for-astrafer {
  margin: 0px;
  display: none;
}

.description-img-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 640px;
  position: relative;
  width: 100%;
}

.token-animation-video {
  width: 33.229vw;
  max-width: 638px;
}

.show-hide-break {
  display: none;
}

.video-shadow-border {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  -moz-box-shadow: inset 0 0 80px black;
  -webkit-box-shadow: inset 0 0 80px black;
  box-shadow: inset 0 0 80px black;
  width: 100%;
  height: 100%;
}

.astrafer-title{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {

  .token-animation-video {
    width: 44.306vw;
    max-width: 638px;

  }
  .description-img-container {
    margin-left: -50px;
  }
  .circle-patern-adjustment {
    top: 55px;
    left: -87px;
  }
  .description-text-title {
    max-width: 57.361vw;
    font-size: 3.12vw;
    line-height: 4.583vw;
  }
  .description-text-content {
    font-size: 1.111vw;
    max-width: 52.778vw;
    line-height: 1.806vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
  .description-text-container {
    left: 81px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .astrafer-main-container .psg-border-img-mini-screen{
    width: 1300px;
    overflow-x: hidden;
    overflow-y: visible;
  }
  .astrafer-content-container {
    top: -69px;
  }

  .astrafer-description-main-container {
    flex-direction: column;
  }
  .token-animation-video {
    width: 62.305vw;
  }
  .description-text-title {
    width: initial;
    max-width: 808px ;
    text-align: center;
    font-size: 4.395vw;
  }
  .description-text-content {
    text-align: center;
  }
  .circle-patern-adjustment {
    top: 48px;
    left: 215px;
  }
  .astrafer-title {
    margin-top: 25px;
  }

  .description-text-container {
    align-items: center;
  }

  }

@media screen and (min-width: 376px) and (max-width: 768px) {

  .show-hide-break {
    display: flex;
  }
  .astrafer-title {
    margin-top: -3.646vw !important;
  }
  .astrafer-title .page-header {
    height: initial;
    padding-bottom: 0px;
  }

  .astrafer-title .page-mobile-header-text-no-margin {
    font-size: 2.604vw;
  }
  .astrafer-title .psg-border-img-mobile {
    display: none;
  }
  .astrafer-title .psg-border-img-tablet {
    display: flex;
    width: 76%;
  }

  .description-text-container {
    justify-content: center;
    align-items: center;
  }
  .astrafer-content-container {
    margin-top: 31px;
    top: initial;
  }

  .astrafer-description-main-container {
    flex-direction: column;
  }
  .token-animation-video {
    width: 37.500vw;
  }
  .description-text-title {
    text-align: center;
    font-size: 3.125vw;
    max-width: 67.839vw;
    line-height: 4.557vw;
    margin-bottom: 41px;
  }
  .description-text-content {
    text-align: center;
    max-width: 84.766vw;
    font-size: 2.083vw;
    line-height: 3.255vw;
  }
  .circle-patern-adjustment {
    top: -10px;
    left: initial;
    width: 395px;
  }

  .astrafer-title {
    margin-top: 25px;
  }

}

@media screen and (min-width: 376px) and (max-width: 395px) {
  .circle-patern-adjustment {
    width: 100%;
  }
  }

@media screen and (max-device-width: 375px) {
  .astrafer-main-container{
    overflow-x: hidden;
  }
  .show-hide-break {
    display: flex;
  }
  .astrafer-title {
    margin-top: -5.333vw !important;
  }
  .astrafer-title .page-header {
    height: initial;
    padding-bottom: 0px;
  }

  .astrafer-title .page-mobile-header-text-no-margin {
    font-size: 6.667vw;
  }
  .astrafer-title .psg-border-img-tablet {
    width: 184%;
  }
  .astrafer-title .psg-border-img-mobile {
    display: none;
  }
  .astrafer-title .psg-border-img-tablet {
    display: flex;
  }

  .description-text-container {
    justify-content: center;
    align-items: center;
    top: -14.933vw;
  }
  .astrafer-content-container {
    margin-top: 31px;
    top: initial;
  }

  .astrafer-description-main-container {
    flex-direction: column;
  }
  .token-animation-video {
    width: 100vw;
  }
  .description-text-title {
    text-align: center;
    font-size: 3.125vw;
    max-width: 86.133vw;
    line-height: 4.557vw;
    margin-bottom: 10.933vw;
  }
  .description-text-content {
    text-align: initial;
    max-width: 86.133vw;
    padding-left: 1.333vw;
    padding-right: 1.333vw;
    font-size: 4.267vw;
    line-height: 6.667vw;
  }
  .show-hide-break {
    display: none;
  }
  .circle-patern-adjustment {
    top: 48vw;
    left: initial;
    width: 63.733vw;
  }

  .astrafer-title {
    margin-top: 6.667vw;
  }

  .center-shadow-element {
    top: 35%;
    width: initial;
    height: initial;
    box-shadow: 0 0 120px 170px black;
  }

  .main-title-for-astrafer {
    display: none;
  }
  .mobile-title-1-for-astrafer {
    display: flex;
    text-align: left;
    font-size: 7.467vw;
    padding-right: 2.667vw;
    line-height: 10.933vw;
    margin-bottom: 5.600vw;
  }
  .mobile-title-2-for-astrafer {
    margin: 0px;
    display: flex;
    text-align: right;
    font-size: 7.467vw;
    line-height: 10.933vw;
    padding-left: 16.267vw;
  }

}
