@import "../../../variables";


.planet-exploration-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .planets-mission-title-container .page-header .page-mobile-header-text {
    @media screen and (max-width: 1440px) {
      font-size: clamp(1.875rem, 3.125vw, 2.8125rem);
    }
    @media screen and (max-device-width: 1024px) {
      font-size: 1.875rem;
    }
  }
  .planet-exploration-timer-counter-main-container {
    margin-top: 50px;

    @media screen and (max-width: 375px) {
      margin: 0 0 78px 0;
    }
  }

  .planet-exploration-timer-counter-container {
    @media screen and (max-width: 375px) {
      width: 100vw;
    }
  }

  .planet-exploration-mission-progress-title {
    margin-bottom: 36px;
    font-family: $oswald;
    font-size: 1.75rem;
  }

  .planet-exploration-mission-progress-button-border {
    width: 221.33px;
    height: 46.02px;
    margin-top: 60px;
    margin-bottom: 80px;

    background-color: $pink;
    clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);

    @media only screen and (max-width: 768px) {
      width: 191px;
      height: 37px;
    }

    @media only screen and (max-device-width: 375px) {
      margin-top: 0;
      margin-bottom: -53px;
    }

    .planet-exploration-mission-progress-button {
      width: 219.322px;
      height: 44.026px;

      color: white;
      font-family: $oswald;
      font-weight: 400;
      font-size: 1.25rem;

      margin: 1px;
      border: none;
      background-color: $black;
      clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
      transition-duration: 0.3s;

      @media only screen and (max-width: 768px) {
        width: 189px;
        height: 35px;
        font-size: 1rem;
      }
    }
  }

  .planet-exploration-earnings-container {
    display: flex;
    justify-content: space-between;
    width: 700px;
    margin-top: 25px;
    margin-bottom: 100px;

    @media screen and (min-width: 769px) and (max-width: 1024px) {
      margin-top: 25px;
    }

    @media screen and (min-width: 376px) and (max-width: 768px) {
      width: 72.917vw;
      margin-top: 5px;
      margin-bottom: 50px;
    }

    @media screen and (max-width: 375px) {
      width: 90.133vw;
      margin-top: 0px;
      margin-bottom: 50px;
    }
  }

  .play-on-behalf-missions-main-container {
    margin-bottom: 60px;

    @media screen and (min-width: 376px) and (max-width: 768px) {
      margin-bottom: 0;
    }
    @media only screen and (max-width: 375px) {
      margin-bottom: 0;
    }
  }

  .exploration-info-container .page-header {
    @media screen and (min-width: 376px) and (max-width: 768px) {
      margin-top: 0;
    }

    @media only screen and (max-width: 375px) {
      margin-top: -30px;
    }
  }
}

.planet-exploration-main-container .check-in-button {
  font-family: $oswald;
  font-size: 20px;
  line-height: 25px;
  width: 221px;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0 100px 0;

  background-color: $pink;
  -webkit-clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
  clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
  cursor: pointer;

  &.disabled {
    background-color: $stack;
    color: $white;
    cursor: auto;
  }

  @media screen and (min-width: 1025px) and (max-width: 1440px) {
    margin: 0 0 7.431vw 0;
  }

  @media screen and (max-width: 768px) {
    font-size: 1rem;
    width: 191px;
    height: 37px;
  }

  @media screen and (max-device-width: 375px) {
    margin-bottom: 50px;
  }
}

/* ADD NEW CSS HERE */

.planet-exploration-missions-main-container.loading-spinner{
  display: flex;
  align-content: center;
  justify-content: center;
  margin-bottom: 100px;
  height: auto;
}


@media only screen and (max-width: 768px) {
  .planet-exploration-missions-main-container.loading-spinner{
    margin-top: 32px;
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 375px) {
  .planet-exploration-missions-main-container.loading-spinner{
    margin-top: 0;
  }
}

