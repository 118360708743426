@import "../../../variables";

.title-description-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 22px;
    margin-bottom: 100px;
}

.title-container {
  width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 73px;
    pointer-events: none;
}
.title-container .page-header{
  height: 350px;
}

.description-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 820px;
  height: 120px;
  margin-bottom: 147px;
  margin-top: -40px;
  z-index: 1;

}

.title-background {
    padding-top: 57px;
}

.title-text {
    position: absolute;
    font-style: normal;
    font-size: 45px;
    line-height: 67px;
    font-family: $oswald;
    text-align: center;
    padding-top: 75px;
}

.description-text {
  position: absolute;
  font-family: $sofia;
  font-style: normal;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: $gray-text;
  z-index: 1;
}

.question-hover {
  position: relative;
  width: 24px;
  height: 27px;
  top: -4px;
  left: 477px;
  color: $gray-text;
  cursor: pointer;
}

.button-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 55px;
  margin-bottom: -78px;
  z-index: 1;
}

.connect-with-blockpass-text {
  position: absolute;
  font-style: normal;
  font-size: 30px;
  line-height: 44.46px;
  font-family: $oswald;
  text-align: center;
  padding-top: 15px;
}

.pink-button-shape {
  width: 381.72px;
  height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px;
  background-color: #D22657;
  clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
}


@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .title-container .page-header{
    margin-top: 10px;
    margin-bottom: 25px;
  }
  .button-container {
    height: 43px;
    top: 27px;
    margin-bottom: -38px;
  }
  .description-container  {
    margin-bottom: 72px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .title-container .page-header{
    height: 34.180vw;
    margin-top: -1.758vw;
  }
  .title-description-container{
    margin-bottom: 50px;
  }
  .question-hover{
    width: 2.344vw;
    height: 2.637vw;
    top: -0.391vw;
    left: 42.090vw;
  }
  .title-container .psg-border-img-mini-screen{
    width: 100% ;
  }
  .title-container .page-mobile-header-text-no-margin {
    font-size: 3.613vw;
    line-height: 5.371vw;
  }
  .title-container{
    margin-top: 8.008vw;
  }
  .description-text{
    font-size: 1.758vw;
    line-height: 1.953vw;
  }
  .description-container {
    margin-top: -4.688vw;
    width: 72.387vw;
    height: 10.547vw;
    margin-bottom:7.324vw;
  }
  .button-container {
    height: 3.516vw;
    top: 1.660vw;
    margin-bottom: 0;
  }

  }

@media screen and (min-width: 376px) and (max-width: 768px) {
  .title-description-container{
    margin-bottom: 50px;
  }
  .title-container{
    margin-top: 12.760vw;
  }
  .title-container .page-header{
    height: 26.042vw;
  }
  .description-text {
    font-size: 2.344vw;
    line-height: 2.604vw;
  }
  .description-container{
    margin-top: -1.693vw;
    width: 72.526vw;
    height: 18.750vw;
    margin-bottom:6.510vw;
  }
  .question-hover{
    width: 2.734vw;
    height: 3.125vw;
    top: -0.521vw;
    left: 55.599vw;
  }
  .button-container {
    height: 4.036vw;
    top: 2.083vw;

    margin-bottom: 0;
  }
  }
@media screen and (max-width: 375px) {
  .title-description-container{
    margin-bottom: 50px;
  }

  .title-container{
    margin-top: 11.467vw;
  }
  .title-container .page-header{
    height: 48vw;
  }
  .description-text {
    font-size: 4.267vw;
    line-height: 4.267vw;
  }
  .description-container{
    margin-top: -0.391vw;
    width: 78.933vw;
    height: 38.400vw;
    margin-bottom: 5.859vw;
  }
  .button-container {
    height: 7.733vw;
    top: -1.042vw;
    margin-top: 13.333vw;
    margin-bottom: 0;
  }
  .question-hover {
    width: 4vw;
    height: 4.267vw;
    top: 4.533vw;
    left: 49.600vw;
  }
  .not-mobile{
    display: none;
  }
}
