@import "../../../variables";
.special-row-leader-board-section{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  background-color: $pink;
  clip-path: polygon(5px 0, 0 5px, 0 calc(100% - 5px), 5px 100%, calc(100% - 5px) 100%, 100% calc(100% - 5px), 100% 5px, calc(100% - 5px) 0);

}
