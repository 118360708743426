@import '../../../variables';

.generative-planet-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1347px;
  margin-bottom: 165px;


  @media screen and (max-width: 1440px) {
    width: 90%;
    max-width: 1167px;
  }

  @media screen and (max-width: 1024px) {
    width: 90%;
    max-width: 797px;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
    max-width: 614px;
  }

  @media screen and (max-device-width: 375px) and (max-width: 375px) {
    width: 93.600vw;
    margin-bottom: 120px;
  }

  .page-mobile-header-text,
  .page-mobile-header-text-no-margins{
    margin-bottom: 0;
    @supports (-webkit-touch-callout: none) {
      padding-top: 31px;
    }
  }

  .page-header{

    width: 100vw;
    padding-bottom: 60px;

    @media screen and (max-width: 1024px) {
      padding-bottom: 40px;

    }
    @media screen and (max-width: 768px) {
      padding-bottom: 0;
    }
    @media screen and (max-device-width: 375px) and (max-width: 375px) {
        margin-bottom: -23.467vw;
    }

    .psg-border-img-pc{
      @supports (-webkit-touch-callout: none) {
        object-fit: contain;
      }
    }
  }

  .mint-planet-info-container,
  .catalyst-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

    .mint-planet-info-header,
    .catalyst-info-header {
      width: 100%;
      background-color: rgba(255, 255, 255, 0.05);
      border-radius: 5px;
      padding: 30px 40px;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 13px;
      cursor: pointer;

      @media screen and (max-width: 1440px) {
        margin-bottom: 11px;
      }

      @media screen and (max-width: 1024px) {
        padding: 23px 31px;
        margin-bottom: 11px;
      }

      @media screen and (max-width: 768px) {
        padding: 30px 41px;
        margin-bottom: 13px;
      }

      @media screen and (max-device-width: 375px) and (max-width: 375px) {
        padding: 5px 4px;
        margin-bottom: 9px;
      }

      .mint-info-arrow {
        width: 19px;
        height: 10px;
        transform: rotate(-90deg);
        margin-right: 10px;

        &.rotate {
          transform: rotate(0deg);
        }
      }

      .mint-planet-info-header-text,
      .catalyst-info-header-text {
        font-family: $oswald;
        font-size: 1.5rem;
      }

    }

    .mint-planet-info-text {
      font-family: $sofia;
      font-size: 0.875rem;
      line-height: 22px;
      font-weight: lighter;
      margin-bottom: 13px;

      width: 100%;
      background-color: rgba(255, 255, 255, 0.05);
      padding: 20px 40px;
      border-radius: 5px;

      @media screen and (max-width: 1440px) {
        margin-bottom: 11px;
      }

      @media screen and (max-width: 1024px) {
        padding: 17px 31px;
        margin-bottom: 11px;
      }

      @media screen and (max-width: 768px) {
        padding: 20px 40px;
        margin-bottom: 9px;
      }

      @media screen and (max-device-width: 375px) and (max-width: 375px) {
        padding: 0 31px;
        margin-bottom: 10px;
        font-size: 0.875rem;
      }
    }

    .catalyst-info-text {
      font-family: $sofia;
      font-size: 0.875rem;
      line-height: 21px;
      font-weight: 500;
      border-radius: 5px;
      margin-bottom: 11px;

      width: 100%;
      background-color: rgba(255, 255, 255, 0.05);
      padding: 20px 40px;

      li {
        font-weight: 300;
        margin: 0;
      }
      @media screen and (max-width: 1024px) {
        padding: 17px 31px;
      }

      @media screen and (max-width: 768px) {
        padding: 20px 40px;
        margin-bottom: 9px;
      }
      @media screen and (max-device-width: 375px) and (max-width: 375px) {
        padding: 0 31px;
        margin-bottom: 10px;
        font-size: 0.875rem;
      }

    }
  }
}

