@import '../../../variables';

@mixin exploration-text-styles {
  font-family: $oswald;
  font-size: 1.75rem;
  text-align: center;
  margin-bottom: 60px;
  margin-top: 10px;
  text-transform: uppercase;

  @media screen and (max-width: 1024px) {
    font-size: 1.3125rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 20px;
  }

  @media screen and (max-device-width: 375px) and (max-width: 375px){
    font-size: 1.25rem;
    margin-bottom: 30px;
  }
}

.exploration-rewards-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -75px;
  @media screen and (max-device-width: 375px) {
    margin-top: -30.667vw;
  }
}

.total-planets-total-decoration {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.total-planets-total-decoration .line-separator {
  width: 808px;
  @media screen and (max-width: 1024px) {
    width: 59.180vw;
  }
  @media screen and (max-width: 768px) {
    width: 59.115vw;
  }
  @media screen and (max-device-width: 375px) and (max-width: 375px) {
    width: 100vw;
  }
}
.total-planets-total-decoration img {
  width: 67px;
  height: 34px;
  @media screen and (min-width: 376px) and (max-width: 768px) {
    width: 37px;
    height: 20px;
  }
}

.exploration-rewards-planets-counter {
  @include exploration-text-styles;
}

.exploration-launch-mission-display-text {
  @include exploration-text-styles;
}

.exploration-rewards-elements-container {
  @media screen and (min-width: 376px) and (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
  }

  @media screen and (max-width: 1024px) {
    @supports (-webkit-touch-callout: none) {
      margin-top: 80px;
    }
  }

  @media screen and (max-width: 376px) {
    @supports (-webkit-touch-callout: none) {
      margin-top: 30px;
    }
  }
}

.exploration-rewards-button-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Oswald", Helvetica, serif;
  font-size: 1.75rem;
  background-color: $pink;
  clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
  cursor: pointer;

  width: 271px;
  height: 48px;

  &.disabled {
    background-color: $disable-gray;
    color: $disable-gray-text;
    pointer-events: none;
  }

  @media screen and (max-width: 1024px) {
    width: 203px;
    height: 36px;
    font-size: 1.3125rem;
  }
  @media screen and (max-device-width: 768px) {
    width: 152px;
    height: 27px;
    font-size: 1rem;
    clip-path: polygon(5px 0, 0 5px, 0 calc(100% - 5px), 5px 100%, calc(100% - 5px) 100%, 100% calc(100% - 5px), 100% 5px, calc(100% - 5px) 0);
  }

}
