@import "src/variables";

.planets-filter-button{
  font-family: $oswald;
  font-size: 1.25rem;
  text-align: center;
  line-height: 50px;
  color: $white;
  background-color: $tab-gray;
  width: 100%;
  height: 50px;
  cursor: pointer;
  position: relative;
  overflow: visible;
  @media only screen and (max-width: 1920px) {
    height: 2.604vw;
    line-height: 2.604vw;
  }
  @media screen and (min-width: 1025px) and (max-width: 1440px) {
    height: 3.472vw;
    line-height: 3.472vw;
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    height: 4.883vw;
    line-height: 4.883vw;
    font-size: clamp(1.563vw,1.375rem, 2.148vw);
  }
  @media screen and (max-width: 768px) {
    height: 5.859vw;
    line-height: 5.859vw;
    font-size: clamp(1.302vw,1.375rem, 2.865vw);

  }
}

.planets-filter-button-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 240px;
  margin: 0 10px;
  height: 55px;
  @media only screen and (max-width: 1920px) {
    height: 2.865vw;
    width: 12.500vw;
    margin: 0 0.521vw;
  }
  @media screen and (min-width: 1025px) and (max-width: 1440px) {
    width: 12.986vw;
    height: 3.819vw;
    margin: 0 0.556vw;
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    width: 14.453vw;
    height: 5.371vw;
    margin: 0 0.586vw;
  }
  @media screen and (max-width: 768px) {
    width: 27.344vw;
    height: 6.510vw;
    margin: 0.977vw 1.172vw;
  }
}

.planets-filter-button-decoration{
  width: 100%;
  background-color: $gray-decoration;
  height: 5px;
}

.planets-filter-button-decoration.selected{
  background-color: $pink;
}


.planets-filter-button.selected{
  background-color: $white;
  color: $pink;
}
