@import "../../../../variables";

.button-view-on-opensea {
  position: relative;
  image-rendering: smooth;
  color: $white !important;
  font-family: $oswald;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 37px;
  text-align: center;
  padding: 0px !important;
  margin: 0 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  z-index: 100;
  margin-bottom: 2.476rem;
}

.button-border-view-open-sea {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px;
  background-color: #d22657;
  clip-path: polygon(
    7px 0,
    0 7px,
    0 calc(100% - 7px),
    7px 100%,
    calc(100% - 7px) 100%,
    100% calc(100% - 7px),
    100% 7px,
    calc(100% - 7px) 0
  );
}

.button-text-view-open-sea {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0px;
  background-image: linear-gradient(#fd2f69, #d42758);
  clip-path: polygon(
    7px 0,
    0 7px,
    0 calc(100% - 7px),
    7px 100%,
    calc(100% - 7px) 100%,
    100% calc(100% - 7px),
    100% 7px,
    calc(100% - 7px) 0
  );
}
