@import "../../../variables";
.buy-on-opensea-container {
  clip-path: polygon(6% 0%, 100% 0%, 94% 100%, 0% 100%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 178px;
  height: 37px;
  font-size: 16px;
  .buy-on-opensea {
    text-align: center;
    width: 176px;
    height: 35px;
    background-color: $light-ocean-blue;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: $oswald;
    color: $white;
    text-decoration: none;
    cursor: pointer;
  }

  .buy-on-opensea img {
    width: 20px;
    height: 17px;
    margin-right: 5px;
  }

}
