@import '../../../variables';

.dashboard-summary-container {
  position: sticky;
  z-index: 2;
  background-color: $black;
  top: 0;
  padding: 0.5rem 0;

  @media screen and (min-width: 1025px) {
    top: 75px;
  }

  .dashboard-summary {
    background-color: $black-night-bg;
    padding: 0.5rem 1rem;
  }
}

