@import "../../variables";

.set-carousel-design {

  .modal-content {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}


.content-of-carousel-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 80%);
  width: 920px;
  height: 600px;
  border: 2px solid white;
  position: relative;
}

.carousel-modal-title-element {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
}

.carouser-title-content {
  font-size: 36px;
  font-family: $oswald;

}

.carousel-container-for-design {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.carousel-close-modal-button {
  position: absolute;
  right: 17px;
  top: 17px;
  cursor: pointer;
}
.carousel-container-for-design .carousel.carousel-slider{
  height: 100%;
}

.carousel-container-for-design  .carousel.carousel-slider .control-arrow:before{
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  margin-bottom: 50px;
}
.carousel-container-for-design .carousel .control-next.control-arrow:before{
  border-left: 20px solid #fff;
}

.carousel-container-for-design  .carousel .control-prev.control-arrow:before{
  border-right: 20px solid #fff;
}
.carousel-container-for-design  .carousel .control-dots .dot{
  margin: 0 5px;
  width: 11px;
  height: 11px;
}

.carousel-container-for-design  .carousel .slider-wrapper{
  height: 100%;
}
.carousel-container-for-design  .carousel .slider-wrapper.axis-horizontal .slider{
  height: 100%;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow{
  opacity: 1 !important;
}
.carousel-component-design {
  width: 100%;
  height: 100%;
}

.carousel-element-manager {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .carousel .control-dots{
    margin: 0.977vw 0;
  }
  .content-of-carousel-content {
    width: 57.031vw;
    height: 55.566vw
  }
  .carouser-title-content {
    font-size: 3.516vw;
  }
  .carousel-container-for-design {
    padding-right: 0.977vw;
    padding-left: 0.977vw;
    height: 49.707vw;
  }
  .carousel-close-modal-button {
    right: 1.660vw;
    top: 1.660vw;
  }
  .carousel-container-for-design  .carousel .control-dots .dot {
    margin: 0 0.391vw;
    width: 0.879vw;
    height: 0.879vw;
  }
  }

@media screen and (min-width: 376px) and (max-width: 768px) {
  .carousel .control-dots{
    margin: 1.302vw 0;
  }
  .content-of-carousel-content {
    width: 76.042vw;
    height: 74.089vw;
  }
  .carouser-title-content {
    font-size: 4.688vw;
  }
  .carousel-container-for-design {
    padding-right:1.302vw;
    padding-left: 1.302vw;
    height: 66.276vw;
  }
  .carousel-close-modal-button {
    right: 2.214vw;
    top: 2.214vw;
  }
  .carousel-container-for-design  .carousel .control-dots .dot {
    margin: 0 0.521vw;
    width: 1.172vw;
    height: 1.172vw;
  }
  .content-of-carousel-content .carousel .control-dots {
    margin: 1.302vw 0;
  }
  }

@media screen and (max-width: 375px) {
  .carousel .control-dots{
    margin: 2.667vw 0 !important;
  }
  .content-of-carousel-content {
    width: 87.200vw;
    height:169.067vw;
  }
  .carouser-title-content {
    font-size: 6.933vw;
  }
  .carousel-container-for-design {
    padding-right:2.933vw;
    padding-left: 2.933vw;
    height: 157vw;
  }
  .carousel-close-modal-button {
    right: 2.933vw;
    top: 2.933vw;
  }
  .carousel-container-for-design  .carousel .control-dots .dot {
    margin: 0 1.067vw;
    width:2.400vw;
    height: 2.400vw;
  }
}

@media screen and (max-width: 1024px) {
  .carousel.carousel-slider .control-arrow{
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .modal-body{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

