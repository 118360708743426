@import "../../../../../../../variables";
.counter-button-design{
  margin-left: 5px;
  margin-right: 5px;
  width: 33px;
  height: 26px;
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  background-color: $pink;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.cbe-center-text{
margin-bottom: 3px;
}
.counter-button-design-inactive{
  @extend .counter-button-design;
  background-color: $stack;
  color:rgba(255, 255, 255, 0.5);
  cursor: auto;
}
