@import 'src/variables';
.footer-main-container{
  width: 100%;
  height: 961px;
  background-color: transparent;
  display: flex !important;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  overflow: hidden;
  clip-path: $section-polygon-top-inside-cut-1920;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .footer-main-container {
    clip-path: $section-polygon-top-inside-cut-1024;
  }
  .empty-div-necessary{
    height: 2px;
    width: 100px;
    background-color: transparent;
  }
}
@media screen and (min-width: 376px) and (max-width: 768px) {
  .footer-main-container{
    height: 106.250vw;
    clip-path: $section-polygon-top-inside-cut-768;
  }
}
@media screen and (max-device-width: 375px) {
  .footer-main-container{
    height: initial;
    clip-path: $section-polygon-top-inside-cut-375;
  }
}
