@import 'src/variables';

.subscribe-component-main-container {
  width: 100%;
  height: 85%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


.subscribe-element-background-big-img {
  position: absolute;
  object-fit: cover;
  object-position: 100% 66%;
  height: 100%;
  z-index: -1;
  min-width: 1800px;
  width: 100%;
  display: none;
}

.subscribe-element-background-small-img {
  position: absolute;
  object-fit: cover;
  object-position: 100% 66%;
  height: 100%;
  z-index: -1;
  min-width: 1800px;
  width: 100%;
  display: none;
}


@keyframes fade-in-animation {
  from {
    opacity: 0;
    transform: translate(0, 100px)
  }
  to {
    opacity: 1;
    transform: translate(0, 0)
  }
}

@keyframes circle-fade-in-animation {
  from {
    opacity: 0;
    transform: translate(0, 50px)
  }
  to {
    opacity: 1;
    transform: translate(0, 0)
  }
}


.subscribe-component-container {
  height: 100%;
  width: 469px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  z-index: 1;
}

.adjust-crown-img {
  width: 100%;
}

.subscribe-title {
  height: 106px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.subscribe-area-design {
  height: 160px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.media-link-buttons {
  height: 118px;
  width: 100%;
  max-width: 298px;
  margin-bottom: 126px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.subscribe-title-text {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
}

.subscribe-title-text-size {
  color: white;
  margin: 0px;
  font-size: 50px;
  font-family: $oswald;
}

.control-the-input-value-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.subscribe-title-slogan {
  color: white;
  margin: 0px;
  font-size: 20px;
  line-height: 23px;
  font-family: $sofia;
  letter-spacing: 2px;
}

.input-email-value {
  text-decoration: none;
  outline: none;
  border: 0px;
  height: 42px;
  width: 100%;
  clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
  font-family: $sofia;
  color: $stack;
  padding-left: 30px;
  padding-right: 170px;
}

.input-email-value::placeholder {
  font-size: 16px;
  font-family: $sofia;
  color: $stack;
}

.subscribe-sign-up-button {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 158px;
  height: 42px;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Oswald";
  background-color: $pink;
  clip-path: polygon(7px 0, 0 7px, 0 calc(100% - 7px), 7px 100%, calc(100% - 7px) 100%, 100% calc(100% - 7px), 100% 7px, calc(100% - 7px) 0);
  cursor: pointer;
  transition: all .2s ease;
  transform: scale(1.0);
  color: white;
  outline: none;
  border: none;
}

.mobile-props-child{
  display: none;
}

.subscribe-sign-up-button:hover {
  transform: scale(1.1);
  transition: all .2s ease;
}

.email-area-input {
  position: relative;
  width: 100%;
}

.main-container-for-media-links {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 55px;
}

.size-container-for-media-links {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  transition: all .2s ease;
}

.icons-smoot-transition:hover {
  transform: scale(1.1);
  transition: all .2s ease;
}


.subscribe-background {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-x: hidden;
  z-index: -1;
}


@media screen and (min-width: 1441px) {
  .subscribe-element-background-big-img {
    display: block;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .subscribe-element-background-big-img {
    display: block;
    transform: translate(-188px, 0px);
  }
  .subscribe-component-container {
    min-width: initial;
  }
  .subscribe-area-design {
    height: 11.111vw;
  }
  .subscribe-title-text-size {
    font-size: 3.472vw;
  }
  .subscribe-title-slogan {
    font-size: 1.389vw;
  }
  .input-email-value {
    height: 2.917vw;
    padding-left: 2.083vw;
    padding-right: 11.806vw;
    padding-bottom: 3px;
  }
  .input-email-value::placeholder {
    font-size: 1.111vw;
  }
  .subscribe-sign-up-button {
    width: 10.972vw;
    height: 2.917vw;
    font-size: 1.389vw;
  }
  .subscribe-component-container {
    width: 100%;
    max-width: 32.569vw;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .subscribe-element-background-big-img {
    display: block;
    transform: translate(-307px, 0px);
  }
  .subscribe-component-container {
    width: 389px;
    max-width: 389px;
    margin-right: 10px;
  }
  .media-link-buttons {
    margin-bottom: 177px;
    justify-content: initial;
  }
  .subscribe-title-text-size {
    font-size: 4.883vw;
  }
  .subscribe-title-slogan {
    font-size: 1.953vw;
  }
  .input-email-value {
    height: 4.102vw;
    padding-left: 2.930vw;
    padding-right: 16.602vw;
  }
  .subscribe-sign-up-button {
    width: 15.430vw;
    height: 4.102vw;
    font-size: 1.953vw;
  }

}


@media screen and (min-width: 376px) and (max-width: 768px) {
  .subscribe-element-background-big-img {
    display: block;
    position: absolute;
    object-position: -41.276vw -27.344vw;
    height: 117.188vw;
    z-index: -1;
    min-width: initial;


  }
  .media-link-buttons {
    margin-bottom: 7.682vw;
  }
  .subscribe-component-container {
    max-width: 50.651vw;
    width: 50.651vw;
  }
  .subscribe-title-text-size {
    font-size: 6.510vw;
  }
  .subscribe-title-slogan {
    font-size: 2.604vw;
  }
  .input-email-value {
    height: 5.469vw;
    padding-left: 3.906vw;
    padding-right: 22.135vw;
    font-size: 2.083vw;
    clip-path: polygon(0.911vw 0, 0 0.911vw, 0 calc(100% - 0.911vw), 0.911vw 100%, calc(100% - 0.911vw) 100%, 100% calc(100% - 0.911vw), 100% 0.911vw, calc(100% - 0.911vw) 0);
  }
  .input-email-value::placeholder {
    font-size: 2.083vw;
    font-family: $sofia;
  }

  .subscribe-sign-up-button {
    width: 20.573vw;
    height: 5.469vw;
    font-size: 2.604vw;
    clip-path: polygon(0.911vw 0, 0 0.911vw, 0 calc(100% - 0.911vw), 0.911vw 100%, calc(100% - 0.911vw) 100%, 100% calc(100% - 0.911vw), 100% 0.911vw, calc(100% - 0.911vw) 0);
  }
  .footer-subscribe-parenthesis {
    width: 8.594vw;
    height: 13.802vw;

  }
  .subscribe-area-design {
    height: 20.833vw;
  }
  .subscribe-title {
    height: 13.802vw;
  }
  .media-link-buttons {
    height: initial;
  }

  .no-mobile-props-child{
    margin-right: -20px;
  }
  .icons-smoot-transition{
    height: 2.083vw;
  }
  .main-container-for-media-links{
    height:7.161vw;
  }
  .size-container-for-media-links{
    width: 39.063vw;
  }
  .adjust-crown-img{
    width: 39.063vw;
  }

}

@media screen and (max-device-width: 375px) {
  .subscribe-element-background-big-img {
    display: block;
    position: absolute;
    object-fit: cover;
    object-position: 35% 35%;
    height: 100%;
    z-index: -1;
    min-width: initial;
    object-fit: cover;
    width: 100%;
  }
  .no-mobile-props-child{
    display: none;
  }

  .subscribe-component-main-container{
    flex-direction: column;
  }
  .mobile-props-child{
    display: flex;
  }
  .subscribe-component-container{
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    margin-top: 50px;
  }
  .control-the-input-value-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .subscribe-sign-up-button{
    position: initial;
    margin-top: 9px;
    width: 303px;
  }
  .input-email-value{
    padding: 0px;
    text-align: center;
    width: 303px;
  }
  .media-link-buttons{
    margin: 0px;
  }
  .subscribe-area-design{
    height: initial;
    margin-top: 59px;
    margin-bottom: 3px;
  }
}
