@import '../../../variables';

.purchase-step-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 34px;
}

.purchase-step-button{
  display: flex;
  justify-content: center;
  align-items: center;
  background: $pink;
  border-color: $pink;
  border-radius: 0;
  clip-path: polygon(5% 0, 100% 0, 95% 100%, 0% 100%);
  font-family: 'Oswald', Helvetica;
  color: white;
  cursor: pointer;
  width: 190px;
  height: 34px;
  //margin-bottom: 20px;
  text-transform: uppercase;
}

.purchase-step-button:hover,
.purchase-step-button:active,
.purchase-step-button:focus {
  background: $dark-pink;
  border-color: $dark-pink;
}

.purchase-step-button:disabled{
  background: $stack;
  border-color: $stack;
  cursor: initial;
}

.purchase-step-button-complete{
  @extend .purchase-step-button;
  background: #31AA5B;
  cursor: initial;
}

.purchase-step-check-icon {
  width: 30px;
}
