@import "../../../variables";

.planet-panel-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 1223px;
  margin-bottom: 90px;
  padding: 0 25px;

  @media only screen and (max-width: 1920px) {
    width: 63.698vw;
    margin-bottom: 4.688vw;
    padding: 0 1.302vw;
  }
  @media screen and (min-width: 1025px) and (max-width: 1440px) {
    width: 84.931vw;
    margin-bottom: 6.250vw;
    padding: 0 1.736vw;
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    width: 87.891vw;
    margin-bottom: 8.301vw;
    padding: 0 2.441vw;
  }
  @media screen and (min-width: 376px) and (max-width: 768px) {
    width: 91.146vw;
    margin-bottom: 11.068vw;
    padding: 0 2.865vw;
  }
  @media screen and (max-device-width: 375px) {
    display: none;
  }
}

.planet-panel-container.mobile {
  display: none;
  @media screen and (max-device-width: 375px) {
    display: flex;
  }
}

.planet-panel-title {
  font-family: $oswald;
  font-size: 2.8125rem;
  text-align: center;

  @media only screen and (max-width: 1920px) {
    font-size: 2.8125rem;
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    font-size: 2.25rem;
  }
  @media screen and (min-width: 376px) and (max-width: 768px) {
    font-size: 2.25rem;
  }
}

.select-all-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 15px;
  right: 54px;
  @media screen and (min-width: 376px) and (max-width: 768px) {
    position: relative;
    top: unset;
    right: unset;
    margin-top: 25px;
  }
}

.select-all-text {
  font-family: $oswald;
  font-size: 1.5625rem;
  margin-right: 18px;
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    font-size: 1.25rem;
  }
  @media screen and (min-width: 376px) and (max-width: 768px) {
    font-size: 1.125rem;
  }
}

.select-all-text.unchecked {
  color: $gray-slider;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  @media screen and (min-width: 376px) and (max-width: 1024px) {
    width: 40px;
    height: 25px;
  }
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $black;
  -webkit-transition: .4s;
  transition: .4s;
  border: 2px solid $gray-slider;
}

input:checked + .slider {
  background-color: $white;
  border: 2px solid $white;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  background-color: $gray-slider;
  bottom: 2.5px;
  -webkit-transition: .4s;
  transition: .4s;
  @media screen and (min-width: 376px) and (max-width: 1024px) {
    width: 19px;
    height: 19px;
    left: 2px;
    bottom: 1px;
  }
}

input:checked + .slider:before {
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-color: $black;
  @media screen and (min-width: 376px) and (max-width: 1024px) {
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
}

.slider.round {
  border-radius: 34px;
  height: 33px;
  @media screen and (max-width: 320px) {
    height: 30px;
  }
  @media screen and (max-width: 280px) {
    height: 26px;
  }
}

.slider.round:before {
  border-radius: 50%;
}

.planets-panel {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
  grid-row-gap: 60px;
  width: 100%;
  margin-top: 60px;

  @media only screen and (max-width: 1920px) {
    grid-row-gap: 3.125vw;
    margin-top: 3.125vw;
  }

  @media screen and (min-width: 1025px) and (max-width: 1440px) {
    grid-row-gap: 4.167vw;
    margin-top: 4.167vw;
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
    grid-row-gap: 3.906vw;
    margin-top: 3.516vw;
  }
  @media screen and (min-width: 376px) and (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
    grid-row-gap: 33px;
    margin-top: 8.594vw;
  }
}

@media screen and (max-device-width: 375px) {
  .planet-panel-header-decoration {
    width: 5px;
    height: 100%;
    background-color: $pink;
  }
  .planet-panel-container {
    padding: 0;
    width: 100%;
    margin-bottom: 5.333vw;
  }
  .planet-panel-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 16vw;
    width: 100%;
    background-color: transparent;
  }
  .planet-panel-header.open {
    background-color: $pink;
  }
  .planet-panel-header-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 79.467vw;
    margin-right: 12vw;
    padding-left: 35px;
  }
  .planet-panel-dropdown-button.right {
    transform: rotate(-90deg);
  }
  .planet-panel-dropdown-button {
    width: 4.800vw;
    height: 2.667vw;
  }
  .planet-panel-title {
    font-size: 1.5rem;
  }
  .planets-panel {
    width: 95.333vw;
    grid-template-columns: repeat(auto-fit, minmax(33%, 33%));
    grid-row-gap: 6px;
    margin-top: 5.333vw;
  }
  .switch {
    width: 35px;
    height: 22px;
    margin-top: 5px;
  }
  .slider:before {
    width: 16px;
    height: 16px;
    left: 2px;
    bottom: 1px;
  }
  input:checked + .slider:before {
    -ms-transform: translateX(14px);
    transform: translateX(14px);
  }
  .select-all-container {
    margin-top: 4.800vw;
    position: relative;
    top: unset;
    right: unset;
  }
}

