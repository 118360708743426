@import "../../../../variables";

.astrafite-token-info-container {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  width: 100%;
}

.astrafite-token-info-group {
  &:first-child {
    margin-right: 1.5rem;
  }

  &:nth-child(2) {
    cursor: pointer;
  }

  &:last-child {
    margin-left: 1.5rem;
  }
}
.astrafite-token-info-box {
  position: relative;
  width: 13.261rem;
  height: 2.299rem;
  &-border {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: $pink;
    border-top: 1px solid $pink;
    border-bottom: 1px solid $pink;
    clip-path: polygon(
      7px 0,
      0 7px,
      0 calc(100% - 7px),
      7px 100%,
      calc(100% - 7px) 100%,
      100% calc(100% - 7px),
      100% 7px,
      calc(100% - 7px) 0
    );
  }
  &-value {
    font-family: "Oswald";
    font-style: normal;
    font-weight: 400;
    font-size: 17.4388px;
    line-height: 26px;

    /* identical to box height */
    text-align: center;

    text-align: center;
    background: black;
    padding: 0.22rem;
    clip-path: polygon(
      7px 0,
      0 7px,
      0 calc(100% - 7px),
      7px 100%,
      calc(100% - 7px) 100%,
      100% calc(100% - 7px),
      100% 7px,
      calc(100% - 7px) 0
    );
    width: 99%;
  }
  &-label {
    font-family: "Oswald";
    font-style: normal;
    font-weight: 400;
    font-size: 1.09rem;
    line-height: 26px;
    text-align: center;

    /* identical to box height */
    text-transform: uppercase;

    color: #878787;
  }
  .copy-hover-element {
    bottom: 44px;
    left: 0px;
  }
  &:hover .copy-hover-element {
    display: flex;
  }
}

.astrafite-token-info-label {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 400;
  font-size: 1.09rem;
  line-height: 26px;
  text-align: center;

  /* identical to box height */
  text-transform: uppercase;

  color: #878787;
}

@media screen and (max-width: 765px) {
  .astrafite-token-info-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    justify-content: center;
    width: 100%;
  }

  .astrafite-token-info-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.67rem;
    &:first-child {
      margin-right: 0;
    }
    > .astrafite-token-info-box {
      margin: 0 auto;
      width: 10.599rem;
      &-label {
        margin-top: 0.58rem;
      }
    }
    &:last-child {
      margin-left: 0;
    }
  }
}
