@import "../../../variables";

.generative-planet-mintpass-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 65px;
  margin-top: 60px;
  @media screen and (max-device-width: 375px) {
    margin-top: 25px;
    margin-bottom: 45px;
  }
}

.generative-planet-mintpass-slider{
  display: none;
  width: 600px;
  @media screen and (max-device-width: 375px) {
    display: block;
  }
}


.generative-planet-mintpass-title{
  font-family: $oswald;
  font-size: 2.8125rem;
  margin-bottom: 8px;

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    font-size: 2.375rem;
  }
  @media screen and (min-width: 376px) and (max-width: 768px) {
    font-size: 1.5rem;
  }
  @media screen and (max-device-width: 375px) {
    font-size: 1.5rem;
  }
}

.generative-planet-mintpass-helper{
  font-family: $oswald;
  font-size: 1.25rem;
  margin-bottom: 45px;
  color: $very-light-gray;
  cursor: pointer;
  @media screen and (max-device-width: 375px) {
    font-size: 1.125rem;
    margin-bottom: 10px;
  }
}

.generative-planet-mintpass-grid{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 376px) and (max-width: 768px) {
    flex-wrap: wrap;
    width: 72.917vw;
    gap: 40px 22px;
  }
  @media screen and (max-device-width: 375px) {
    display: none;

    &.batch {
      display: block;
    }
  }


}

@supports (-webkit-touch-callout: none) {
  @media screen and (min-width: 376px) and (max-width: 768px) {
    .generative-planet-mintpass-helper{
      margin-bottom: 0;
    }
    .generative-planet-mintpass-grid {
      gap: 0 0;
    }
    .generative-planet-border {
      margin: 40px 15px;
    }
  }

}

