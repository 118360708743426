@import "../../../../variables";

.other-nft-main-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 20px 0 20px;
}

.token-id-container{
  font-family: $oswald;
  font-size: 24px;
  line-height: 35px;
  color: $stack;
  margin-bottom: 16px;
  text-align: center;
}
.token-id-container .token-id-number{
  color: $white;
}

.other-nft-img{
  width: 282px;
  height: 282px;
  margin-bottom: 19px;
}

.view-other-nft-button{
  width: 172px;
  height: 46px;
}

.view-other-nft-button .claim-button-label,
.view-other-nft-button .claim-rewards-button-container{
  font-size: 21px;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .other-nft-img{
    width: 232px;
    height: 228px;
    margin-bottom: 16px;
  }
  .token-id-container{
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 12px;
  }
  .view-other-nft-button{
    width: 134px;
    height: 35px;
  }
  .view-other-nft-button .claim-button-label,
  .view-other-nft-button .claim-rewards-button-container{
    font-size: 17px;
    margin-bottom: 0;
  }

}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .other-nft-img{
    width: 16.602vw;
    height: 16.602vw;
    margin-bottom: 1.172vw;
  }
  .other-nft-main-container {
    margin: 0 2.441vw 0 2.441vw;
  }
  .token-id-container{
    font-size: 1.367vw;
    line-height: 2.051vw;
    margin-bottom: 0.781vw;
    width: 16.602vw;
  }
  .view-other-nft-button{
    width: 10.156vw;
    height: 2.734vw;
  }
  .view-other-nft-button .claim-button-label,
  .view-other-nft-button .claim-rewards-button-container{
    font-size: 1.270vw;
    margin-bottom: 0;
    line-height: 1.855vw;
  }
}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .other-nft-main-container {
    margin: 0 3.125vw 0 3.125vw;
  }
    .other-nft-img{
    width: 22.526vw;
    height: 22.526vw;
    margin-bottom: 1.563vw;
  }
  .token-id-container{
    font-size: 2.083vw;
    line-height: 3.125vw;
    margin-bottom: 1.563vw;
    width: 22.526vw;
  }
  .view-other-nft-button{
    width: 13.672vw;
    height: 3.646vw;
  }
  .view-other-nft-button .claim-button-label,
  .view-other-nft-button .claim-rewards-button-container{
    font-size: 1.693vw;
    margin-bottom: 0;
    line-height: 2.604vw;
  }

}

@media screen and (max-width: 375px) {
  .other-nft-main-container {
    margin: 0 3.733vw 0 3.733vw;

  }
  .other-nft-img{
    width: 39.733vw;
    height: 39.733vw;
    margin-bottom: 2.667vw;
  }
  .token-id-container{
    font-size: 4.267vw;
    line-height:5.333vw;
    margin-bottom: 3.200vw;
    width: 30.733vw;
  }
  .view-other-nft-button{
    width: 24vw;
    height: 6.400vw;
  }
  .view-other-nft-button .claim-button-label,
  .view-other-nft-button .claim-rewards-button-container{
    font-size: 4.267vw;
    margin-bottom: 0;
    line-height: 6.400vw;
  }
}
